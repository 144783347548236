<template>
  <a-drawer
    :width="'65%'"
    :height="'100%'"
    :placement="placement"
    :visible="visible"
    :closable='closable'
    :headerStyle="{backgroundColor: '#F0F5FF',padding: '10px 15px'}"
  >
  <template #title>
    <div class="drawer-title" v-if="name">
      {{name}}
    </div>
  </template>
    <provision v-show="visible"  @nextpage="querenquanbuneirong" @title="title" :hasSign="hasSign" ></provision>
  </a-drawer>
</template>

<script>
// import {routerJump} from '../../utils/product_process'
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import provision from './public/provision'
// import VuePdfEmbed from 'vue-pdf-embed'
export default defineComponent({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    provision
  },
  setup() {
    const placement = ref('bottom')
    const state = reactive({
      closable: false,
      visible: false,
      isyes: false,
      name: '请仔细阅读一下文件，充分理解全部内容并确认。',
      hasSign: false,
      yesNO: true,
      yuedufenshu: 0,
      // 用于条款展示页面
      tiankuanList: [],
      tiankuan: []
    })
    const showDrawer = function (oldData = [], value) {
      state.visible = true
      state.isyes = true
      state.tiankuan = oldData
      state.hasSign = value
    }
    const router = useRouter()
    const userDrawerRef = ref()
    console.log(userDrawerRef)
    const nextPage = function () {
      router.push({
        path: '/informationInput'
      })
    }
    const title = function (value) {
      document.title = value
      state.name = ''
    }
    const querenquanbuneirong = function () {
      state.visible = false
      // emit('jumpFun')
      // routerJump()
      // let i = 1
      // router.push({
      //   path: '/payStatus',
      // })
    }
    return {
      ...toRefs(state),
      placement,
      showDrawer,
      nextPage,
      querenquanbuneirong,
      title
    }
  }
})
</script>

<style scoped>
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background-color: #e0e0e0;
  color: #00a0e9;
  font-size: 16px;
  font-weight: bold;
  /*padding-top: 10px;*/

}

.div {
  top: 0;
  position: fixed;
  z-index: 999;
}

.vue-pdf-embed {
  text-align: center;
}

.tab-bar2 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 49px;
  z-index: 100;
}

table td {
  text-align: left;
  padding-left: 8px;
  width: 80%;
}

table tr:nth-child(2n) {
  background-color: #eeeeee;
}
.drawer-title{
  text-align: left;
  font-size: .85rem;
  color: #0079FE !important;
}
</style>
