<template>
  <div>
    <div id="confirm_info" v-cloak>
      <div class="heard">
        {{ tbPerson }}正在为您(姓名{{ bbPerson }})投保{{ productName }}产品，保障情况如下，请仔细查看确认
      </div>
<!--      <div class="confirm_info_item" v-for='(insureSing,idx) in dataList' :key="'insuredConfim'+idx">-->
      <div  v-if="dataList&&dataList.length >0">
        <div class="confirm_info_item" v-for='(item, idx) in dataList[currIndex-1].insureSing' :key="'ic'+idx">
          <a-row class="confirm_info_item_info"  justify="space-around" align="middle" v-if="item.val">
            <a-col :span="6">
              <span class="left-lab">{{ item.key }}</span>
            </a-col>
            <a-col :span="18" class="right-lab">
              <span >{{ item.val }}</span>
            </a-col>
          </a-row>
        </div>
      </div>

<!--      </div>-->
      <div class="confirm_info_tip">
        <!-- {{productName}}含有以死亡作为给付条件的保险责任，{{getMoney?'受益人为'+getMoney:'未指定受益人'}}。 -->
        我，姓名{{ bbPerson }}已确认作为被保人充分了解本保险合同内容，同时同意{{ tbPerson }}为我订立本保险合同。
      </div>
      <div class="confirm_info_btn" @click="saveProduct">确认本页内容并同意继续投保</div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import router from '../../router'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'
import store from '../../store'
import {getAge} from '../../utils/utlis'
import {getRouterJumpBean} from '../../utils/product_process'

export default {
  name: 'inusredsConfimPage',
  setup() {
    const state = reactive({
      tradeNo: router.currentRoute.value.query.tradeNo,
      shareId: router.currentRoute.value.query.shareId,
      tbPerson: '习近平',// 投保人
      bbPerson: '伊丽莎白二世',// 被保人
      productName: '恒荣国家分裂险种',//产品名称
      // getMoney: '奥巴马',// 受益人
      currIndex: 0,// 当前阅读第几个被保人
      dataList: [],//当前被保人列表
    })
    const getInfoByCache = () => {
      if (store.getters['insured/getSaveDate'].contInfo=== {}) {
        // 根据接口获取riskLisk数据存入缓存
        request(getExtendUri('getCacheParameters'), {
          tradeNo: state.tradeNo
        }).then(res => {
          console.log('getCacheParameters:', res.result)
          store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
          store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
        })
      }

    }
    onMounted(async () => {
      await getInfoByCache()
      // 保单信息
      let _insuredata =  store.getters['insured/getSaveDate'].contInfo
      if (_insuredata) {
        state.tbPerson = _insuredata.applicant.appntName
        state.productName =  _insuredata.insuranceInfo.productName || ''
        state.dataList = _insuredata.insureds
        // 去除被保人中投保人  和超过18岁的
        state.dataList = state.dataList.filter(item => {
          if (!(item.relationToAppnt === '01')) {
            // 返回不是本人的
            return getAge(item.birthday, 0).age[0] > 17
          } else {
            return false
          }
        })
        state.dataList.map((item) => {
          let insureSing = [
            {
              key: '产品名称',
              val: state.productName
            },
            {
              key: '缴费期间',
              val: _insuredata.insuranceInfo.feeYear
            },
            {
              key: '本期保费',
              val: item.premium
            }
          ]
          item.insureSing = insureSing
          return item
        })
        getPersonInfo()
      }
    })

    const getPersonInfo = (index) => {
      if (index) {
        state.currIndex = 1 + index
      } else {
        state.currIndex = 1
      }
      if (state.currIndex <= state.dataList.length) {
        let item = state.dataList[index||0]
        state.bbPerson = item.name
      }
    }
    const saveProduct = () => {
      // 判断是不是最后一个被保人信息
      if (state.dataList.length - state.currIndex == 0) {
        // 跳转确认页
        goJump()
      } else {
        // 跳转下个被保人
        getPersonInfo(state.currIndex)
      }
    }
    const goJump = async () => {
      const bean = await getRouterJumpBean(state.shareId,state.tradeNo)
      console.info('goJump --- 999', bean)
      if (bean) {
        router.push({
          path: bean.path,
          query: {
            tradeNo: bean.tradeNo,
            shareId: bean.shareId,
            sort:bean.sort,
            id: bean.id
          }
        })
      }
    }
    return {
      ...toRefs(state),
      saveProduct,
    }
  }
}
</script>

<style scoped>
.heard {
  width: 100%;
  background-color: #F0F5FF;
  padding: .4rem .9rem;
  font-size: .9rem;
  color: #0079FE;
  line-height: 1.22rem;
  text-align: justify;
}

.confirm_info_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: .6rem;
  background-color: #ffffff;
  padding: 0 .9rem;
  font-size: .9rem;
  color: #666666;
}

.confirm_info_item_info {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: .03rem solid #DEDEDE;
}

.confirm_info_item_info:last-child {
  border-bottom: none;
}

.confirm_info_tip {
  font-size: .8rem;
  color: #999999;
  padding: .1rem .9rem;
  text-align: justify;
}

.confirm_info_btn {
  margin: 0 auto;
  width: 92%;
  line-height: 3rem;
  background: #0079FE;
  border-radius: .25rem;
  font-size: .96rem;
  color: #ffffff;
  text-align: center;
  margin-top: 3rem;
}

.left-lab {
  text-align: left;
}

.right-lab {
  text-align: right;
}

:deep(.ant-col-18){
  display: block;
  max-width: 75%;
}
</style>
