<template>
<div></div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {InsuranceElementBean} from './bean/InsuranceElementBean'

export default {
  name: 'riskLayout',
  setup(){
    const state = reactive({
      bean: {}
    })
    const setbean = () => {
      state.bean = new InsuranceElementBean(1,1,1,1)
      console.log(state.bean.toString())
    }
    return {
      ...toRefs(state),
      setbean
    }
  }
}
</script>

<style scoped>

</style>
