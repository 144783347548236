<template>
  <div>
    <div v-for="item in detailList" :key="item.value">
      <div class="title">{{item.label}}</div>
      <div v-if="item.list && item.list.length > 0">
        <div class="img_class">
          <div v-for="(ele,index) in item.list" :key="index" :class="index > 1 ? 'img_m_t' : ''" >
            <img :src="ele.label? ele.label : ele.imageUrl" alt="没了, 重新上传哇">
          </div>
        </div>
      </div>
    </div>
<!--     v-if="relationship === '1'" -->
    <div v-if="relationship === '1'">
      <div class="title">投被保人关系证明影像</div>
      <div class="prove_class">
        <div class="displayType" v-for="rem in relationshipList" :key="rem.value">
          <upload-file-new @click="handelClick(rem)" @getFileList="handelDel" :key="time" filetype="img"  @fileChange="uploadCosFileChange" listType="picture-card" num="1"  ></upload-file-new>
        </div>
        <div class="add_class" v-if="relationshipList.length < 3">
          <div class="add_class_img">
            <img @click="handelSaveProve" src="../../../public/anysign/image/add.png" alt="没了, 重新上传哇">
          </div>
          <div>新增</div>
        </div>
      </div>
    </div>
<!--    v-if="insuranceReminder === '1'"-->
    <div style="margin-bottom: 6rem;"  v-if="insuranceReminder === '1'">
      <div class="title">投保提示书影像</div>
      <div class="prove_class">
        <div class="displayType" v-for="rem in reminderList" :key="rem.value">
          <upload-file-new @click="handelClick(rem)" :placeholder="rem.placeholder" @getFileList="handelDelReminder" :key="time" filetype="img"  @fileChange="uploadFileChangeReminder" listType="picture-card" num="1"  ></upload-file-new>
        </div>
      </div>
    </div>
    <div  v-if="isConfirmation === '1' && confirmationDetail.relationshipList.length">
      <div style="min-height: 350px;">
        <div class="title">投被保人关系证明影像</div>
        <div class="prove_img_class">
          <div v-for="item in confirmationDetail.relationshipList" :key="item.value">
            <img :src="item.imageUrl" alt="没了, 重新上传哇">
          </div>
        </div>
      </div>
    </div>
    <div  v-if="isConfirmation === '1' && confirmationDetail.reminderList.length">
      <div style="min-height: 350px;">
        <div class="title">投保提示书影像</div>
        <div class="prove_img_class">
          <div v-for="item in confirmationDetail.reminderList" :key="item.value">
            <img :src="item.imageUrl" alt="没了, 重新上传哇">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from 'vue'
import Emblem from '../../assets/img/emblem.png'
import uploadFileNew from './public/hrhb_upload'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    uploadFileNew
  },
  props:{
    relationshipList: {
      type: Array,
      default: ()=> []
    },
    reminderList: {
      type: Array,
      default: ()=> []
    },
    relationship: {
      type: String,
      default: ()=> ''
    },
    insuranceReminder: {
      type: String,
      default: ()=> ''
    },
    isConfirmation:{
      type: String,
      default: ()=> ''
    },
    confirmationObj:{
      type: Object,
      default: ()=> {}
    }
  },
  emits: ['update:relationshipList', 'update:reminderList'],
setup(props, {emit}){
  const state = reactive({
    isConfirmation: props.isConfirmation,
    confirmationDetail: props.confirmationObj,
    detailList: [
      {value: '1', label:'投保人证件影像', type: ['applicant'], list: [], isShow: true},
      {value: '2', label:'被保人证件影像', type: ['insureds'], list: [], isShow: true},
      {value: '3', label:'受益人证件影像', type: ['beneficiarys'], list: [], isShow: true},
      {value: '4', label:'银行卡影像', type: ['contract', 'payment'], list: [], isShow: true},
      {value: '5', label:'健康资料', type: ['health'], list: [], isShow: true},
    ],
    imgValue: Emblem,
    relationshipList:[{value: new Date().getTime(), label: ''}],
    reminderList: [
      {value: '1', label:'',placeholder:'签署投保提示书照片', },
      {value: '2', label:'',placeholder:'投保提示书正面'},
      {value: '3', label:'',placeholder:'投保提示书反面'},
    ],
    clickData: {},

  })
  const showData = reactive({
    time:new Date().getTime(),
    showImg: [],
    // 是否上传关系证明
    relationship: props.relationship || '',
    // 是否上传投保提示书
    insuranceReminder: props.insuranceReminder || '',

  })


  // 获取上传的图片信息
  const getImgList = (data = {}) =>{
    if(JSON.stringify(data) !== '{}') {
      for (const list of Object.values(data)) {
        for (const item of list) {
          for (const [key,value] of Object.entries(item)) {
            if (value.cosUrls && value.cosUrls.length > 0) {
              state.detailList.forEach(det => {
                if (det.type.length && det.type.includes(value.moduleType)) {
                  state.key = key
                  det.list = value.cosUrls
                }
              })
            }
          }
        }
      }
    }
  }
  // 获取 确认页的影像附件
  const getDetailImgList = (list = []) =>{
    if(list.length){
      list.forEach(item =>{
        state.detailList.forEach(data => {
          if (data.type.includes(item.type)) {
            data.list = item.list
          }
        })
      })

    }
  }





  // 获取点击的那个
  const handelClick = (data) =>{
    state.clickData = data
  }
  // 上传文件
  const uploadCosFileChange=(data)=>{
    let obj = state.relationshipList.filter(item => item.value === state.clickData.value)[0]
    obj.label = data[0].playUrl
    getResultData(state.relationshipList, 2)
  }
  const handelDel = () =>{
    if(state.relationshipList.length === 1){
      state.relationshipList[0].label = ''
    }else{
      for (let i = 0; i < state.relationshipList.length; i++) {
        if(state.relationshipList[i].value === state.clickData.value){
          state.relationshipList.splice(i, 1)
          break
        }
      }
    }
    getResultData(state.relationshipList, 2)
  }
  // 新增投保书
  const handelSaveProve = () =>{
    for (let i = 0; i < state.relationshipList.length; i++) {
      if (!state.relationshipList[i].label){
        return message.warning('请先上传投被保人关系证明影像')
      }
    }
    state.relationshipList.push({value: new Date().getTime(), label: ''})
    getResultData(state.relationshipList, 2)
  }
  // 提示书
  const handelDelReminder = () =>{
    for (let i = 0; i < state.reminderList.length; i++) {
      if(state.reminderList[i].value === state.clickData.value){
        state.reminderList[i].label = ''
        break
      }
    }
    getResultData(state.reminderList, 1)
  }
  const uploadFileChangeReminder = (data) =>{
    let obj = state.reminderList.filter(item => item.value === state.clickData.value)[0]
    obj.label = data[0].playUrl
    getResultData(state.reminderList, 1)
  }
  const getResultData = (list, num) =>{
    if(num === 1){
      emit('update:reminderList', state.reminderList)
    }else if(num === 2){
      emit('update:relationshipList', state.relationshipList)
    }
  }





  return{
    ...toRefs(state),
    ...toRefs(showData),
    getImgList,
    uploadCosFileChange,
    handelDel,
    handelSaveProve,
    handelClick,
    handelDelReminder,
    uploadFileChangeReminder,
    getResultData,
    getDetailImgList
  }
}
})
</script>

<style scoped>
  .title{
    height: 3.19rem;
    line-height: 3.19rem;
    font-size: .88rem;
    color: #333;
    font-weight: 600;
    text-align: left;
    padding-left: 0.3rem;
    border-bottom: 1px solid #DEDEDE;
    border-top: 1px solid #DEDEDE;
  }
  .img_class{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 0;
  }
  .img_m_t {
    margin-top: 1rem;
  }
  .img_class img{
    width: 8rem;
    height: 5.7rem;
  }
  .prove_class{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .prove_img_class{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .prove_img_class img{
    margin-top: 1rem;
    width: 8rem;
    height: 5.7rem;
  }
  .displayType{
  }
  .add_class{
    width: 8rem;
    height: 5.7rem;
    padding-top: .5rem;
  }
  .add_class_img img{
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 5px;
  }
  .add_class div{
    text-align: center;
  }
</style>
