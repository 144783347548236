import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'

// 签名所需接口请求-api

// 短信校验-获取短信校验所需的信息
export const getPhoneDateApi = (query) => request(getExtendUri('getPhoneDate'), query, null, 'post')
// 短信校验-获取验证码
export const getVCodeApi = (query) => request(getExtendUri('getVCode'), query, null, 'post')
export const getTKSignatureLink = (query) => request(getExtendUri('getTKSignatureLink'), query, null, 'post')

// 短信校验-校验验证码
export const sendSMSApi = (query) => request(getExtendUri('sendSMS'), query, null, 'post')
// 获取签名列表信息（确认页底部签名列表）
export const getSignListApi = (query) => request(getExtendUri('getSignList'), query, null, 'post')
// 获取签名信息（签名页面） --- 单人
export const getSignDateApi = (query) => request(getExtendUri('getSignDate'), query, null, 'post')
// 签名API
export const signEventApi = (query) => request(getExtendUri('signEvent'), query, null, 'post')
// 人脸识别 - 获取三方url
export const faceEventApi = (query) => request(getExtendUri('face'), query, null, 'post')
// 人脸识别-获取识别结果
export const getFacialReturnApi = (query) => request(getExtendUri('getFacialReturn'), query, null, 'post')

// 瑞泰人脸识别-获取三方url

export const getFaceRecognition = (query) => request(getExtendUri('faceRecognition'), query, null, 'post')

export const getFaceResultApi = (query) =>{
  let url = `${getExtendUri('getFaceResult')}${query}`
 return request(url, null, null, 'get')
}

