<template>
<div>
  <div v-if="!vis">
    <verification-code ref="codeRef" :attributive="routerInfo.attributive" :customCode="routerInfo.customCode" :sign-provider="routerInfo.signProvider" :trade-no="routerInfo.tradeNo" :name="name" :phone="phone"  @handleCode="handleCode"></verification-code>
  </div>
  <a-button class="btn-ensure" :class="{'btn-already':status === true}" @click="toSign">确定</a-button>
</div>
</template>

<script>
import VerificationCode from '../public/verificationCode'
import {onMounted, reactive, nextTick, toRefs, ref} from 'vue'
import router from '../../../router'
// import {startRecord} from '../../../utils/rrweb'
import {request} from '@/utils/request'
import {getExtendUri} from '../../../utils/request_extend'

export default {
  name: 'verificationSign',
  components: {VerificationCode},
  setup(){
    const state = reactive({
      routerInfo: {
        // 订单号
        tradeNo: router.currentRoute.value.query['tradeNo'] || undefined,
        // 客户编号
        customCode: router.currentRoute.value.query['customCode'] || undefined,
        // 签名人的身份类型（投，被，代）
        attributive: router.currentRoute.value.query['attributive'] || undefined,
        signProvider: router.currentRoute.value.query['signProvider'] || '1',
        holderInsuredSame: router.currentRoute.value.query['holderInsuredSame']
      },
      status: false,
      vis: false,
      phone: '',
      name: ''
    })
    onMounted(()=> {
      // startRecord(state.routerInfo.tradeNo)
      getSignInfo()
      console.log('去执行签名操作',router, state)
    })
    const codeRef = ref()
    const toSign = () => {

      if (state.status) {
        // 执行签名操作
        console.log('去执行签名操作',router)
        router.push({
          path: '/app/elecSignature',
          query: {
            tradeNo: state.routerInfo.tradeNo,
            customCode: state.routerInfo.customCode,
            attributive: state.routerInfo.attributive,
            holderInsuredSame: state.routerInfo.holderInsuredSame
          }
        })
      }
    }
    const getSignInfo = () => {
      let data = {
        tradeNo: state.routerInfo.tradeNo,
        attributive: state.routerInfo.attributive,
        customCode: state.routerInfo.customCode
      }
      request(getExtendUri('getCheckedMessage'),{data}).then(res =>{
        if(res.code === 1){
          state.phone = res.result.customPhone
          state.name = res.result.customName
          state.vis = true
          nextTick(() => {
            state.vis = false
          })
        }
      })
    }
    const handleCode = (code) => {
      if (code) {
        state.status = true
        return
      }
      state.status = false
    }
    return {
      ...toRefs(state),
      toSign,
      handleCode,
      codeRef
    }
  }
}
</script>

<style scoped>
.btn-ensure {
  height: 3rem;
  background: #93C3FC;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  line-height: 2.94rem;
  z-index: 1001;
  text-align: center;
  font-size: 1.13rem;
}
.btn-already {
  background: #0079FE;
}
</style>
