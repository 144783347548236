<style scoped>
/* #c7c7c7 */
:deep(.anticon-right) {
  color: #c7c7c7;
}
.w45{
  width: 45% !important;
}

.product_wrap {
  width: 100%;
  height: 100%;
  background-color: #f6f8fb;
  overflow-x: hidden;
  text-align: left;
  overflow-y: auto;
}

.product_wrap > .modal_class > :global(.ant-modal-content) {
  border-radius: 15px !important;
}

.bottom_bar, .bottom_barA {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
}

.bottom_barA {
  height: 4.3rem;
  line-height: 0.5rem;
}

.bottom_fix {
  height: 4rem;
}

.insure_btn {
  font-size: 1.2rem;
  height: 100%;
  width: 40%;
  font-weight: 500;
  border-radius: 0;
}
.prem_total {
  color: #ff8400;
  margin-left: 10px;
  height: 100%;
  font-weight: 500;
}
/* .bottom_bar label {
  display: inline-block;
  width: 60%;
  font-size: 1.5rem;
  font-weight: 500;
  color: #ff8400;
  text-align: right;
} */

.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}

.prem {
  width: 60%;
  padding-top: 10px;
  line-height: 1rem;
}

.prem p {
  font-size: 1.1rem;
  font-weight: 500;
  color: #ff8400;
  margin-left: 20px;
  padding: 0;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
}

.prem p span {
  display: inline-block;
  text-align: right;
}

.prem p span:nth-child(1) {
  flex: 4;
}

.prem p span:nth-child(2) {
  flex: 8;
  padding-right: 25px;
}

.insure_btnA {
  float: right;
  margin-top: -3.7rem;
}

.select_checkbox {
  height: auto;
  font-size: .98rem;
}

.select_checkbox .van-checkbox {
  background-color: #ffffff;
  padding: 0.5rem 1rem;

}

.select_title {
  height: auto;
  padding: 0.4rem 0.7rem 0;
  font-size: .98rem;
  margin: 10px;
}
.riskName_class{
  padding: 0.5rem 1rem;
}
.benefit {
  background: #fff;
}
.add-benefit-btn-row {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-btn {
  width: 150px;
  border: 1px solid cornflowerblue;
  color: cornflowerblue;
  border-radius: 10px;
}
.add-confirm {
  display: block;
  width: 100%;
  height: 40px;
  background: cornflowerblue;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.benefit-item {
  padding: 10px 10px 0 10px;
  /* border-bottom: 1px solid #f3f5f5; */
}
.benefit-order {
  font-size: .98rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.benefit-order .order-left {
  display: flex;
  align-items: center;
}
.order-right {
  color: #666666;
}
.left-border {
  display: inline-block;
  width: 5px;
  height: 20px;
  margin-right: 3px;
  border-radius: 1px;
  background: cornflowerblue;
}
.benefit-name, .benefit-percentage {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f3f5f5;
  font-size: .98rem;
  color: #666666;
}
.benefit-value {
  color: rgba(0, 0, 0, 0.85);
}
.add-benefit-title {
  height: 40px;
  padding: 0 10px;
  font-size: .98rem;
  line-height: 40px;
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #666666;
}
.benefit-delete {
  color: #ee0a24;
}
.benefit-popup-contaner {
  height: 100%;
}
.add-benefit-content {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.plus-icon {
  margin-right: 3px;
}
.benefit-tip {
  background: #fff;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
}
</style>

<template>
  <IOSbackBar :title="'信息录入页'" :backMethod="backMethod"></IOSbackBar>
  <div class="product_wrap">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div>
      <!-- 投保人 -->
      <template v-for="(item,index) in showData" :key="index">
        <template v-if="item.moduleType !== 'addBeneficiarys'">
        <PreviewPop :isShowRFlag="true" @getImageParameters="getImageParameters" :insuranceReminder="insuranceReminder" :relationship="relationship" :insuredObj="insuredObj" :insuredsType="insuredsType" :maximumLimit="maximumLimit"
                    :isInsureds="true" :checkType="checkType" :previewData="item" @PreviewPopData='PreviewPopData' :isButton="true"
                    @deleteElementFun="deleteElementFun" @addElementFun="addElementFun" @setUserDetail="setUserDetail"
        />
        <!-- 被保人 -->
      </template>
        <div class="benefit-tip" v-if="item.moduleType === 'beneficiarys' && !isInclude">
          <span>本合同的各项保险金的受益人均为被保险人本人</span>
        </div>
        <div v-if="item.moduleType === 'beneficiarys' && addBenefitModule" class="benefit">
          <template v-for="(benefit, index) in designatedBenefitList" :key="benefit.id">
            <div class="benefit-item">
              <div class="benefit-order">
                <div class="order-left">
                  <span class="left-border"></span>{{benefitOrderTrans(benefit.bnfNo)}}受益人</div>
                  <div class="order-right" @click="viewBenefitDetail(benefit, index)">查看详情<van-icon name="arrow"/></div>
              </div>
              <div class="benefit-name">
                <span>姓名</span>
                <span class="benefit-value">{{ benefit.name }}</span>
              </div>
              <div class="benefit-percentage">
                <span>受益比例</span>
                <span class="benefit-value">{{ benefit.bnfLot }}%</span>
              </div>
            </div>
          </template>
          <div class="designated-insuredList">
          </div>
          <div class="add-benefit-btn-row">
            <a-button class="add-btn" @click="addBenefit"><van-icon name="plus" class="plus-icon"/>添加受益人</a-button>
          </div>
          <template>
        <a-drawer
          :title="openBenefitType === 'add' ? '添加受益人' : '受益人详情'"
          :visible="BeneFitVisible"
          :footerStyle="{padding: 0}"
          :headerStyle="{
            background: '#dcf0ff',
            paddingLeft: '15px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }"
          destroyOnClose
          @close="closeBenefitPopup"
          placement="bottom"
          height="70%">
        <div v-if="BeneFitVisible" class="benefit-popup-contaner">
            <div style="position:relative;">
              <PreviewPop  :isShowRFlag="true" @getImageParameters="getImageParameters" :insuranceReminder="insuranceReminder" :relationship="relationship" :insuredObj="insuredObj" :insuredsType="insuredsType" :maximumLimit="maximumLimit"
                    :isInsureds="true" :checkType="checkType" :previewData="benefitConfig" @PreviewPopData='PreviewPopData' :isButton="true"
                    @deleteElementFun="deleteElementFun" @addElementFun="addElementFun" @setUserDetail="setUserDetail"
              />
          </div>
        </div>
       <template #extra>
        <span v-if="openBenefitType != 'add'" class="benefit-delete" @click="deleteBenefit">删除</span>
    </template>
      <template #footer>
        <div v-if="openBenefitType === 'add'" class="add-confirm" @click="confirmAddBenefit">添加</div>
        <div v-else class="add-confirm" @click="confirmUpdateBenefit">修改</div>
      </template>
      <a-modal style="width: 80%;z-index: 99999;" centered :closable="false" v-model:visible="isIdentical"
               @ok="insuranceErrorHandleOk">
        <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
        <template #footer>
          <a-button type="link"
                    style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                    @click="insuranceErrorHandleOk">确认
          </a-button>
        </template>
      </a-modal>
      </a-drawer>
     </template>
        </div>
      </template>
      <!-- 保险信息 -->
      <div v-if="false">
        <show-info-item v-if="showProductInfo" :show-data2="showData2"></show-info-item>
      </div>
      <!--    可选责任  -->
      <div class="select_checkbox" v-if="detailData.riskFormData['riskList'] && detailData.riskFormData['riskList'].length && detailData.riskFormData['riskList'].find(item => item.hasOptionalDuty === true)">
        <h3 class="select_title">可选责任</h3>
        <div style="background-color: #ffffff;">
          <template v-for="(dutyItem, riskIndex) in detailData.riskFormData['riskList']"
                    :key="'optionalDuty_' + dutyItem.id">
            <div v-if="detailData.riskFormData['riskList'].length > 1 && dutyItem.hasOptionalDuty" class="riskName_class">
              <div>{{ dutyItem.riskName }}</div>
            </div>
            <template v-for="dutyValue in detailData.resetOptionalDutys.riskList[dutyItem.index]['dutyList']"
                      :key="'riskFormDataCopy_' + dutyValue.id">
              <van-checkbox v-if="detailData.optionalDutys.includes(dutyValue.id) && dutyValue.isShowStatus === 'Y'"
                            v-model="dutyValue.isSelected" :name="detailData.resetOptionalDutys.riskList[dutyItem.index].id" @click="optionalDutyChange(dutyValue, dutyItem.index, riskIndex)">
                {{ dutyValue.dutyName }}
              </van-checkbox>
            </template>

          </template>
        </div>
      </div>


    </div>
    <div style="margin: 3px 10px 10px 20px; color:#999">
      温馨提示：投保信息绝对保密，仅供投保使用，本产品由{{ pCompany }}承保
    </div>
    <div class="bottom_fix"></div>
    <!--    <div class="bottom_bar" v-if="!premiumCalculationIf">-->
    <!--      <label>￥{{ premium }}元&nbsp;&nbsp;</label>-->
    <!--      <a-button :disabled="loading" type="primary" class="insure_btn" size="large" @click="nextPage">下一步</a-button>-->
    <!--    </div>-->

    <div class="bottom_bar">
      <!-- <label class="prem_total">{{ premium }}元</label> -->
      <label class="prem_total">
          <span>{{ Number(premium).toFixed(2) }}元</span>
        </label>
      <a-button :disabled="loadingShow" type="primary" class="insure_btn" size="large" @click="nextPage">下一步</a-button>
    </div>


    <!--    手机验证码-->
    <div class="modal_class">
      <a-modal v-model:visible="visible" title="您的支付账户尚未签约，请完成签约！" @ok="handleOk" width="90%"
               :centered="visible" @cancel="handleCancel">
        <template #footer>
          <a-button key="back" @click="handleCancel">取消</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
        </template>
        <p>验证码发送到手机号:{{ insuredMobile }}</p>
        <div>
          <a-input-group compact style="width:100%">
            <a-input v-model:value="verificationCode" style="width: calc(100% - 120px)" :maxlength="6"/>
            <a-button :loading="sendLoading" type="primary" @click="countDown" style="width: 120px">{{ textshow }}</a-button>
          </a-input-group>
        </div>
      </a-modal>
      <!--    投保错误提示-->
      <a-modal v-model:visible="insuranceError" title="提示" @ok="insuranceErrorHandleOk">
        <p v-for="(item,index) in insuranceErrorArr" :key="index">{{ item.resultContent }}</p>
      </a-modal>
      <!-- 先作为 出生日期选择 判断是否和身份证一样 -->
      <a-modal style="width: 80%;z-index: 99999;" centered :closable="false" v-model:visible="isIdentical"
               @ok="insuranceErrorHandleOk">
        <div style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;" v-html="detailModal"></div>
        <template #footer>
          <a-button
            type="link"
            style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
            :class="{w45:  autoCheckFlag === '0'}"
            @click="insuranceErrorHandleOk">确认
          </a-button>
          <a-button
            v-if="autoCheckFlag === '0'"
            type="link"
            style="width:45%;font-size: 20px;text-align: center !important; color: #1677FF;"
            @click="insuranceErrorHandleOff">取消
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>



<script>
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUpdate,
  onMounted,
  provide,
  reactive,
  toRefs,
  ref,
  watch
} from 'vue'
import { useChildren } from '@vant/use'
import {getBBRAge
  // , getTBRAge
  , RELATION_KEY,formatNumberToFix2, getPremCalculatorParam} from '../../utils/utlis'
import {useRouter} from 'vue-router'
import {nowPathShow, routerJump, gePInfo, getCacheDate, showArr} from '../../utils/product_process'
import {request} from '../../utils/request.js'
import {getExtendUri, tradeNoStateIshavePay} from '../../utils/request_extend'
import {message} from 'ant-design-vue'
// import {startRecord} from '../../utils/rrweb'
import ShowInfoItem from './public/ShowInfoItem'
import {checkData, checkPlanLimitList, getSexFromIdCard} from '../../utils/rules_common_insure'
import store from '../../store'
import {informationVerification, insuredsFirstOne, checkGFRule} from './componentsJS/informmationJS'
import {Checkbox, Dialog, Popup, Icon, Sticky} from 'vant'
import 'vant/lib/index.css'
import PremCalculator from '../../utils/prem_calculator'
import {hiddenElement, sameNewIsY} from '@/pages/transaction/componentsJS/informationShow'
import {getAmntElementRefAndSpecailCal, newPremiumInTK} from '@/utils/special_treatment'
import {vTools} from '@/pages/InsuranceConfiguration/js/verificationTool'
import {checkGFDutySelect} from '@/pages/transaction/componentsJS/gf'
import {getNewSubmitData} from '@/utils/relational_mapping_helper.js'
import {
  benefitConfig,
  benefitBrithDayDateConfig,
  benefitBrithDayInputConfig,
  idTypeStartDateConfig,
  idTypeStartDateInputConfig,
  idTypeEndDateConfig,
  idTypeEndDateInputConfig
} from './addBenefit.js'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    document.title = '信息填写'
    next()
  },
  components: {
    IOSbackBar,
    ShowInfoItem,
    [Checkbox.name]: Checkbox,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
  },
  setup() {
    const { linkChildren } = useChildren(RELATION_KEY)
    const benefitRef = ref()
    // const userDrawerRef = ref()
    const state = reactive({
      routerFlag: false,
      isInclude: false,
      sendLoading: false,
      designatedBenefitList: [],
      openBenefitType: 'add',
      BeneFitVisible: false,
      addBenefitModule: false,
      openBenefitIndex: 0,
      benefitOrderOptions: [],
      checkType: true,
      insuredsType: '',
      // isUnderwriting: '',
      appntDetailValue: '',
      detailModal: '',
      isIdentical: false,
      autoCheckFlag: '1',
      signFlag: '',
      showData2: [],
      showProductInfo: false,
      productId: '',
      pCompany: '',
      insuranceError: false,
      premiumCalculationIf: false,
      insuranceErrorArr: [],
      textshow: '获取验证码',
      loadingShow: false,
      verificationCode: '',
      visible: false,
      loading: false,
      name: '',
      showData: [],
      showObj: {},
      insuredObj: {},
      insuredBackUp: {},
      riskList: '',
      premium: 0,
      insuredMobile: '',
      // 倒计时
      countDownTime: 60,
      timer: null,
      countDownIng: false,
      relationToAppnt: null,
      bnfType: 1,
      bankBeforeSignFlag: false,
      premiumA: 0,
      tradeNo: '',
      contInfo: {},
      slectPlanId: {},
      routerType: '',
      //被保人数量
      maximumLimit: null,
      beneficiarysDictionary: '',
      optionalDutys: [],
      optionalDutysList: [],
      routerCode: '1',
      premiumObj: {},
      // 作为多被保人的告知查询ID
      elementId: '',
      // 需要上传投保提示书影像文件
      relationship: '',
      // 需要上传关系证明影像文件
      insuranceReminder: '',
      imageList: [],
      selectOptionalDutyList: [],
      isOcrSet: false,
      viewBenefit: false
    })
    onBeforeMount(async () => {
      state.tradeNo = router.currentRoute.value.query.tradeNo
      const orderList = router.currentRoute.value.query.fromOrderList
      if (orderList == '1') {
        let getProductDetailData = store.getters['insured/getProductDetailData']
          if (!getProductDetailData || getProductDetailData === '{}' || Object.keys(getProductDetailData).length === 0) {
            const data = await request(getExtendUri('getCacheParameters'), {
            tradeNo: state.tradeNo + 'productDetailData'
          })
          if (data.code === 1) {
            store.commit('insured/updateInsureJSInfo', { productDetailData: JSON.stringify(data.result.saveData.productDetailData)})
            store.commit('insured/setProductId', data.result.saveData.productDetailData.productInfo.productId)
          }
        }
        await gePInfo()
        await getCacheDate(state.tradeNo, orderList)
      }
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
      if (state.riskList?.[0]?.calculateFactor?.[0]) { // 判断有计划
        state.slectPlanId = {
          planCode: state.riskList[0].calculateFactor[0].factorValue || '',
          planName: state.riskList[0].calculateFactor[0].factorValueName
        }
      }
      state.selectOptionalDutyList = store.getters['insured/getOptionalDutyList']
      state.productId = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).productId : ''
      // state.pCompany = JSON.parse(sessionStorage.getItem('productDetailData')).productInfo.supplierName || '产品提供方'
      state.pCompany = JSON.parse(store.getters['insured/getProductDetailData'])?.productInfo?.supplierName || '产品提供方'
      provide('setUserDetail', setUserDetail)
      provide('setIdType', setIdType)
      if(router.currentRoute.value.query.continuousRenew && router.currentRoute.value.query.continuousRenew === '1'){
        nowPathShow(null, null, state.tradeNo, async () => {
          state.maximumLimit = JSON.parse(store.getters['insured/getRouterJump']).maximumLimit || ''
          getRenewalData()
        })
      }else{
        getShowDataTemporary()
      }
      state.riskList.forEach(res => {
        if (res.riskSign === '1' && res.premiumCalculation === '2') {
          state.premiumCalculationIf = true
          res.calculateFactor.forEach(item => {
            if (item.factorType === 'Prem') {
              state.premiumA = item.factorValue
            }
          })
        }
      })
      getInsuranceInformation()
    })
    // 获取缓存数据
    const getCacheParametersData = (premium) =>{
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo
      }).then(async res => {
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
        await getInitialValue(res.result.selectRisk.riskList,premium)
      })
    }
    // 获取续保数据
    const getRenewalData = async () =>{

      // request(getExtendUri('getInsuredInfo'), {data: '0e1f2632b57310dff9a1eb4b80fdda5a'}).then(async res =>{
      request(getExtendUri('getInsuredInfo'), {data: router.currentRoute.value.query.renewalId}).then(async res =>{
        if(res.code === 1){
          state.policyCode = res.result.policyCode.value
          // 续保不可修改的字段
          let data = JSON.parse(JSON.parse(store.getters['insured/getRouterJump']).pageJson)
          data.forEach(item =>{
            let list = []
            if(item.moduleType === 'applicant'){
              list = res.result.applicantList
            }else if(item.moduleType === 'insureds'){
              list = res.result.insuredList
            }else if(item.moduleType === 'payment'){
              list.push(res.result.payment)
            }
            for (let i = 0; i < item.productElementList.length; i++) {
              const productElementList = item.productElementList[i]
              for (let j = 0; j < productElementList.length; j++) {
                const product = productElementList[j]
                product.isDisabled = false
                if(product.elementCode === 'updateLodeDetail'){
                  product.isDisabled = true
                  product.firstValue = 'Y'
                  continue
                }
                const arr = list.filter(obj => obj[product.elementCode])
                if(arr && arr.length){
                  product.isDisabled =  Number(arr[0][product.elementCode].isEdit) === 1
                  if(product.elementCode === 'areaCode' && arr[0]['areaCodeName']){
                    product.show_tagName = arr[0]['areaCodeName'].value
                  }
                  if(product.elementCode === 'occupationCode'){
                    product.show_level =  arr[0]['occupationLevel'].value
                    product.show_tagName = arr[0]['occupationName'].value
                  }
                  if(product.elementCode === 'newBankCityCode' && arr[0]['newBankCityName']){
                    product.show_tagName = arr[0]['newBankCityName'].value
                  }
                  product.firstValue =  arr[0][product.elementCode].value
                }

              }
            }
          })
          getInitialValue()
          state.showData = data
          state.showDataCopy = JSON.parse(JSON.stringify(state.showData))
          await showObjFun()
          await getCacheHealthToldData(state.showData)
          await getElementId()

          console.log('续保数据', res, state.showData, state.insuredObj)

        }
      })
    }

    const getShowDataTemporary = () =>{
      state.showData = []
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo+ 'showDataTemporary'
      }).then(async res => {
        if(res.code === 1 && res.result){
          await getCacheParametersData(res.result.selectRisk.premium)
          await nowPathShow(null, null, state.tradeNo, async () => {
            const routerData = JSON.parse(store.getters['insured/getRouterJump'])
            state.maximumLimit = routerData.maximumLimit || ''
            state.showData = res.result.selectRisk.showData
            // state.premium = res.result.selectRisk.premium
            state.showDataCopy = JSON.parse(JSON.stringify(state.showData))
            await showObjFun()
            await getCacheHealthToldData(state.showData)
            // 获取初始化的告知ID
            getElementId()
            // optionalDutyCheck()
          })
        }else{
          nowPathShow(null, null, state.tradeNo, async () => {
            state.maximumLimit = JSON.parse(store.getters['insured/getRouterJump']).maximumLimit || ''
            Initialization()
            // 获取初始化的告知ID
            getElementId()
            if (typeof (store.getters['insured/getMultipleRiskList']) == 'string' && store.getters['insured/getMultipleRiskList']) {
              handelAssignmentPremium()
            }
            getInitialValue()
          })
        }
      })
    }
    // 只是为了改变健告状态
    const getCacheHealthToldData = async (showData) => {
      const healthToldIdentifying = store.getters['insured/getHealthToldIdentifying']
      // 获取健告的归属
      const associationType = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.associationType
      if(!healthToldIdentifying || !healthToldIdentifying.length) return
      let list = []
      let typeList = []
      const applicantIdNo = showData.filter(item => item.moduleType === 'applicant')[0].productElementList[0].filter(ele => ele.elementCode === 'appntIdNo')[0].firstValue
      const insuredsList = showData.filter(item => item.moduleType === 'insureds')[0].productElementList

      for (let i = 0; i < insuredsList.length; i++) {
        const oneList = insuredsList[i].filter(ele => ele.elementCode === 'relationToAppnt' && ele.firstValue === '01')
        if(oneList && oneList.length){
          list = healthToldIdentifying.filter(item => item.applicantIdNo === applicantIdNo && !item.insuredsIdNo)
          if(list &&list.length){
            await getHealthData(i, true)
          }else{
            typeList.push('1')
            await getHealthData(i, false, typeList)
          }
        }else{
          const idNo = insuredsList[i].filter(ele => ele.elementCode === 'idNo')[0].firstValue
          if (associationType.includes('2')){
            list = healthToldIdentifying.filter(item => item.applicantIdNo === applicantIdNo && item.insuredsIdNo === idNo)
          }else{
            list = healthToldIdentifying.filter(item => item.moduleIndex === i+'' && item.insuredsIdNo === idNo)
          }
          if(list &&list.length){
            await getHealthData(i, true)
          }else{
            typeList.push('1')
            await getHealthData(i, false)
          }
        }
      }
      setRelationToAppntType() // 被保人
    }
    const getHealthData = (index, type, typeList=[]) =>{
      // 健康告知改变
      for (let i = 0; i < state.showData.length; i++) {
        if (state.showData[i].moduleType === 'insureds') {
          state.showData[i].productElementList[index].forEach(item => {
            if (item.elementCode === 'healthToldFlag') {
              item.firstValue =  type ? 'Y' : null
            }
          })
        }
      }
      const typeInfo = !(typeList.length && typeList.includes('1'))
      for (let i = 0; i < state.showData.length; i++) {
        if (state.showData[i].moduleType === 'insuranceInfo') {
          state.showData[i].productElementList[0].forEach(item => {
            if (item.elementCode === 'healthToldFlag') {
              item.firstValue = typeInfo ? 'Y' : null
            }
          })
        }
      }
    }



    const getElementId = () =>{
      if(store.getters['insured/getRouterJump'] !== '{}'){
        const data = JSON.parse(JSON.parse(store.getters['insured/getRouterJump'])?.pageJson)
        const elementData = data.filter(item => item.moduleType === 'insureds')[0].productElementList[0]
        state.elementId = elementData.filter(ele => ele.elementCode === 'healthToldFlag')[0]?.id
      }
    }
    const detailData = reactive({
      insuredIndex: 0,
      flag: -1,
      productInfo: JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {},
      firstMainRiskInfo: {},
      moreMainRiskList: [],
      calculating: false,
      riskFormData: {},
      optionalDutys: [],
      dataValue: '',
      riskFormDataCopy: {},
      productInfoCopy: JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {},
      resetOptionalDutys: JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {}
    })
    // 获取数据初始值
    const getInitialValue = (arr=[],premium) => {
      if (Object.keys(detailData.productInfo).length === 0) {
        detailData.productInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {},
        detailData.productInfoCopy = JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {},
        detailData.resetOptionalDutys = JSON.parse(store.getters['insured/getProductDetailData']).productInfo ?? {}
      }
      console.log(detailData.productInfo, 'detailData.productInfo')
      const list = arr.length ? arr : JSON.parse(store.getters['insured/getRiskList']).riskList
      const riskState = PremCalculator.createRiskInfo(detailData.productInfo, list)
      detailData.firstMainRiskInfo = riskState.firstMainRiskInfo
      detailData.moreMainRiskList = riskState.moreMainRiskList
      detailData.riskFormData = riskState.riskFormData
      detailData.riskFormDataCopy = JSON.parse(JSON.stringify(riskState.riskFormData))
      detailData.optionalDutys = riskState.optionalDutys
      const {birthday, idNo, insuredAge } = typeof (store.getters['insured/getSaveDate']) === 'string' ? JSON.parse(store.getters['insured/getSaveDate'])?.contInfo?.insureds?.[0] : store.getters['insured/getSaveDate']?.contInfo?.insureds?.[0]
      const orderList = router.currentRoute.value.query.fromOrderList
      const continuousRenew = router.currentRoute.value.query.continuousRenew
      PremCalculator.factorChange({riskId: detailData.firstMainRiskInfo.riskId, getDefault: 1}, null, (action, flag, value,result, param) => {
        if(premium){
          state.premium = premium
        }else{
          let tk = newPremiumInTK(param)
          if (tk) {
            state.premium = tk
          }else if (result && param) {
            console.log(result, param, value, birthday, idNo, insuredAge, 'param1111111')
            if (birthday || idNo || insuredAge || orderList === '1' || continuousRenew === '1') {
              changeAmntValue(result, param, value)
            }
          }else{
            state.premium = value || '0.00'
          }
          if(result && result.code === 0){
            state.isIdentical = true
            state.detailModal = result.message
          }
        }
        state.premiumObj = result
        handelPayTypeData(result)
      })
      showOptional()
      resetOptionalDuty(detailData.resetOptionalDutys, list)
    }

    const getContInfo = () =>{
      //将组建返回数据组合成接口传参
      state.contInfo = {}
      //当前页面录入的信息
      for (let key in state.insuredObj) {
        if (state.insuredObj[key] === 'healthNotification') continue
        if (key === 'insureds' || key === 'beneficiarys') {
          //  insureds被保人 beneficiarys 受益人
          state.contInfo[key] = []
          state.insuredObj[key].forEach((res ,index) => {
            let obj = {}
            //  判断是否是本人
            let relationToInsured = res['relationToInsured'] ? Number(res['relationToInsured']['relationToInsured']) : 0
            let relationToAppnt = res['relationToAppnt'] ? Number(res['relationToAppnt']['relationToAppnt']) : 0
            if (relationToInsured === 1 || relationToAppnt === 1) {
              obj = oneselfValuation(key,index)
            } else {
              for (let keyA in res) {
                let objKey = res[keyA][keyA]
                obj[keyA] = objKey
              }
            }
            state.contInfo[key].push(obj)
          })
        } else {
          //其余的数组只有一条数据，组合成obj格式
          state.contInfo[key] = {}
          for (let keyA in state.insuredObj[key][0]) {
            let obj = state.insuredObj[key][0][keyA][keyA]
            state.contInfo[key][keyA] = obj
          }
        }
      }
      state.contInfo.applicant.appntSex = ''
      if (state.contInfo.applicant.appntIdNo) {
        state.contInfo.applicant.appntSex = parseInt(state.contInfo.applicant.appntIdNo.substr(16, 1)) % 2 == 1 ? '1' : '2'
      }
      state.contInfo.insureds.forEach(item => {
        if (item.relationToAppnt == '01') {
          item.occupationLevel = ''
          item.occupationLevel = state.contInfo.applicant.occupationLevel
        }
        if(item.idNo && item.idType === '01'){
          item.sex = ''
          item.sex = parseInt(item.idNo.substr(16, 1)) % 2 == 1 ? '1' : '2'
        }else {
          return '错误'
        }
        if(item.birthday){
          let age = calAge(item.birthday)
          item.insuredAge =age
        }
      })
      //险种信息数据格式处理
      state.contInfo.risks = []//险种  ======= 从产品详情页带入

      state.riskList.forEach(res => {
        res.calculateFactor.forEach(item => {
          if (item.factorType === 'insuredNum') {
            item.factorValue = (state.insuredObj['insureds'].length) + ''
            item.factorValueName = state.insuredObj['insureds'].length + '人'
          }
        })
      })
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
      state.riskList.forEach(item => {
        let obj = {}
        obj.riskId = item.riskId
        obj.riskCode = item.riskCode
        obj.riskSign = item.riskSign
        obj.productInfoId = store.getters['insured/getProductId']
        obj.calculateFactor = item.calculateFactor
        obj.dutys = item.dutyList
        state.contInfo.risks.push(obj)
      })
      if (state.addBenefitModule) {
        state.contInfo.beneficiarys = state.designatedBenefitList
      }
      let submitData = {
        baseInfo: {
          'tradeNo': state.tradeNo,
          'shareId': store.getters['insured/getShareId']
        },
        contInfo: state.contInfo
      }
      return submitData
    }
    // 发生改变的时候, 需要重组数据并传递
    const factorChange = (valObject, moduleType) => {
      console.log(valObject, 'sssssyyyy')
      valObject.getDefault = 1
      if(Number(state.maximumLimit > 1)){
        PremCalculator.collationInputInfoFactor(valObject)
      }else{
        // exemptRisk  applicant   insureds
        let type = true
        let bool = true
        let insurList = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList
        if(insurList && insurList.length === 1){
          for (const item of insurList[0]) {
            if(item.elementCode === 'relationToAppnt' && item.firstValue === '01'){
              type = false
            }else if(item.elementCode === 'relationToAppnt' && item.firstValue === '02'){
              let status = getContInfo()
              console.log(status, 'status11211')
              if(status !== '错误'){
                showErrTipModal(checkData(status, state.isInclude))
              }
            }
          }
        }
        if(!bool && valObject.factorType !== 'SocialFlag') return
        const riskList = detailData.riskFormData.riskList
        detailData.insuredIndex = valObject.insuredIndex || 0
        let exemptRisk =  moduleType === 'applicant' ? 'Y' : 'N'
        for (let i = 0; i < (riskList || []).length; i++) {
          if(riskList[i].exemptRisk === exemptRisk || (moduleType === 'applicant' && !type)){
            const calculateList = riskList[i].calculateFactor
            for (let j = 0; j < calculateList.length; j++) {
              if(calculateList[j]['factorType'] === valObject.factorType){
                calculateList[j].factorValue = valObject.value
                calculateList[j].factorValueName = valObject.name
              }
            }
          }
        }
      }



    }
    // 监视缓存数据
    watch(() => detailData.riskFormData?.riskList, () => {
      if(Number(state.maximumLimit > 1)) return
      const newVal = detailData.riskFormData.riskList
      const oldVal = detailData.riskFormDataCopy.riskList
      if (newVal && newVal.length > 0 && oldVal && oldVal.length > 0) {
        for (let i = 0; i < newVal.length; i++) {
          for (let j = 0; j < newVal[i].calculateFactor.length; j++) {
            if (oldVal[i].calculateFactor[j] && newVal[i].calculateFactor[j] && oldVal[i].calculateFactor[j].factorValue !== newVal[i].calculateFactor[j].factorValue) {
              console.debug('calculateFactor', oldVal[i].calculateFactor[j].factorType, oldVal[i].calculateFactor[j].factorValue, newVal[i].calculateFactor[j].factorValue)
              detailData.riskFormDataCopy = JSON.parse(JSON.stringify(detailData.riskFormData))
              const valObject = {
                factorType: newVal[i].calculateFactor[j].factorType,
                value: newVal[i].calculateFactor[j].factorValue,
                name: newVal[i].calculateFactor[j].factorValueName,
                riskId: oldVal[i].riskId,
                insuredIndex: detailData.insuredIndex,
                getDefault: 1,
                isInformation: 1,
              }
              PremCalculator.factorChange(valObject, null, (action, flag, value, result,param) => {
                let tk = newPremiumInTK(param)
                if (tk) {
                  state.premium = tk
                }else if (result && param) {
                  changeAmntValue(result, param, value)
                }else{
                  state.premium = value || '0.00'
                }
                if(result && result.code === 0){
                  state.isIdentical = true
                  state.detailModal = result.message
                }
                state.premiumObj = result
                // 保险模块中可能含有保费
                handelPayTypeData(result)
              })
            }
          }
        }
      }
    }, {deep: true})

    // 反向关联
    watch(() => detailData.productInfo.riskList, () => {
      if(Number(state.maximumLimit > 1)) return
      const newVal = detailData.productInfo.riskList
      const oldVal = detailData.productInfoCopy.riskList
      console.debug('detailData. productInfo---> ', newVal)
      if (newVal && newVal.length > 0 && oldVal && oldVal.length > 0) {
        for (let k = 0; k < detailData.productInfo.riskList.length; k++) {
          const riskInfo = detailData.productInfo.riskList[k]
          for (let i = 0; i < riskInfo.calculateFactor.length; i++) {
            const calculateFactorInfo = riskInfo.calculateFactor[i]
            if (JSON.stringify(calculateFactorInfo.factorValueList) !== JSON.stringify(oldVal[k].calculateFactor[i].factorValueList)) {
              detailData.productInfoCopy = JSON.parse(JSON.stringify(detailData.productInfo))
              if(!detailData.riskFormData.riskList[k]) break
              const curFactors = detailData.riskFormData.riskList[k].calculateFactor
              for (let j = 0; j < curFactors.length; j++) {
                if (curFactors[j].factorType === calculateFactorInfo.factorType && curFactors[j].factorId ===calculateFactorInfo.factorId ) {
                  let needReset = true
                  calculateFactorInfo.factorValueList.forEach(item => {
                    if (item.value === curFactors[j].factorValue) {
                      needReset = false
                    }
                    if (item.value !== curFactors[j].factorValue && item.name === curFactors[j].factorValueName) {
                      needReset = false
                      curFactors[j].factorValue = item.value
                    }
                  })
                  if (needReset) {
                    curFactors[j].factorValue = calculateFactorInfo.factorValueList[0].value
                    curFactors[j].factorValueName = calculateFactorInfo.factorValueList[0].name
                  }
                }
              }

            }
          }
        }
      }

    }, {deep: true})

    // 可选责任回显
    // const resetOptionalDuty = (data, list) =>{
    const resetOptionalDuty = (data) =>{
      // 这个是防止责任不参与保费计算
      for (let i = 0; i < data.riskList.length; i++) {
        const dutyList = data.riskList[i].dutyList
        for (let j = 0; j < dutyList.length; j++) {
          if(state.selectOptionalDutyList && state.selectOptionalDutyList.length){
            const index = state.selectOptionalDutyList.findIndex(item => item.id === dutyList[j].id)
            if(index !== -1) dutyList[j].isSelected = true
          }
        }
      }
      // 这个是根据责任是参与保费计算的
      // for (let i = 0; i < list.length; i++) {
      //   const dutyList = data.riskList[list[i].index].dutyList
      //   for (let j = 0; j < list[i].calculateFactor.length; j++) {
      //     if(list[i].calculateFactor[j].factorType === 'duty'){
      //       for (let k = 0; k < dutyList.length; k++) {
      //         if(dutyList[k].id === list[i].calculateFactor[j].factorId){
      //           dutyList[k].isSelected = true
      //         }
      //       }
      //     }
      //   }
      // }
    }
    // 给多被保人的保费赋值
    const getInsuredsPrem = (result) => {
      if (result && (result.insuredIndex !== null || result.insuredIndex !== undefined)) {
        state.premium = 0
        // 找到对应的被保险人元素列表，更新总保费所对应的元素值
        const insuredList = state.showData.filter(item => item.moduleType === 'insureds')
        if (insuredList.length > 0) {
          const insuredElementList = insuredList[0].productElementList[result.insuredIndex]
          if(insuredElementList && insuredElementList.length){
            for (let j = 0; j < insuredElementList.length; j++) {
              const element = insuredElementList[j]
              if (element.elementCode === 'premium') {
                element.firstValue = result.totalPremium || null
                break
              }
            }
          }

        }
        let totalPremium = 0
        for (const insured of state.showData.filter(item => item.moduleType === 'insureds')) {
          for (const elementList of insured.productElementList) {
            for (const element of elementList) {
              if (element.elementCode === 'premium' && element.firstValue !== null && element.firstValue !== undefined) {
                totalPremium += Number(element.firstValue)
              }
            }
          }
        }
        state.premium = formatNumberToFix2(totalPremium)
      }
    }

    // 保险模块的赋值
    const handelPayTypeData = (result) => {
      if(!state.showData || !state.showData.length) return
      let infoList = state.showData.filter(item => item.moduleType === 'insuranceInfo')
      let dataList =  state.showData.filter(item => item.moduleType === 'insuranceInfo')
      const code = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode
      if(infoList.length){
        const cloneProductElementList = JSON.parse(JSON.stringify(infoList[0].productElementList[0]))
        // const riskSign1List = state.riskList.filter(item => item.riskSign === '1')
        let newList = state.riskList.map((risk, index) => {
          const list = JSON.parse(JSON.stringify(cloneProductElementList))
          for (let j = 0; j < list.length; j++) {
            list[j].riskIdShow = risk.riskId
            let riskList = []
            switch (list[j]['elementCode']) {
              case 'productName': //  产品名称
                riskList = risk.calculateFactor.filter(item => item.factorType === 'Plan')
                if(code === '1018'){
                  list[j].firstValue = risk.riskName
                }else{
                  list[j].firstValue = `${risk.riskName} ` + (riskList && riskList.length && riskList[0].factorValueName ?`(${riskList[0].factorValueName})` : '')
                }
                list[j].id += j
                break
              case 'feeYear': // 缴费期间
                //  || item.factorType === 'PayTimes' || item.factorType === 'PayType'
                riskList = risk.calculateFactor.filter(item => item.factorType === 'FeeYear')
                if(riskList.length && riskList[0].factorInMain){
                  list[j].firstValue = riskList[0].factorValueName
                }else if(riskList.length){
                  list[j].firstValue = riskList[0].factorValueName
                }else{
                  list[j].firstValue = ''
                  list[j].required = '0'
                }
                break
              case 'insurancePeriod': // 保险期间
              case 'period': //保险信息
                riskList = risk.calculateFactor.filter(item => item.factorType === 'Period')
                if(riskList.length && riskList[0].factorInMain){
                  list[j].firstValue = riskList[0].factorValueName
                }else if(riskList.length){
                  list[j].firstValue = riskList[0].factorValueName
                }else{
                  list[j].firstValue = ''
                  list[j].required = '0'
                }
                break
              case 'Amnt':
              case 'amount': // 保额
                riskList = risk.calculateFactor.filter(n => n.factorType === 'Amnt')
                if(risk.exemptRisk === 'Y' && result){
                  var preList = result.riskPremiumList.filter(pre => pre.riskId === risk.riskId)
                  if(preList.length && preList[0].exemptRisk === 'Y'){
                    list[j].firstValue = preList[0].exemptCoverage
                  }
                }else{
                  if(riskList.length && riskList[0].factorInMain){
                    list[j].firstValue = riskList[0].factorValueName
                  }else if(riskList.length){
                    list[j].firstValue = riskList[0].factorValueName
                  }
                }

                break
              case 'premium': // 保费
                // 保费算保额的
                if(risk.premiumCalculation === '2'){
                  if(risk.riskSign === '2' && risk.supRiskCode ==='U24S'&& risk.riskCode === '100000000889'){
                    riskList = risk.calculateFactor.filter(item => item.factorType === 'Prem')
                  }else if(risk.supRiskCode === 'AV1' && risk.riskCode === '100000000887'){
                    let baseCoverage = 0
                    let mult = 0
                    for (let i = 0; i < risk.calculateFactor.length; i++) {
                      let item = risk.calculateFactor[i]
                      if (item.factorType === 'BaseCoverage') {
                        baseCoverage = item.factorValue ? Number(item.factorValue) : 0
                      }
                      if (item.factorType === 'Mult') {
                        mult = item.factorValue ? Number(item.factorValue) : 0
                      }
                    }
                    list[j].firstValue = baseCoverage * mult
                  }else{
                    riskList = risk.calculateFactor.filter(item => item.factorType === 'Amnt')
                  }
                  if(riskList.length){
                    list[j].firstValue = riskList[0].factorValue
                  }
                }else{
                  if(result && result.riskPremiumList.length){
                    list[j].firstValue = result.riskPremiumList[(risk.riskSign === '1' ? 0 : index)].premium
                  }
                }
                break
            }
            if(!list[j].firstValue && list[j].elementCode !== 'healthToldFlag' && list[j].elementCode !== 'imageAttachments'){
              list[j].isShow = true
            }
          }
          return list
        }).flat()
        let healthList = newList.filter (item => item.elementCode === 'healthToldFlag')
        for (let i = 0; i < healthList.length-1; i++) {
          let index = newList.findIndex(item => item.elementCode === 'healthToldFlag')
          newList.splice(index, 1)
        }
        let imageList = newList.filter (item => item.elementCode === 'imageAttachments')
        for (let i = 0; i < imageList.length-1; i++) {
          let index = newList.findIndex(item => item.elementCode === 'imageAttachments')
          newList.splice(index, 1)
        }
        // dataList[0].productElementList[0] = newList
        dataList[0].productElementList[0] = newList.reduce((prev, cur) => {
          let index = prev.findIndex((item) => {
            return item.elementCode === cur.elementCode && item.firstValue === cur.firstValue && item.riskIdShow === cur.riskIdShow
          })
          if (index === -1) {
            prev.push(cur)
          }
          return prev
        }, [])
      }
      if(code === '2079'){ // 泰康
        state.premium = 0
        dataList[0].productElementList[0].forEach(item =>{
          if (item.elementCode === 'premium' && item.firstValue){
            state.premium += (item.firstValue*1)
          }
        })
      }
    }


    const getInsuranceInformation = () => {
      let list = []
      state.showData2 = []
      if (sessionStorage.getItem('inputShowList')) {
        list = JSON.parse(sessionStorage.getItem('inputShowList'))
      } else {
        list = window.getInputShowList ? window.getInputShowList() : []
        sessionStorage.setItem('inputShowList', JSON.stringify(list))
      }
      if (list && list.length > 0) {
        list.forEach(item => {
          state.riskList[0].calculateFactor.forEach(cal => {
            if (item.value === cal.factorType && item.value !== 'Plan' && item.value !== 'Money') {
              item.name = cal.factorValueName
            }
            if (item.value === 'Plan' && cal.factorType === 'Plan') {
              item.name = `${JSON.parse(store.getters['insured/getProductDetailData']).productInfo.productName}(${cal.factorValueName})`
            }
          })
        })
        list.forEach(item => {
          state.showData2.push({label: item.label, value: item.name, type: item.value})
        })
        state.showProductInfo = true
      } else {
        state.showProductInfo = false
      }
    }
    watch(() => state.premium, (newValue) => {
      state.showData2.forEach(item => {
        if (item.type === 'Money') item.value = newValue
        if (item.type === 'Amnt') item.value = simplifyNum(item.value)
      })
    })
    // 转换 保额 单位
    const simplifyNum = (number) => {
      // 1E3 --> 1000   1E4--> 10000 以此类推
      if (!number && number != 0) return number
      var str_num
      if (number >= 1E3 && number < 1E4) {
        str_num = number / 1E3
        return str_num + '千'
      } else if (number >= 1E4 && number < 1E6) {
        str_num = number / 1E4
        return str_num + '万'
      } else if (number >= 1E6 && number < 1E7) {
        str_num = number / 1E6
        return str_num + '百万'
      } else if (number >= 1E7 && number < 1E8) {
        str_num = number / 1E7
        return str_num + '千万'
      } else if (number >= 1E8 && number < 1E10) {
        str_num = number / 1E8
        return str_num + '亿'
      } else if (number >= 1E10 && number < 1E11) {
        str_num = number / 1E10
        return str_num + '百亿'
      } else if (number >= 1E11 && number < 1E12) {
        str_num = number / 1E11
        return str_num + '千亿'
      } else if (number >= 1E12) {
        str_num = number / 1E12
        return str_num + '万亿'
      } else { //一千以下
        return number
      }
    }
    onMounted(() => {
      document.title = '信息填写'
      // startRecord(state.tradeNo)
      // 获取代理人城市编码
      localStorage.removeItem('isShareFlag')
      const userId = router.currentRoute.value.query.userId ?? ''
        if (userId) {
          store.commit('insured/setUserId', userId)
        }
    })
    onBeforeUpdate(()=>{
      document.title = '信息填写'
    })
    const insuranceErrorHandleOk = function () {
      if(state.autoCheckFlag === '0'){
        if (Number(state.signFlag) === 1) {
          //   需要手机号验证码
          state.visible = true
          state.loadingShow = false
        }else{
          state.routerFlag = true
          routerJump(state.tradeNo)
        }
      }else{
        insuranceErrorHandleOff()
      }
    }
    const insuranceErrorHandleOff = () =>{
      state.insuranceError = false
      state.isIdentical = false
      state.loadingShow = false
    }
    const router = useRouter()
    // 这个现在没用上
    const premCalculator = () => {
      let a = false
      if (!a) return
      let result = {bool: true}
      if (state.insuredObj.insureds && state.insuredObj.insureds.length === 1) {
        if (state.insuredObj.insureds[0].relationToAppnt.relationToAppnt === '01' &&
          !state.insuredObj.applicant[0].appntBirthday.appntBirthday) {
          result = {
            bool: true
          }
        } else {
          result = meetTheConditions()
        }
      }
      if (result.bool) {
        let productInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo
        let data = {
          productId: state.productId,
          riskList: state.riskList
        }
        getPremCalculatorParam(data, productInfo)
        store.dispatch('insured/changeInsureJSInfo', {
          riskList: JSON.stringify(data)
        })
        request(getExtendUri('calPremium'), {
          productInfo: data
        }).then(res => {
          if (res.code === 1 && res.result) {
            state.premium = res.result.totalPremium
            handelPayTypeData()
          } else {
            message['error'](res.message)
          }
        })
      } else {
        showErrTipModal(result)
      }
    }
    // 初始数据
    const Initialization = () => {
      state.loadingShow = true
      request(getExtendUri('selectByInsuredId'), {data: {tradeNo: state.tradeNo}}).then(res => {
        // 告知问卷的
        //获取信息录入页数据
        state.showData = JSON.parse(JSON.parse(store.getters['insured/getRouterJump']).pageJson)
        state.showData.push(benefitConfig)
        state.showDataCopy = JSON.parse(JSON.parse(store.getters['insured/getRouterJump']).pageJson)
        state.showDataCopy.push(benefitConfig)
        let selectRisk = JSON.parse(store.getters['insured/getRiskList'])
        state.isInclude = false
        if (selectRisk.riskList.length > 0) {
          selectRisk.riskList.forEach(item => {
            item.calculateFactor.forEach(ite => {
              if (ite.factorType === 'ElementDuty' && ite.factorValue === '2') {
                state.isInclude = true
              }
            })
          })
        }
        if (res.code === 1) {
          // 回显
          //  获取展示数据
          state.callbackData = res.result.contInfo
          if (state.callbackData.beneficiarys?.length > 0) {
            state.designatedBenefitList = []
            state.callbackData.beneficiarys.forEach(item => {
              if (item.idDateType === '1') {
                item.idTypeEndDate = '9999-01-01'
              }
              if (item.bnfType === '1') {
                state.designatedBenefitList.push(item)
              }
            })
          }
          if (state.callbackData.applicant.salary) {
            state.callbackData.applicant.salary = Number(state.callbackData.applicant.salary) / 10000
          }
          if (state.callbackData.applicant?.idDateType === '1') {
            state.callbackData.applicant.idTypeEndDate = '9999-01-01'
          }
          if (state.callbackData.insureds?.length) {
            state.callbackData.insureds.forEach(item => {
              if (item.salary) {
                item.salary = Number(item.salary) / 10000
              }
              if (item.idDateType === '1') {
                item.idTypeEndDate = '9999-01-01'
              }
            })
          }
          let insuredsType = true
          if(res.result.contInfo.contract.excelUrl){
            handelEditInsuredsExcelUrl(state.callbackData,state.showData )
            insuredsType = false
          }
          state.showData.forEach(item => {
            if ((item.moduleType === 'insureds' && insuredsType) || item.moduleType === 'beneficiarys') {
              //  被保人、受益人特殊处理
              let showObj = item.productElementList
              let num = state.callbackData[item.moduleType].length
              for (let i = 0; i < num; i++) {
                showObj?.[i]?.forEach(res => {
                  if (res.elementCode === 'bnfType') {
                    res.firstValue = state.callbackData[item.moduleType][i][res.elementCode]
                    if (res.firstValue === '1') {
                      state.addBenefitModule = true
                    }
                    // console.log(state.callbackData[item.moduleType][i][res.elementCode], 'wwww')
                  }
                  res.firstValue = state.callbackData[item.moduleType][i][res.elementCode]
                  if (router.currentRoute.value.query.disabled && item.moduleType != 'contract') {
                    //回显返回数据，且只能修改投保银行卡信息
                    res.disabledType = true
                  } else {
                    res.disabledType = false
                  }
                  if (res.elementCode != 'bnfType') {
                    res.isShow = false
                  }
                  if(res.elementCode === 'occupationCode'){
                    res.show_Level = state.callbackData[item.moduleType][i]['occupationLevel']
                  }
                  if(res.elementCode === 'updateLodeDetail' || res.elementCode === 'uploadIDcard'){
                    res.firstValue = state.callbackData[item.moduleType][i]['imageList']
                  }
                  res.oneself = false

                  let tagName = res.elementCode + '_tagName'
                  res.show_tagName = state.callbackData[item.moduleType][i][tagName] ? state.callbackData[item.moduleType][i][tagName] : state.callbackData[item.moduleType][i][res.elementCode]
                })
              }
            } else if(item.moduleType !== 'insureds' && item.moduleType !== 'addBeneficiarys'){
              item.productElementList[0].forEach(res => {
                res.firstValue = ''
                res.firstValue = state.callbackData[item.moduleType][res.elementCode]
                if(res.elementCode === 'updateLodeDetail' || res.elementCode === 'uploadIDcard'){
                  res.firstValue = state.callbackData[item.moduleType]['imageList']
                }
                let tagName = res.elementCode + '_tagName'
                if (router.currentRoute.value.query.disabled && item.moduleType != 'contract') {
                  //回显返回数据，且只能修改投保银行卡信息
                  res.disabledType = true
                } else {
                  res.disabledType = false
                }
                if(res.elementCode === 'occupationCode'){
                  res.show_Level = state.callbackData[item.moduleType]['occupationLevel']
                }
                res.show_tagName = state.callbackData[item.moduleType][tagName] || ''
              })
            }
          })
        } else {
          let obj = {
            'stature' : '',
            'avoirdupois': '',
            'smokingQuantity' : ''
          }
          // 获取投被报人

          state.riskList[0].calculateFactor.forEach(item =>{
            const factorType = item.factorType.charAt(0).toLowerCase() + item.factorType.slice(1)
            if (factorType in obj){
              obj[factorType] = item.factorValue
            }
          })
          //  正常展示+数据处理
          state.showData.forEach(item => {
            item.productElementList[0].forEach(res => {
              res.firstValue = null
              res.show_tagName = null
              if(res.elementCode in obj){
                res.firstValue = obj[res.elementCode]
                if(res.elementCode === 'updateLodeDetail' || res.elementCode === 'uploadIDcard'){
                  res.firstValue = obj['imageList']
                }
              }
            })
          })
        }
        handelPayTypeData(state.premiumObj)
        setRelationToAppntType() // 被保人
        let dataType = showObjFun()
        if (dataType === 'Y'){
          getPreviewData()  // 受益人
          reconfigurationTargetList() // 装修宝的赋值
        }
      })
    }
    // 这个是上传excel的 被保人
    const handelEditInsuredsExcelUrl = (callbackData, showData) =>{
      let list = showData.filter(item => item.moduleType === 'insureds')[0].productElementList[0]
      for (let i = 0; i < list.length; i++) {
        if(list[i].elementCode === 'excelUrl'){
          list[i].firstValue = callbackData.contract.excelUrl
        }
        if(list[i].elementCode === 'insuredNum' && callbackData.insureds && callbackData.insureds.length > 0){
          list[i].firstValue = callbackData.insureds.length
        }
      }
    }

    // 装修宝的赋值
    const reconfigurationTargetList = () =>{
      let targetList = state.showData.filter(item => item.moduleType === 'productInsuredTargetForm')
      // 标的信息
      if(targetList.length){
        targetList[0].productElementList[0].forEach(ele => {
          if(ele.elementCode === 'targetProjectCost'){
            ele.firstValue = state.riskList[0].calculateFactor.filter(risk => risk.factorType === 'EngineeringCost')[0].factorValue + '元'
          }
        })
      }
    }

    const showObjFun = function () {
      // 将页面数据处理成自己的格式
      for (let i = 0; i < state.showData.length; i++) {
        state.insuredObj[state.showData[i].moduleType] = []
        state.showData[i].productElementList.forEach((item, index) => {
          state.insuredObj[state.showData[i].moduleType][index] = {}
          item.forEach((res, num) => {
            if(state.showData[i].moduleType === 'insuranceInfo'
              && res.elementCode !== 'healthToldFlag'
              && state.insuredObj[state.showData[i].moduleType][index]
              && state.insuredObj[state.showData[i].moduleType][index][res.elementCode]
              && state.insuredObj[state.showData[i].moduleType][index][res.elementCode][res.elementCode] !== res.firstValue){
              let elementCode = res.elementCode + num
              state.insuredObj[state.showData[i].moduleType][index][elementCode] = {
                [elementCode]: res.firstValue,
                elementName: res.elementName,
                required: res.required,
                elementId: res.id,
                index: index,
                moduleType: state.showData[i].moduleType,
              }
            }else{
              state.insuredObj[state.showData[i].moduleType][index][res.elementCode] = {
                [res.elementCode]: res.firstValue,
                elementName: res.elementName,
                required: res.required,
                elementId: res.id,
                index: index,
                moduleType: state.showData[i].moduleType,
              }
            }

          })
        })
      }
      state.loadingShow = false
      state.insuredBackUp = JSON.parse(JSON.stringify(state.insuredObj))
      return 'Y'
    }
    // 回显是被保人的状态显示
    const setRelationToAppntType = async (type='',keys, data, index) => {
      await hiddenElement(state.showData)
      if(type ==='1' && state.insuredObj['insureds']){
        const list = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList[index]
        const keyObj = state.insuredObj['insureds'][index]
        Object.keys(keyObj).forEach(key =>{
          list.forEach(ele =>{
            if(ele.elementCode === key){
              keyObj[key].required = ele.required
            }
          })
        })
      }
    }
    // 下一步的赋值
    const oneselfValuation = function (type, index) {
      let obj = {}
      if (type === 'insureds') {
        //被保人为本人，投保人赋值
        obj = {
          name: state.insuredObj.applicant[0].appntName ? state.insuredObj.applicant[0].appntName.appntName : state.insuredObj[type][index]?.name?.name,
          sex: state.insuredObj.applicant[0].appntSex ? state.insuredObj.applicant[0].appntSex.appntSex : state.sex,
          birthday: state.insuredObj.applicant[0].appntBirthday ? state.insuredObj.applicant[0].appntBirthday.appntBirthday : state.insuredObj[type][index]?.birthday?.birthday,
          idType: state.insuredObj.applicant[0].appntIdType ? state.insuredObj.applicant[0].appntIdType.appntIdType : '',
          idNo: state.insuredObj.applicant[0].appntIdNo ? state.insuredObj.applicant[0].appntIdNo.appntIdNo : '',
          idTypeStartDate: state.insuredObj.applicant[0].idTypeStartDate ? state.insuredObj.applicant[0].idTypeStartDate.idTypeStartDate : state.insuredObj[type][index]?.idTypeStartDate?.idTypeStartDate,
          idTypeEndDate: state.insuredObj.applicant[0].idTypeEndDate ? state.insuredObj.applicant[0].idTypeEndDate.idTypeEndDate : state.insuredObj[type][index]?.idTypeEndDate?.idTypeEndDate,
          marriage: state.insuredObj.applicant[0].marriage ? state.insuredObj.applicant[0].marriage.marriage : state.insuredObj[type][index]?.marriage?.marriage,
          nativePlace: state.insuredObj.applicant[0].nativePlace ? state.insuredObj.applicant[0].nativePlace.nativePlace : '',
          nationality: state.insuredObj.applicant[0].nationality ? state.insuredObj.applicant[0].nationality.nationality : '',
          industry: state.insuredObj.applicant[0].industry ? state.insuredObj.applicant[0].industry.industry : '',
          workType: state.insuredObj.applicant[0].workType ? state.insuredObj.applicant[0].workType.workType : '',
          partTime: state.insuredObj.applicant[0].partTime ? state.insuredObj.applicant[0].partTime.partTime : '',
          occupationType: state.insuredObj.applicant[0].occupationType ? state.insuredObj.applicant[0].occupationType.occupationType : '',
          occupationCode:state.insuredObj.applicant[0].occupationCode ? state.insuredObj.applicant[0].occupationCode.occupationCode : state.insuredObj[type][index]?.occupationCode?.occupationCode,
          occupationLevel: state.insuredObj.applicant[0].occupationCode? state.insuredObj.applicant[0].occupationCode.occupationLevel : state.insuredObj[type][index]?.occupationCode?.occupationLevel,
          occupationWorkType: state.insuredObj.applicant[0].occupationCode ?state.insuredObj.applicant[0].occupationCode.occupationName : state.insuredObj[type][index]?.occupationCode?.occupationName,
          birthAddress: state.insuredObj.applicant[0].birthAddress ?state.insuredObj.applicant[0].birthAddress.birthAddress : state.insuredObj[type][index]?.birthAddress?.birthAddress,
          license: state.insuredObj.applicant[0].license ? state.insuredObj.applicant[0].license.license : '',
          licenseType: state.insuredObj.applicant[0].licenseType ? state.insuredObj.applicant[0].licenseType.licenseType : '',
          sequenceNo: '1', //被保人序号
          relationToMainInsured:state.insuredObj.applicant[0].relationToMainInsured? state.insuredObj.applicant[0].relationToMainInsured.relationToMainInsured: state.insuredObj.insureds[index]?.relationToMainInsured?.relationToMainInsured, //与主被保人关系，字典表
          relationToAppnt: '01', //与投保人关系，字典表
          smokingQuantity: state.insuredObj.applicant[0].smokingQuantity ? state.insuredObj.applicant[0].smokingQuantity.smokingQuantity : state.insuredObj.insureds[index]?.smokingQuantity?.smokingQuantity,
          //投保人年收入
          salary: state.insuredObj.applicant[0].salary ?state.insuredObj.applicant[0].salary.salary :  state.insuredObj[type][index]?.salary?.salary,
          isSocialInsu: '', //公费医疗与社会医疗保险
          socialInsuFlag: state.insuredObj.applicant[0].socialInsuFlag ?state.insuredObj.applicant[0].socialInsuFlag.socialInsuFlag : state.insuredObj.insureds[index]?.socialInsuFlag?.socialInsuFlag ,//社保
          crsIdType: state.insuredObj.applicant[0].crsIdType ? state.insuredObj.applicant[0].crsIdType.crsIdType : state.insuredObj[type][index]?.crsIdType?.crsIdType,
          insuredAge: state.insuredObj.applicant[0].appntAge ? Number(state.insuredObj.applicant[0].appntAge.appntAge) : null,
          homeAddress: state.insuredObj.applicant[0].homeAddress ? state.insuredObj.applicant[0].homeAddress.homeAddress : state.insuredObj[type][index]?.homeAddress?.homeAddress,
          areaCode: state.insuredObj.applicant[0].areaCode ? state.insuredObj.applicant[0].areaCode.areaCode : state.insuredObj[type][index]?.areaCode?.areaCode,
          degree: state.insuredObj.applicant[0].degree ? state.insuredObj.applicant[0].degree.degree : state.insuredObj[type][index]?.degree?.degree,
          stature: state.insuredObj.applicant[0].stature ? state.insuredObj.applicant[0].stature.stature : state.insuredObj[type][index]?.stature?.stature,
          avoirdupois: state.insuredObj.applicant[0].avoirdupois ? state.insuredObj.applicant[0].avoirdupois.avoirdupois : state.insuredObj[type][index]?.avoirdupois?.avoirdupois,
          imageList: state.insuredObj.applicant[0].imageList ? state.insuredObj.applicant[0].imageList : state.insuredObj[type][index]?.imageList,

          province: state.insuredObj.applicant[0].province ? state.insuredObj.applicant[0].province.province : '',
          city: state.insuredObj.applicant[0].city ? state.insuredObj.applicant[0].city.city : '',
          county: state.insuredObj.applicant[0].county ? state.insuredObj.applicant[0].county.county : '',
          postalAddress: state.insuredObj.applicant[0].postalAddress ? state.insuredObj.applicant[0].postalAddress.postalAddress : '',
          zipCode: state.insuredObj.applicant[0].zipCode ? state.insuredObj.applicant[0].zipCode.zipCode : state.insuredObj[type][index]?.zipCode?.zipCode,
          homeProvince: state.insuredObj.applicant[0].homeProvince ? state.insuredObj.applicant[0].homeProvince.homeProvince : '',
          homeCity: state.insuredObj.applicant[0].homeCity ? state.insuredObj.applicant[0].homeCity.homeCity : '',
          homeCounty: state.insuredObj.applicant[0].homeCounty ? state.insuredObj.applicant[0].homeCounty.homeCounty : '',
          postalAddress1: state.insuredObj.applicant[0].postalAddress1 ? state.insuredObj.applicant[0].postalAddress1.postalAddress1 : '',
          zipCode1: state.insuredObj.applicant[0].zipCode1 ? state.insuredObj.applicant[0].zipCode1.zipCode1 : '',
          mobile: state.insuredObj.applicant[0].mobile ? state.insuredObj.applicant[0].mobile.mobile : state.insuredObj[type][index]?.mobile?.mobile,
          companyAreaCode: state.insuredObj.applicant[0].companyAreaCode ? state.insuredObj.applicant[0].companyAreaCode.companyAreaCode : '',
          companyPhone: state.insuredObj.applicant[0].companyPhone ? state.insuredObj.applicant[0].companyPhone.companyPhone : '',
          companyExtension: state.insuredObj.applicant[0].companyExtension ? state.insuredObj.applicant[0].companyExtension.companyExtension : '',
          fax: state.insuredObj.applicant[0].fax ? state.insuredObj.applicant[0].fax.fax : '',
          homeAreaCode: state.insuredObj.applicant[0].homeAreaCode ? state.insuredObj.applicant[0].homeAreaCode.homeAreaCode : '',
          homePhone: state.insuredObj.applicant[0].homePhone ? state.insuredObj.applicant[0].homePhone.homePhone : state.insuredObj[type][index]?.homePhone?.homePhone,
          homeExtension: state.insuredObj.applicant[0].homeExtension ? state.insuredObj.applicant[0].homeExtension.homeExtension : '',
          phone: state.insuredObj.applicant[0].phone ? state.insuredObj.applicant[0].phone.phone : state.insuredObj[type][index]?.phone?.phone,
          appntReturnCall: state.insuredObj.applicant[0].appntReturnCall ? state.insuredObj.applicant[0].appntReturnCall.appntReturnCall : '',
          grpName: state.insuredObj.applicant[0].grpName ? state.insuredObj.applicant[0].grpName.grpName : '',
          companyAddress: state.insuredObj.applicant[0].companyAddress ? state.insuredObj.applicant[0].companyAddress.companyAddress : '',
          email: state.insuredObj.applicant[0].email ? state.insuredObj.applicant[0].email.email : state.insuredObj[type][index]?.email?.email,
          insureImparts: state.insuredObj.applicant[0].applicantImparts ? state.insuredObj.applicant[0].applicantImparts.applicantImparts : null,
          workCompanyName: state.insuredObj.applicant[0].workCompanyName ? state.insuredObj.applicant[0].workCompanyName.workCompanyName : state.insuredObj[type][index]?.workCompanyName?.workCompanyName,
          yearIncome: state.insuredObj.applicant[0].yearIncome?  state.insuredObj.applicant[0].yearIncome.yearIncome : state.insuredObj[type][index]?.yearIncome?.yearIncome,
          // excelUrl: state.insuredObj.applicant[0].applicantImparts ? state.insuredObj.applicant[0].applicantImparts.applicantImparts : null
        }
      } else if (type === 'beneficiarys') {
        //受益人为本人，被保人赋值
        obj = {
          bnfType: '受益人类别，字典表',
          bnfNo: '1a',
          insuSeqNo: '受益人所属被保人序号',
          insuredName: state.insuredObj.insureds[index].name ? state.insuredObj.insureds[index].name.name : '',
          relationToInsured: '受益人与被保人关系，字典表',
          name: state.insuredObj.insureds[index].name ? state.insuredObj.insureds[index].name.name : '',
          sex: state.insuredObj.insureds[index].sex ? state.insuredObj.insureds[index].sex.sex : '',
          birthday: state.insuredObj.insureds[index].birthday ? state.insuredObj.insureds[index].birthday.birthday : '',
          idType: state.insuredObj.insureds[index].idType ? state.insuredObj.insureds[index].idType.idType : '',
          idNo: state.insuredObj.insureds[index].idNo ? state.insuredObj.insureds[index].idNo.idNo : '',
          idTypeStartDate: state.insuredObj.insureds[index].idTypeStartDate ? state.insuredObj.insureds[index].idTypeStartDate.idTypeStartDate : '',
          idTypeEndDate: state.insuredObj.insureds[index].idTypeEndDate ? state.insuredObj.insureds[index].idTypeEndDate.idTypeEndDate : '',
          nativePlace: state.insuredObj.insureds[index].nativePlace ? state.insuredObj.insureds[index].nativePlace.nativePlace : '',
          bnfGrade: '受益人级别',
          bnfLot: '100',
          bnfAddress: state.insuredObj.insureds[index].postalAddress ? state.insuredObj.insureds[index].postalAddress.postalAddress : '',
          occupationCode: state.insuredObj.insureds[index].occupationCode ? state.insuredObj.insureds[index].occupationCode.occupationCode : '',
          // occupationLevel: state.insuredObj.insureds[index].occupationCode ? state.insuredObj.insureds[index].occupationCode.occupationLevel : '',

        }
      }
      return obj

    }
//     const groupBy = (array, key) => {
//       return array.reduce((result, currentItem) => {
//       // 使用 key 函数获取分组的键
//       const groupKey = key(currentItem)
//       // 确保 result 对象中有对应键的数组
//       if (!result[groupKey]) {
//         result[groupKey] = []
//       }
//     // 将当前项添加到对应分组的数组中
//     result[groupKey].push(currentItem)
//     return result
//   }, {})
// }
    const checkCustomerInfo = async (params) => {
      return new Promise(resolve => {
        request(getExtendUri('checkCustomerInfo'), {
          ...params
        }).then(res => {
          if (res.code == '1') {
            resolve(true)
          } else {
            resolve(res.message)
          }
        }).catch(() => {
          resolve('投保人信息校验失败')
        })
      })
    }
    // const getElementIsShow = (key, index, value) => {
    //   for (let i = 0; i < state.showData.length; i++) {
    //     if (state.showData[i].moduleType === key) {
    //       for(let j = 0; j < state.showData[i].productElementList[index].length; j++) {
    //         if ()
    //       }
    //     }
    //   }
    // }
    const nextPage = async () => {
      // console.log(state.insuredObj, 'state.insuredObj')
      console.debug('准备下一步', state.insuredObj)
      const productDetailData = JSON.parse(store.getters['insured/getProductDetailData'])
      const userId = store.getters['insured/getUserId']
      console.log(productDetailData.productInfo, userId, 'productDetailData?.productInfo?.productCode')
      // const shareId =  router.currentRoute.value.query.shareId
      if (userId && productDetailData?.productInfo?.productCode === 'RUIEBAO' && state.insuredObj.applicant?.[0]?.['appntName']['appntName'] && state.insuredObj.applicant?.[0]?.['appntIdNo']['appntIdNo'] && state.insuredObj.applicant[0]['mobile']['mobile']) {
        let isNext = await checkCustomerInfo({
          userId: store.getters['insured/getUserId'],
          idCard: state.insuredObj.applicant[0]['appntIdNo']['appntIdNo'],
          realName: state.insuredObj.applicant[0]['appntName']['appntName'],
          phone: state.insuredObj.applicant[0]['mobile']['mobile']
        })
        if (isNext !== true) {
          state.detailModal = isNext
          state.isIdentical = true
          return false
        }
      }
      let a = await tradeNoStateIshavePay(state.tradeNo)
      if (a === '2'){
        message.error('异常操作，该单已支付,不允许重新提交')
        return
      }
      if (a === '-1') {
        message.error('服务异常,请稍后操作')
        return
      }
      state.insuredObj['insureds'].forEach(item => {
        if(item.occupationCode){
          if (item.relationToAppnt && item.relationToAppnt['relationToAppnt'] !== '01') {
            item.occupationLevel = {}
            item.occupationWorkType = {}
            item.occupationLevel['occupationLevel'] = item.occupationCode && item.occupationCode.occupationLevel ? item.occupationCode.occupationLevel : ''
            item.occupationWorkType['occupationWorkType'] = item.occupationCode && item.occupationCode.occupationName ? item.occupationCode.occupationName : ''
          }else if(item.relationToAppnt && item.relationToAppnt['relationToAppnt'] === '01'){
            item.occupationCode['occupationCode'] = state.insuredObj.applicant[0].occupationCode ? state.insuredObj.applicant[0].occupationCode.occupationCode : item.occupationCode['occupationCode']
          }
        }

      })
      if (state.addBenefitModule) {
        // const bnfLotObj = groupBy(state.designatedBenefitList, item => item.bnfNo)
        // for (let key in bnfLotObj) {
        //   if (bnfLotObj[key].length > 0) {
        //     let bnfLot = 0
        //     for (let i = 0; i < bnfLotObj[key].length; i++) {
        //       bnfLot += Number(bnfLotObj[key][i].bnfLot)
        //     }
        //     if (bnfLot != 100) {
        //       Dialog.alert({
        //         message: '同一受益顺序受益人比例总和应为100%'
        //       }).then(() => {
        //       })
        //       return false
        //     }
        //   }
        // }
        if (state.designatedBenefitList.length === 0) {
          state.detailModal = '请添加指定受益人'
          state.isIdentical = true
          return false
        }
        let bnfLot = 0
        state.designatedBenefitList.forEach(item => {
          bnfLot += Number(item.bnfLot)
        })
        if (bnfLot != 100) {
          state.detailModal = '同一受益顺序受益人比例总和应为100%'
          state.isIdentical = true
          return false
        }
      }
      let phoneList = []
      let idList = []
      if (state.insuredObj['applicant'][0]['mobile']['mobile']) {
        phoneList.push(state.insuredObj['applicant'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['applicant'][0]['appntIdNo']['appntIdNo'])
      }
      if (state.insuredObj['insureds'][0]['mobile']['mobile'] && state.insuredObj['insureds'][0]['relationToAppnt']['relationToAppnt'] !== '01' && state.insuredObj['insureds'][0]['birthday']['birthday'] && calAge(state.insuredObj['insureds'][0]['birthday']['birthday']) >= 18) {
        phoneList.push(state.insuredObj['insureds'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['insureds'][0]['idNo']['idNo'])
      }
      state.designatedBenefitList.forEach(bItem => {
        if (calAge(bItem.birthday) >= 18 && bItem.idNo ) {
          phoneList.push(bItem.mobile)
          idList.push(bItem.idNo)
        }
      })
      if ([... new Set(phoneList)].length < [... new Set(idList)].length) {
        state.detailModal = '投保人、被保人、受益人手机号不可重复'
        state.isIdentical = true
        return false
      }
      //校验必填非必填
      for (let key in state.insuredObj) {
        if (key === 'addBeneficiarys') {
          // state.insuredObj[key] = []
          continue
        }
        if (key === 'healthNotification') {
          state.insuredObj[key] = []
          continue
        }
        for (let i = 0; i < state.insuredObj[key].length; i++) {
          if (key === 'beneficiarys') {
            for (let i = 0; i < state.insuredObj[key].length; i++) {
              if (state.insuredObj[key][i].bnfType['bnfType'] == state.beneficiarysDictionary && state.beneficiarysDictionary !== 'str' ) {
                console.debug('受益人')
              }
            }
          } else {
            for (let value in state.insuredObj[key][i]) {
              if(value === 'relationToMainInsured') {
                const newArr  = state.insuredObj[key].filter(item => item[value][value] === '01')
                if(newArr && newArr.length >1){
                  state.detailModal = '主被保险人只能有一个'
                  state.isIdentical = true
                  return
                }else if(!newArr || !newArr.length){
                  state.detailModal = '必须有一个主被保险人'
                  state.isIdentical = true
                  return
                }
              }
            }
            for (let value in state.insuredObj[key][i]) {
              console.log(state.insuredObj[key][i], 'state.insuredObj[key][i]')
              if (state.insuredObj[key][i]?.['relationToAppnt']?.['relationToAppnt'] === '01') continue
              if (state.insuredObj[key][i][value]['required'] == '1' && (state.insuredObj[key][i][value][value] === null || state.insuredObj[key][i][value][value] === undefined || state.insuredObj[key][i][value][value] === '' || state.insuredObj[key][i][value][value] === '新增')) {
                if(value === 'excelUrl'){
                  state.detailModal = '请上传被保险人资料清单'
                }else{
                  const index = state.insuredObj[key][i][value].elementName?.indexOf('[')
                  console.log(state.insuredObj[key], 'state.insuredObj[key]')
                  if(index !== -1){
                    state.detailModal = `${(value === 'updateLodeDetail' || value === 'newBankCardUrlnewBankCardUrl') ? '请上传' : '请填写'}` + state.insuredObj[key][i][value]?.elementName?.split('[')[0]
                  }else{
                    state.detailModal = `${(value === 'updateLodeDetail' || value === 'newBankCardUrl') ? '请上传' : '请填写'}` + state.insuredObj[key][i][value].elementName
                  }
                }
                state.isIdentical = true
                return
              }
              else if(state.insuredObj[key][i][value]['required'] == '1'){
                if (value === 'updateLodeDetail' && state.insuredObj[key][i][value][value] === 'N'){
                  state.detailModal = '请上传' + state.insuredObj[key][i][value].elementName
                  state.isIdentical = true
                  return
                }else{
                  // 影像上传的状态
                  state.insuredObj[key][i]['uploadType'] = {}
                  state.insuredObj[key][i]['uploadType']['uploadType'] = 'Y'
                }
                if (value === 'relationToAppnt') {
                  console.log(state.insuredObj[key][i][value][value], 'state.insuredObj[key][i][value][value]')
                  let applicantMarriage = state.insuredObj.applicant[i]['marriage']['marriage']
                  let insuredMarriage = state.insuredObj.insureds[i]?.['marriage']?.['marriage']
                  let flag = {bool: true,msg: ''}
                  if (state.insuredObj[key][i][value][value] === '01') {
                    for (let i = 0; i < state.designatedBenefitList.length; i++) {
                    if (applicantMarriage && state.designatedBenefitList[i].relationToInsured === '02' && (applicantMarriage !== '1' || state.designatedBenefitList[i].marriage !== '1')) {
                      flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
                      showErrTipModal(flag)
                      return false
                    }
                  }
                  }
                  if (state.insuredObj[key][i][value][value] === '02') {
                    if(insuredMarriage && applicantMarriage && (applicantMarriage !== '1' || insuredMarriage !== '1')) {
                      flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
                      showErrTipModal(flag)
                      return false
                    }
                  }
                  for (let i = 0; i < state.designatedBenefitList.length; i++) {
                    console.log(state.designatedBenefitList[i].relationToInsured, insuredMarriage, 'insuredMarriageinsuredMarriageinsuredMarriage')
                    if (insuredMarriage && state.designatedBenefitList[i].relationToInsured === '02' && (insuredMarriage !== '1' || state.designatedBenefitList[i].marriage !== '1')) {
                      flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
                      showErrTipModal(flag)
                      return false
                    }
                  }
                }
                if (value === 'relationToInsured') {
                  console.log('wwwweeee')
                  let applicantMarriage = state.insuredObj.applicant[i]['marriage']['marriage']
                  let insuredMarriage = state.insuredObj.insureds[i]?.['marriage']?.['marriage']
                  let flag = {bool: true,msg: ''}
                  if (state.insuredObj[key][i][value][value] === '02' && insuredMarriage) {
                    if(applicantMarriage !== '1' || insuredMarriage !== '1') {
                      flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
                      showErrTipModal(flag)
                      return false
                    }
                  }
                  for (let i = 0; i < state.designatedBenefitList.length; i++) {
                    console.log(state.designatedBenefitList[i].relationToInsured, insuredMarriage, 'insuredMarriageinsuredMarriageinsuredMarriage')
                    if (state.designatedBenefitList[i].relationToInsured === '02' && (insuredMarriage !== '1' || state.designatedBenefitList[i].marriage !== '1')) {
                      flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
                      showErrTipModal(flag)
                      return false
                    }
                  }
                }
                if ((value === 'appntBirthday' || value === 'birthday') && key !== 'addBeneficiarys')  {
                  if (new Date(state.insuredObj[key][i][value][value]) > new Date().getTime()) {
                    state.detailModal = '请填写正确的' + `${key === 'applicant' ? '投保人' : '被保人'}` + state.insuredObj[key][i][value].elementName
                    state.isIdentical = true
                    return
                  }
                }
              }
                if (value === 'postalAddress' && state.insuredObj[key][i][value][value]) {
                  if (vTools('address', state.insuredObj[key][i][value][value]) !== true) {
                    state.detailModal = '请填写正确的' + state.insuredObj[key][i][value].elementName
                    state.isIdentical = true
                    return
                  }
                }
                if (value === 'idTypeStartDate') {
                  if (key === 'applicant') {
                    let applicantBirthday = state.insuredObj.applicant[i]['appntBirthday']['appntBirthday']
                    let idTypeStartDate = state.insuredObj[key][i][value][value]
                    if (applicantBirthday && idTypeStartDate) {
                      if (new Date(applicantBirthday).getTime() > new Date(idTypeStartDate).getTime() ||  new Date(idTypeStartDate).getTime() > new Date().getTime()) {
                        state.detailModal = '请填写正确的投保人证件有效起期'
                        state.isIdentical = true
                        return
                      }
                    }
                  }
                  if (key === 'insureds') {
                    console.log(key, 'key111')
                    let insuredsBirthday = state.insuredObj[key][i]['birthday']['birthday']
                    let idTypeStartDate = state.insuredObj[key][i][value][value]
                    if (insuredsBirthday && idTypeStartDate) {
                      console.log(insuredsBirthday, idTypeStartDate, '888999')
                      if (new Date(insuredsBirthday).getTime() > new Date(idTypeStartDate).getTime() ||  new Date(idTypeStartDate).getTime() > new Date().getTime()) {
                        state.detailModal = '请填写正确的被保人证件有效起期'
                        state.isIdentical = true
                        return
                      }
                    }
                  }
                }
                if (value === 'idTypeEndDate') {
                  if (key === 'applicant') {
                    let applicantBirthday = state.insuredObj.applicant[i]['appntBirthday']['appntBirthday']
                    let idTypeEndDate = state.insuredObj[key][i][value][value]
                    if (idTypeEndDate) {
                      if (new Date().getTime() > new Date(idTypeEndDate).getTime() || new Date(applicantBirthday).getTime() > new Date(idTypeEndDate).getTime()) {
                        state.detailModal = '请填写正确的投保人证件有效止期'
                        state.isIdentical = true
                        return
                      }
                    }
                  }
                  if (key === 'insureds') {
                    let insuredsBirthday = state.insuredObj[key][i]['birthday']['birthday']
                    let idTypeEndDate = state.insuredObj[key][i][value][value]
                    if (idTypeEndDate) {
                      if (new Date().getTime() > new Date(idTypeEndDate).getTime() || new Date(insuredsBirthday).getTime() > new Date(insuredsBirthday).getTime()) {
                        state.detailModal = '请填写正确的被保人证件有效止期'
                        state.isIdentical = true
                        return
                      }
                    }
                  }
              }
              if (value === 'crsIdType' && state.insuredObj[key][i][value][value] !== '0'){
                  state.detailModal = '非仅为中国大陆税收居民不支持线上投保'
                  state.isIdentical = true
                  return
                }
                if (value === 'salary') {
                  // State.valueSatate = State.valueSatate.replace(/^(-)*(\d+)\.(\d{0,1}).*$/g, '$1$2.$3')
                if (key === 'applicant') {
                  if (Number(state.insuredObj[key][i][value][value]) < 0.1) {
                    state.detailModal ='请填写正确的' + state.insuredObj[key][i][value].elementName
                    state.isIdentical = true
                    return false
                  } else {
                    if (vTools('salary', state.insuredObj[key][i][value][value]) !== true) {
                      state.detailModal = '请填写正确的' + state.insuredObj[key][i][value].elementName
                      state.isIdentical = true
                      return false
                    }
                  }
                } else {
                  if (vTools('salary', state.insuredObj[key][i][value][value]) !== true) {
                      state.detailModal = '请填写正确的' + state.insuredObj[key][i][value].elementName
                      state.isIdentical = true
                      return false
                    }
                }
                } else if (value === 'name' || value === 'appntName') {
                  let keyName = (key === 'applicant' ? 'appntIdType' : 'idType')
                  let idType = state.insuredObj[key][0][keyName][keyName]
                  if (idType === '15') {
                    let v = vTools('fName', state.insuredObj[key][i][value][value])
                    if (v !== true) {
                      state.detailModal = `请填写正确的${key === 'applicant' ? '投保人' : '被保人'}姓名`
                      state.isIdentical = true
                      return false
                    }
                  } else {
                    let v = vTools('name', state.insuredObj[key][i][value][value])
                    if (v !== true) {
                      state.detailModal = `请填写正确的${key === 'applicant' ? '投保人' : '被保人'}姓名`
                      state.isIdentical = true
                      return false
                    }
                  }
                }
                if (value === 'idNo' || value === 'appntIdNo') {
                  let idType = (key === 'applicant' ? state.insuredObj[key][0]['appntIdType']['appntIdType'] : state.insuredObj[key][0]['idType']['idType'])
                  let idNo = (key === 'applicant' ? state.insuredObj[key][0]['appntIdNo']['appntIdNo'] : state.insuredObj[key][0]['idNo']['idNo'])
                  if (idType && idNo) {
                    if (idType === '15') {
                      let VResult = vTools('wgrjlz', idNo)
                      if (VResult !== true) {
                        state.detailModal = `请填写正确的${key === 'applicant' ? '投保人' : '被保人'}证件号码`
                        state.isIdentical = true
                        return false
                      }
                    }
                    if (idType === '01') {
                      let VResult = vTools('pId', idNo)
                      if (VResult !== true) {
                        state.detailModal = `请填写正确的${key === 'applicant' ? '投保人' : '被保人'}证件号码`
                        state.isIdentical = true
                        return false
                      }
                    }
                  }
                }
                if (value === 'nativePlace') {
                let idType = (key === 'applicant' ? state.insuredObj[key][0]['appntIdType']['appntIdType'] : state.insuredObj[key][0]['idType']['idType'])
                let nativePlace = state.insuredObj[key][0][value][value]
                  if (idType && nativePlace && idType === '15'&& nativePlace === 'CHN') {
                    state.detailModal = '证件类型为外国人永久居留身份证时国籍不可为中国'
                    state.isIdentical = true
                    return false
                  }
                }
                if (value === 'updateLodeDetail' && key === 'insureds') {
                  let idType = (key === 'applicant' ? state.insuredObj[key][0]['appntIdType']['appntIdType'] : state.insuredObj[key][0]['idType']['idType'])
                  if (idType === '15' && (state.insuredObj[key][i][value][value] === 'N' || state.insuredObj[key][i]?.[value]?.[value]?.length === 0 ||state.insuredObj[key][i]?.[value]?.[value] === null)) {
                    state.detailModal = '请上传被保人证件影像'
                    state.isIdentical = true
                    return false
                  }
                }
                if (value === 'appntBirthday') {
                  let idType = (key === 'applicant' ? state.insuredObj[key][0]['appntIdType']['appntIdType'] : state.insuredObj[key][0]['idType']['idType'])
                  if (idType !== '01' && state.insuredObj[key][i][value][value]) {
                    if (calAge(state.insuredObj[key][i][value][value]) < 18) {
                      state.detailModal = '投保人年龄不能小于18周岁'
                      state.isIdentical = true
                      return
                    }
                  }
                }
            }
          }
        }
      }
      // 手机号验真
      const verifyShow = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.isVerifyMobile
      if(verifyShow === 'Y'){
        let verifyCodeList = store.getters['insured/getVerifyCode']
        const applicantMobile = state.insuredObj['applicant'][0]['mobile'].mobile
        if(applicantMobile && verifyCodeList.findIndex(item => item.mobile === applicantMobile) === -1) {
          state.detailModal = '投保人手机号未做校验,请先完成校验'
          state.isIdentical = true
          return
        }
        for (let i = 0; i <  state.insuredObj['insureds'].length; i++) {
          const insMobile = state.insuredObj['insureds'][i]['mobile']?.mobile
          if(insMobile && state.insuredObj['insureds'][i]['relationToAppnt']['relationToAppnt'] !== '01' &&  verifyCodeList.findIndex(item => item.mobile === insMobile) === -1) {
            state.detailModal = '被保人手机号未做校验,请先完成校验'
            state.isIdentical = true
            return
          }
        }
        for (let i = 0; i <  state.insuredObj['beneficiarys'].length; i++) {
          const bInsMobile = state.insuredObj['beneficiarys'][i]['mobile']?.mobile
          if(Number(state.insuredObj['beneficiarys'][i]['bnfType']['bnfType']) !== 3  && bInsMobile &&  verifyCodeList.findIndex(item => item.mobile === bInsMobile) === -1) {
            state.detailModal = '被保人手机号未做校验,请先完成校验'
            state.isIdentical = true
            return
          }
        }
      }
      // 国富保额,年龄,责任校验
      let flagType = checkGFRule(detailData.productInfo.supplierCode, state.insuredObj, state.riskList)
      if(flagType && flagType.type) {
        state.detailModal = flagType.msg
        state.isIdentical = flagType.type
        return
      }
      // 没用的代码~注释掉
      // handelContInfoDetail(state.insuredObj)

      //将组建返回数据组合成接口传参
      state.contInfo = {}
      //当前页面录入的信息
      for (let key in state.insuredObj) {
        if (state.insuredObj[key] === 'healthNotification') continue
        if (key === 'insureds' || key === 'beneficiarys') {
          //  insureds被保人 beneficiarys 受益人
          state.contInfo[key] = []
          state.insuredObj[key].forEach((res, index) => {
            let obj = {}
            //  判断是否是本人
            let relationToInsured = res['relationToInsured'] ? Number(res['relationToInsured']['relationToInsured']) : 0
            let relationToAppnt = res['relationToAppnt'] ? Number(res['relationToAppnt']['relationToAppnt']) : 0
            if (relationToInsured === 1 || relationToAppnt === 1) {
              obj = oneselfValuation(key, index, res,)
            }else {
              for (let keyA in res) {
                let objKey
                if(keyA === 'imageList'){
                  objKey = res[keyA]
                }else{
                  objKey = res[keyA][keyA]
                }
                obj[keyA] = objKey
              }
            }
            state.contInfo[key].push(obj)
          })
        } else {
          //其余的数组只有一条数据，组合成obj格式
          state.contInfo[key] = {}
          for (let keyA in state.insuredObj[key][0]) {
            let obj
            if(keyA === 'newBankCardUrl' && Array.isArray(state.insuredObj[key][0][keyA][keyA]) ){
              obj = state.insuredObj[key][0][keyA][keyA][0].label
            }else if(keyA === 'imageList'){
              obj = state.insuredObj[key][0][keyA]
            }else{
              obj = state.insuredObj[key][0][keyA][keyA]
            }
            state.contInfo[key][keyA] = obj
          }
        }
      }
      //险种信息数据格式处理
      state.contInfo.risks = []
      state.riskList.forEach(res => {
        res.calculateFactor.forEach(item => {
          if (item.factorType === 'insuredNum') {
            item.factorValue = (state.insuredObj['insureds'].length) + ''
            item.factorValueName = state.insuredObj['insureds'].length + '人'
          }
        })
      })
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
      state.riskList.forEach(item => {
        let obj = {}
        obj.riskId = item.riskId
        obj.riskCode = item.riskCode
        obj.riskSign = item.riskSign
        // obj.productInfoId = sessionStorage.getItem('productId')
        obj.productInfoId = store.getters['insured/getProductId']
        obj.calculateFactor = item.calculateFactor
        obj.dutys = item.dutyList
        state.contInfo.risks.push(obj)
      })
      //处理js校验
      let os = window.calibrationMethod_Information ? window.calibrationMethod_Information : true
      if (!os) {
        message['error'](os.message)
      }
      // 投保人 applicant insureds被保人 beneficiarys 受益人
      if (state.contInfo.applicant.appntIdNo && state.contInfo.applicant.appntIdType === '01') {
        state.contInfo.applicant.appntSex = ''
        state.contInfo.applicant.appntSex = parseInt(state.contInfo.applicant.appntIdNo.substr(16, 1)) % 2 == 1 ? '1' : '2'
      }
      state.contInfo['contract'] = {}

      state.contInfo.insureds.forEach(item => {
        if(item.idNo && item.idType === '01' && vTools('pId', item.idNo)){ // 判断是否是身份证
          item.sex = ''
          item.sex = parseInt(item.idNo.substr(16, 1)) % 2 == 1 ? '1' : '2'
        }
        if (item.birthday) {
          let age = calAge(item.birthday)
          item.insuredAge = age
        }
        if (item.salary) {
          item.salary = Number(item.salary) * 10000
        }
        if (item.excelUrl) {
          state.contInfo['contract']['excelUrl'] = ''
          state.contInfo['contract']['excelUrl'] = item.excelUrl
        }
      })
      let agentObj = JSON.parse(store.getters['insured/getProductDetailData'])
      if (agentObj && agentObj.agentInfo) {
        state.contInfo.agentIds = [agentObj.agentInfo.cardCode]//当前登录人id
      }
      // 支付信息
      if(state.contInfo.payment){
        Object.assign(state.contInfo['contract'], {
          ...state.contInfo.payment,
          ...state.contInfo['contract'],
        })
      }
      // state.contInfo.agentIds = ['eb5afc27614e41eeb4b5f7ae229cdb14']//当前登录人id
      state.contInfo.customer = {} //客户信息
      let obj = agentObj.channelInfo
      if (obj) {
        state.contInfo['contract']['channelId'] = ''
        state.contInfo['contract']['channelId'] = obj.id
      }
      const continuousRenew = router.currentRoute.value.query.continuousRenew
      if(continuousRenew === '1') state.contInfo['contract']['policyCode'] = state.policyCode
      state.contInfo['contract']['processId'] = JSON.parse(store.getters['insured/getRouterJump']).processId
      Object.keys(state.contInfo).forEach(cont => {
        if (cont === 'contract') {
          if (!state.contInfo[cont].remark) {
            state.contInfo[cont].remark = ''
          }
          if (!state.contInfo[cont].destination) {
            state.contInfo[cont].destination = ''
          }
          if (!state.contInfo[cont].platformCode) {
            state.contInfo[cont].platformCode = ''
          }
          if (state.insuredObj['insuranceInfo']) {
            let keyList = []
            state.insuredObj['insuranceInfo'].forEach(item => {
              keyList = Object.keys(item)
              keyList.forEach(k => {
                if (k === 'healthToldFlag' && state.insuredObj['insuranceInfo'][0][k][k] ==='Y') {
                  // 保险模块的告知问卷
                  state.contInfo['contract']['healthToldFlag'] = 'Y'
                }
              })
            })
          }
          state.contInfo[cont].imageList = state.imageList
        }
        if (cont === 'applicant') {
          if (!state.contInfo[cont].postalAddress) {
            state.contInfo[cont].postalAddress = ''
          }
          if (!state.contInfo[cont].zipCode) {
            state.contInfo[cont].zipCode = ''
          }
          if (state.contInfo[cont].nativePlace) {
            state.contInfo[cont].nativePlaceLabel = state.insuredObj[cont][0]?.['nativePlace']?.label ? state.insuredObj[cont]?.[0]?.['nativePlace']?.label : state.callbackData?.['applicant']?.['nativePlace_tagName']
          }
          if (state.contInfo[cont].salary) {
            state.contInfo[cont].salary = (Number(state.contInfo[cont].salary) * 10000).toString()
          }
          if (state.contInfo[cont].idTypeEndDate) {
            if (state.contInfo[cont].idTypeEndDate === '9999-01-01') {
              state.contInfo[cont].idDateType = '1'
            } else {
              state.contInfo[cont].idDateType = '0'
            }
          }
        }
        if (cont === 'insureds') {
          state.contInfo[cont].forEach((item, index) => {
            if (item.socialInsuFlag == null || item.socialInsuFlag == undefined || item.socialInsuFlag == '') {
              item.socialInsuFlag = ''
            }
            if (item.idTypeEndDate) {
            if (item.idTypeEndDate === '9999-01-01') {
              item.idDateType = '1'
            } else {
              item.idDateType = '0'
            }
          }
            if (item.nativePlace && item.relationToAppnt !== '01') {
              item.nativePlaceLabel = state.insuredObj[cont][index]['nativePlace'].label ? state.insuredObj[cont][index]['nativePlace'].label : state.callbackData[cont]?.[index]?.['nativePlace_tagName']
            }else if (item.nativePlace && item.relationToAppnt === '01'){
              item.nativePlaceLabel = state.insuredObj['applicant'].nativePlaceLabel
            }
          })
        }
        if (cont === 'payment') {
          console.log(state.contInfo[cont].newBankCode, '99999999')
          if (state.contInfo[cont]?.newBankCode) {
            state.contInfo[cont].newBankCodeLabel = state.insuredObj?.[cont]?.[0]['newBankCode']?.label ? state.insuredObj?.[cont]?.[0]['newBankCode']?.label : state.callbackData?.[cont]?.['newBankCode_tagName']
            if (!state.contInfo[cont].newBankCodeLabel) {
              let bankArr = []
              state.showData.forEach(item => {
                if (item.moduleType === cont) {
                  item.productElementList[0].forEach(ite => {
                    if (ite.elementCode === 'newBankCode') {
                      bankArr = ite.elementOperatingMode.mode_select.dropDownConfiguration
                    }
                  })
                }
              })
              console.log(bankArr, state.contInfo[cont].newBankCode, 'bankArr')
              state.contInfo[cont].newBankCodeLabel = bankArr.find(item => {
                return (item.value === state.contInfo[cont].newBankCode)
              })?.label ?? ''
              console.log(state.contInfo[cont].newBankCodeLabel, 'rrrrrrrt')
            }
          }
        }
        if (cont === 'risks') {
          if (state.contInfo[cont]?.length > 0) {
            state.contInfo[cont].forEach(item => {
              if (!item.productInfoId) {
                item.productInfoId = store.getters['insured/getProductId']
              }
            })
          }
        }
      })
      if (state.addBenefitModule) {
        state.contInfo.beneficiarys = state.designatedBenefitList
        state.contInfo.beneficiarys.forEach(item => {
          item.nativePlaceLabel = state.insuredObj['applicant'][0]['nativePlace'].label ? state.insuredObj['applicant'][0]['nativePlace'].label : state.callbackData['applicant']['nativePlace_tagName']
        })
      } else {
        state.contInfo.beneficiarys.forEach(item => {
          item.bnfAppSame = '0'
        })
      }
      if (!state.isInclude) {
        state.contInfo.beneficiarys = [{
          bnfType: '2',
          bnfAppSame: state.contInfo.insureds[0]?.relationToAppnt === '01' ? '1' : '0'
        }]
      }
      let submitData = {
        baseInfo: {
          'tradeNo': state.tradeNo,
          'shareId': store.getters['insured/getShareId'] || router.currentRoute.value.query.shareId
        },
        contInfo: state.contInfo
      }
      console.log('提交的时候', submitData)
      // 校验判断
      let bool = true
      if(['20', '21', '22', '23', '24'].includes(state.contInfo.applicant.appntIdType) || state.contInfo.insureds[0].excelUrl){
        bool = true
      }else{
        bool = showErrTipModal(checkData(submitData, state.isInclude))
      }

      if (bool)
      {
        store.dispatch('insured/saveSubmitDate', submitData)
        // 多被保人的存储
        if (state.maximumLimit > 1) {
          handelSaveMultipleInsured()
        }
        state.loadingShow = true
        if (state.routerType === '1') {
          request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+ 'showDataTemporary',selectRisk:{showData: state.showData}})
        }
        submitData = getNewSubmitData(submitData)
        handelCacheParameters(submitData)
        request(getExtendUri('saveData'), submitData).then(res => {
          if (res.code === 1) {
            store.dispatch('insured/changeInsureJSInfo', {
              contInfoDetail: submitData
            })
            state.checkType = true
            let insuredsData = submitData.contInfo.insureds
            sessionStorage.setItem('insuredsData', JSON.stringify(insuredsData))
            state.autoCheckFlag = '1'
            state.signFlag = res.result.signFlag
            if(res.result.autoCheckFlag === '0'){
              state.isIdentical = true
              state.autoCheckFlag = '0'
              state.detailModal = `<div style="text-align: left">${res.result.underWritingMsg}<br><br>该投保申请需要转入人工核保, 请确认是否继续?</div>`
              state.loadingShow = false
            } else if (Number(res.result.signFlag) === 1) {
              //   需要手机号验证码
              state.visible = true
              state.loadingShow = false
            }
            // return
              // else if (Number(res.result.bankSignFlag) === 1 && Number(res.result.bankBeforeSignFlag) === 1) {
              //   // 需要手机号验证码且先弹框签约
              //   state.visible = true
              //   // state.bankBeforeSignFlag = true
              //   return
            // }
            else {
              state.loadingShow = false
              // handelCacheParameters(submitData)
              // todo 这里要清一下确认页的缓存  数据
              store.dispatch('insured/changeInsureJSInfo', {
                clausePdfList: []
              })
              store.dispatch('insured/saveSignDate', {})
              Object.keys(sessionStorage).forEach(item =>{
                if(item.includes('srcUrl') || item.includes('readDone')){
                  sessionStorage.removeItem(item)
                }
              })
              // todo 如果某个控制状态值存在  是否多被保人预览
              if (JSON.parse(store.getters['insured/getProductDetailData']).productInfo.isMultiPage === 'Y') {
                router.push({
                  path: '/app/inusredsConfimPage',
                  query: {
                    tradeNo: state.tradeNo,
                    shareId: store.getters['insured/getShareId']
                  }
                })
              } else {
                state.routerFlag = true
                routerJump(state.tradeNo)
              }
            }
          } else {
            if(res.code === 602){
              // 清空手机号的缓存
              state.checkType = false
              store.dispatch('insured/saveVerifyCode', '')
            }
            state.insuranceReminder = res.result?.insuranceReminder || ''
            state.relationship = res.result?.relationship || ''
            state.isIdentical = true
            state.detailModal = res.message
            state.loadingShow = false
          }
        }).catch(error => {
          console.error(error)
          state.loadingShow = false
        })
      }
    }

    // 获取 投保提示书
    const getImageParameters = (data) =>{
      state.imageList = data.imageList
    }
    // 多被保人的存储
    const handelSaveMultipleInsured = () => {
      let eidtPageJson = JSON.parse(store.getters['insured/getRouterJump'])
      eidtPageJson.pageJson = JSON.stringify(state.showData)
      let temp = new URLSearchParams(location.search)
      let ids = temp.get('id')
      let sort = temp.get('sort')
      request(getExtendUri('getCacheNodeDetails'), {
        id: ids,
        sort,
        tradeNo: state.tradeNo,
        enterDataValue: {...eidtPageJson}
      }).then(res => {
        if (res.code === 1) {
          console.debug('成功')
        }
      })
    }
    // 这个是核保
    const handelUnderwriting = (submitData) => {
      Reflect.deleteProperty(submitData['contInfo']['contract'], 'callerSource')
      request(getExtendUri('saveData'), submitData).then(res => {
        state.loadingShow = false
        if (res.backInfo.result === 1) {
          handelCacheParameters(submitData)
          // todo 这里要清一下数据缓存阅读条款状态的数据
          store.dispatch('insured/changeInsureJSInfo', {
            clausePdfList: []
          })
          state.routerFlag = true
          routerJump(state.tradeNo)
        } else {
          state.detailModal = getErrListInfo(res.backInfo.resultInfos.resultInfo)
          state.isIdentical = true
        }
      })
    }
    // 新增缓存数据接口
    const handelCacheParameters = (saveData) => {
      let selectRisk = JSON.parse(store.getters['insured/getRiskList'])
      // selectRisk.riskList = detailData.riskFormData.riskList
      let multipleRiskList
      if (store.getters['insured/getMultipleRiskList']) {
        multipleRiskList = JSON.parse(store.getters['insured/getMultipleRiskList'])
      }
      let healthRouterJump = JSON.parse(store.getters['insured/getHealthRouterJump'])
      Reflect.deleteProperty(saveData['contInfo']['contract'], 'callerSource')
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo, saveData, selectRisk, multipleRiskList, healthRouterJump})
    }
    // 增加只校验规则不核保标识
    const getSub = (submitData) => {
      submitData['contInfo']['contract']['callerSource'] = 'rule'
      return submitData
    }
    const getErrListInfo = (errList) => {
      let str = ''
      for (let i = 0; i < errList.length; i++) {
        str += errList[i].resultContent + '\n'
      }
      return str
    }
    // 回显赋值
    // const handelContInfoDetail = (obj) => {
    //   console.log('handelContInfoDetail-------obj', obj )
    //   let contInfo = store.getters['insured/getContInfoDetail']?.contInfo
    //   if (!contInfo) return
    // }

    // 转换, 关联方法
    const handelShowData = (moduleType = '', type = '', detail = '', index, tagName = '') => {
      // console.log(moduleType, type, detail, index, 'data111+++++')
      state.showData.forEach(item => {
        if (item.moduleType === moduleType) {
          // console.log(item.productElementList, 'data---')
          item.productElementList[index].forEach(pro => {
            // console.log(pro, 'prodata111')
            if (pro.elementCode === type) {
              pro.firstValue = ''
              pro.firstValue = detail
            }
            if (pro.elementCode === 'newBankCode') {
              pro.defaultRequired = 'Y'
              state.insuredObj['payment'][0].newBankCode.newBankCode = detail
              pro.show_tagName = tagName
            }
          })

        }
      })
    }

    // 被保人类型关系切换清空
    const handelClearInsureds = (data, keys, index) => {
      if (keys !== 'relationToAppnt') return
      if (keys == 'relationToAppnt' && data[keys][keys] == '新增') return
      if (state.insuredBackUp['insureds'] && state.insuredBackUp['insureds'][index] && state.insuredBackUp['insureds'][index][keys][keys] !== data[keys][keys]) {
        for (let i = 0; i < state.showData.length; i++) {
          if (state.showData[i].moduleType === 'insureds') {
            let inList = state.showData[i].productElementList[data[keys].index]
            for (let j = 0; j < inList.length; j++) {
              if (inList[j].elementCode === 'relationToAppnt') continue
              inList[j].firstValue = undefined
              inList[j].show_tagName = ''
              inList[j].show_Level = ''
              inList[j].tagName = ''
              if(inList[j].elementCode === 'updateLodeDetail'){
                inList[j].firstValue = 'N'
              }
              if(inList[j].elementCode === 'idDateType'){
                inList[j].firstValue = '0'
              }
            }
          }
        }
        let insuredData = state.insuredObj[data[keys].moduleType][data[keys].index]
        Object.keys(insuredData).forEach(item => {
          if (item !== 'relationToAppnt') {
            insuredData[item][item] = ''
          }
          if(item === 'updateLodeDetail'){
            insuredData[item][item] = 'N'
          }
          if(item === 'idDateType'){
            insuredData[item][item] = '0'
          }
        })
      }
      state.insuredBackUp = JSON.parse(JSON.stringify(state.insuredObj))
    }
    // 清空健告标识
    const getInsuredsType = (type ='',index, idNo) =>{
      // 获取健告标识
      const healthToldIdentifying = store.getters['insured/getHealthToldIdentifying']
      if(!healthToldIdentifying || !healthToldIdentifying.length){
        editHealthToldFlag('applicant',index)
        return
      }
      let idNoStr = type === 'applicant' ? 'applicantIdNo' : 'insuredsIdNo'
      const healthList = healthToldIdentifying.filter(item => item.tradeNo === state.tradeNo && item.moduleType === type && item.moduleIndex === index && item[idNoStr] === idNo)
      if(healthList && healthList.length) return
      // 获取健告的归属
      const associationType = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.associationType
      if ((associationType.includes('2') && type === 'applicant') || type === 'insureds'){
        editHealthToldFlag(type,index)
      }else if(!associationType.includes('2') && type === 'applicant'){
        const proList = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList
        proList.forEach((pro,i) => {
          pro.forEach(ele => {
            if(ele.elementCode === 'relationToAppnt' && ele.firstValue === '01'){
              editHealthToldFlag('insureds',i)
            }
          })
        })
      }
    }
    // 健告
    const editHealthToldFlag = (type,index) =>{
      let list = []
      if(type === 'applicant'){
        // 全部清空
        let insuredList = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList
        for (let i = 0; i < insuredList.length; i++) {
          for (let j = 0; j < insuredList[i].length; j++) {
            editInsuranceInfoList(insuredList[i])
          }
        }
        for (let i = 0; i < state.insuredObj['insureds'].length; i++) {
          Object.keys(state.insuredObj['insureds'][i]).forEach(key =>{
            if(key === 'healthToldFlag'){
              state.insuredObj['insureds'][i][key][key] = ''
            }
          })
        }
      }else if(type === 'insureds'){
        // 指定清空
        list = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList[index]
        if(state.insuredObj['insureds'][index]['healthToldFlag'] && state.insuredObj['insureds'][index]['healthToldFlag']['healthToldFlag']){
          state.insuredObj['insureds'][index]['healthToldFlag']['healthToldFlag'] = null
        }
        editInsuranceInfoList(list)
      }
    }
    // 健告
    const editInsuranceInfoList = (list) =>{
      if(list && list.length){
        let health = list.filter(ele => ele.elementCode ==='healthToldFlag')
        if(health && health.length) health[0].firstValue = null
      }
      let insuranceInfoList = state.showData.filter(item => item.moduleType === 'insuranceInfo')
      if(insuranceInfoList && insuranceInfoList.length){
        let healthInfo =  insuranceInfoList[0].productElementList[0].filter(ele => ele.elementCode === 'healthToldFlag')
        if(healthInfo && healthInfo.length) healthInfo[0].firstValue = null
        if(state.insuredObj['insuranceInfo']&& state.insuredObj['insuranceInfo'].length && state.insuredObj['insuranceInfo'][0]['healthToldFlag']){
          state.insuredObj['insuranceInfo'][0]['healthToldFlag']['healthToldFlag'] = null
        }
      }
    }
    const getDayBeforeBirthdayNextYear = (birthday, age) => {
    // 确保birthday是Date对象
    if (!(birthday instanceof Date)) {
        birthday = new Date(birthday)
    }
    // 获取当前年份
    const currentYear = new Date(birthday).getFullYear()
    // 计算下一年份
    const nextYear = currentYear + age
    // 设置目标日期为下一年份的相同月份和日期
    const targetDate = new Date(nextYear, birthday.getMonth(), birthday.getDate())

    // 如果目标日期在当前日期之前，则应该使用当前年份的下一年的生日
    if (targetDate < new Date()) {
        targetDate.setFullYear(currentYear + age + 1)
    }
    // 计算周岁前一天
    const dayBeforeBirthday = new Date(targetDate.getTime()) // 86400000毫秒是一天
    return dayBeforeBirthday?.toISOString()?.split('T')[0]
}
    const PreviewPopData = async (data) => {
      // console.log(data, 'data111')
      let keys = Object.keys(data)[0]
      // console.log(keys, 'keys++++')
      let index = data[keys].index
      let moduleType = data[keys].moduleType
      let lengthNum = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList.length - 1
      if (index > lengthNum) return
      if(state.insuredObj[moduleType] && state.insuredObj[moduleType][index]) {
        state.insuredObj[moduleType][index][keys] = data[keys]
      }
      console.log(state.showData, 'state.showData')
      let select = []
      state.showData.forEach(val => {
        if (val.moduleType === 'payment') {
          val.productElementList[0].forEach(item => {
            if (item.elementCode === 'newBankCode') {
              select = item.elementOperatingMode.mode_select.dropDownConfiguration
            }
          })
        }
      })
      // console.log(select, 'select')
      // if () {
      //
      // }
      detailData.dataValue = data[keys][keys]
        // console.log(state.insuredObj, 'detailData')
      //  投保人手机号
      if (keys === 'phoneNo' && moduleType === 'payment') {
        state.insuredMobile = data[keys][keys]
      }else if(keys === 'appntName' && moduleType === 'applicant'){
        let paymentList = state.showData.filter(item => item.moduleType === 'payment')
        if(paymentList.length){
          const nameList = paymentList[0].productElementList[0].filter(ele => ele.elementCode === 'newAccName' || ele.elementCode === 'accName')
          if(nameList.length) {
            nameList.forEach( value=>{
              value.firstValue = data[keys][keys]
            })
          }
        }
      }
      if (keys === 'newBankCardUrl' && Object.keys(data.newBankCardUrl.detailData).length) {
        let val = ''
        let label = ''
        let bankName = data.newBankCardUrl.detailData?.bankName
        // console.log(bankName, 'bankNamedata111')
        select.forEach(itm => {
          if (bankName?.includes(itm.label) || (bankName === '邮政储蓄银行' && itm.label === '邮储银行')) {
            val = itm.value
            label = itm.label
          }
        })
        // console.log(val, 'valdata111')
        handelShowData(moduleType, 'newBankCode', val, index, label)
      }
      //年龄
      if (keys === 'birthday' && (moduleType === 'insureds' || moduleType === 'addBeneficiarys')) {
        let birthday = state.insuredObj[moduleType][index]['birthday']['birthday']
        if (new Date(birthday).getTime() > new Date().getTime()) {
          state.detailModal = '请填写正确的出生日期'
          state.isIdentical = true
          return false
        }
        let idNo = state.insuredObj[moduleType][index]['idNo']['idNo']
        let idType = state.insuredObj[moduleType][index]['idType'] && state.insuredObj[moduleType][index]['idType']['idType'] !== '01' ? true : false
        if(state.insuredObj[moduleType][index]['idType']['idType'] === '05'){
          let type = false
          if(calAge(birthday) > 15){
            state.detailModal = `证件类型为户口簿时, ${moduleType === 'addBeneficiarys' ? '受益' : '被保' }人年龄不能大于16周岁`
            state.isIdentical = true
            type= true
          }
          if(type){
            nextTick(()=>{
              state.showData.forEach(item => {
                if (item.moduleType === moduleType) {
                  updateProductElements(item.productElementList[index], {
                    birthday: { isShow: false, firstValue: null, required: '1' },
                    idTypeEndDate: {isShow: false, firstValue: null, required: '1'}
                  })
                }
              })
              state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = null
              state.insuredObj[moduleType][index]['birthday']['birthday'] = null
            })
            return
          }
          let idTypeEndDate = getDayBeforeBirthdayNextYear(birthday, 16)
          handelShowData(moduleType, 'idTypeEndDate', idTypeEndDate, index)
        }
        if(state.insuredObj[moduleType][index]['idType']['idType'] === '07'){
          let type = false
          if(calAge(birthday) > 0){
            state.detailModal = `证件类型为出生证明时, ${moduleType === 'addBeneficiarys' ? '受益' : '被保' }人年龄不能大于1周岁`
            state.isIdentical = true
            type= true
          }
          if(type){
            nextTick(()=>{
              state.showData.forEach(item => {
                if (item.moduleType === moduleType) {
                  updateProductElements(item.productElementList[index], {
                    birthday: { isShow: false, firstValue: null, required: '1' },
                    idTypeEndDate: {isShow: false, firstValue: null, required: '1'}
                  })
                }
              })
              state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = null
              state.insuredObj[moduleType][index]['birthday']['birthday'] = null
            })
            return
          }
          let idTypeEndDate = getDayBeforeBirthdayNextYear(birthday, 1)
          handelShowData(moduleType, 'idTypeEndDate', idTypeEndDate, index)
        }
        if (state.insuredObj[moduleType][index]['idType']['idType'] === '15'){
          console.log(data[keys][keys], 'data[keys][keys]')
          let age = calAge(data[keys][keys]) + ''
          state.insuredObj['insureds'][index]['insuredAge'] = {}
          state.insuredObj['insureds'][index]['insuredAge']['insuredAge'] = age
          let limitList = JSON.parse(JSON.stringify(store.getters['insured/getDateRange']))
          let res = checkPlanLimitList(limitList, {birthday: data[keys][keys]}, null)
          console.log(res, 'ressssss')
          if (!res.bool) {
            state.detailModal = res.msg
            state.isIdentical = true
            return
          }
          // 多被保人
          getPremium(data, keys, index, moduleType)
          if (state.maximumLimit <= 1 || !state.maximumLimit) {
            let valObject = {
              elementType: 'date',
              factorType: 'TextAge',
              value: age,
              name: age,
              riskId: state.riskList[0].riskId,
              insuredIndex: index
            }
            factorChange(valObject, moduleType)
          }
        }
        // 判断是否为身份证
        if (idType) return
        if ((!idNo || vTools(idNo) !== true)) return
        let strBirthday = idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2)
        // handelBirthday(birthday, strBirthday)
        let age = calAge(data[keys][keys]) + ''
        state.insuredObj['insureds'][index]['insuredAge'] = {}
        state.insuredObj['insureds'][index]['insuredAge']['insuredAge'] = age

        if (birthday !== strBirthday) {
          handelShowData(moduleType, 'birthday', strBirthday, index)
          state.detailModal = '所选生日与证件号码不符合'
          state.isIdentical = true
        }
        let limitList = JSON.parse(JSON.stringify(store.getters['insured/getDateRange']))
        let res = checkPlanLimitList(limitList, {birthday: strBirthday}, null)
        if (!res.bool) {
          return
        }
        // 多被保人
        getPremium(data, keys, index, moduleType)
        if (state.maximumLimit <= 1 || !state.maximumLimit) {
          let valObject = {
            elementType: 'date',
            factorType: 'TextAge',
            value: age,
            name: age,
            riskId: state.riskList[0].riskId,
            insuredIndex: index
          }
          factorChange(valObject, moduleType)
        }
      } else if (keys === 'sex' && moduleType === 'insureds') {
        let idType = state.insuredObj && state.insuredObj[moduleType][index]['idType'] && state.insuredObj[moduleType][index]['idType']['idType'] !== '01' ? true : false
        let idNo = state.insuredObj[moduleType][index]['idNo'] && state.insuredObj[moduleType][index]['idNo']['idNo'] ? state.insuredObj[moduleType][index]['idNo']['idNo'] : ''
        // if(idType) return
        let flag = optionalDutyCheck()

        if(flag && !flag.type){
          state.isIdentical = true
          state.detailModal = flag.message
          return
        }
        if (idNo && !idType) {
          if (idNo.length !== 18) return
          let sex = parseInt(idNo.substr(16, 1)) % 2 == 1 ? 1 : 2
          if (sex != data[keys][keys] && state.isOcrSet !== true) {
            state.sex = sex
            handelShowData('insureds', 'sex', sex + '', index)
            state.detailModal = '所选择性别与证件号码不符'
            state.isIdentical = true
            return
          }
        }
        // 多被保人
        getPremium(data, keys, index, moduleType)
        if ((state.maximumLimit <= 1 || !state.maximumLimit) && idNo) {
          let valObject = {
            elementType: 'radio',
            factorType: 'Sex',
            value: data[keys][keys],
            name: data[keys][keys] == 1 ? '男' : '女',
            riskId: state.riskList[0].riskId,
            insuredIndex: index
          }
          factorChange(valObject, moduleType)
        }
      } else if (keys === 'bnfAppSame' && moduleType === 'addBeneficiarys') {
        console.log(data[keys][keys], '090909', state.insuredObj, '67777')
        if (data[keys][keys] === '1') {
          nextTick(()=>{
          state.showData.forEach(item => {
                  if (item.moduleType === moduleType) {
                    item.productElementList[0].forEach(ite => {
                      if ((ite.elementCode !== 'bnfAppSame' && ite.elementCode !== 'bnfLot') || (ite.elementCode === 'bnfAppSame' && state.insuredObj.insureds[0].relationToAppnt.relationToAppn === '01')) {
                        ite.disabledType = true
                        // ite.isShow = true
                        if (ite.elementCode === 'updateLodeDetail') {
                          ite.firstValue = state.insuredObj.applicant[0].updateLodeDetail.updateLodeDetail
                          state.insuredObj.addBeneficiarys[0].updateLodeDetail.updateLodeDetail = state.insuredObj.applicant[0].updateLodeDetail.updateLodeDetail
                        }
                        // else if (ite.elementCode === 'relationToInsured') {
                        //   ite.firstValue = state.insuredObj.insureds[0].relationToAppnt.relationToAppnt
                        //   state.insuredObj.addBeneficiarys[0].relationToInsured.relationToInsured = state.insuredObj.insureds[0].relationToAppnt.relationToAppnt
                        // }
                        else if (ite.elementCode === 'idType') {
                          ite.firstValue = state.insuredObj.applicant[0].appntIdType.appntIdType
                          state.insuredObj.addBeneficiarys[0].idType.idType = state.insuredObj.applicant[0].appntIdType.appntIdType
                        } else if (ite.elementCode === 'idNo') {
                          ite.firstValue = state.insuredObj.applicant[0].appntIdNo.appntIdNo
                          state.insuredObj.addBeneficiarys[0].idNo.idNo = state.insuredObj.applicant[0].appntIdNo.appntIdNo
                        } else if (ite.elementCode === 'relationToInsured') {
                          let relationToAppnt = state.insuredObj.insureds[0].relationToAppnt.relationToAppnt
                          let relationToInsured = ''
                          let showLable = ''
                          if (relationToAppnt === '02') {
                            relationToInsured = '02'
                            showLable = '配偶'
                          }
                          if (relationToAppnt === '03') {
                            relationToInsured = '04'
                            showLable = '子女'
                          }
                          if (relationToAppnt === '04') {
                            relationToInsured = '03'
                            showLable = '父母'
                          }
                          console.log(relationToInsured, 'relationToInsured')
                          ite.firstValue = relationToInsured
                          ite.tagName = showLable
                          ite.show_tagName = showLable
                          ite.show_Level = ''
                          state.insuredObj.addBeneficiarys[0].relationToInsured.relationToInsured = relationToInsured
                        }
                        else if (ite.elementCode === 'idTypeStartDate') {
                          ite.firstValue = state.insuredObj.applicant[0].idTypeStartDate.idTypeStartDate
                          state.insuredObj.addBeneficiarys[0].idTypeStartDate.idTypeStartDate = state.insuredObj.applicant[0].idTypeStartDate.idTypeStartDate
                        } else if (ite.elementCode === 'idTypeEndDate') {
                          ite.firstValue = state.insuredObj.applicant[0].idTypeEndDate.idTypeEndDate
                          state.insuredObj.addBeneficiarys[0].idTypeEndDate.idTypeEndDate = state.insuredObj.applicant[0].idTypeEndDate.idTypeEndDate
                        } else if (ite.elementCode === 'birthday') {
                          ite.firstValue = state.insuredObj.applicant[0].appntBirthday.appntBirthday
                          state.insuredObj.addBeneficiarys[0].birthday.birthday = state.insuredObj.applicant[0].appntBirthday.appntBirthday
                        } else if (ite.elementCode === 'name') {
                          ite.firstValue = state.insuredObj.applicant[0].appntName.appntName
                          state.insuredObj.addBeneficiarys[0].name.name = state.insuredObj.applicant[0].appntName.appntName
                        } else if (ite.elementCode === 'sex') {
                          ite.firstValue = state.insuredObj.applicant[0].appntSex.appntSex
                          state.insuredObj.addBeneficiarys[0].sex.sex = state.insuredObj.applicant[0].appntSex.appntSex
                        } else if (ite.elementCode === 'nativePlace') {
                          ite.firstValue = state.insuredObj.insureds[0].nativePlace.nativePlace
                          state.insuredObj.addBeneficiarys[0].nativePlace.nativePlace = state.insuredObj.insureds[0].nativePlace.nativePlace
                        } else if (ite.elementCode === 'marriage') {
                          ite.firstValue = state.insuredObj.applicant[0].marriage.marriage
                          state.insuredObj.addBeneficiarys[0].marriage.marriage = state.insuredObj.applicant[0].marriage.marriage
                        } else if (ite.elementCode === 'occupationCode') {
                          ite.firstValue = state.insuredObj.applicant[0].occupationCode.occupationCode
                          ite.tagName = state.insuredObj.applicant[0].occupationCode.occupationName
                          ite.show_tagName = state.insuredObj.applicant[0].occupationCode.occupationName
                          ite.show_Level = state.insuredObj.applicant[0].occupationCode.occupationLevel
                          console.log(state.insuredObj.applicant[0].occupationCode, 'state.insuredObj.applicant[0].occupationCode')
                          state.insuredObj.addBeneficiarys[0].occupationCode.occupationCode = state.insuredObj.applicant[0].occupationCode.occupationCode
                          state.insuredObj.addBeneficiarys[0].occupationCode.occupationName = state.insuredObj.applicant[0].occupationCode.occupationName
                          state.insuredObj.addBeneficiarys[0].occupationCode.occupationLevel = state.insuredObj.applicant[0].occupationCode.occupationLevel
                        } else if (ite.elementCode === 'areaCode') {
                          ite.tagName = state.insuredObj.applicant[0].areaCode.occupationName
                          ite.show_tagName = state.insuredObj.applicant[0].areaCode.occupationName
                          ite.show_Level = state.insuredObj.applicant[0].areaCode.occupationLevel
                          ite.firstValue = state.insuredObj.applicant[0].areaCode.areaCode
                          state.insuredObj.addBeneficiarys[0].areaCode.areaCode = state.insuredObj.applicant[0].areaCode.areaCode
                          state.insuredObj.addBeneficiarys[0].areaCode.occupationLevel = state.insuredObj.applicant[0].areaCode.occupationLevel
                          state.insuredObj.addBeneficiarys[0].areaCode.occupationName = state.insuredObj.applicant[0].areaCode.occupationName
                        } else if (ite.elementCode === 'bnfAddress') {
                          ite.firstValue = state.insuredObj.applicant[0].postalAddress.postalAddress
                          state.insuredObj.addBeneficiarys[0].bnfAddress.bnfAddress = state.insuredObj.applicant[0].postalAddress.postalAddress
                        } else if (ite.elementCode === 'zipCode') {
                          ite.firstValue = state.insuredObj.applicant[0].zipCode.zipCode
                          state.insuredObj.addBeneficiarys[0].zipCode.zipCode = state.insuredObj.applicant[0].zipCode.zipCode
                        } else if (ite.elementCode === 'mobile') {
                          ite.firstValue = state.insuredObj.applicant[0].mobile.mobile
                          state.insuredObj.addBeneficiarys[0].mobile.mobile = state.insuredObj.applicant[0].mobile.mobile
                        } else if (ite.elementCode === 'email') {
                          ite.firstValue = state.insuredObj.applicant[0].email.email
                          state.insuredObj.addBeneficiarys[0].email.email = state.insuredObj.applicant[0].email.email
                        }
                      }
                    })
                  }
              })
            })
        } else {
          nextTick(() => {
            state.showData.forEach(item => {
              if (item.moduleType === moduleType) {
                    item.productElementList[0].forEach(ite => {
                      console.log(state.insuredObj.insureds[0].relationToAppnt, '666666888')
                      if (state.insuredObj.insureds[0].relationToAppnt.relationToAppnt === '01' && ite.elementCode === 'bnfAppSame') {
                        console.log('32223232')
                        ite.disabledType = true
                      } else {
                        ite.disabledType = false
                      }
                    })
                  }
            })
            console.log(state.showData, 'state.showData12323')
          })
        }
      } else if (keys === 'idType' && (moduleType === 'addBeneficiarys' || moduleType === 'insureds')) {
        let idType = data[keys][keys]
        let nativePlace = state.insuredObj[moduleType][index]['nativePlace']['nativePlace']
        let fileIsRequired = state.insuredObj[moduleType][index]?.['updateLodeDetail']?.['required']
        const continuousRenew = router.currentRoute.value.query?.continuousRenew

        if (state.insuredObj[moduleType][index]['updateLodeDetail']['updateLodeDetail']?.length && continuousRenew !== '1' && !state.viewBenefit) {
          nextTick(()=>{
              state.detailModal = `请重新上传${moduleType === 'addBeneficiarys' ? '受益' : '被保'}人证件影像`
              state.isIdentical = true
                state.showData.forEach(item => {
                  if (item.moduleType === moduleType) {
                    item.productElementList[0].forEach(ite => {
                      if (
                        ite.elementCode === 'updateLodeDetail' ||
                        ite.elementCode === 'idNo' ||
                        ite.elementCode === 'birthday' ||
                        ite.elementCode === 'idTypeEndDate' ||
                        ite.elementCode === 'idTypeStartDate' ||
                        ite.elementCode === 'sex'
                      ) {
                        ite.isShow = false
                        ite.firstValue = null
                        // ite.required = '1'
                        if (ite.elementCode === 'updateLodeDetail') {
                          state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = 'N'
                          if (state.insuredObj[moduleType][index]?.['imageList']?.length) {
                            state.insuredObj[moduleType][index]['imageList'] = []
                          }
                        } else {
                          state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                        }
                        state.insuredObj[moduleType][index][ite.elementCode]['elementName'] = ite.elementName
                      }
                    })
                  }
              })
          })
        }
        if (nativePlace && idType && nativePlace === 'CHN' && idType === '15') {
          console.log(nativePlace, idType, '88889999')
            state.showData.forEach(item => {
              if (item.moduleType === moduleType) {
                item.productElementList[0].forEach(ite => {
                  if (ite.elementCode === 'nativePlace') {
                    console.log('111222222')
                    ite.defaultRequired = 'N'
                    ite.isShow = false
                    ite.firstValue = null,
                    ite.required = '1'
                    state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                    console.log(state.showData, '122233311')
                    }
                })
              }
          })
        }
        if (nativePlace && idType && nativePlace !== 'CHN' && idType === '01') {
          nextTick(()=>{
            state.showData.forEach(item => {
              if (item.moduleType === moduleType) {
                item.productElementList[0].forEach(ite => {
                  if (ite.elementCode === 'nativePlace') {
                    ite.defaultRequired = 'N'
                    ite.isShow = false
                    ite.firstValue = null,
                    ite.required = '1'
                    state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                    }
                  })
                }
              })
          })
        }
        // let birthday = state.insuredObj['addBeneficiarys'][0]['birthday']['birthday']
        if (idType === '07' || idType === '05') {
          let birthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          if (idType === '05') {
            state.showData.forEach(items => {
          if (items.moduleType === 'addBeneficiarys') {
            items.productElementList[0].forEach(el => {
              if (el.elementCode === 'birthday') {
                el.elementConfig = benefitBrithDayInputConfig.elementConfig
                el.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                el.elementVaule = benefitBrithDayInputConfig.elementVaule
                el.elementMode = benefitBrithDayInputConfig.elementMode
              }
              if (el.elementCode === 'idTypeStartDate') {
                el.elementConfig = idTypeStartDateConfig.elementConfig
                el.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                el.elementVaule = idTypeStartDateConfig.elementVaule
                el.elementMode = idTypeStartDateConfig.elementMode
              }
            })
          }
        })
            let type = false
            if (birthday && calAge(birthday) > 15) {
              state.detailModal = `证件类型为户口本时, ${moduleType === 'addBeneficiarys' ? '受益' : '被保' }人年龄不能大于16周岁`
              state.isIdentical = true
              type= true
            }
            if(type){
              nextTick(()=>{
                state.showData.forEach(item => {
                  if (item.moduleType === moduleType) {
                    updateProductElements(item.productElementList[index], {
                      birthday: { isShow: false, firstValue: null, required: '1' },
                      idTypeEndDate: {isShow: false, firstValue: null, required: '1'}
                    })
                  }
                })
                state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = null
                state.insuredObj[moduleType][index]['birthday']['birthday'] = null
              })
            }
          }
          if (idType && idType === '07') {
          state.showData.forEach(items => {
          if (items.moduleType === 'addBeneficiarys') {
            items.productElementList[0].forEach(el => {
              if (el.elementCode === 'birthday') {
                el.elementConfig = benefitBrithDayDateConfig.elementConfig
                el.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
                el.elementVaule = benefitBrithDayDateConfig.elementVaule
                el.elementMode = benefitBrithDayDateConfig.elementMode
              }
              if (el.elementCode === 'idTypeStartDate') {
                el.elementConfig = idTypeStartDateConfig.elementConfig
                el.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                el.elementVaule = idTypeStartDateConfig.elementVaule
                el.elementMode = idTypeStartDateConfig.elementMode
              }
            })
          }
        })
            let type = false
            if (birthday && calAge(birthday) > 0) {
              state.detailModal = `证件类型为出生证明时, ${moduleType === 'addBeneficiarys' ? '受益' : '被保' }人年龄不能大于1周岁`
              state.isIdentical = true
              type= true
            }
            if(type){
              nextTick(()=>{
                state.showData.forEach(item => {
                  if (item.moduleType === moduleType) {
                    updateProductElements(item.productElementList[index], {
                      birthday: { isShow: false, firstValue: null, required: '1' },
                      idTypeEndDate: {isShow: false, firstValue: null, required: '1'}
                    })
                  }
                })
                state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = null
                state.insuredObj[moduleType][index]['birthday']['birthday'] = null
              })
            }
          }
        }else if (idType === '15') {
          state.showData.forEach(items => {
          if (items.moduleType === 'insureds') {
            items.productElementList[0].forEach(el => {
              if (el.elementCode === 'birthday') {
                el.elementConfig = benefitBrithDayDateConfig.elementConfig
                el.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
                el.elementVaule = benefitBrithDayDateConfig.elementVaule
                el.elementMode = benefitBrithDayDateConfig.elementMode
              }
              if (el.elementCode === 'idTypeStartDate') {
                el.elementConfig = idTypeStartDateConfig.elementConfig
                el.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                el.elementVaule = idTypeStartDateConfig.elementVaule
                el.elementMode = idTypeStartDateConfig.elementMode
                console.log(state.showData, idTypeStartDateInputConfig, '3334444')
              }
              if (el.elementCode === 'idTypeEndDate') {
                el.elementConfig = idTypeEndDateConfig.elementConfig
                el.elementOperatingMode = idTypeEndDateConfig.elementOperatingMode
                el.elementVaule = idTypeEndDateConfig.elementVaule
                el.elementMode = idTypeEndDateConfig.elementMode
                // el.elementOperatingMode.mode_time.timeMinValue = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              }
            })
          }
        })
        } else if (idType === '01') {
          state.showData.forEach(items => {
          if (items.moduleType === 'addBeneficiarys' || items.moduleType === 'insureds') {
            items.productElementList[0].forEach(el => {
              console.log(el, 'el111122')
              if (el.elementCode === 'birthday') {
                el.elementConfig = benefitBrithDayInputConfig.elementConfig
                el.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                el.elementVaule = benefitBrithDayInputConfig.elementVaule
                el.elementMode = benefitBrithDayInputConfig.elementMode
              }
              if (el.elementCode === 'idTypeStartDate' && fileIsRequired === '1') {
                el.elementConfig = idTypeStartDateInputConfig.elementConfig
                el.elementOperatingMode = idTypeStartDateInputConfig.elementOperatingMode
                el.elementVaule = idTypeStartDateInputConfig.elementVaule
                el.elementMode = idTypeStartDateInputConfig.elementMode
                console.log(state.showData, idTypeStartDateInputConfig, '3334444')
              }
              if (items.moduleType === 'insureds' && el.elementCode === 'idTypeEndDate' && fileIsRequired === '1') {
                el.elementConfig = idTypeEndDateInputConfig.elementConfig
                el.elementOperatingMode = idTypeEndDateInputConfig.elementOperatingMode
                el.elementVaule = idTypeEndDateInputConfig.elementVaule
                el.elementMode = idTypeEndDateInputConfig.elementMode
              }
            })
          }
        })
        }
      } else if (keys === 'appntIdType' && moduleType === 'applicant') {
        let idType = data[keys][keys]
        let nativePlace = state.insuredObj[moduleType][index]['nativePlace']['nativePlace']
        const continuousRenew = router.currentRoute.value.query?.continuousRenew
        if (state.insuredObj[moduleType][index]['updateLodeDetail']['updateLodeDetail']?.length && continuousRenew !== '1') {
          nextTick(()=>{
              state.detailModal = '请重新上传投保人证件影像'
              state.isIdentical = true
                state.showData.forEach(item => {
                  if (item.moduleType === moduleType) {
                    item.productElementList[0].forEach(ite => {
                      if (
                        ite.elementCode === 'updateLodeDetail' ||
                        ite.elementCode === 'idNo' ||
                        ite.elementCode === 'appntIdNo' ||
                        ite.elementCode === 'appntBirthday' ||
                        ite.elementCode === 'birthday' ||
                        ite.elementCode === 'idTypeEndDate' ||
                        ite.elementCode === 'idTypeStartDate' ||
                        ite.elementCode === 'sex'
                      ) {
                        ite.isShow = false
                        ite.firstValue = null
                        // ite.required = '1'
                        if (ite.elementCode === 'updateLodeDetail') {
                          state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = 'N'
                          if (state.insuredObj[moduleType][index]?.['imageList']?.length) {
                            state.insuredObj[moduleType][index]['imageList'] = []
                          }
                        } else {
                          state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                        }
                        state.insuredObj[moduleType][index][ite.elementCode]['elementName'] = ite.elementName
                      }
                    })
                  }
              })
          })
        }
        if (nativePlace && idType && nativePlace === 'CHN' && idType === '15') {
          console.log(nativePlace, idType, '88889999')
            state.showData.forEach(item => {
              if (item.moduleType === moduleType) {
                item.productElementList[0].forEach(ite => {
                  if (ite.elementCode === 'nativePlace') {
                    console.log('111222222')
                    ite.defaultRequired = 'N'
                    ite.isShow = false
                    ite.firstValue = '',
                    ite.required = '1'
                    state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                    console.log(state.showData, '122233311')
                    }
                })
              }
          })
        }
        if (nativePlace && idType && nativePlace !== 'CHN' && idType === '01') {
          nextTick(()=>{
            state.showData.forEach(item => {
              if (item.moduleType === moduleType) {
                item.productElementList[0].forEach(ite => {
                  if (ite.elementCode === 'nativePlace') {
                    ite.defaultRequired = 'N'
                    ite.isShow = false
                    ite.firstValue = null,
                    ite.required = '1'
                    state.insuredObj[moduleType][index][ite.elementCode][ite.elementCode] = null
                    }
                  })
                }
              })
          })
        }
        if (idType !== '01') {
          state.showData.forEach(items => {
          if (items.moduleType === moduleType) {
            items.productElementList[0].forEach(ite => {
              if (ite.elementCode === 'appntBirthday') {
                ite.elementName = '投保人出生日期'
                ite.elementConfig = benefitBrithDayDateConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayDateConfig.elementVaule
                ite.elementMode = benefitBrithDayDateConfig.elementMode
              }
              if (ite.elementCode === 'idTypeStartDate') {
                ite.elementName = '投保人证件有效起期'
                ite.elementConfig = idTypeStartDateConfig.elementConfig
                ite.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                ite.elementVaule = idTypeStartDateConfig.elementVaule
                ite.elementMode = idTypeStartDateConfig.elementMode
              }
              if (ite.elementCode === 'idTypeEndDate') {
                ite.elementName = '投保人证件有效止期'
                ite.elementConfig = idTypeEndDateConfig.elementConfig
                ite.elementOperatingMode = idTypeEndDateConfig.elementOperatingMode
                ite.elementVaule = idTypeEndDateConfig.elementVaule
                ite.elementMode = idTypeEndDateConfig.elementMode
              }
            })
          }
        })
        } else {
          state.showData.forEach(items => {
          if (items.moduleType === moduleType && router.currentRoute.value.query.continuousRenew !== '1') {
            items.productElementList[0].forEach(ite => {
              if (ite.elementCode === 'appntBirthday') {
                    ite.elementName = '投保人出生日期'
                    ite.elementConfig = benefitBrithDayInputConfig.elementConfig
                    ite.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                    ite.elementVaule = benefitBrithDayInputConfig.elementVaule
                    ite.elementMode = benefitBrithDayInputConfig.elementMode
              }
                    if (ite.elementCode === 'idTypeStartDate') {
                      ite.elementName = '投保人证件有效起期'
                      ite.elementConfig = idTypeStartDateInputConfig.elementConfig
                      ite.elementOperatingMode = idTypeStartDateInputConfig.elementOperatingMode
                      ite.elementVaule = idTypeStartDateInputConfig.elementVaule
                      ite.elementMode = idTypeStartDateInputConfig.elementMode
                    }
                    if (ite.elementCode === 'idTypeEndDate') {
                      ite.elementName = '投保人证件有效止期'
                      ite.elementConfig = idTypeEndDateInputConfig.elementConfig
                      ite.elementOperatingMode = idTypeEndDateInputConfig.elementOperatingMode
                      ite.elementVaule = idTypeEndDateInputConfig.elementVaule
                      ite.elementMode = idTypeEndDateInputConfig.elementMode
                    }
            })
          }
        })
        }
      } else if (keys === 'idNo' && moduleType === 'addBeneficiarys') {
        let idNo = data[keys][keys]
        let strBirthday = null
        let idType = state.insuredObj['addBeneficiarys'][index]['idType']['idType']
        let marriage = state.insuredObj['addBeneficiarys'][index]['marriage']['marriage']
        if(idNo)  getInsuredsType('addBeneficiarys', index, idNo)
        if(idType !== '01' && idType !== '05') return
        if (idNo && idNo.length === 18) {
          const vToolResult = vTools('pId',idNo)
          if (vToolResult !== true) {
            state.detailModal = vToolResult
            state.isIdentical = true
            return
          }
          strBirthday = idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2)
          //更具身份证给生日赋值
          state.insuredObj[moduleType][index]['birthday'] = {}
          state.insuredObj[moduleType][index]['birthday']['birthday'] = strBirthday
          handelShowData(moduleType, 'birthday', strBirthday, index)
          let sex = ''
          parseInt(idNo.substr(16, 1)) % 2 == 1 ? sex = '1' : sex = '2'
          state.insuredObj[moduleType][index]['sex'] = {}
          state.insuredObj[moduleType][index]['sex']['sex'] = sex
          handelShowData(moduleType, 'sex', sex, index)
            if (moduleType === 'addBeneficiarys' &&  marriage && marriage !== '0' && calAge(strBirthday) < 18 ) {
              state.detailModal = '受益人未满18周岁，婚姻状态仅能为未婚！'
              state.isIdentical = true
              return
            }
        }
        if (idType === '05' && vTools('pId', idNo) === true) {
          let idTypeEndDate = getDayBeforeBirthdayNextYear(strBirthday, 16)
          state.insuredObj[moduleType][index]['idTypeEndDate'] = {}
          state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = idTypeEndDate
          handelShowData(moduleType, 'idTypeEndDate', idTypeEndDate, index)
        }
        if (idType === '07' && vTools('birthCard', idNo) === true) {
          let idTypeEndDate = getDayBeforeBirthdayNextYear(strBirthday, 1)
          state.insuredObj[moduleType][index]['idTypeEndDate'] = {}
          state.insuredObj[moduleType][index]['idTypeEndDate']['idTypeEndDate'] = idTypeEndDate
          handelShowData(moduleType, 'idTypeEndDate', idTypeEndDate, index)
        }
      } else if (keys === 'idNo' && moduleType === 'insureds') {
        let idNo = data[keys][keys]
        let strBirthday = idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2)
        let idType = ''
        if(state.insuredObj['insureds'][index]['idType']['idType']){
          idType = state.insuredObj['insureds'][index]['idType']['idType']
        }else{
          state.showData.filter(show => show.moduleType === 'insureds').forEach(ite => {
            idType = ite.productElementList[index].filter(pro => pro.elementCode === 'idType')[0].firstValue
          })
        }
        if(idNo)  getInsuredsType('insureds', index, idNo)
        if (idNo.length !== 18) return
        if(idType !== '01') return
        if (idType === '01') {
          const vToolResult = vTools('pId',idNo)
          if (vToolResult !== true) {
            state.detailModal = vToolResult
            state.isIdentical = true
            return
          }
        }
        if (idType === '15') {
            if (!idNo || vTools('wgrjlz', idNo) !== true) {
              return
            }
            if (idNo.length === 15) {
              let yearNum = idNo.substr(7, 2)
              let year = (Number(yearNum) >= 0 && Number(yearNum) <= 17) ? ('20' + yearNum) : ('19' + yearNum)
              let month = idNo.substr(9, 2)
              let date = idNo.substr(11, 2)
              strBirthday = `${year}-${month}-${date}`
            }
          }
        //更具身份证给生日赋值
        state.insuredObj[moduleType][index]['birthday'] = {}
        state.insuredObj[moduleType][index]['birthday']['birthday'] = strBirthday
        handelShowData(moduleType, 'birthday', strBirthday, index)
        let sex = ''
        if (idNo.length === 18) {
          parseInt(idNo.substr(16, 1)) % 2 == 1 ? sex = '1' : sex = '2'
          state.insuredObj[moduleType][index]['sex'] = {}
          state.insuredObj[moduleType][index]['sex']['sex'] = sex
          handelShowData(moduleType, 'sex', sex, index)
        }
        let age = calAge(strBirthday) + ''
        let limitList = JSON.parse(JSON.stringify(store.getters['insured/getDateRange']))
        let res = checkPlanLimitList(limitList, {birthday: strBirthday}, null)
        if (!res.bool) {
          state.detailModal = res.msg
          state.isIdentical = true
          return
        }
        // 多被保人
        getPremium(data, keys, index, moduleType)
        if (state.maximumLimit <= 1 || !state.maximumLimit) {
          let valObject = {
            elementType: 'date',
            factorType: 'TextAge',
            value: age,
            name: age,
            insuredIndex: index,
            riskId: state.riskList[0].riskId
          }
          factorChange(valObject, moduleType)
          let valObject1 = {
            elementType: 'radio',
            factorType: 'Sex',
            value: sex,
            name: sex == 1 ? '男' : '女',
            riskId: state.riskList[0].riskId,
            insuredIndex: index
          }
          factorChange(valObject1, moduleType)
          let valObject2 = {
            elementType: 'input',
            factorType: 'IdNo',
            value: idNo,
            name: '身份证',
            riskId: state.riskList[0].riskId,
            insuredIndex: index
          }
          factorChange(valObject2, moduleType)
        }

      } else if (keys === 'appntIdNo' && moduleType === 'applicant') {
        //投保人
        let idNo = data[keys][keys]
        let strBirthday = idNo ? idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2) : ''
        // 以下判断只做展示
        let appntType = ''
        if(state.insuredObj[moduleType] && state.insuredObj[moduleType][index]){
          appntType = state.insuredObj[moduleType][index]['appntIdType']['appntIdType']
        }
        if(appntType === '01'){
          if (appntType === '01') {
            if (!idNo || idNo.length !== 18) return
            const vToolResult = vTools('pId',idNo)
            if (vToolResult !== true) {
              state.detailModal = vToolResult
              state.isIdentical = true
              return
            }
          }
          if (appntType === '15') {
            if (!idNo || vTools('wgrjlz', idNo) !== true) {
              return
            }
            if (idNo.length === 15) {
              let yearNum = idNo.substr(7, 2)
              let year = (Number(yearNum) >= 0 && Number(yearNum) <= 17) ? ('20' + yearNum) : ('19' + yearNum)
              let month = idNo.substr(9, 2)
              let date = idNo.substr(11, 2)
              strBirthday = `${year}-${month}-${date}`
              console.log(`${year}-${month}-${date}`, '15位外国身份证')
            }
          }
          const isTBR = true
          let age = calAge(strBirthday, isTBR) + ''
          let type = age >= 18 ? true : false
          if (!type) {
            state.detailModal = '投保人未满18周岁'
            state.isIdentical = true
            return
          }
          // 寻找被保人为本人的
          getInsuredsType('applicant', index, idNo)
          //更具身份证给生日赋值
          let sex = ''
          state.insuredObj[moduleType][index]['appntBirthday'] = {}
          state.insuredObj[moduleType][index]['appntBirthday']['appntBirthday'] = strBirthday
          if (idNo.length === 18) {
            parseInt(idNo.substr(16, 1)) % 2 == 1 ? sex = 1 : sex = 2
            state.sex = sex
          }
          if (state.insuredObj[moduleType][index]['appntSex']) {
            state.insuredObj[moduleType][index]['appntSex']['appntSex'] = sex
            handelShowData('applicant', 'appntSex', sex + '', index)
          }
          let flag = optionalDutyCheck()
          if(flag && !flag.type){
            state.isIdentical = true
            state.detailModal = flag.message
            return
          }

          handelShowData(moduleType, 'appntBirthday', strBirthday, index)
          let limitList = JSON.parse(JSON.stringify(store.getters['insured/getDateRange']))
          let res = checkPlanLimitList(limitList, {birthday: strBirthday}, null)
          let num = state.insuredObj['insureds'][0]?.relationToAppnt?.relationToAppnt
          if (!res.bool && num === '01') {
            state.detailModal = res.msg
            state.isIdentical = true
            return
          }
          let excelUrlShow = state.insuredObj['insureds'][0].excelUrl
          if ((Number(num) === 1 || !num) && !excelUrlShow) {
            // 多被保人
            getPremium(data, keys, index, moduleType)
            if (state.maximumLimit <= 1 || !state.maximumLimit) {
              let valObject1 = {
                elementType: 'radio',
                factorType: 'Sex',
                value: sex,
                name: sex == 1 ? '男' : '女',
                riskId: state.riskList[0].riskId,
                insuredIndex: '0'
              }
              factorChange(valObject1, moduleType)
              let valObject2 = {
                elementType: 'input',
                factorType: 'IdNo',
                value: idNo,
                name: '身份证',
                riskId: state.riskList[0].riskId,
                insuredIndex: index
              }
              factorChange(valObject2, moduleType)
            }
          }
        }
      } else if (keys === 'appntBirthday' && moduleType === 'applicant') {
        let birthday = ''
        let idNo = ''
        if(state.insuredObj[moduleType] &&state.insuredObj[moduleType][index]['appntBirthday'] ){
          birthday = state.insuredObj[moduleType][index]['appntBirthday']['appntBirthday']
          if (new Date(birthday).getTime() > new Date().getTime()) {
            state.detailModal = '请输入正确的出生日期'
            state.isIdentical = true
            return
          }
        }
        if(state.insuredObj[moduleType] && state.insuredObj[moduleType][index]){
          idNo = state.insuredObj[moduleType][index]?.appntIdNo?.appntIdNo
        }
        // 以下判断只做展示
        let appntType = ''
        if (state.insuredObj[moduleType] && state.insuredObj[moduleType][index] && state.insuredObj[moduleType][index]['appntIdType']){
          appntType = state.insuredObj[moduleType][index]['appntIdType']['appntIdType']
        }
        if (appntType !== '01' && data[keys][keys]) {
          if (calAge(data[keys][keys]) < 18) {
            state.detailModal = '投保人年龄不能小于18周岁'
            state.isIdentical = true
            return
          }
        }
        if (!idNo || appntType !== '01' ) return
        if (idNo.length !== 18) return
        let strBirthday = idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2)
        // handelBirthday(birthday, strBirthday)
        if (birthday !== strBirthday) {
          handelShowData(moduleType, keys, strBirthday, index, index)
          state.detailModal = '所选生日与证件号码不符合'
          state.isIdentical = true
          return
        }
        let limitList = JSON.parse(JSON.stringify(store.getters['insured/getDateRange']))
        let res = checkPlanLimitList(limitList, {birthday: strBirthday}, null)
        if (!res.bool) {
          return
        }
        let age = calAge(strBirthday) + ''
        if (state.maximumLimit <= 1 || !state.maximumLimit) {
          let valObject = {
            elementType: 'date',
            factorType: 'TextAge',
            value: age,
            name: age,
            insuredIndex: index,
            riskId: state.riskList[0].riskId
          }
          factorChange(valObject, moduleType)
        }

      } else if (keys === 'sex' && moduleType === 'addBeneficiarys') {
        let idNo = state.insuredObj[moduleType] &&  state.insuredObj[moduleType][index] &&  state.insuredObj[moduleType][index]['idNo'] && state.insuredObj[moduleType][index]['idNo']['idNo'] ? state.insuredObj[moduleType][index]['idNo']['idNo'] : ''
        if(state.insuredObj[moduleType] && state.insuredObj[moduleType][index] && state.insuredObj[moduleType][index]['idType']){
          let idType = state.insuredObj[moduleType][index]['idType']['idType']
          if (idType !== '01' && idType !== '05') return
        }
        if (idNo) {
          if (idNo.length !== 18) return
          let sex = parseInt(idNo.substr(16, 1)) % 2 == 1 ? 1 : 2
          if (sex != data[keys][keys] && state.isOcrSet !== true && !state.viewBenefit) {
            state.sex = sex
            state.detailModal = '所选择性别与证件号码不符'
            state.isIdentical = true
            handelShowData('addBeneficiarys', 'sex', sex + '', index)
          }
          // if (state.maximumLimit <= 1 || !state.maximumLimit) {
          //   let valObject = {
          //     elementType: 'radio',
          //     factorType: 'Sex',
          //     value: sex+'',
          //     name: sex === 1 ? '男': '女',
          //     insuredIndex: index,
          //     riskId: state.riskList[0].riskId
          //   }
          //   factorChange(valObject, moduleType)
          // }
        }
      } else if (keys === 'appntSex' && moduleType === 'applicant') {
        let idNo = state.insuredObj[moduleType] &&  state.insuredObj[moduleType][index] &&  state.insuredObj[moduleType][index]['appntIdNo'] && state.insuredObj[moduleType][index]['appntIdNo']['appntIdNo'] ? state.insuredObj[moduleType][index]['appntIdNo']['appntIdNo'] : ''
        if(state.insuredObj[moduleType] && state.insuredObj[moduleType][index] && state.insuredObj[moduleType][index]['appntIdType']){
          let appntType = state.insuredObj[moduleType][index]['appntIdType']['appntIdType']
          if (appntType !== '01') return
        }
        let insuredsType = state.insuredObj?.['insureds']?.[0]?.relationToAppnt?.relationToAppnt
        if(insuredsType === '01') {
          let flag = optionalDutyCheck()
          if(flag && !flag.type){
            state.isIdentical = true
            state.detailModal = flag.message
            return
          }
        }
        if (idNo) {
          if (idNo.length !== 18) return
          let sex = parseInt(idNo.substr(16, 1)) % 2 == 1 ? 1 : 2
          console.debug('所选择性别与证件号码不符typeIsNotOcr------------', state.isOcrSet)
          if (sex != data[keys][keys] && state.isOcrSet !== true) {
            state.sex = sex
            state.detailModal = '所选择性别与证件号码不符'
            state.isIdentical = true
            handelShowData('applicant', 'appntSex', sex + '', index)
          }
          if (state.maximumLimit <= 1 || !state.maximumLimit) {
            let valObject = {
              elementType: 'radio',
              factorType: 'Sex',
              value: sex+'',
              name: sex === 1 ? '男': '女',
              insuredIndex: index,
              riskId: state.riskList[0].riskId
            }
            factorChange(valObject, moduleType)
          }

        }
      } else if (keys === 'idNo' && moduleType === 'beneficiarys') {
        //受益人
          let idNo = data[keys][keys]
          if (idNo && idNo.length === 18) {
          let strBirthday = idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2)
          //更具身份证给生日赋值
          state.insuredObj[moduleType][index]['birthday'] = {}
          state.insuredObj[moduleType][index]['birthday']['birthday'] = strBirthday
          handelShowData(moduleType, 'birthday', strBirthday, index)
        }
      } else if (keys === 'bnfType' && moduleType === 'beneficiarys') {
        let bnfType = data[keys][keys]
        if (bnfType === '1') {
          state.addBenefitModule = true
        } else {
          state.addBenefitModule = false
          state.designatedBenefitList = []
        }
      } else if (keys === 'relationToAppnt' && moduleType === 'insureds') {
        setRelationToAppntType('1',keys, data, index)
        const insuredsList = state.showData.filter(item => item.moduleType === moduleType)[0].productElementList[index]
        const elementMode = insuredsList.filter(item => item.elementCode === 'relationToAppnt' && item.firstValue === '01')[0]?.elementMode
        if(elementMode === '103'){
          Object.keys(state.insuredObj[moduleType][index]).forEach(key =>{
            state.insuredObj[moduleType][index][key].required = '0'
          })
        }
        let value = data[keys][keys]
        // state.relationToAppnt = value
        if (Number(value) === 1) {
          // 多被保人
          getPremium(data, keys, index, moduleType)
          if (state.maximumLimit <= 1 || !state.maximumLimit) {
            //  如果是本人情况
            //计算保费试算
            let birthday = state.insuredObj['applicant'] && state.insuredObj['applicant'][0]['appntBirthday'] ? state.insuredObj['applicant'][0]['appntBirthday']['appntBirthday'] : ''
            let sex = state.insuredObj['applicant'] && state.insuredObj['applicant'][0]['appntSex'] ? state.insuredObj['applicant'][0]['appntSex']['appntSex'] : ''
            let idNo = state.insuredObj['applicant'] && state.insuredObj['applicant'][0]['appntIdNo'] ? state.insuredObj['applicant'][0]['appntIdNo']['appntIdNo'] : ''
            if (birthday) {
              let age = calAge(birthday) + ''
              let valObject = {
                elementType: 'radio',
                factorType: 'Sex',
                value: sex,
                insuredIndex: index,
                name: Number(sex) === 1 ? '男' : '女',
                riskId: state.riskList[0].riskId
              }
              factorChange(valObject, moduleType)
              let valObject1 = {
                elementType: 'date',
                factorType: 'TextAge',
                value: age,
                name: age,
                insuredIndex: index,
                riskId: state.riskList[0].riskId
              }
              factorChange(valObject1, moduleType)
              let valObject2 = {
                elementType: 'input',
                factorType: 'IdNo',
                value: idNo,
                name: '身份证',
                insuredIndex: index,
                riskId: state.riskList[0].riskId
              }
              factorChange(valObject2, moduleType)

            }
          }
        }
        let flag = {bool: true,msg: ''}
        let applicantMarriage = state.insuredObj.applicant?.[0]?.['marriage']?.['marriage']
        let insuredsMarriage = state.insuredObj.insureds?.[0]?.['marriage']?.['marriage']
        if (data[keys][keys] === '02' && insuredsMarriage && (applicantMarriage !== '1' || insuredsMarriage !== '1')) {
          flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
          showErrTipModal(flag)
          return false
        }
      } else if (keys === 'relationToInsured' && moduleType === 'addBeneficiarys') {
        let flag = {bool: true,msg: ''}
        let benefitMarriage = state.insuredObj.addBeneficiarys?.[0]?.['marriage']?.['marriage']
        let insuredsMarriage = state.insuredObj.insureds?.[0]?.['marriage']?.['marriage']
        if (data[keys][keys] === '02' && (benefitMarriage !== '1' || insuredsMarriage !== '1')) {
          flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
          showErrTipModal(flag)
          return false
        }
      } else if (keys === 'excelUrl' && moduleType === 'insureds' ) {
        state.premium = data[keys].countPremium
        state.showData.forEach(item => {
          if (item.moduleType === 'insureds') {
            item.productElementList[0].forEach(pro => {
              if(pro.elementCode === 'insuredNum') {
                pro.firstValue = data['excelUrl'].countInsured
              }
            })

          }
        })
        let valObject1 = {
          elementType: 'radioSelection',
          factorType: 'insuredNum',
          value: data['excelUrl'].num + '',
          name: data['excelUrl'].num + '人',
          insuredIndex: index,
          riskId: state.riskList[0].riskId
        }
        factorChange(valObject1, moduleType)
      } else if (keys === 'bnfType' && moduleType === 'beneficiarys') {/*keys === 'bnfType' && moduleType === 'insureds'*/
        state.bnfType = Number(data[keys][keys])
      } else if (keys === 'idTypeStartDate') {
        if (moduleType === 'applicant') {
          let applicantBirthday = state.insuredObj.applicant[index]['appntBirthday']['appntBirthday']
          let idTypeStartDate = state.insuredObj[moduleType][index][keys][keys]
          if (applicantBirthday && idTypeStartDate) {
            if (new Date(applicantBirthday).getTime() > new Date(idTypeStartDate).getTime() || new Date().getTime() < new Date(idTypeStartDate).getTime()) {
              state.detailModal = '请填写正确的投保人证件有效起期'
              state.isIdentical = true
              return
            }
          }
        }
        if (moduleType === 'insureds') {
          let insuredsBirthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          let idTypeStartDate = state.insuredObj[moduleType][index][keys][keys]
          if (insuredsBirthday && idTypeStartDate) {
            if (new Date(insuredsBirthday).getTime() > new Date(idTypeStartDate).getTime() || new Date().getTime() < new Date(idTypeStartDate).getTime()) {
              console.log(state.insuredObj[moduleType][index][keys], 'state.insuredObj[moduleType][index][keys]')
              state.detailModal = '请填写正确的被保人证件有效起期'
              state.isIdentical = true
              return
            }
          }
        }
        if (moduleType === 'addBeneficiarys') {
          let insuredsBirthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          let idTypeStartDate = state.insuredObj[moduleType][index][keys][keys]
          if (insuredsBirthday && idTypeStartDate) {
            if (new Date(insuredsBirthday).getTime() > new Date(idTypeStartDate).getTime() || new Date().getTime() < new Date(idTypeStartDate).getTime()) {
              console.log(state.insuredObj[moduleType][index][keys], 'state.insuredObj[moduleType][index][keys]')
              state.detailModal = '请填写正确的受益人证件有效起期'
              state.isIdentical = true
              return
            }
          }
        }
      } else if (keys === 'idTypeEndDate') {
        if (moduleType === 'applicant') {
          let applicantBirthday = state.insuredObj.applicant[index]['appntBirthday']['appntBirthday']
          let idTypeEndDate = state.insuredObj[moduleType][index][keys][keys]
          if (idTypeEndDate && applicantBirthday) {
            if (new Date().getTime() > new Date(idTypeEndDate).getTime() || new Date(applicantBirthday).getTime() > new Date(idTypeEndDate)) {
              state.detailModal = '请填写正确的投保人证件有效止期'
              state.isIdentical = true
              return
            }
          }
        }
        if (moduleType === 'insureds') {
          let insuredsBirthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          let idTypeEndDate = state.insuredObj[moduleType][index][keys][keys]
          if (idTypeEndDate) {
            if (new Date().getTime() > new Date(idTypeEndDate).getTime() || new Date(insuredsBirthday).getTime() > new Date(idTypeEndDate)) {
              state.detailModal = '请填写正确的被保人证件有效止期'
              state.isIdentical = true
              return
            }
          }
        }
        if (moduleType === 'addBeneficiarys') {
          let insuredsBirthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          let idTypeEndDate = state.insuredObj[moduleType][index][keys][keys]
          if (idTypeEndDate) {
            if (new Date().getTime() > new Date(idTypeEndDate).getTime() || new Date(insuredsBirthday).getTime() > new Date(idTypeEndDate)) {
              state.detailModal = '请填写正确的受益人证件有效止期'
              state.isIdentical = true
              return
            }
          }
        }
      } else if (keys === 'socialInsuFlag') {
        // 多被保人
        getPremium(data, keys, index, moduleType)
        if (state.maximumLimit <= 1 || !state.maximumLimit) {
          // if(state.insuredObj['insureds'][index]['relationToAppnt']['relationToAppnt'] !== '01' && data[keys].moduleType === 'applicant') return
          let valObject1 = {
            elementType: 'radioSelection',
            factorType: 'SocialFlag',
            value: data[keys][keys],
            name: data[keys][keys] === 'Y' ? '有' : '无',
            riskId: state.riskList[0].riskId,
            insuredIndex: index,
          }
          factorChange(valObject1, moduleType)
        }
      }else if (keys === 'idDateType' || keys === 'longterm') {
        // 证件长期有效  身份证止期不展示 且赋值为空
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            updateProductElements(item.productElementList[index], {
              idTypeEndDate: { isShow: data[keys][keys] === '1' ? true : false,isPublic:data[keys][keys] === '1' ? true : false, firstValue: data[keys][keys] === '1' ? null : item.productElementList[index].filter(value => value.elementCode ===  'idTypeEndDate')[0].firstValue, required: data[keys][keys] === '1' ? '0': '1' }
            })
          }
        })
        if(state.insuredObj[moduleType].length>0){
          state.insuredObj[moduleType].forEach(item =>{
            if(item['idTypeEndDate']){
              item['idTypeEndDate'].required = data[keys][keys] === '1'  ? '0' : '1'
              item['idTypeEndDate']['idTypeEndDate'] = data[keys][keys] === '1'  ? null : item['idTypeEndDate']['idTypeEndDate']
            }
            if(item['idTypeStartDate']){
              item['idTypeStartDate'].required = data[keys][keys] === '1'  ? '1' : '0'
            }

          })
        }
      } else if (keys === 'birthAddress') {
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            item.productElementList[index].forEach(pro => {
              if (pro.elementCode === keys) {
                pro.tagName = data[keys][keys]
              }
            })
          }
        })
      }else if (keys === 'bankCityCode' ||keys === 'newBankCityCode' || keys === 'occupationCode' || keys === 'areaCode' || keys === 'newBankAreaCode' || keys ==='targetArea') {
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            item.productElementList[index].forEach(pro => {
              if (pro.elementCode === keys) {
                pro.tagName = data[keys]['occupationName']
                pro.show_tagName = data[keys]['occupationName']
                pro.show_Level = data[keys]['occupationLevel']
              }
            })
          }
        })
      } else if (keys === 'idType' && (moduleType === 'Guardian' || moduleType === 'guardian')) {
        // 01 : 身份证 不展示   90: 出生日期
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            updateProductElements(item.productElementList[index], {
              idNo: { isShow: data[keys][keys] === '01' ? false : true, firstValue: data[keys][keys] === '01' ? item.productElementList[index].filter(value => value.elementCode ===  'idNo')[0].firstValue : undefined, required: data[keys][keys] === '01' ? '1': '0'  },
              birthday: { isShow: data[keys][keys] === '01' ? true : false, firstValue:data[keys][keys] !== '01' ? item.productElementList[index].filter(value => value.elementCode ===  'birthday')[0].firstValue : undefined, required: data[keys][keys] === '01' ? '0': '1' }
            })
          }
        })
        if(state.insuredObj[moduleType].length>0){
          state.insuredObj[moduleType].forEach(item =>{
            if(item['birthday']) item['birthday'].required = data[keys][keys] === '50'  ? '1' : '0'
            if(item['idNo']) item['idNo'].required = data[keys][keys] === '01' ? '1' : '0'
          })
        }
      }else if((keys === 'idNo' || keys ==='birthday') && (moduleType === 'Guardian' || moduleType === 'guardian')){
        let list  = state.showData.filter(item => item.moduleType === moduleType)
        if(list.length){
          let age
          let value = list[0].productElementList[0].filter(ele => ele.elementCode === 'idType')[0].firstValue
          if(value === '01'){
            if (data[keys][keys].length !== 18) return
            let strBirthday = data[keys][keys].substr(6, 4) + '-' + data[keys][keys].substr(10, 2) + '-' + data[keys][keys].substr(12, 2)
            age = calAge(strBirthday) + ''
          }else if(value === '50'){
            if(!data[keys][keys]) return
            age = calAge(data[keys][keys]) + ''
          }
          if(Number(age) >= 18){
            state.detailModal = '被监护人年龄不能大于17周岁'
            state.isIdentical = true
          }else if(Number(age) < 0){
            state.detailModal = '被监护人年龄不能小于0周岁'
            state.isIdentical = true
          }
        }
      }else if (keys === 'staffFlag') {
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            updateProductElements(item.productElementList[index], {
              staffRelativesRelation: { isShow: data[keys][keys] === '1' ? false: true,isPublic: data[keys][keys] === '1' ? false: true, firstValue: '99999', required: '0' },
            })
          }
        })
      }else if(keys === 'appntType' || keys === 'idType'){
        if(keys === 'idType' && data[keys][keys] === '05'){
          const birthday = state.insuredObj[moduleType][index]['birthday']['birthday']
          let type = false
          if(!birthday){
            state.detailModal = '请填写被保人出生日期'
            state.isIdentical = true
            type= true
          }else if(calAge(birthday) > 15){
            state.detailModal = '证件类型为户口簿时, 被保人年龄不能大于15周岁'
            state.isIdentical = true
            type= true
          }
          if(type){
            nextTick(()=>{
              state.showData.forEach(item => {
                if (item.moduleType === moduleType) {
                  updateProductElements(item.productElementList[index], {
                    idType: { isShow: false, firstValue: '01', required: '1' },
                  })
                }
              })
              state.insuredObj[moduleType][index]['idType']['idType'] = '01'
            })
            return
          }
        }
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            updateApplentType(item.productElementList[index], keys)
          }
        })
      }else if(keys === 'renewFlag'){
        // 支付模块的判断
        state.showData.forEach(item => {
          if (item.moduleType === moduleType) {
            updateProductElements(item.productElementList[index], {
              newBankAccNo: { isShow: data[keys][keys] === 'Y' ? false: true, firstValue: data[keys][keys] === 'Y' ? item.productElementList[index].find(ele =>ele.elementCode ==='newBankAccNo').firstValue : '', required: '0' },
              newBankAreaCode: { isShow: data[keys][keys] === 'Y' ? false: true, firstValue: data[keys][keys] === 'Y' ? item.productElementList[index].find(ele =>ele.elementCode ==='newBankAreaCode').firstValue : '', required: '0' },
              newBankCardUrl: { isShow: data[keys][keys] === 'Y' ? false: true, firstValue: data[keys][keys] === 'Y'? item.productElementList[index].find(ele =>ele.elementCode ==='newBankCardUrl').firstValue: '', required: '0' },
              newBankCode: { isShow: data[keys][keys] === 'Y' ? false: true, firstValue: data[keys][keys] === 'Y' ? item.productElementList[index].find(ele =>ele.elementCode ==='newBankCode').firstValue : '', required: '0' },
            })
          }
        })
        if(state.insuredObj[moduleType].length>0){
          state.insuredObj[moduleType].forEach(item =>{
            if(item['newBankAccNo']) item['newBankAccNo'].required = data[keys][keys] === 'Y'  ? '1' : '0'
            if(item['newBankAreaCode']) item['newBankAreaCode'].required = data[keys][keys] === 'Y' ? '1' : '0'
            if(item['newBankCardUrl']) item['newBankCardUrl'].required = data[keys][keys] === 'Y' ? '1' : '0'
            if(item['newBankCode']) item['newBankCode'].required = data[keys][keys] === 'Y' ? '1' : '0'

          })
        }
      }else if(keys === 'updateLodeDetail'){
        state.insuredObj[moduleType][index]['imageList'] = []
        if (!data.isDisabled && Array.isArray(data[keys][keys]) && data[keys][keys].length){
          data[keys][keys].forEach(item =>{
            state.insuredObj[moduleType][index]['imageList'].push({
              imageTYpe: '3',
              cardType: item.cardType,
              imageUrl: item.label? item.label : item.imageUrl
            })
          })
        }

      }else if(keys === 'isSameNew') {
        // if (data[keys][keys] === '1'){
          let elementList = []
          state.showData.forEach(item => {
            if (item.moduleType === moduleType) {
              for (const element of item.productElementList[index]) {
                elementList.push(element.elementCode)
              }
              for (const element of item.productElementList[index]) {
                sameNewIsY(element, elementList, state.insuredObj[moduleType][index])
              }
            }
          })
        // }
        // else{
        //   state.insuredObj[moduleType].forEach(item =>{
        //     Object.keys(item).forEach(key =>{
        //       item[key]['required'] = '1'
        //     })
        //   })
        //   state.showData.forEach(item => {
        //     if (item.moduleType === moduleType) {
        //       for (const element of item.productElementList[index]) {
        //         element.isShow =  false
        //         element['required'] = '1'
        //       }
        //     }
        //   })
        // }
      }else if(keys === 'marriage'){
        let applicantMarriage = state.insuredObj.applicant[0][keys][keys]
        let insuredMarriage = state.insuredObj.insureds[0]?.[keys]?.[keys]
        let flag = {bool: true,msg: ''}
        if (moduleType === 'addBeneficiarys') {
          let birthday = state.insuredObj[moduleType][0]['birthday']['birthday']
          if (birthday && calAge(birthday) < 18 && state.insuredObj[moduleType][0]?.['marriage']?.['marriage'] && state.insuredObj[moduleType][0]['marriage']['marriage'] !== '0')  {
            state.detailModal = '受益人未满18周岁，婚姻状态仅能为未婚！'
            state.isIdentical = true
            return
          }
        }
        if(moduleType === 'applicant'){
          state.insuredObj.insureds.forEach(item =>{
            if(applicantMarriage && item['relationToAppnt']['relationToAppnt'] === '02' && applicantMarriage !== '1') {
              flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
            }
          })
        }else if (moduleType === 'insureds') {
          let re= state.insuredObj.insureds[0]['relationToAppnt']['relationToAppnt']
          // let re2= state.insuredObj.insureds[index]['relationToAppnt']['relationToAppnt']
          if(re === '02' && insuredMarriage && (insuredMarriage !== '1' || applicantMarriage !== '1')) {
            flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
          }
          state.designatedBenefitList.forEach(benefit => {
            if (insuredMarriage && benefit.relationToInsured && benefit.relationToInsured === '02' && insuredMarriage !== '1') {
              flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
            }
          })
        } else if (moduleType === 'addBeneficiarys') {
          let benefit = state.insuredObj.addBeneficiarys[0][keys][keys]
          let re= state.insuredObj.addBeneficiarys[0]['relationToInsured']['relationToInsured']
          // let re2= state.insuredObj.insureds[index]['relationToAppnt']['relationToAppnt']
          if(insuredMarriage && re === '02' && (insuredMarriage !== '1' || benefit !== '1')) {
            flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'}
          }
        }
        showErrTipModal(flag)
      }
      // else if (keys === 'nativePlace') {
      //   let flag = {bool: true,msg: ''}
      //   let idType = (moduleType === 'applicant' ? state.insuredObj[moduleType][0]['appntIdType']['appntIdType'] : state.insuredObj[moduleType][0]['idType']['idType'])
      //   if (idType === '15'&& data[keys][keys] === 'CHN') {
      //     flag = {bool: false,msg: '证件类型为外国人永久居留证时国籍不可为中国'}
      //     showErrTipModal(flag)
      //     return false
      //   }
      // }


      //受益人待定
    }
    // 多被保人的保费判断
    const getPremium = async (data, keys, index, moduleType) =>{
      // 多被保人的
      if (state.maximumLimit > 1 && (moduleType == 'applicant' || moduleType === 'insureds')) {
        const insuredValue = state.insuredBackUp[moduleType][index][keys][keys]
        if((data[keys][keys] || data[keys][keys] == '0') && insuredValue !== data[keys][keys]){
          state.insuredBackUp[moduleType][index][keys][keys] = data[keys][keys]
          await handelAssignmentPremium()
          await handelClearInsureds(data, keys, index)
        }
      }
    }

    const updateProductElements = (elementList, updateList) => {
      for (const element of elementList) {
        if (updateList[element.elementCode]) {
          const { isShow,isPublic, firstValue, required } = updateList[element.elementCode]
          element.isShow = isShow
          element.firstValue = firstValue
          element.required = required
          element.isPublic = isPublic
        }
      }
    }
    // 下拉框关联  el: 投保人类型关联证件类型
    const updateApplentType = (elementList, eleCode) =>{
      let keys = []
      let childrenType = {}
      for (const ele of elementList) {
        if (ele.elementCode === eleCode && ele.elementOperatingMode.mode_select.dropDownConfiguration.length > 0){
          const list = ele.elementOperatingMode.mode_select.dropDownConfiguration
          for (const obj of list) {
            if(obj.childrenType && JSON.stringify(obj.childrenType) !== '{}' && obj.value === ele.firstValue){
              childrenType = obj.childrenType
              keys = Object.keys(obj.childrenType)
            }
          }
        }
      }
      if (keys.length){
        for (let i = 0; i < keys.length; i++) {
          for (const ele of elementList) {
            if(ele.elementCode === keys[i]){
              ele.firstValue = childrenType[keys[i]].value
              ele.dictionaryName = null
              ele.elementOperatingMode.mode_select.dropDownConfiguration = []
              ele.elementOperatingMode.mode_select.dropDownConfiguration = childrenType[keys[i]]
              ele.elementConfig.promptLanguage = childrenType[keys[i]][0]?.placeholder
            }
          }
        }
      }

    }


    const calAge = (d, isTBR=false) => {
      console.log(isTBR)
      // let age = 0
      // if (isTBR) {
      //   age = getTBRAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : undefined).age[0]
      // } else {
      let age = getBBRAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : '0', store.getters['insured/getAGEDIFF']).age
      // }
      console.log('-s-s-s-s-s-s-', age)
      return age
    }
    const deleteElementFun = function (id, index, moduleType) {
      state.loadingShow = true
      let arr = state.insuredObj[moduleType]
      let arr1 = state.insuredBackUp[moduleType]
      arr.splice(index, 1)
      arr1.splice(index, 1)
      for (let i = 0; i < state.showData.length; i++) {
        if (state.showData[i].id === id) {
          state.showData[i].productElementList.splice(index, 1)
          if (state.showData[i].moduleType === 'insureds' && state.showData[i].productElementList.length === 1) {
            state.insuredsType = 'N'
            state.showData[i].type = ''
            let show = false
            state.showData[i].productElementList[0].forEach(pro => {
              if (pro.elementCode === 'relationToAppnt' && pro.firstValue === '01') {
                show = true
              }
              if (pro.elementCode === 'premium') pro.isShow = show
            })
          }
        }

      }
      handelAssignmentPremium()
      state.loadingShow = false
    }
    const addElementFun = function (id, index, moduleType) {
      let arr = JSON.parse(JSON.stringify(state.insuredBackUp[moduleType][0]))
      const moduleList = JSON.parse(JSON.parse(store.getters['insured/getRouterJump'])?.pageJson)
      const keylist = moduleList.filter(item => item.moduleType === moduleType)[0].productElementList[0]
      Object.keys(arr).forEach(key =>{
        arr[key][key] = null
        keylist.forEach(ele =>{
          if(ele.elementCode === key){
            arr[key].required = ele.required
          }
        })
      })
      let cloneList = []
      let _index = 0
      let list = []
      for (let i = 0; i < state.showData.length; i++) {
        if (state.showData[i].id === id) {
          _index = i
          list = state.showData[i]
        }
      }
      cloneList = JSON.parse(JSON.stringify(list.productElementList[index]))
      const cList = cloneList.filter(item => item.elementCode === 'relationToAppnt' && item.elementMode === '103')

      for (let i = 0; i < cloneList.length; i++) {
        cloneList[i].firstValue = null
        cloneList[i].endValue = null
        cloneList[i].tagName = ''
        cloneList[i].id += new Date().getTime()
        if (cloneList[i].elementCode === 'premium') {
          cloneList[i].isShow = false
        }
        if (cloneList[i].elementCode === 'healthToldFlag') {
          cloneList[i].firstValue = null
        }
        if (cloneList[i].elementCode === 'relationToAppnt' && cloneList[i].elementMode !== '103') {
          cloneList[i].firstValue = '新增'
        }else if(cList &&cList.length){
          cloneList[i].isShow = false
          if(cloneList[i].elementCode === 'relationToAppnt' && cloneList[i].elementMode === '103'){
            cloneList[i].firstValue = '99'
          }

        }
      }
      if(cList && cList.length){
        arr['relationToAppnt']['relationToAppnt'] = '99'
      }else{
        arr['relationToAppnt']['relationToAppnt'] = '新增'
      }
      state.insuredObj[moduleType].push(arr)
      state.insuredBackUp[moduleType].push(JSON.parse(JSON.stringify(arr)))
      state.showData[_index].productElementList.push(cloneList)
      // 新增后的数据 进行保费赋值
      handelAssignmentPremium()
    }
    // 第一个保费赋值
    const handelAssignmentPremium = async () => {
      let insurList = state.showData.filter(item => item.moduleType === 'insureds')
      let type = 'some'
      if (!insurList || !insurList.length) return
      let list = insurList[0].productElementList
      // 整理出来 需要试算的数据
      let arr = []
      let multipleInsuredFormList = []
      for (let i = 0; i < list.length; i++) {
        const targetItem = list[i].filter((item) => item.elementCode === 'relationToAppnt' && item.firstValue && item.firstValue !== '新增' && item.firstValue !== '99')
        if (targetItem) {
          arr.push(list[i])
          multipleInsuredFormList.push({
            sequenceNo: i,
            riskList: JSON.parse(JSON.stringify(detailData.riskFormData.riskList))
          })
        }
      }
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
          await getFinalMultipleInsuredFormList(i, j, arr, multipleInsuredFormList, 'skip')
        }
        await PremCalculator.factorChangeInputInfo(i, type,(action, flag, value, result,param) => {
          if (Number(state.maximumLimit) > 1 && result.premAddSign === 0) {
            // 给被保人的对应项进行 保费赋值
            getInsuredsPrem(result)
          } else {
            let tk = newPremiumInTK(param)
            if (tk) {
              state.premium = tk
            }else if (result && param) {
              changeAmntValue(result, param, value)
            }else{
              state.premium = value || '0.00'
            }
          }
          if(result && result.code === 0){
            state.isIdentical = true
            state.detailModal = result.message
          }
          state.premiumObj = result
          // 保险模块中可能含有保费
          handelPayTypeData(result)
        })
      }
    }
    // 这个是德华安顾的附加险  两全
    const changeAmntValue = (result, data, value) => {
      let obj = getAmntElementRefAndSpecailCal(result, data)
      if (obj) {
        const index = detailData.riskFormData.riskList.findIndex(item => item.riskId === obj.addRiskId)
        const riskIndex = state.riskList.findIndex(item => item.riskId === obj.addRiskId)
        state.riskList[riskIndex].calculateFactor.forEach(risk =>{
          if(risk.factorType === 'Amnt'){
            risk.factorValue = obj.val
            risk.factorValueName = obj.val + '元'
          }
        })
        if(index !== -1){
          const amntList = detailData.riskFormData.riskList[index].calculateFactor.filter(risk => risk.factorType === 'Amnt')
          if(amntList[0].factorValue !== obj.val){
            amntList[0].factorValue = obj.val
            amntList[0].factorValueName = obj.val + '元'
          }else{
            state.premium = value || '0.00'
          }
        }
      }else{
        state.premium = value || '0.00'
      }
    }
    const getFinalMultipleInsuredFormList = async (i, j, arr, multipleInsuredFormList) => {
      if (arr[i][j].elementCode === 'socialInsuFlag'){
        const value = arr[i].filter(item => item.elementCode === 'relationToAppnt')[0]
        if(value.firstValue === '01'){
          arr[i][j].firstValue = state.insuredObj['applicant'][0].socialInsuFlag? state.insuredObj['applicant'][0]['socialInsuFlag']['socialInsuFlag'] : (arr[i][j].firstValue ? arr[i][j].firstValue : 'Y')
        }
      }
      if (arr[i][j].firstValue) {
        let birthday
        let sexValue
        let age
        switch (arr[i][j].elementCode) {
          case 'socialInsuFlag':
            //社保
            for (const res of multipleInsuredFormList[i].riskList) {
              for (const item of res.calculateFactor) {
                if (item.factorType === 'SocialFlag') {
                  let valObject = createValObject('radioSelection', 'SocialFlag', arr[i][j].firstValue, ['无', '有'][arr[i][j].firstValue === 'Y' ? 1 : 0], res.riskId, i)
                  await factorChange(valObject)
                } else if (item.factorType === 'insuredNum') {
                  let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                  await factorChange(valObject)
                }
              }
            }
            break
          case 'relationToAppnt':
            if(arr[i][j].firstValue === '01'){
              birthday = state.insuredObj['applicant'] && state.insuredObj['applicant'][0]['appntBirthday']
                ? state.insuredObj['applicant'][0]['appntBirthday']['appntBirthday'] : ''
              sexValue = state.insuredObj['applicant'] && state.insuredObj['applicant'][0]['appntSex']
                ? state.insuredObj['applicant'][0]['appntSex']['appntSex'] : ''
            }else{
              birthday = state.insuredObj['insureds'] && state.insuredObj['insureds'][i]['birthday']
                ? state.insuredObj['insureds'][i]['birthday']['birthday'] : ''
              sexValue = state.insuredObj['insureds'] && state.insuredObj['insureds'][i]['sex']
                ? state.insuredObj['insureds'][i]['sex']['sex'] : ''
            }
            //计算保费试算

            if (birthday) {
              age = calAge(birthday) + ''

              for (const res of multipleInsuredFormList[i].riskList) {
                for (const item of res.calculateFactor) {
                  if (item.factorType === 'TextAge') {
                    let valObject = createValObject('date', 'TextAge', age, age, res.riskId, i)
                    await factorChange(valObject)
                  } else if (item.factorType === 'Sex') {
                    let valObject = createValObject('radio', 'Sex', sexValue, ['男', '女'][Number(sexValue) === 1 ? 0 : 1], res.riskId, i)
                    await factorChange(valObject)
                  } else if (item.factorType === 'insuredNum') {
                    let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                    await factorChange(valObject)
                  }
                }
              }
            }
            break
          case 'birthday':
            if (!arr[i][j].firstValue) break
            age = calAge(arr[i][j].firstValue) + ''

            for (const res of multipleInsuredFormList[i].riskList) {
              for (const item of res.calculateFactor) {
                if (item.factorType === 'TextAge') {
                  let valObject = createValObject('date', 'TextAge', age, age, res.riskId, i)
                  await factorChange(valObject)
                } else if (item.factorType === 'insuredNum') {
                  let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                  await factorChange(valObject)
                }
              }
            }
            break
          case 'appntSex':
          case 'sex':
            if (i !== multipleInsuredFormList[i].sequenceNo) return
            for (const res of multipleInsuredFormList[i].riskList) {
              for (const item of res.calculateFactor) {
                if (item.factorType === 'Sex') {

                  let valObject = createValObject('radio', 'Sex', arr[i][j].firstValue + '', ['男', '女'][Number(arr[i][j].firstValue) === 1 ? 0 : 1], res.riskId, i)
                  await factorChange(valObject)
                } else if (item.factorType === 'insuredNum') {
                  let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                  await factorChange(valObject)
                }
              }
            }
            break
          case 'idNo':
            if (!arr[i][j].firstValue) break
            birthday = arr[i][j].firstValue ? arr[i][j].firstValue.substr(6, 4) + '-' + arr[i][j].firstValue.substr(10, 2) + '-' + arr[i][j].firstValue.substr(12, 2) : ''
            age = calAge(birthday) + ''
            parseInt(birthday.substr(16, 1)) % 2 == 1 ? sexValue = 1 : sexValue = 2

            for (const res of multipleInsuredFormList[i].riskList) {
              for (const item of res.calculateFactor) {
                if (item.factorType === 'TextAge') {
                  let valObject = createValObject('date', 'TextAge', age, age, res.riskId, i)
                  await factorChange(valObject)
                } else if (item.factorType === 'insuredNum') {
                  let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                  await factorChange(valObject)
                }
              }
            }
            break
          case 'appntIdNo':
            if (!arr[i][j].firstValue) break
            birthday = arr[i][j].firstValue ? arr[i][j].firstValue.substr(6, 4) + '-' + arr[i][j].firstValue.substr(10, 2) + '-' + arr[i][j].firstValue.substr(12, 2) : ''
            age = calAge(birthday) + ''
            parseInt(birthday.substr(16, 1)) % 2 == 1 ? sexValue = 1 : sexValue = 2
            for (const res of multipleInsuredFormList[i].riskList) {
              for (const item of res.calculateFactor) {
                if (item.factorType === 'TextAge') {
                  let valObject = createValObject('date', 'TextAge', age, age, res.riskId, i)
                  await factorChange(valObject, 'applicant')
                } else if (item.factorType === 'insuredNum') {
                  let valObject = createValObject('radioSelection', 'insuredNum', arr.length + '', arr.length + '人', res.riskId, i)
                  await factorChange(valObject, 'applicant')
                } else if (item.factorType === 'Sex') {
                  let valObject = createValObject('radio', 'Sex', sexValue, ['男', '女'][Number(sexValue) === 1 ? 0 : 1], res.riskId, i)
                  await factorChange(valObject, 'applicant')
                }
              }
            }
            break
        }
      }
    }
    // 用于创建 valObject 对象
    const createValObject = (elementType, factorType, value, name, riskId, insuredIndex) => {
      return {
        elementType,
        factorType,
        value,
        name,
        riskId,
        insuredIndex,
        type: 'skip'
      }
    }
    // const getFactorChangePrem = (valObject) =>{
    //   PremCalculator.factorChange(valObject, null, (action, flag, value, result) => {
    //     if (Number(state.maximumLimit) > 1) {
    //       // 给被保人的对应项进行 保费赋值
    //       getInsuredsPrem(result)
    //     } else {
    //       state.premium = value
    //     }
    //     state.premiumObj = result
    //     // 保险模块中可能含有保费
    //     handelPayTypeData(result)
    //   })
    // }

    const getPreviewData = () => {
      // console.log(state.showData, 'state.showData')
      state.showData.forEach(item => {
        if (item.moduleType === 'beneficiarys') {
          for (let i = 0; i < item['productElementList'].length; i++) {
            for (let j = 0; j < item['productElementList'][i].length; j++) {
              if (item['productElementList'][i][j].elementCode === 'bnfType') {
                if (!state.isInclude) {
                  item['productElementList'][i][j].isShow = true
                  item['productElementList'][i][j].required = '0'
                } else {
                  item['productElementList'][i][j].isShow = false
                  item['productElementList'][i][j].required = '1'
                }
                // item['productElementList'][i][j].firstValue = null
                // 获取受益人类型
                if (item['productElementList'][i][j].elementOperatingMode.mode_select.isDictionary) {
                  request(getExtendUri('dictionary_query_byType'),
                    {data: {ids: [], types: [item['productElementList'][i][j].dictionaryName]}}).then(
                    res => {
                      if (res.code == 1) {
                        state.beneficiarysDictionary = res.result[item['productElementList'][i][j].dictionaryName][0].value
                      }
                    })
                } else if(item['productElementList'][i][j].elementMode !== '0'){
                  state.beneficiarysDictionary = item['productElementList'][i][j].elementOperatingMode.mode_select.dropDownConfiguration[0].value
                }else{
                  state.beneficiarysDictionary = 'str'
                }
                continue
              }
              item['productElementList'][i][j].isShow = true
            }
          }
        }
      })
    }
    const setIdType = (data) => {
      console.log(data, 'data111222333')
      let fileIsRequired = state.insuredObj[data['idType']['moduleType']][0]?.['updateLodeDetail']?.['required']
      state.showData.forEach(item => {
        if (item.moduleType === data['idType']['moduleType']) {
          item.productElementList[0].forEach(ite => {
            if(ite.elementCode === 'idType' || ite.elementCode === 'appntIdType') {
              ite.firstValue = data['idType']['idType']
            }
            if (data['idType']['idType'] === '07' && data.isClear) {

              if (ite.elementCode === 'birthday') {
              ite.elementConfig = benefitBrithDayDateConfig.elementConfig
              ite.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
              ite.elementVaule = benefitBrithDayDateConfig.elementVaule
              ite.elementMode = benefitBrithDayDateConfig.elementMode
              }
              if (ite.elementCode === 'idTypeStartDate') {
                ite.elementConfig = idTypeStartDateConfig.elementConfig
                ite.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                ite.elementVaule = idTypeStartDateConfig.elementVaule
                ite.elementMode = idTypeStartDateConfig.elementMode
              }
              if (
                        ite.elementCode === 'idNo' ||
                        ite.elementCode === 'birthday' ||
                        ite.elementCode === 'idTypeEndDate' ||
                        ite.elementCode === 'idTypeStartDate' ||
                        ite.elementCode === 'sex'
                      ) {
                        ite.isShow = false
                        ite.firstValue = null,
                        ite.required = '1'
                        state.insuredObj[data['idType']['moduleType']][0][ite.elementCode][ite.elementCode] = null
                        state.insuredObj[data['idType']['moduleType']][0][ite.elementCode]['elementName'] = ite.elementName
                      }
            }
            if (data['idType']['idType'] === '05' && data.isClear) {
              if (ite.elementCode === 'birthday') {
                ite.elementConfig = benefitBrithDayInputConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayInputConfig.elementVaule
                ite.elementMode = benefitBrithDayInputConfig.elementMode
              }
              if (ite.elementCode === 'idTypeStartDate') {
                ite.elementConfig = idTypeStartDateConfig.elementConfig
                ite.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                ite.elementVaule = idTypeStartDateConfig.elementVaule
                ite.elementMode = idTypeStartDateConfig.elementMode
              }
              if (
                        ite.elementCode === 'idNo' ||
                        ite.elementCode === 'birthday' ||
                        ite.elementCode === 'idTypeEndDate' ||
                        ite.elementCode === 'idTypeStartDate' ||
                        ite.elementCode === 'sex'
                      ) {
                        ite.isShow = false
                        ite.firstValue = null,
                        ite.required = '1'
                        state.insuredObj[data['idType']['moduleType']][0][ite.elementCode][ite.elementCode] = null
                        state.insuredObj[data['idType']['moduleType']][0][ite.elementCode]['elementName'] = ite.elementName
                      }
            }
            if (data['idType']['idType'] === '15' && data.isClear) {
              if (ite.elementCode === 'birthday') {
                ite.elementConfig = benefitBrithDayDateConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayDateConfig.elementVaule
                ite.elementMode = benefitBrithDayDateConfig.elementMode
                console.log(state.showData, benefitBrithDayInputConfig, '3334444')
              }
              if (ite.elementCode === 'appntBirthday') {
                ite.elementConfig = benefitBrithDayDateConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayDateConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayDateConfig.elementVaule
                ite.elementMode = benefitBrithDayDateConfig.elementMode
                console.log(state.showData, benefitBrithDayInputConfig, '3334444')
              }
              if (ite.elementCode === 'idTypeStartDate') {
                ite.elementConfig = idTypeStartDateConfig.elementConfig
                ite.elementOperatingMode = idTypeStartDateConfig.elementOperatingMode
                ite.elementVaule = idTypeStartDateConfig.elementVaule
                ite.elementMode = idTypeStartDateConfig.elementMode
                console.log(state.showData, idTypeStartDateInputConfig, '3334444')
              }
              if (ite.elementCode === 'idTypeEndDate') {
                ite.elementConfig = idTypeEndDateConfig.elementConfig
                ite.elementOperatingMode = idTypeEndDateConfig.elementOperatingMode
                ite.elementVaule = idTypeEndDateConfig.elementVaule
                ite.elementMode = idTypeEndDateConfig.elementMode
                // ite.elementOperatingMode.mode_time.timeMinValue = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              }
          if (
            ite.elementCode === 'idNo' ||
            ite.elementCode === 'birthday' ||
            ite.elementCode === 'idTypeEndDate' ||
            ite.elementCode === 'idTypeStartDate' ||
            ite.elementCode === 'sex'
          ) {
            ite.isShow = false
            ite.firstValue = null,
            ite.required = '1'
            state.insuredObj[data['idType']['moduleType']][0][ite.elementCode][ite.elementCode] = null
            state.insuredObj[data['idType']['moduleType']][0][ite.elementCode]['elementName'] = ite.elementName
          }
        }
            if (data['idType']['idType'] === '01' && data.isClear){
              if (ite.elementCode === 'birthday' || (fileIsRequired === '1' && (ite.elementCode === 'idTypeStartDate' || ite.elementCode === 'idNo' || ite.elementCode === 'idTypeEndDate'))) {
                ite.elementConfig = benefitBrithDayInputConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayInputConfig.elementVaule
                ite.elementMode = benefitBrithDayInputConfig.elementMode
              }
            }
          })
        }
      })
      if (data['idType']['moduleType'] === 'applicant') {
        state.insuredObj[data['idType']['moduleType']][0]['appntIdType']['appntIdType'] =  data['idType']['idType']
      } else {
        state.insuredObj[data['idType']['moduleType']][0]['idType']['idType'] =  data['idType']['idType']
      }
      // PreviewPopData(data)
    }
    // ocr回显
    const setUserDetail = (data, ttt = '') => {
      console.log('33333', ttt)

      if (ttt === 'ocr') {
        state.isOcrSet = true
      }
      let keys = Object.keys(data)[0]
      let index = data[keys].index
      let moduleType = data[keys].moduleType
      // console.log(state.insuredObj, 'state.insuredObj[moduleType][index][keys]')
      state.insuredObj[moduleType][index][keys] = data[keys]
      let type = (data[keys].selectId === '1' || data[keys].selectId === '01' || !data[keys].selectId)
      // let fileIsRequired = '0'
      state.showData.forEach(item => {
        if (item.moduleType === moduleType) {
          setUserDetailType(item['productElementList'][index], data[keys]['detailData'], type)
          console.log(data[keys]['detailData'], 'data3334443')
          if (data[keys]['detailData']?.selectId === '01') {
            state.showData.forEach(items => {
          if (items.moduleType === moduleType) {
            items.productElementList[0].forEach(ite => {
              // if (ite.elementCode === 'updateLodeDetail') {
              //   fileIsRequired = ite.required
              // }
              if (ite.elementCode === 'appntBirthday' || ite.elementCode === 'birthday') {
                ite.elementConfig = benefitBrithDayInputConfig.elementConfig
                ite.elementOperatingMode = benefitBrithDayInputConfig.elementOperatingMode
                ite.elementVaule = benefitBrithDayInputConfig.elementVaule
                ite.elementMode = benefitBrithDayInputConfig.elementMode
              }
              if (ite.elementCode === 'idTypeStartDate') {
                ite.elementConfig = idTypeStartDateInputConfig.elementConfig
                ite.elementOperatingMode = idTypeStartDateInputConfig.elementOperatingMode
                ite.elementVaule = idTypeStartDateInputConfig.elementVaule
                ite.elementMode = idTypeStartDateInputConfig.elementMode
              }
              if (ite.elementCode === 'idTypeEndDate') {
                ite.elementConfig = idTypeEndDateInputConfig.elementConfig
                ite.elementOperatingMode = idTypeEndDateInputConfig.elementOperatingMode
                ite.elementVaule = idTypeEndDateInputConfig.elementVaule
                ite.elementMode = idTypeEndDateInputConfig.elementMode
              }
            })
          }
        })
          }
        }
      })
      if(moduleType === 'payment'){
        state.insuredObj[moduleType][index]['newBankCardUrl'] = {
          newBankCardUrl: data[keys][keys],
          cosUrls: [{positiveUrl:data[keys][keys]}],
          moduleType: data[keys].moduleType
        }
      }else{
        if(moduleType === 'insureds' || moduleType === 'applicant' || moduleType === 'beneficiarys'){
          let code = (moduleType === 'insureds'|| moduleType === 'beneficiarys') ? 'idType' : 'appntIdType'
          // console.log( state.insuredObj[moduleType][index], ' state.insuredObj[moduleType][index]')
          state.insuredObj[moduleType][index][code][code] = ''
          state.insuredObj[moduleType][index][code][code] = data[keys].selectId
        }
        state.insuredObj[moduleType][index]['imageList'] = []
        data[keys][keys].forEach(item =>{
          state.insuredObj[moduleType][index]['imageList'].push(
            {
              cardType: item.cardType,
              imageTYpe: '3',
              imageUrl: item.label
            }
          )
        })
      }
    }
    const setUserDetailType = (item, data, type) => {
      console.log(item, data, type, 'erererer')
      if (item && item.length) {
        for (let i = 0; i < item.length; i++) {
          switch (item[i].elementCode) {
            case 'appntName':
            case 'name':
              item[i].firstValue = data.name || item[i].firstValue
              break
            case 'appntSex':
            case 'sex':
              item[i].firstValue = type ? data.sex == '男' ? '1' : (data.sex == '女' ? '2' : '3') : ''
              break
            case 'appntIdNo':
            case 'idNo':
              if(type){
                getInsuredsType(data.moduleType, i, data.idCardNo)
                item[i].firstValue = data.idCardNo
              }else{
                item[i].firstValue = ''
              }
              break
            case 'appntIdType':
            case 'idType':
            case 'newAccountType':
              item[i].firstValue = data.selectId
              break
            case 'idTypeStartDate':
              item[i].firstValue = type ? data.idIssueDate?.split('.')?.join('-') : ''
              break
            case 'idTypeEndDate':
              item[i].firstValue = type ? (data.idExpiryDate.includes('长期') ? '9999-01-01': (data.idExpiryDate?.split('.')?.join('-') ?? '')) : ''
              break
            case 'homeAddress':
              item[i].firstValue = type ? data.address : ''
              break
            case 'newBankAccNo':
            case 'card':
              item[i].firstValue = type ? data.bankCardNo : ''
              break
            case 'appntBirthday':
            case 'birthday':
              item[i].firstValue = type ? data.birth : ''
              break
            default:
              break
          }
        }
      }
    }
    const handleOk = () => {
      if (state.verificationCode.length < 6) {
        message['error']('请输入完整的验证码')
        return false
      }
      state.loading = true
      setTimeout(() => {
        state.loading = false
      }, 2000)
      let baseInfo = {
        codeNo: state.verificationCode,
        tradeNo: state.tradeNo
      }
      request(getExtendUri('confirmBankAccountProtocol'), {baseInfo: baseInfo}).then(res => {
        //&& !state.bankBeforeSignFlag
        if (res.code === 1) {
          state.visible = false
          state.loading = false
          state.loadingShow = false
          state.timer = null
          state.verificationCode = ''
          //直接跳转下一个页面
          state.routerFlag = true
          routerJump(state.tradeNo)
        }else {
          message['error'](res.message)
          state.loading = false
          state.loadingShow = false
        }
      })

    }
    const handleCancel = () => {
      state.visible = false
      state.loading = false
      state.loadingShow = false
      clearInterval(state.timer)
      localStorage.removeItem('startTimeLogin')
      state.timer = null
      state.textshow = '获取验证码'
      state.countDownIng = false
      state.verificationCode = ''
      state.countDownTime = 60
    }
    //倒计时功能
    const countDown = () => {
      if (!state.countDownIng) {
        let baseInfo = {
          tradeNo: state.tradeNo
        }
        state.sendLoading = true
        request(getExtendUri('applyBankAccountProtocol'), {baseInfo: baseInfo}).then(res => {
          if (res.code != 1) {
            message['error'](res.message)
            return false
          } else {
            state.timer = setInterval(() => {
          state.countDownTime--
          state.textshow = state.countDownTime + 's后重新发送'
          state.getCodeDisabled = true
          state.countDownIng = true
          if (state.countDownTime <= 0) {
            localStorage.removeItem('startTimeLogin')
            clearInterval(state.timer)
            state.textshow = '获取验证码'
            state.countDownTime = 60
            state.countDownIng = false
          }
        }, 1000)
          }
        }).finally(() => {
          state.sendLoading = false
        })
      }
    }

    const meetTheConditions = () => {
      // 从risklist里面去构造一个被保人对象
      // state.slectPlanId  计划id
      // 被保人对象
      if (state.insuredObj.insureds) {
        if (state.insuredObj.insureds[0].relationToAppnt.relationToAppnt === '01' && state.insuredObj.applicant && state.insuredObj.applicant.length) {
          for (let i = 0; i < state.insuredObj.applicant.length; i++) {
            let item = state.insuredObj.applicant[i]
            let idNum = item.appntIdNo && item.appntIdNo.appntIdNo ? item.appntIdNo.appntIdNo : ''
            let insureitem = {
              birthday: item.appntBirthday && item.appntBirthday.appntBirthday ? item.appntBirthday.appntBirthday : '',
              sex: getSexFromIdCard(idNum) || ''
            }
            let res = checkPlanLimitList(null, insureitem, state.slectPlanId)
            if (!res.bool) {
              return res
            }
          }
        } else {
          for (let i = 0; i < state.insuredObj.insureds.length; i++) {
            let item = state.insuredObj.insureds[i]
            let insureitem = {
              birthday: item.birthday && item.birthday.birthday ? item.birthday.birthday : '',
              sex: item.sex && item.sex.sex ? item.sex.sex : ''
            }
            let res = checkPlanLimitList(null, insureitem, state.slectPlanId)
            if (!res.bool) {
              return res
            }
          }
        }
      }
      return {
        bool: true
      }
    }
    const showErrTipModal = (res) => {
      if (res && !res.bool) {
        if (!state.isIdentical) {
          state.isIdentical = true
          state.detailModal = res.msg
        }
        return false
      }
      return true
    }


    watch(() => router.currentRoute.value.query.type, (newValue) => {
      if (newValue === '1') {
        state.routerType = newValue
      }
    }, {immediate: true, deep: true})
    watch(() => router.currentRoute.value.query.code, (newValue) => {
      if(newValue !== null && newValue !== undefined){
        state.routerCode = newValue
      }
    }, {immediate: true, deep: true})
    // 告知问卷的请求
    const handelNodeHealth = (data) => {
      // 跳转之前先进行投被报人的是否填写的校验
      let titleName = informationVerification(state.insuredObj, data)
      if (typeof titleName === 'string') {
        state.detailModal = '请填写' + titleName
        state.isIdentical = true
        return
      }
      let relationToappnt = ''
      handelSaveQuestionnaire()
      let list = state.showData.filter(item => item.moduleType === 'insureds')
      let relList = list[0].productElementList[data.moduleIndex].filter(rel => rel.elementCode === 'relationToAppnt')
      relationToappnt = relList[0]['firstValue']
      // 数据缓存
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+ 'showDataTemporary',selectRisk:{showData: state.showData, premium: state.premium}}).then(res =>{
        if(res.code === 1){
          // 跳转新的页面
          router.push({
            path: '/app/relevanceHealthTold',
            query: {
              tradeNo: state.tradeNo,
              elementId: data.moduleId === 'insureds' ?state.elementId : data.elementId,
              relationToappnt,
              routerCode: state.routerCode,
              moduleType: data.moduleId,
              moduleIndex: data.moduleIndex,
            }
          })
        }
      })

    }
    // 告知问卷缓存数据
    const handelSaveQuestionnaire = () => {
      let selectRisk = JSON.parse(store.getters['insured/getRiskList'])
      selectRisk.riskList = detailData.riskFormData.riskList
      let multipleRiskList
      if (store.getters['insured/getMultipleRiskList']) {
        multipleRiskList = JSON.parse(store.getters['insured/getMultipleRiskList'])
      }
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo,selectRisk, multipleRiskList,})


    }

    const handelToAppnt = () => {
      let type = {
        status: 'N',
        index: null
      }

      let list = state.insuredObj.insureds
      let lengthNum = state.showData.filter(item => item.moduleType === 'insureds')[0].productElementList.length
      if (lengthNum === 1) return type
      for (let i = 0; i < list.length; i++) {
        let insuredsList = Object.keys(list[i])
        for (let j = 0; j < insuredsList.length; j++) {
          if (insuredsList[j] === 'relationToAppnt' && list[i][insuredsList[j]][insuredsList[j]] === '01') {
            type.status = 'Y'
            type.index = i
            return type
          }
        }
      }
      return type
    }
    provide('handelNodeHealth', handelNodeHealth)
    provide('handelToAppnt', handelToAppnt)

    // 可选责任点击
    const optionalDutyChange = (dutyItem,index, riskIndex) => {
      if(dutyItem.isSelected){
        const flag = insuredsFirstOne(dutyItem,state.insuredObj)
        if(flag && !flag.type){
          dutyItem.isSelected = false
          state.isIdentical = true
          state.detailModal = flag.message
          return
        }
      }
      let type = optionalDutyRepulsionKey(dutyItem, detailData.resetOptionalDutys.riskList[index]['dutyList'])
      if(!dutyItem.isSelected || type){
        detailData.riskFormData.riskList.forEach(item =>{
          item.calculateFactor.forEach((cal, index) =>{
            if(cal.factorId === dutyItem.id){
              item.calculateFactor.splice(index, 1)
            }
          })
          item.dutyList.forEach(duty =>{
            if(duty.id === dutyItem.id){
              duty.isSelected = false
            }
          })
        })
      }else{
        const riskList = detailData.riskFormData.riskList[riskIndex]
        let dutyArr = detailData.resetOptionalDutys.riskList[index]['dutyList']
        for (let j = dutyArr.length - 1; j >= 0; j--) {
          if (!type && dutyArr[j].isSelected && dutyArr[j].isRelatedPremiumCalculation === 'Y') {
            riskList['calculateFactor'].push({
              factorId: dutyArr[j].id,
              factorType: 'duty',
              factorValue: PremCalculator.initDutyPremAmount(dutyArr[j]),
              isShow: true
            })
          }
        }
        if(!type && dutyItem.isSelected){
          riskList['dutyList'].push({
            id: dutyItem['id'],
            riskId: dutyItem['riskId'],
            dutyCode: dutyItem['dutyCode'],
            comDutyCode: dutyItem['comDutyCode'],
            dutyName: dutyItem['dutyName'],
            dutyIntroduce: dutyItem['dutyIntroduce'],
            introduceFirstline: dutyItem['deductibleExcess'] + '',
            isShow: dutyItem['isShowStatus'] === 'Y',
            isOptional: dutyItem['isRisk'] === 'Y',
            isSelected: dutyItem.isSelected,
            countMode: dutyItem['countMode'],
            isRelatedPremiumCalculation: dutyItem['isRelatedPremiumCalculation'] === 'Y',
            premiumAmount: PremCalculator.initDutyPremAmount(dutyItem),
            repulsionKey: dutyItem['repulsionKey'] || '',
            disabled: dutyItem['factorTypeList'] && dutyItem['factorTypeList'].length > 0
          })
        }
      }
      // 去重
      for (let i = 0; i < detailData.riskFormData.riskList.length; i++) {
        let map = new Map()
        for (let cal of detailData.riskFormData.riskList[i].calculateFactor) {
          if (!map.has(cal['factorId'])) {
            map.set(cal['factorId'], cal)
          }
        }
        detailData.riskFormData.riskList[i].calculateFactor = [...map.values()]
      }
      // 国富小红花
      if(JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supProductCode === '1113124'){
        let dutyCodeObj = detailData.riskFormData.riskList.filter(risk => risk.riskId === dutyItem.riskId)[0]
        checkGFDutySelect(dutyCodeObj)
      }
      PremCalculator.optionalDutyChange(dutyItem, () => {
        handelAssignmentPremium()
        saveOptionalDuty(dutyItem)
      })
    }
    // 将选中的可选责任保存一下
    const saveOptionalDuty = (dutyItem) =>{
      const index = state.selectOptionalDutyList.findIndex(item => item.id === dutyItem.id)
      if(dutyItem.isSelected && index === -1){
        state.selectOptionalDutyList.push(dutyItem)
      }else if(!dutyItem.isSelected && index !== -1){
        state.selectOptionalDutyList.splice(index, 1)
      }
      store.dispatch('insured/saveOptionalDuty', state.selectOptionalDutyList)
    }
    // 这个是可选责任的校验
    const optionalDutyCheck = () =>{
      if(detailData.riskFormData && detailData.riskFormData['riskList'] && detailData.riskFormData['riskList'].length){
        for (let i = 0; i < detailData.riskFormData['riskList'].length; i++) {
          for (let j = 0; j < detailData.resetOptionalDutys.riskList[i]['dutyList'].length; j++) {
            const dutyValue = detailData.resetOptionalDutys.riskList[i]['dutyList'][j]
            if(detailData.optionalDutys.includes(dutyValue.id) && dutyValue.isShowStatus === 'Y' && dutyValue.isSelected){
              return insuredsFirstOne(dutyValue,state.insuredObj)
            }
          }
        }
      }

    }

    const optionalDutyRepulsionKey = (dutyItem, dutyList) => {
      // 责任互斥
      if (dutyItem.repulsionKey && dutyItem.repulsionKey.length > 0) {
        const repulsionKeyArr = dutyItem.repulsionKey.split(',|，') || []
        for (let j = 0; j < repulsionKeyArr.length; j++) {
          const key = repulsionKeyArr[j]
          const repulsionArr = []
          for (let i = 0; i < dutyList.length; i++) {
            if (dutyList[i].repulsionKey === key && dutyList[i].isSelected && dutyList[i].id !== dutyItem.id) {
              repulsionArr.push(dutyList[i])
            }
          }
          if (repulsionArr.length) {
            repulsionArr.push(dutyItem)
            Dialog.alert({
              message: repulsionArr.reduce((pre, cur) => pre.dutyName + ',\n' + cur.dutyName) + '\n只能选择一个。',
              theme: 'round-button',
              confirmButtonColor: '#0078fc'
            }).then(()=>{
              dutyItem.isSelected = false
            })
            return 'err'
          }
        }
      }
    }
    // 这个是查看是否展示可选责任
    const showOptional = ()=>{
      let type = false
      if(detailData.riskFormData.riskList.length && detailData.optionalDutys.length){
        for (const risk of detailData.riskFormData.riskList) {
          if(risk.dutyList.length){
            for (const ele of risk.dutyList) {
              if(detailData.optionalDutys.includes(ele.id) !== -1){
                type = true
              }
            }
          }
        }
        return type
      }
    }

    const addBenefit = () => {
      state.openBenefitType = 'add'
      // for(let key in state.insuredObj.addBeneficiarys[0]) {
      //   state.insuredObj.addBeneficiarys[0][key][key] = ''
      // }
      state.BeneFitVisible = true
    }
    const confirmAddBenefit = () => {
      const obj = state.insuredObj.addBeneficiarys[0]
      if (obj.updateLodeDetail?.cosUrls?.length) {
        obj.imageList = obj.updateLodeDetail.cosUrls
      }
      if (obj.imageList?.length > 0) {
        obj.imageList.forEach(item => {
          item.imageUrl = item.label
        })
      }
      if (obj.updateLodeDetail?.updateLodeDetail?.length) {
        obj.updateLodeDetail.updateLodeDetail.forEach(item => {
          item.imageUrl = item.label
        })
      }
      const benefitData = {
        bnfType: '1',
        name: obj.name.name,
        bnfAppSame: obj.bnfAppSame.bnfAppSame,
        relationToInsured: obj.relationToInsured.relationToInsured,
        bnfNo: obj.bnfNo.bnfNo,
        idType: obj.idType.idType,
        idNo: obj.idNo.idNo,
        idDateType: obj?.idTypeEndDate?.idTypeEndDate === '9999-01-01' ? '1' : '0',
        idTypeStartDate: obj.idTypeStartDate.idTypeStartDate,
        idTypeEndDate: obj.idTypeEndDate.idTypeEndDate,
        sex: obj.sex.sex,
        birthday: obj.birthday.birthday,
        nativePlace: obj.nativePlace.nativePlace,
        occupationCode: obj.occupationCode.occupationCode,
        areaCode: obj.areaCode.areaCode,
        bnfAddress: obj.bnfAddress.bnfAddress,
        bnfLot: obj.bnfLot.bnfLot,
        mobile: obj.mobile.mobile,
        zipCode: obj.zipCode.zipCode,
        marriage: obj.marriage.marriage,
        bnfClass: obj.bnfClass.bnfClass,
        imageList: obj.imageList?.imageList?.length > 0 ? obj.imageList?.imageList : obj.updateLodeDetail.updateLodeDetail,
        updateLodeDetail: obj.updateLodeDetail.updateLodeDetail
      }
      console.log(benefitData, 'benefitDatabenefitData')
      console.log(state.insuredObj.addBeneficiarys[0], 'state.insuredObj.addBeneficiarys')
      for(let key in state.insuredObj.addBeneficiarys[0]) {
        if(!benefitData[key] && key != 'imageList' && key != 'updateLodeDetail' && key !== 'bnfAppSame') {
          if (key === 'idTypeEndDate' && benefitData.idDateType === '1') {
            continue
          }
          state.detailModal = '请填写' + state.insuredObj.addBeneficiarys[0][key].elementName
            state.isIdentical = true
            return false
        } else if ((!benefitData[key] || benefitData[key].length === 0 ) && key === 'updateLodeDetail') {
          state.detailModal =  '请上传受益人影像资料'
            state.isIdentical = true
            return false
        }
        // const vToolPhone = vTools('phone',benefitData.mobile)
        // if (vToolZipCode !== true) {
        //   state.detailModal = vToolZipCode
        //   state.isIdentical = true
        //   return false
        // }
        let temList = [... state.designatedBenefitList]
        temList.push(benefitData)
        if (temList.length > 2) {
          state.detailModal = '最多可添加2位受益人'
          state.isIdentical = true
          return false
        }
        const po = temList.filter(item => {
          return (item.relationToInsured === '02')
        })
        if (po?.length > 1) {
          state.detailModal = '只能添加一位配偶！'
          state.isIdentical = true
          return
        }
        if (key === 'birthday') {
          if (benefitData.idType === '05') {
            if(calAge(benefitData.birthday) > 15){
              state.detailModal = '证件类型为户口簿时, 受益人年龄不能大于16周岁'
              state.isIdentical = true
              return false
            }
          } else if (benefitData.idType === '07') {
            if(calAge(benefitData.birthday) > 1){
              state.detailModal = '证件类型为出生证明时, 受益人年龄不能大于1周岁'
              state.isIdentical = true
              return false
            }
          }
        }
        if (key === 'bnfLot') {
          // const bnfLotObj = groupBy(temList, item => item.bnfNo)
          // console.log(bnfLotObj, 'bnfLotObj2')
          // for (let bnfKey in bnfLotObj) {
          //   if (bnfLotObj[bnfKey].length > 0) {
          //     let bnfLotSum = 0
          //     for (let i = 0; i < bnfLotObj[bnfKey].length; i++) {
          //       bnfLotSum += Number(bnfLotObj[bnfKey][i].bnfLot)
          //     }
          //     if (Number(bnfLotSum) > 100) {
          //     Dialog.alert({
          //       message: '同一受益顺序受益比例总和应等于100%',
          //     }).then(() => {
          //       // on close
          //     })
          //     return false
          //   }
          //   }
          // }
          let bnfLotSum = 0
          temList.forEach(item => {
            bnfLotSum += Number(item.bnfLot)
          })
          if (Number(bnfLotSum) > 100) {
            state.detailModal = '受益比例总和应等于100%'
            state.isIdentical = true
            return false
            }
        }
        if (key === 'idNo') {
          if (benefitData.idType === '01' || benefitData.idType === '05') {
            const vToolIdNo = vTools('pId',benefitData.idNo)
            if (vToolIdNo !== true) {
              state.detailModal = vToolIdNo
              state.isIdentical = true
              return false
            }
          }
          if (benefitData.idType === '07') {
            const vToolIdNo = vTools('birthCard',benefitData.idNo)
            if (vToolIdNo !== true) {
              state.detailModal = vToolIdNo
              state.isIdentical = true
              return false
            }
          }
        }
        if (key === 'idTypeStartDate' && benefitData.idTypeStartDate) {
          if (new Date(benefitData.birthday).getTime() > new Date(benefitData.idTypeStartDate).getTime() || new Date(benefitData.idTypeStartDate).getTime() > new Date().getTime()) {
            state.detailModal = '请填写正确的受益人证件有效起期'
            state.isIdentical = true
            return false
          }
        }
        if (key === 'idTypeEndDate' && benefitData.idTypeEndDate) {
          if (new Date().getTime() > new Date(benefitData.idTypeEndDate).getTime() || new Date(benefitData.birthday).getTime() > new Date(benefitData.idTypeEndDate).getTime()) {
            state.detailModal = '请填写正确的受益人证件有效止期'
            state.isIdentical = true
            return false
          }
        }
        if (key === 'bnfAddress' && benefitData.bnfAddress) {
          if (vTools('address', benefitData.bnfAddress) !== true) {
            state.detailModal = '请填写正确的受益人详细地址'
            state.isIdentical = true
            return
          }
        }
        if (key === 'mobile') {
          let phoneList = []
          let idList = []
          let personList = []
      if (state.insuredObj['applicant'][0]['mobile']['mobile']) {
        phoneList.push(state.insuredObj['applicant'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['applicant'][0]['appntIdNo']['appntIdNo'])
      }
      if (state.insuredObj['insureds'][0]['mobile']['mobile'] && state.insuredObj['insureds'][0]['relationToAppnt']['relationToAppnt'] !== '01' && state.insuredObj['insureds'][0]['birthday']['birthday'] && calAge(state.insuredObj['insureds'][0]['birthday']['birthday']) >= 18) {
        phoneList.push(state.insuredObj['insureds'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['insureds'][0]['idNo']['idNo'])
      }
      temList.forEach(bItem => {
        if (calAge(bItem.birthday) >= 18 && bItem.idNo ) {
          phoneList.push(bItem.mobile)
          idList.push(bItem.idNo)
        }
        if (bItem.name && bItem.idNo) {
          personList.push(bItem.name + bItem.idNo)
        }
      })
      if ([... new Set(personList)].length < personList.length) {
        state.detailModal = '不可添加相同受益人'
        state.isIdentical = true
        return false
      }
      if ([... new Set(phoneList)].length < [... new Set(idList)].length) {
        state.detailModal = '投保人、被保人、受益人手机号不可重复'
        state.isIdentical = true
        return false
      }
        }
      }
      if (benefitData.birthday && benefitData.marriage && calAge(benefitData.birthday) < 18 && benefitData.marriage !== '0') {
        state.detailModal = '受益人未满18周岁，婚姻状态仅能位未婚！'
        state.isIdentical = true
        return
      }
      if (benefitData.relationToInsured === '02' && benefitData.marriage !== '1'){
        state.detailModal = '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'
        state.isIdentical = true
        return
      }
      if (benefitData.relationToInsured === '02'){
        const sex = state.insuredObj['insureds']?.[0]?.['sex']?.['sex'] ?? ''
        if (sex === benefitData.sex) {
          state.detailModal = '与被保人关系为配偶时性别不能相同'
          state.isIdentical = true
          return
        }
      }
      const relationToAppnt = state.insuredObj['insureds']?.[0]?.['relationToAppnt']?.['relationToAppnt'] ?? ''
      if (relationToAppnt === '02') {
        if (benefitData.relationToInsured === '02' && benefitData.bnfAppSame !== '1') {
          state.detailModal = '投、被保人关系为配偶，且受益人不是投保人本人，受益人与被保人关系不能为配偶'
          state.isIdentical = true
          return
        }
      }
      const vToolZipCode = vTools('zipCode',benefitData.zipCode)
        if (vToolZipCode !== true) {
          state.detailModal = vToolZipCode
          state.isIdentical = true
          return false
        }
        const vToolPhone = vTools('phone',benefitData.mobile)
        if (vToolPhone !== true) {
          state.detailModal = vToolPhone
          state.isIdentical = true
          return false
        }
      state.designatedBenefitList.push(benefitData)
      closeBenefitPopup()
      state.BeneFitVisible = false
    }
    const confirmUpdateBenefit = () => {
      const obj = state.insuredObj.addBeneficiarys[0]
      console.log(obj, '2233311w')
      if (obj.updateLodeDetail?.cosUrls?.length) {
        obj.imageList = obj.updateLodeDetail.cosUrls
      }
      if (obj.imageList?.length > 0) {
        obj.imageList.forEach(item => {
          item.imageUrl = item.label
        })
      }
      if (obj.updateLodeDetail?.updateLodeDetail?.length) {
        obj.updateLodeDetail.updateLodeDetail.forEach(item => {
          item.imageUrl = item.label
        })
      }
      const benefitData = {
        bnfType: '1',
        name: obj.name.name,
        bnfAppSame: obj.bnfAppSame.bnfAppSame,
        relationToInsured: obj.relationToInsured.relationToInsured,
        bnfNo: obj.bnfNo.bnfNo,
        idType: obj.idType.idType,
        idNo: obj.idNo.idNo,
        idDateType: obj?.idTypeEndDate?.idTypeEndDate === '9999-01-01' ? '1' : '0',
        idTypeStartDate: obj.idTypeStartDate.idTypeStartDate,
        idTypeEndDate: obj.idTypeEndDate.idTypeEndDate,
        sex: obj.sex.sex,
        birthday: obj.birthday.birthday,
        nativePlace: obj.nativePlace.nativePlace,
        occupationCode: obj.occupationCode.occupationCode,
        areaCode: obj.areaCode.areaCode,
        bnfAddress: obj.bnfAddress.bnfAddress,
        bnfLot: obj.bnfLot.bnfLot,
        mobile: obj.mobile.mobile,
        bnfClass: obj.bnfClass.bnfClass,
        zipCode: obj.zipCode.zipCode,
        marriage: obj.marriage.marriage,
        imageList: obj.imageList?.imageList?.length > 0 ? obj.imageList?.imageList : obj.updateLodeDetail.updateLodeDetail,
        updateLodeDetail: obj.updateLodeDetail.updateLodeDetail
      }
      let temList = [... state.designatedBenefitList]
        temList[state.openBenefitIndex] = benefitData
        console.log(temList, temList.length, 'temListtemListtemList')
        if (temList.length > 2) {
          state.detailModal = '最多可添加2位受益人'
          state.isIdentical = true
          return false
        }
      for(let key in state.insuredObj.addBeneficiarys[0]) {
        // console.log(state.insuredObj.addBeneficiarys[0])
        if(!benefitData[key] && key != 'imageList' && key != 'updateLodeDetail' && key !== 'bnfAppSame') {
          if (key === 'idTypeEndDate' && benefitData.idDateType === '1') {
            continue
          }
          state.detailModal = '请填写' + state.insuredObj.addBeneficiarys[0][key].elementName
          state.isIdentical = true
          return false
        } else if ((!benefitData[key] || benefitData[key].length === 0 ) && key === 'updateLodeDetail') {
          state.detailModal = '请上传受益人影像资料'
          state.isIdentical = true
          return false
        }
        if (key === 'birthday') {
          if (benefitData.idType === '05') {
            if(calAge(benefitData.birthday) > 15){
              state.detailModal = '证件类型为户口簿时, 受益人年龄不能大于16周岁'
              state.isIdentical = true
              return false
            }
          } else if (benefitData.idType === '07') {
            if(calAge(benefitData.birthday) > 1){
              state.detailModal = '证件类型为出生证明时, 受益人年龄不能大于1周岁'
              state.isIdentical = true
              return false
            }
          }
        }
        if (key === 'bnfLot') {
          // const bnfLotObj = groupBy(temList, item => item.bnfNo)
          // console.log(bnfLotObj, 'bnfLotObj1')
          // for (let bnfKey in bnfLotObj) {
          //   if (bnfLotObj[bnfKey].length > 0) {
          //     let bnfLotSum = 0
          //     for (let i = 0; i < bnfLotObj[bnfKey].length; i++) {
          //       bnfLotSum += Number(bnfLotObj[bnfKey][i].bnfLot)
          //     }
          //     if (Number(bnfLotSum) > 100) {
          //       Dialog.alert({
          //         message: '同一受益顺序受益比例总和应等于100%',
          //       }).then(() => {
          //         // on close
          //       })
          //       return false
          //     }
          //   }
          let bnfLotSum = 0
          temList.forEach(item => {
            bnfLotSum += Number(item.bnfLot)
          })
          if (Number(bnfLotSum) > 100) {
            state.detailModal = '受益比例总和应等于100%'
            state.isIdentical = true
            return false
            }
        }
        if (key === 'idNo') {
          if (benefitData.idType === '01' || benefitData.idType === '05') {
            const vToolIdNo = vTools('pId',benefitData.idNo)
            if (vToolIdNo !== true) {
              state.detailModal = vToolIdNo
              state.isIdentical = true
              return false
            }
          }
          if (benefitData.idType === '07') {
            const vToolIdNo = vTools('birthCard',benefitData.idNo)
            if (vToolIdNo !== true) {
              state.detailModal = vToolIdNo
              state.isIdentical = true
              return false
            }
          }
        }
        if (key === 'idTypeStartDate' && benefitData.idTypeStartDate) {
          if (new Date(benefitData.birthday).getTime() > new Date(benefitData.idTypeStartDate).getTime() || new Date(benefitData.idTypeStartDate).getTime() > new Date().getTime()) {
            state.detailModal = '请填写正确的受益人证件有效起期'
            state.isIdentical = true
            return false
          }
        }
        if (key === 'idTypeEndDate' && benefitData.idTypeEndDate) {
          if (new Date().getTime() > new Date(benefitData.idTypeEndDate).getTime() || new Date(benefitData.birthday).getTime() > new Date(benefitData.idTypeEndDate).getTime()) {
            state.detailModal = '请填写正确的受益人证件有效止期'
            state.isIdentical = true
            return false
          }
        }
        if (key === 'bnfAddress' && benefitData.bnfAddress) {
          if (vTools('address', benefitData.bnfAddress) !== true) {
            state.detailModal = '请填写正确的受益人详细地址'
            state.isIdentical = true
            return
          }
        }
        if (key === 'mobile') {
          let phoneList = []
          let idList = []
          let personList = []
      if (state.insuredObj['applicant'][0]['mobile']['mobile']) {
        phoneList.push(state.insuredObj['applicant'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['applicant'][0]['appntIdNo']['appntIdNo'])
      }
      if (state.insuredObj['insureds'][0]['mobile']['mobile'] && state.insuredObj['insureds'][0]['relationToAppnt']['relationToAppnt'] !== '01' && state.insuredObj['insureds'][0]['birthday']['birthday'] && calAge(state.insuredObj['insureds'][0]['birthday']['birthday']) >= 18) {
        phoneList.push(state.insuredObj['insureds'][0]['mobile']['mobile'])
        idList.push(state.insuredObj['insureds'][0]['idNo']['idNo'])
      }
      temList.forEach(bItem => {
        if (calAge(bItem.birthday) >= 18 && bItem.idNo ) {
          phoneList.push(bItem.mobile)
          idList.push(bItem.idNo)
        }
        if (bItem.name && bItem.idNo) {
          personList.push(bItem.name + bItem.idNo)
        }
      })
      if ([... new Set(personList)].length < personList.length) {
        state.detailModal = '不可添加相同受益人'
        state.isIdentical = true
        return false
      }
      if ([... new Set(phoneList)].length < [... new Set(idList)].length) {
        state.detailModal = '投保人、被保人、受益人手机号不可重复'
        state.isIdentical = true
        return false
      }
        }
      }
      const po = temList.filter(item => {
        return (item.relationToInsured === '02')
      })
      if (po?.length > 1) {
        state.detailModal = '只能添加一位配偶！'
        state.isIdentical = true
        return
      }
      if (benefitData.birthday && benefitData.marriage && calAge(benefitData.birthday) < 18 && benefitData.marriage !== '0') {
        state.detailModal = '受益人未满18周岁，婚姻状态仅能位未婚！'
        state.isIdentical = true
        return
      }
      if (benefitData.relationToInsured === '02' && benefitData.marriage !== '1'){
        state.detailModal = '婚姻状况为未婚、离异、丧偶时，被保险人、受益人关系不能为配偶'
        state.isIdentical = true
        return
      }
      if (benefitData.relationToInsured === '02'){
        const sex = state.insuredObj['insureds']?.[0]?.['sex']?.['sex'] ?? ''
        if (sex === benefitData.sex) {
          state.detailModal = '与被保人关系为配偶时性别不能相同'
          state.isIdentical = true
          return
        }
      }
      const relationToAppnt = state.insuredObj['insureds']?.[0]?.['relationToAppnt']?.['relationToAppnt'] ?? ''
      if (relationToAppnt === '02') {
        if (benefitData.relationToInsured === '02' && benefitData.bnfAppSame !== '1') {
          state.detailModal = '投、被保人关系为配偶，且受益人不是投保人本人，受益人与被保人关系不能为配偶'
          state.isIdentical = true
          return
        }
      }
      const vToolZipCode = vTools('zipCode',benefitData.zipCode)
        if (vToolZipCode !== true) {
          state.detailModal = vToolZipCode
          state.isIdentical = true
          return false
        }
        const vToolPhone = vTools('phone',benefitData.mobile)
        if (vToolPhone !== true) {
          state.detailModal = vToolPhone
          state.isIdentical = true
          return false
        }
      state.designatedBenefitList[state.openBenefitIndex] = benefitData
      closeBenefitPopup()
    }
    const viewBenefitDetail = (obj, index) => {
      state.openBenefitIndex = index
      state.openBenefitType = 'view'
      if (obj.imageList?.length > 0) {
        obj.imageList.forEach(item => {
          item.label = item. imageUrl
        })
      }
      state.viewBenefit = true
      // nextTick(() => {
        state.showData.forEach(item => {
        if (item.moduleType === 'addBeneficiarys') {
          let relationToAppnt = state.insuredObj['insureds'][0]['relationToAppnt']['relationToAppnt']
          console.log(state.insuredObj['insureds'][0]['relationToAppnt']['relationToAppnt'], 'state.insuredObj5554444')
          item.productElementList[0].forEach(procItem => {
            procItem.firstValue = obj[procItem.elementCode]
            if (procItem.elementCode === 'bnfAppSame') {
              console.log(procItem.elementCode, 'procItem.elementCode')
              if (relationToAppnt === '01') {
                procItem.disabledType = true
              } else {
                procItem.disabledType = false
              }
            }
            if (procItem.elementCode === 'bnfClass') {
              if (obj[procItem.elementCode] && obj[procItem.elementCode] === 'D') {
                procItem.firstValue = '0'
              }
            }
            if (procItem.elementCode === 'occupationCode') {
              procItem.show_Level = obj['occupationLevel']
              procItem.show_tagName = obj['occupationCode_tagName'] ?? null
            }
            if (procItem.elementCode === 'areaCode') {
              procItem.show_tagName = obj['areaCode_tagName'] ?? null
            }
            if (procItem.elementCode === 'updateLodeDetail') {
              procItem.firstValue = obj['imageList']?.length > 0 ? obj['imageList'] : null
            }
          })
        }
      })
      // })
      console.log(state.showData, 'state.showData11122')
      for(let key in state.insuredObj.addBeneficiarys[0]) {
        state.insuredObj.addBeneficiarys[0][key][key] = state.designatedBenefitList[state.openBenefitIndex][key]
      }
      state.BeneFitVisible = true
      setTimeout(() => {
        state.viewBenefit = false
      }, 2500)
    }
    const benefitOrderTrans = (code) => {
      const options = [
        {
          value: '1',
          label: '第一顺位'
        },
        {
          value: '2',
          label: '第二顺位'
        },
        {
          value: '3',
          label: '第三顺位'
        },
      ]
      return options.find(item => {
        return item.value === code
      })?.label ?? ''
    }
    const deleteBenefit = () => {
      Dialog.confirm({
        title: '删除确认',
        message: '确认删除该受益人？',
        confirmButtonColor: '#0078fc'
      }).then(() => {
        state.designatedBenefitList.splice(state.openBenefitIndex, 1)
        closeBenefitPopup()
      }).catch(() => {
        // on cancel
      })
    }
    const closeBenefitPopup = () => {
        for(let key in state.insuredObj.addBeneficiarys[0]) {
          state.insuredObj.addBeneficiarys[0][key][key] = null
        }
        state.showData.forEach(item => {
          if (item.moduleType === 'addBeneficiarys') {
            item.productElementList[0].forEach(procItem => {
              procItem.firstValue = null
            })
          }
        })
        state.BeneFitVisible = false
    }
    onBeforeUpdate(() => {

    })
    watch(()=> state.isOcrSet, (val) => {
      if (val === true) {
        setTimeout(()=>{
          state.isOcrSet = false
        },500)
      }
    })
    const backMethod = () => {
      const processDetailsList = store.getters['insured/getProcessDetailsList']
      const currentPcrocessNode = store.getters['insured/getCurrentProcessNode']
      processDetailsList.forEach((item,index) => {
        if (item.id === currentPcrocessNode.id && index !== 0) {
          router.push({
            path: '/app' + showArr[processDetailsList[index - 1].code].value,
            query: {
              ...router.currentRoute.value.query,
              sort: router.currentRoute.value.query.sort - 1
            }
          })
        } else if (item.id === currentPcrocessNode.id && index === 0) {
          router.push({
            path: '/app/detail',
            query: {
              shareId: router.currentRoute.value.query.shareId
            }
          })
        }
      })
    }
    linkChildren({ insuredObj: state.insuredObj, BeneFitVisible: state.BeneFitVisible })
    return {
      backMethod,
      benefitRef,
      closeBenefitPopup,
      deleteBenefit,
      benefitConfig,
      confirmAddBenefit,
      viewBenefitDetail,
      confirmUpdateBenefit,
      benefitOrderTrans,
      addBenefit,
      showOptional,
      detailData,
      getSub,
      Initialization,
      optionalDutyChange,
      premCalculator,
      factorChange,
      getInitialValue,
      handelToAppnt,
      handelNodeHealth,
      handleOk,
      handleCancel,
      setUserDetail,
      setIdType,
      ...toRefs(state),
      nextPage,
      showObjFun,
      PreviewPopData,
      addElementFun,
      deleteElementFun,
      getPreviewData,
      countDown,
      insuranceErrorHandleOk,
      insuranceErrorHandleOff,
      setRelationToAppntType,
      getErrListInfo,
      handelUnderwriting,
      handelShowData,
      handelPayTypeData,
      handelSaveMultipleInsured,
      handelEditInsuredsExcelUrl,
      handelSaveQuestionnaire,
      getCacheParametersData,
      reconfigurationTargetList,
      getContInfo,
      oneselfValuation,
      getInsuredsPrem,
      getImageParameters,
      getShowDataTemporary
    }
  },
  async beforeRouteLeave (to, from) {
    console.log(to, from)
    const processDetailsList = store.getters['insured/getProcessDetailsList']
    const currentPcrocessNode = store.getters['insured/getCurrentProcessNode']
    console.log(processDetailsList,currentPcrocessNode, 'ppppppppp')
    let backPath = ''
    processDetailsList.forEach((item,index) => {
        if (item.id === currentPcrocessNode.id && index !== 0) {
          backPath = '/app' + showArr[processDetailsList[index - 1].code].value
        }
      })
    if (to.path !== backPath && this.routerFlag !== true) {
      this.backMethod()
      // console.log('....')
    }
  }
})
</script>
