<!--测试地址：http://api.test.hrhbbx.com/msconsumer/bxb/html/insure/identification.html?bizNo=3ee391d60f10439a925f202d0e1169ca&customCode=6184754690001&attributive=1&orderNo=6184754690001&holderInsuredSame=1&user=-->
<!--测试地址：http://developer.7ytech.com:8978/product/app/elecSignature?bizNo=3ee391d60f10439a925f202d0e1169ca&customCode=6184754690001&attributive=1&orderNo=6184754690001&holderInsuredSame=1&user=唐老鸭-->
<template>
  <div class="elecSign">
    <div class="nav-bar">
      <IOSbackBar :title="'签署确认'" :backMethod="backMethod"></IOSbackBar>
    </div>
    <div style="height: 3rem"></div>
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div v-if="!showPdfState" class="context-style">
      <div class="signBox1">
        <div class="noticeBox">
          <!--  阅读块-->
          <div class="agree-head">请您逐页仔细阅读以下文件并确认</div>
          <div class="agreen-list" v-for="(clauseItem, index) in clauseList" :key="'clauseList'+index">
            <a-row :gutter="10">
              <a-col :span="20" style="text-align: left">
                <label @click="showPdf(clauseItem)">《{{ clauseItem.attachName }}》</label>
              </a-col>
              <a-col :span="4">
                <a-tag :color="clauseItem.isRead?'grey':'blue'" @click="showPdf(clauseItem)">
                  {{ clauseItem.isRead ? '已读' : '未读' }}
                </a-tag>
              </a-col>
            </a-row>
          </div>
          <!--        :disabled="disabled"-->
          <a-checkbox class="check-box" v-model:checked="checked" @change="isHaveReadState">
<!--            本人已充分了解本保险产品，并承诺投保信息的真实性，已阅读以上全部内容-->
            本人已充分了解本保险产品，并承诺投保信息的真实性，已阅读以上全部内容。本人同意采用电子签名的方式签订上述投保资料并同意由本人亲自或授权瑞泰人寿保险有限公司向北京数字认证股份有限公司申请电子签名认证证书，自愿遵守其电子认证业务规则。本人保证提交的申请资料真实、准确、完整，愿意承担由于资料虚假失实而导致的一切后果。<strong style="color: red">签名即表示本人已同意上述所有集成了电子签名的单证内容</strong>
          </a-checkbox>

        </div>

        <div class="tempDiv">
        </div>
        <div class="insure">
          <div class="signBox">
            <div class="signInsureType">
              <span v-if="signData.attributive === '1' && signData.holderInsuredSame === 'N' ">投保人签名</span>
              <span v-else-if="signData.attributive === '2' && signData.holderInsuredSame === 'N' ">被保人签名</span>
              <span v-else-if="signData.attributive === '3'">受益人签名</span>
              <span v-else-if="signData.attributive === '4'">代理人签名</span>
              <span
                v-else-if="signData.attributive === '1' && signData.holderInsuredSame === 'Y' ">投保人/被保险人签字</span>

            </div>
            <div class="signatureBox">
              <div class="sigeName" v-if="signImage64">
                <img class="sigeNameImg signImage64" :src="signImage64">
                <p class="sigeNameText" v-if="!checkType && !cat" @click="showSigeName">重签</p>
              </div>
              <div class="default" @click="showSigeName" v-else>
                <img class="signatureImg" :src="signatureIcon">
                <p class="signatureText">点击签字</p>
              </div>
            </div>
            <a-row :gutter="10">
              <a-col :span="12" style="text-align: left">
                <label>签名日期</label>
              </a-col>
              <a-col :span="12" style="text-align: right">
                <label>{{ today }}</label>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="tempDiv2">
        </div>
        <a-button v-if="!cat" class="btn-ensure" :class="{'btn-already': signImage64}" @click="signSureEvent">确认签署
        </a-button>
      </div>
    </div>
    <clause-read v-if="showPdfState" class="readBox" ref="clauseRead" @readPdfResult="readPdfResult"></clause-read>
    <a-modal style="width: 80%;z-index: 1002" centered :closable="false" v-model:visible="isShowErr"
             @ok="insuranceErrorHandleOk">
      <p style="font-family: PingFangSC-Regular;
        font-size: 1.01rem;
        color: #333333;
        text-align: center;
        margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button v-if="isShowOk" type="link"
                  class="width100"
                  :class="{width45: signSureEventShow || isRouterShow}"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
        <a-button v-if="signSureEventShow || isRouterShow" type="link"
                  style="width: 45%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="()=>isShowErr=false">取消
        </a-button>

      </template>
    </a-modal>
  </div>

</template>

<script>
import {nextTick, onMounted, reactive, ref, toRefs, watch} from 'vue'
import ClauseRead from './clauseRead'
import {calculateCacheSize, getQueryJson, getUrlParams, getYYMMDD} from '../../../utils/utlis'
import {showSigeRisk} from './js/signature'
import signatureIcon from '../../../assets/img/sign/signatureIcon.png'
import {useRouter} from 'vue-router'
import store from '../../../store'
import {faceEventApi, getSignDateApi, getSignListApi, signEventApi} from './js/signature_api'
// import {startRecord} from '../../../utils/rrweb'
import {getRouterJumpBean} from '../../../utils/product_process'
import {message} from 'ant-design-vue'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
// import {request} from '../../../utils/request'

export default {
  name: 'elecSignature',
  components: {
    ClauseRead,
    IOSbackBar
  },
  setup() {
    const router = useRouter()
    document.title = '签署确认'
    const today = getYYMMDD()
    const clauseRead = ref()
    const state = reactive({
      signSureEventShow: false,
      loadingShow: false,
      isqz: false,
      isShowOk: true,
      isShare: router.currentRoute.value.query['userFlag'] || false,
      isShowErr: false,
      // 查看模式
      cat: router.currentRoute.value.query['cat'] || false,
      detailModal: '',
      disabled: true,
      isReadAll: false,
      checked: false,
      isRouterShow: false,
      // 条款列表
      clauseList: [],
      showPdfState: false,
      // 签名后的base64
      // signImage64: '',
      signImage64: sessionStorage.getItem('srcUrl' + router.currentRoute.value.query['customCode']) || '',
      encrySign: sessionStorage.getItem('encrySign' + router.currentRoute.value.query['customCode']) || {},
      // 检查是否允许重签
      checkType: false,
      // signComposeSource: 0, // 是否易高， type number：1-易高 0-系统，默认为系统合成
      signData: {
        tradeNo: router.currentRoute.value.query['tradeNo'],
        shareId: router.currentRoute.value.query['shareId'],
        attributive: router.currentRoute.value.query['attributive'],
        customCode: router.currentRoute.value.query['customCode'],
        holderInsuredSame: router.currentRoute.value.query['holderInsuredSame'],
        signProvider: router.currentRoute.value.query['signProvider'],
        userFlag: router.currentRoute.value.query['userFlag'],
        riskStatus: 0
      },
      goToConfirmPage: () => goToConfirmPage()
      // faceApi: {
      //   orderNo:router.currentRoute.value.query['orderNo'],
      //   customCode:router.currentRoute.value.query['customCode'],
      //   attributive:router.currentRoute.value.query['attributive'],
      //   holderInsuredSame:router.currentRoute.value.query['holderInsuredSame'],
      // }
    })
    /**
     * 查询列表
     */
    const queryList = async () => {
      // const demoJson = demoJson2()
      const demoJson = await getSignDateApi({
        data: state.signData
      }).then(res => {
        if (res.code === 1) {
          return res.result
        }
        return {}
      })
      const demoList = demoJson.attachList || []
      store.dispatch('insured/saveSignDate', demoJson)
      localStorage.setItem(state.signData.customCode, demoJson.customName)
      if (state.signData.userFlag && state.signData.userFlag === 'true') {
        localStorage.setItem('userFlag', state.signData.tradeNo)
      }
      state.clauseList = mergeArray(demoList)
    }
    // 均增加一个未读标识
    const mergeArray = (demoList) => {
      let temp = {
        isRead: sessionStorage.getItem('readDone' + state.signData.customCode) === 'readDone' || state.cat,
        type: 'pdf'
      }
      return demoList.map((item) => {
        return {
          ...item,
          ...temp
        }
      })
    }
    const showPdf = (item) => {
      state.showPdfState = true
      nextTick(() => {
        clauseRead.value.read(item,state.signData.shareId)
      })
    }
    /**
     * 阅读结果
     * @param item
     */
    const readPdfResult = (item) => {
      for (let i = 0; i < state.clauseList.length; i++) {
        if (item.uuid === state.clauseList[i].uuid) {
          state.clauseList[i].isRead = item.isRead
        }
      }
      state.showPdfState = false
    }

    /**
     * 签名确认事件
     */
    const signSureEvent = () => {
      watchList()
      if (!state.isReadAll) {
        showErrPop('请您先完成文件阅读')
        return
      }
      if (!state.checked){
        showErrPop('请您确认已阅读并勾选申明内容')
        return
      }
      if(!state.signImage64) {
        showErrPop('请您先完成签名')
        return
      }
      state.signSureEventShow = true
      state.isShowErr = true
      state.detailModal = '签署后订单信息将无法修改,确认提交吗?'
    }
    const goToConfirmPage = async () => {
      const bean = await getRouterJumpBean(state.signData.shareId, state.signData.tradeNo)
      console.log(router.currentRoute.value.query, 'router.currentRoute.value.query')
      if (bean) {
        router.push({
          path: bean.path,
          query: {
            tradeNo: bean.tradeNo,
            shareId: bean.shareId,
            sort: bean.sort,
            id: bean.id,
            signature: '1',
            holderInsuredSame: router.currentRoute.value.query.holderInsuredSame,
            userFlag: router.currentRoute.value.query?.signatureType == '1' ? true : false,
            signatureType: router.currentRoute.value.query?.signatureType == '1' ? '1' : '0',
            customCode: router.currentRoute.value.query.customCode,
            attributive: router.currentRoute.value.query.attributive,
            code: router.currentRoute.value.query?.code,
            leavel: router.currentRoute.value.query?.leavel,
            detailType: router.currentRoute.value.query?.detailType,
          }
        })
      }
    }
    /**
     * 签名事件
     */
    const signEvent = () => {
      // 跳转验证页面
    }
    const isHaveReadState = () => {
      watchList()
      if (!state.isReadAll){
        state.checked = false
        state.detailModal = '请您先完成文件阅读'
        state.isShowErr = true
      }
      // if (!state.checked) {
      //   // state.isShowErr = true
      //   // state.detailModal = '请您先完成文件阅读'
      // }
    }
    watch(() => state.signImage64, newVal => {
      if (newVal) {
        state.checked = true
      }
    })
    onMounted(() => {
      // startRecord(state.signData.tradeNo)
      getSignatureStatus()
      setTimeout(() => queryList(), 1000)
      sessionStorage.setItem('callBackSignUrl', window.location.origin + window.location.pathname)
      console.log(calculateCacheSize())
      watchList()
      console.log(state.isReadAll)
      if (sessionStorage.getItem('srcUrl' + router.currentRoute.value.query['customCode']) && state.isReadAll) {
        state.checked = true
      }
    })
    // onBeforeMount(()=> {
    //   if (state.isShare) {
    //     store.dispatch('insured/changeInsureJSInfo',{
    //       userFlag: true
    //     })
    //     // http://developer.7ytech.com:8978/product/app/verificationSign?tradeNo=13
    //     router.push({
    //       path:'/app/verificationSign',
    //       query: state.signData
    //     })
    //   }
    // })

    /**
     * 判断是否允许
     */
    const watchList = () => {
      let a = state.clauseList.filter(item => item.isRead === false)
      if (a.length <= 0) {
        // state.checked = true
        state.isReadAll = true
      } else {
        state.isReadAll = false
      }
    }
    /**
     * 关掉错误弹窗
     */
    const insuranceErrorHandleOk = () => {
      if (state.signSureEventShow) {
        state.signSureEventShow = false
        state.isShowErr = false
        state.loadingShow = true
        let param = {
          customCode: state.signData.customCode,
          customType: state.signData.attributive,
          img64: state.signImage64,
          tradeNo: state.signData.tradeNo,
          signJsonData: state.encrySign,
          holderInsuredSame: router.currentRoute.value.query.holderInsuredSame,
          attributive: router.currentRoute.value.query.attributive,
          signatureType: router.currentRoute.value.query['signatureType'] === '1' ? '1' : '0',
        }
        signEventApi({
          data: param
        }).then(res => {
          console.log(res)
          if (res.code === 1) {
            goToConfirmPage()
            state.isShowErr = true
            state.detailModal = '签署成功'
            state.isShowOk = false
          } else {
            showErrPop(res.message)
          }
          state.loadingShow = false

        })
      }else{
        state.isShowErr = false
        state.detailModal = ''
      }

    }

    const showErrPop = (str) => {
      state.isShowErr = true
      state.detailModal = str
    }
    /**
     * 是否去签名逻辑
     */
    const showSigeName = () => {
      watchList()
      state.isqz = false
      if (!state.isReadAll) {
        showErrPop('请您先完成文件阅读')
        return
      }
      if (!state.checked) {
        showErrPop('请您确认已阅读并勾选声明内容')
        return
      }
      if (state.signData.riskStatus == 1) {
        showErrPop('请抄录风险提示语')
        return
      }
      state.isqz = true
      const params = getUrlParams(window.location.href)
      if (router.currentRoute.value.query.signatureType === '1') {
        params['signatureType'] = '1'
        params['userFlag'] = 'true'
      }
      sessionStorage.setItem('readDone' + state.signData.customCode, 'readDone')
        faceEventApi({
          data: params
        }).then(res => {
          console.error('faceapi::=>', JSON.stringify(res))
          if (res.code === 1) {
            if (res.result.bizStatus === 'Y' && res.result.bizNo) {
              params['bizNo'] = res.result.bizNo
              router.push({
                path: '/app/identification',
                query: params
              })
            } else {
              if (res.result.respUrl) {
                if (router.currentRoute.value.query.signatureType === '1') {
                  res.result.respUrl = res.result.respUrl + '&userFlag=true&signatureType=1'
                }
                if (router.currentRoute.value.query.leavel) {
                  res.result.respUrl = res.result.respUrl + `&leavel=${getQueryJson('leavel')}&code=${getQueryJson('code')}`
                }
                window.location.href = res.result.respUrl
              } else {
                if (res.result.bizStatus === 'Y' && res.result.bizNo) {
                  params['bizNo'] = res.result.bizNo
                  router.push({
                    path: '/app/identification',
                    query: params
                  })
                } else {
                  message.warn('人脸识别接口异常，请联系管理员')
                }
              }
            }
          } else {
            // 获取校验异常
            showErrPop('获取校验身份信息接口异常，请联系管理员')
          }
        })

      // 查一次人脸识别
      // request('http://api.test.hrhbbx.com/msconsumer/api/face/getFaceApi',
      //   state.faceApi).then(res => {
      //   console.log('getFaceApi',JSON.stringify(res))
      // })
      // sessionStorage.setItem(state.signData.customCode, 'readDone')
      // router.push({
      //   path: '/app/identification',
      //   query: params
      // })
    }
    watch(() => state.clauseList, () => {
      watchList()
    })
    const getSignatureStatus = () => {
      let param = {
        data: {
          tradeNo: state.signData.tradeNo,
          shareId: state.signData.shareId
        }
      }
      getSignListApi(param).then(res => {
        if (res.code === 1) {
          const list = res.result.autographDetailList.filter(item => item.customCode === state.signData.customCode)
          if (list.length && list[0].signaturePicture) {
            state.signImage64 = sessionStorage.getItem('srcUrl' + router.currentRoute.value.query['customCode']) ?? list[0].signaturePicture
            if (state.signImage64) {
              state.checked = true
            }
          }
        }
      })
    }
    const backMethod = () => {
      if (state.showPdfState === true) {
        state.showPdfState = false
      } else {
        goToConfirmPage()
      }
    }
    window.addEventListener('popstate', backMethod, false)
    return {
      today,
      backMethod,
      ...toRefs(state),
      clauseRead,
      showPdf,
      readPdfResult,
      signEvent,
      signSureEvent,
      isHaveReadState,
      insuranceErrorHandleOk,
      signatureIcon,
      showSigeRisk,
      showSigeName,
      goToConfirmPage
    }
  }
}
</script>

<style scoped>
.product_wrap > .modal_class > :global(.ant-modal-content) {
  border-radius: 15px !important;
}
.elecSign::-webkit-scrollbar{width: 0 !important;}
.nav-bar{
  position: fixed;
  width: 100%;
  height: 3rem;
  z-index: 20;
}
.elecSign::-webkit-scrollbar {width: 0 !important;}

.context-style {
  position: relative;
  width: 100%;
  height: 100%;

}

.signBox1 {
  background: #9d9d9d;
  width: 100%;
  height: 100%;
  position: absolute;

}

.readBox {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 11;
}

.noticeBox {
  background-color: #ffffff;
  padding: 10px;

}

.agree-head {
  font-family: PingFangSC-Regular;
  text-align: left;
  font-size: 1.2rem;
  color: #333333;
  letter-spacing: 0;
  font-weight: 666;
  padding: 10px 0;
}

.agreen-list {
  margin-top: 5px;
}

.check-box {
  margin-top: 20px;
  text-align: left;
}
:deep(.ant-checkbox-wrapper) {
  text-align: left !important;
}

.insure {
  background: #ffffff;
  padding: 10px;
  margin-bottom: 3rem;
  min-height: 250px;
}

.signBox {
  background: #fff;
  padding: 0 1px;
}

/*.sigeName {*/
/*  width: 100%;*/
/*  height: 150px;*/
/*  text-align: center;*/
/*  background: #ffffff;*/
/*  border-radius: 10px;*/
/*  position: relative;*/
/*  border: 1px solid #aaa;*/
/*}*/

.weidu,
.yidu {
  display: inline-block;
  line-height: .35rem;
  padding: 0 .1rem
}

.weidu {
  background: #F0F5FF;
  font-size: .24rem;
  color: #0079FE;
}

.yidu {
  background: #EDEDED;
  font-size: .24rem;
  color: #666;
}

.signatureBox .default {
  width: 100%;
  height: 7.2rem;
  text-align: center;
  background: #F2F8FF;
  border-radius: 10px;
}

.signatureImg {
  width: 1.2rem;
  height: 1.2rem;
  margin: 1.6rem 0 0.15rem;
}

.signatureText {
  font-family: PingFangSC-Regular;
  font-size: 0.74rem;
  color: #0079FE;
  letter-spacing: 0;
  font-weight: 400;
}

.signatureBox .sigeName {
  width: 100%;
  height: 7.2rem;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  border: 1px solid #aaa;
}

.sigeNameImg {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .signImage64{
    height: 50%;
} */

.sigeNameText {
  font-family: PingFangSC-Regular;
  font-size: 0.74rem;
  color: #0079FE;
  letter-spacing: 0;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 16px;
}

.btn-ensure {
  height: 3rem;
  background: #93C3FC;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  line-height: 1.94rem;
  z-index: 9;
  text-align: center;
  font-size: 1.13rem;
}

.signInsureType {
  width: 100%;
  text-align: left;
}

.tempDiv {
  height: 10px;
  width: 100%;
  background: #DCDCDC;
}

.tempDiv2 {
  height: 100%;
  width: 100%;
  background: #DCDCDC;
}

.btn-already {
  background: #0079FE;
}

.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}

.width100 {
  width: 100%;
  font-size: 20px;
  text-align: center !important;
  color: #1677FF;
}

.width45 {
  width: 45%;
}
</style>
