<template>
<div style="width: 100%;height: 100%;background: white; z-index: 15">
  <div class="div_wrap">
<!--    <div :v-if="false" class="fixed-buttons">-->
<!--      <div>-->
<!--        <button class="btn-bg" @click="zoomIn">放大</button>-->
<!--      </div>-->
<!--      <div>-->
<!--        <button @click="zoomOut">缩小</button>-->
<!--      </div>-->
<!--      <div>-->
<!--        <button @click="pageRest">重置缩放</button>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div :v-if="false" id="page-view" :style="{-->
<!--      background: 'white',-->
<!--        position: 'absolute',-->
<!--        top: '50%',-->
<!--        left: '50%',-->
<!--        transform: `translate(-50%,-50%) scale(${scaleData})`,-->
<!--        width: '100%',-->
<!--        height: `${pageHeight}`,-->
<!--        'z-index': 999,-->
<!--    }">-->
<!--      <vuePdfEmbed-->
<!--        v-if="['pdf','xls', 'xlsx'].includes(item.type)"-->
<!--        :source="item.url"-->
<!--        @progress="showProgress"-->
<!--        @loaded="loaded"-->
<!--        @loading-failed="loadingFailed"-->
<!--        @rendering-failed="renderingFailed"-->
<!--        @rendered="rendered"-->
<!--      />-->
<!--    </div>-->
    <a-spin style="width: 100%;height: 100%;
            position: fixed;
            top: 50%;
            left: 0;" :spinning="pdfList.length< 1 && yuedufenshu === 1" :delay="50" tip="加载中..." size="large">
      <div v-for="(pdf, index) in pdfList" :key="index">
        <!--            <img alt="" style="width:100%" :src="pdf"/>-->
        <a-image
            alt="" style="width:100%" :src="pdf"
        />
      </div>
    </a-spin>
<!--    <div class="box1" v-if="loadingShow">-->
<!--      <a-spin size="large"/>-->
<!--    </div>-->
<!--    <img style="width:100%" v-if="['png','jpg', 'jpeg', 'gif', 'bmp'].includes(item.type)"-->
<!--         :src="item.pdfUrl"/>-->
    <readClick class="btn-ensure"  :btn-label="'我已阅读并知晓同意'" :seconds=5 @sureCLick="readPdfEvent"></readClick>
  </div>
<!--  <readClick :btn-label="'我已阅读并知晓同意'" :seconds="5" @click.prevent="readPdfEvent"></readClick>-->
</div>
</template>

<script>
import readClick from './componts/readClickBtn'
import {nextTick, reactive, ref, toRefs} from 'vue'
// import vuePdfEmbed from 'vue-pdf-embed'
// import store from "@/store/index.js";
import {request} from '@/utils/request.js'
import {getExtendUri} from '@/utils/request_extend.js'

export default {
  name: 'clauseRead',
  components: {
    readClick,
    // vuePdfEmbed
  },
  setup (props, context){
    const scaleData = ref(1.0)
    const pageHeight = ref('100%')
    const state = reactive({
      item: {},
      loadingShow: false,
      pdfList: [],
      yuedufenshu: 1
    })
    // 放大
    const zoomIn = () => {
      scaleData.value += 0.1
      pageHeight.value = (parseInt(pageHeight.value) - 5.0) + '%'
    }
    // 缩小
    const zoomOut = () => {
      scaleData.value -= 0.1
      pageHeight.value = (parseInt(pageHeight.value) + 5.0) + '%'
    }
// 页面 放大/缩小 还原默认值
    const pageRest = () => {
      scaleData.value = 1.0
      pageHeight.value = '100%'
    }
    const read = (val,shareId) => {
      state.item = JSON.parse(JSON.stringify(val))
      showPdf(state.item,shareId)
    }
    const showPdf = async (item, shareId) => {
     let res = await getProdcuctCode(shareId)
      if (res.code === 1 && res.result) {
        await getPdfList(item, res.result)
      }
    }
    const getProdcuctCode = async (shareId) => {
     return request(getExtendUri('getProductCodeByShareId'), {
       data: {
         shareId: shareId
       }
     })
    }
    /**
     * 阅读行为
     * @param item
     */
    const readPdfEvent = (flag) => {
      // state.pdfUrl = item.url
      if (flag) {
        state.item.isRead = true
        context.emit('readPdfResult', state.item)
      }

    }
    const showProgress = (progressParams) => {
      console.debug(JSON.stringify(progressParams))
    }
    const loaded = (document) => {
      console.debug(document)
      // this.$emit('loaded', this.document)
    }
    const loadingFailed = () => {
      state.loadingShow = false

    }
    const rendered = () => {
      // this.$emit('rendered')
      state.loadingShow = false

    }
    const renderingFailed = () => {
      state.loadingShow = false
      // this.$emit('rendering-failed', e)
    }

    const getPdfList = async (item, pcode) =>{
      let data = {
        pdfUrl: item.url,
        fileName: item.attachName,
        productCode: pcode
      }
      request(getExtendUri('getPdfDecompose'), data).then(res =>{
        if(res.code === 1){
          state.yuedufenshu = 0
          nextTick(()=>{
            state.pdfList = res.result
          })
        }
      })
    }

    return {
      ...toRefs(state),
      scaleData,
      pageHeight,
      readPdfEvent,
      read,
      showProgress,
      loaded,
      loadingFailed,
      rendered,
      renderingFailed,
      zoomIn,
      zoomOut,
      pageRest
    }
  }
}
</script>

<style scoped>
.div_wrap {
  width: 100%;
  height: 100%;
  background-color: #f6f8fb;
  overflow-x: hidden;
  text-align: left;
  overflow-y: auto;
}
.box1 {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.box1 div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
.btn-ensure {
  height: 3rem;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  line-height: 1.94rem;
  z-index: 1001;
  text-align: center;
  font-size: 1.13rem;
}
.vue-pdf-embed :deep(canvas){
  height: 100% !important;
  opacity: 0;
}
.vue-pdf-embed{
  height: 100% !important;

}
.vue-pdf-embed :deep(.textLayer){
  opacity: 1;
}
.vue-pdf-embed :deep(.textLayer span){
  color: #000;
  opacity: 1;
}
.vue-pdf-embed div:nth-child(1){
  height: 100%;
}
.fixed-buttons {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 10px;
  /* 背景色设置为透明 */
  background-color: transparent;
  border-radius: 5px;
  box-shadow: none; /* 移除阴影以保持透明效果 */
}

.fixed-buttons button {
  margin-left: 10px;
  /* 设置按钮背景为透明 */
  background-color: transparent;
  /* 设置边框颜色 */
  border: 1px solid #000000; /* 或者您想要的其他颜色 */
  /* 设置文字颜色 */
  color: #000000; /* 或者您想要的其他颜色 */
  /* 可选：设置边框圆角 */
  border-radius: 3px;
}
</style>
