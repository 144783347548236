<template>
  <div v-if="showRefresh">
    <!--    signMethod 签名方式 ： 1签名 2远程 3-->
<!--    {{orderSignedDetail}}-->
    <!-- <IOSbackBar :title="'签署确认'" ></IOSbackBar> -->
    <div class="signedWrap" style="display: block;"
         v-if="orderSignedDetail&&orderSignedDetail.signProvider == '1'&&orderSignedDetail.autographDetailList&&orderSignedDetail.autographDetailList.length>0">
      <div class="signSure">
        <span class="leftText_yes">签署确认</span>
        <div v-if="user" class="leftBTn"
             :style="{background:orderSignedDetail&&!orderSignedDetail.finished?'#B3D7FE':'#0079FE'}">
          {{ orderSignedDetail && !orderSignedDetail.finished ? '签名未齐' : '签名已齐' }}
        </div>
        <div v-if="user" class="clickCSS" @click="getSignDate(true, 'click')">
          <img :src="refreshIcon" alt="">
        </div>
      </div>
      <div v-for="(item,index) in orderSignedDetail.autographDetailList" :key="index" class="signSure"
           style="font-size: .28rem;color:#999">
        <span class="leftText" v-if="item.attributive === '1' && item.holderInsuredSame === 'N' ">投保人签署</span>
        <span class="leftText" v-else-if="item.attributive === '2' && item.holderInsuredSame === 'N' ">被保人签署</span>
        <span class="leftText" v-else-if="item.attributive === '3'">受益人签署</span>
        <span class="leftText" v-else-if="item.attributive === '4'">代理人签署</span>
        <span class="leftText" v-else-if="item.attributive === '1' && item.holderInsuredSame === 'Y' ">投保人/被保险人签署</span>

        <div
          v-if="!shareSignFlag&&!item.signed && item.attributive !== '1' && user && (orderSignedDetail.signMethod == 2 || orderSignedDetail.signMethod == 3)"
          class="leftBTn copyBtn" @click="shareLocal(item)" :style="{color:'#0079FE'}"
          :data-clipboard-text="item.remoteShareUrl">远程签名
        </div>
        <div v-if="item.signed && orderSignedDetail.signProvider === '1'" class="leftBTn" @click="toShowSignImg(item)"
             :style="{color:'#0079FE'}">查看签名
        </div>
        <div v-if="item.signed" class="clickCSS" :style="{color:'#999'}">已签名</div>
        <div v-if="!item.signed && (orderSignedDetail.signMethod == 1 || orderSignedDetail.signMethod == 3)"
             class="clickCSS" @click="toSign(item,orderSignedDetail.signProvider)" :style="{color:'#0079FE'}">点击签名
        </div>
      </div>
      <!--      0000000000-->
      <div class="signSure" style="font-size: .28rem;color:#999">
        <span class="leftText">投保日期</span>
        <div class="leftBTn leftBTnDate" style="color:#999">{{ orderSignedDetail.applyDate }}</div>
      </div>

    </div>

    <div class="signedWrap" style="display: block;"
         v-if="orderSignedDetail&&orderSignedDetail.signProvider == '2' &&orderSignedDetail.autographDetailList&&orderSignedDetail.autographDetailList.length>0">
      <div class="signed" v-for="(obj,i) in orderSignedDetail.autographDetailList" :key="i">
        <div>
          <span class="floatA">{{ obj.attributive ? attributive[obj.attributive] : '' }}签署</span>
          <div v-if="i === 0" class="clickCSS floatB" @click="getSignDate(true, 'click')">
            <img :src="refreshIcon" alt="">
          </div>
        </div>
        <div class="holder">
          <span>{{ obj.attributive ? attributive[obj.attributive] : '签名人' }}：{{ obj.appellation }}</span>
          <span class="colr">{{ obj.signed === true ? '已签名' : '未签名' }}</span>
        </div>
        <div class="holderphone">
          <span>{{ obj.attributive ? attributive[obj.attributive] : '签名人' }}手机号：{{ obj.customPhone }}</span>
          <div class="note" @click="sendFn(obj)" :class="obj.signed===true?'gray':''">{{ obj.buttonTextStr }}</div>
        </div>
      </div>
      <div class="tips">
        注:
        <br/>
        请投保人及被保人注意查看签名短信，并通过短信中的签名链接
        完成文件签署，需投/被保人均完成签名后方可承保。签名链接
        当日24时后失效,链接失效可重新获取签名短信。
      </div>
    </div>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isShowErr" @ok="insuranceErrorHandleOk">
      <p style="font-family: PingFangSC-Regular;
        font-size: 1.01rem;
        color: #333333;
        text-align: center;
        margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {onMounted, onUnmounted, reactive, toRefs} from 'vue'
import useClipboard from 'vue-clipboard3'
import store from '../../../../store'
import refreshIcon from '../../../../assets/img/sign/refresh.png'
import {useRouter} from 'vue-router'
import {createShareUrls} from '../js/signature'
import {getSignListApi, getTKSignatureLink} from '../js/signature_api'
import {getExtendUri} from '../../../../utils/request_extend'
import {request} from '../../../../utils/request'
import {message} from 'ant-design-vue'
import {getQueryJson} from '../../../../utils/utlis'
// import IOSbackBar from '@/components/extended/IOSbackBar.vue'
export default {
  name: 'signPartInConfirmation',
  components: {
    // IOSbackBar
  },
  props: {
    obj: {
      type: Object,
      default: () => {
      }
    },
    signPDic: {
      tyte: Array,
      default: () => []
    },
    shareSign: {
      type: Object,
      default: () => null
    },
    finishedType:{
      type: Boolean,
      default: () => false
    },
    checkedShow: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  setup(props, {emit}) {
    const router = useRouter()
    const {toClipboard} = useClipboard()//复制
    const getNewDicList = (arr) => {
      const obj = arr.reduce((acc, curr) => {
        acc[curr.value] = curr.label
        return acc
      }, {})
      return obj
    }
    const state = reactive({
      tradeNo: router.currentRoute.value.query.tradeNo,
      shareId: store.getters['insured/getShareId'],
      shareSignFlag: props.shareSign ? true : false,
      orderSignedDetail: createShareUrls(props.obj, router.currentRoute.value.query.tradeNo, store.getters['insured/getShareId'], props.shareSign),
      isShowErr: false,
      detailModal: '',
      showConfirm: true,
      attributive: getNewDicList(props.signPDic),
      showRefresh: true,
      isToPlay: null,
      isHaveGetProduct: false,
      // {
      //       1:'投保人',
      //       2:'被保人',
      //       3:'受益人',
      //       4:'代理人',
      //       10010:'投保人/被保人(或其监护人)'
      //   },
      user: true,
      finished: props.finishedType
    })
    const querySignDate = (f, type = '') => {
      if (f && state.orderSignedDetail) {
        if(state.orderSignedDetail.autographDetailList && state.orderSignedDetail.autographDetailList.length){
          const x = state.orderSignedDetail.autographDetailList.filter(item => item.countdown !== 0)
          if (x&&x.length>0) {
            state.isShowErr = true
            state.detailModal = '发送短信操作中,请稍后刷新'
            return
          }
        }
      }
      let param = {
        data: {
          tradeNo: router.currentRoute.value.query.tradeNo,
          shareId: store.getters['insured/getShareId']
        }
      }
      const signatureType = router.currentRoute.value.query?.signatureType
      const customCode = router.currentRoute.value.query?.customCode
      const attributive = router.currentRoute.value.query?.attributive
      const leavel = router.currentRoute.value.query?.leavel
      getSignListApi(param).then(res => {
        if (res.code === 1) {
          if(state.finished || type === 'click'){
            emit('getElectronicProposalFun', 'signFlag', state.finished)
          }
          if (!res.result) {
            state.signFlag = false
            return
          }
          state.signFlag = true
          let list = createShareUrls(res.result, router.currentRoute.value.query.tradeNo, store.getters['insured/getShareId'])
          if (signatureType && signatureType === '1' && leavel && leavel !== '4' && attributive !== '4'){
            list.autographDetailList = list.autographDetailList.filter(item =>item.customCode === customCode)
            if (list.autographDetailList?.length) {
              list.autographDetailList.forEach(item => {
                if (item.signed === true) {
                  emit('insuredSingStatusChange', true)
                }
              })
            }
          }
          state.orderSignedDetail = list
        }
      }).catch(err => {
        console.error(err)
      })
    }
    const getSignDate = (f, type) => {
      querySignDate(f, type)
    }
    /**
     *
     * @param 刷新状态
     */
    const refresh = () => {
      getSignDate
    }
    // 远程链接
    const shareLocal = async (item) => {
      item.remoteShareUrl = `${window.location.origin}${process.env.VUE_APP_BASE_PATH}/app/signatureVerification?id=${getQueryJson(undefined, 'id')}&sort=${getQueryJson(undefined,'sort')}&tradeNo=${getQueryJson(undefined,'tradeNo')}&shareId=${getQueryJson(undefined,'shareId')}&attributive=${item.attributive}&customCode=${item.customCode}&signProvider=${item.signProvider}&holderInsuredSame=${item.holderInsuredSame}&userFlag=true&signatureType=1`
      if (item) {
        try {
          await toClipboard(item.remoteShareUrl)
          state.isShowErr = true
          state.detailModal = '远程签名链接已复制到剪切板'
        } catch (err) {
          console.debug(err)
        }
      }
    }
    // 点击签名
    const toSign = (item, signProvider) => {
      const signatureType = router.currentRoute.value.query?.signatureType
      const attributive = router.currentRoute.value.query?.attributive
      let isShare = '0'
      if (signatureType === '1' && attributive !== '4') {
        isShare = '1'
      } else {
        isShare = '0'
      }
      if (!props.checkedShow && isShare !== '1') {
        state.isShowErr = true
        state.detailModal = '请确认是否同意产品相关条款'
        return false
      }
      if(router.currentRoute.value.query.signatureType === '1'){
        item['signatureType'] = router.currentRoute.value.query.signatureType
      }
      emit('chooseSign', {beanItem: item, provider: signProvider})
      let param = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        shareId: store.getters['insured/getShareId'],
        customCode: item.customCode,
        attributive: item.attributive,
        holderInsuredSame: item.holderInsuredSame,
        signProvider: state.orderSignedDetail.signProvider,
        userFlag: state.shareSignFlag,
        signatureType: router.currentRoute.value.query?.signatureType,
        leavel: router.currentRoute.value.query?.attributive,
        code: router.currentRoute.value.query?.customCode,
        detailType: router.currentRoute.value.query?.detailType
      }
      const flagList = store.getters['insured/getVerifySMSFlag'].filter(ele => ele.customCode === item.customCode)

      // 判断是否已校验身份
      if (flagList && flagList.length && !flagList[0].msgPassed) {
        emit('showSMS', param)
        return
      }
      // 判断是否已签名
      if (item.signed) return
      if (signProvider == 2) {
        // 保司的直接跳出去
        window.location.href = item.localUrl
      } else {
        if (item.attributive === '2') {
          emit('showReConfirmFun')
        } else {
          let param = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        shareId: store.getters['insured/getShareId'],
        customCode: item.customCode,
        attributive: item.attributive,
        holderInsuredSame: item.holderInsuredSame,
        signProvider: state.orderSignedDetail.signProvider,
        userFlag: state.shareSignFlag,
        signatureType: router.currentRoute.value.query?.signatureType,
        leavel: router.currentRoute.value.query?.attributive,
        code: router.currentRoute.value.query?.customCode,
        detailType: router.currentRoute.value.query?.detailType
      }
      router.push({
          path: '/app/elecSignature',
          query: param
        })
        }

      }
    }
    const reSign = (item) => {
      let param = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        shareId: store.getters['insured/getShareId'],
        customCode: item.customCode,
        attributive: item.attributive,
        holderInsuredSame: item.holderInsuredSame,
        signProvider: state.orderSignedDetail.signProvider,
        userFlag: state.shareSignFlag,
        signatureType: router.currentRoute.value.query?.signatureType,
        leavel: router.currentRoute.value.query?.attributive,
        code: router.currentRoute.value.query?.customCode,
        detailType: router.currentRoute.value.query?.detailType
      }
      router.push({
          path: '/app/elecSignature',
          query: param
        })
    }
    const insuranceErrorHandleOk = () => {
      state.isShowErr = false
      state.detailModal = ''
    }

    const toShowSignImg = (item) => {
      emit('changeClickSign')
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+'PDF', saveData: {clausePdfList: store.getters['insured/getClausePdfList']}})
      // 跳签名页
      let param = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        shareId: store.getters['insured/getShareId'],
        customCode: item.customCode,
        attributive: item.attributive,
        detailType: router.currentRoute.value.query?.detailType,
        holderInsuredSame: item.holderInsuredSame,
        userFlag: router.currentRoute.value.query?.signatureType == '1' ? true : false,
        signatureType: router.currentRoute.value.query?.signatureType == '1' ? '1' : '0',
        cat: true,
        code: router.currentRoute.value?.query?.customCode,
        leavel: router.currentRoute.value?.query?.attributive
      }
      router.push({
        path: '/app/elecSignature',
        query: param
      })
    }

    const getDicList = async () => {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['signUserType']}}).then(
        res => {
          console.log('字典数据', res.result.signUserType)
          state.attributive = getNewDicList(res.result.signUserType)
        })
    }
    const sendFn = async (item) => {
      if (item.signed===true) {
        return
      }
      if (item.countdown > 0) {
        return
      }
      await getTKSignatureLink({data:{
          tradeNo: state.tradeNo,
          customerType: item.attributive === '1' ? 'applicant': 'insured',
        }
      }).then(res => {
        if (res.code === 1) {
          message.success('验证码发送成功')
          countDownTimer(item)
        } else {
          state.isShowErr = true
          state.detailModal = `验证码发送失败：${res.message}`
        }
      })
    }
    // 倒计时函数
    const countDownTimer = (item) => {
      item.countdown = 60
      item.buttonTextStr =  `获取短信${item.countdown}s`
      const timerId = setInterval(() => {
        item.countdown--
        item.buttonTextStr =  `获取短信${item.countdown}s`
        if (item.countdown === 0) {
          item.buttonTextStr =  '获取短信'
          clearInterval(timerId)
        }
      }, 1000)
    }
    const getCacheParametersData = (list) =>{
      if(router.currentRoute.value.query.signatureType && router.currentRoute.value.query.signatureType === '1') return
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo + 'autographDetailList'
      }).then( res => {
        if(res.code === 1 && res.result){
          list.forEach((item, index) =>{
            if(item.signed !== res.result.selectRisk.autographDetailList[index].signed){
              request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+ 'autographDetailList',selectRisk:{autographDetailList: list}}).then(res => {
                if(res.code === 1){location.reload()}
              })
            }
          })
        }
      })
    }
    const getSignatureStatus = () =>{
      let param = {
        data: {
          tradeNo: state.tradeNo,
          shareId: state.shareId|| store.getters['insured/getShareId']
        }
      }
      getSignListApi(param).then(async res => {
        if (res.code === 1){
          const list = res.result.autographDetailList.filter(item => item.signed === '1')
          let flagList = store.getters['insured/getVerifySMSFlag']
          // 更新状态
          if(list.length && flagList.length){
            list.forEach(item => {
              const index = flagList.findIndex(flag => flag.customCode === item.customCode)
              if(index >= 0) {
                flagList[index].signature = true
                flagList[index].msgPassed = true
              }
            })
            store.dispatch('insured/changeInsureJSInfo', {
              verifySMSFlag: flagList
            })
          }
          await getCacheParametersData(res.result.autographDetailList)
          if(res.result.finished && !state.finished){
            getSignDate(true)
            state.finished = res.result.finished
            clearInterval(state.isToPlay)
            state.isToPlay = null
          }
          if(list.length && !state.finished && state.orderSignedDetai){
            await state.orderSignedDetail.autographDetailList.forEach(item =>{
              const type = list.filter(item => item.customCode === item.customCode)[0].signed === '1'
              if(item.signed !== type){
                getSignDate(true)
                throw ''
              }
            })
          }else{
            getSignDate(true)
          }

        }
      })
    }
    const getOperatorSwitchData = () =>{
      request(getExtendUri('getOperatorSwitch'), {baseInfo: {tradeNo: state.tradeNo}}).then(res => {
        if(res.code === 1 && res.result.hasSign === 'Y'){
          if(state.isToPlay){
            clearInterval(state.isToPlay)
            return
          }
          state.isToPlay = setInterval(()=>{
            getSignatureStatus()
            if(state.finished || router.currentRoute.value.name !== 'confirmation'){
              clearInterval(state.isToPlay)
              state.isToPlay = null
            }
          }, 2000)
        }
      })
    }
    onMounted(async () => {
      if (!props.signPDic) {
        await getDicList()
      }
      if (!props.obj) {
        await getSignDate()
      }
      if (state.orderSignedDetail?.autographDetailList?.length > 0) {
        const signatureType = router.currentRoute.value.query?.signatureType
        const customCode = router.currentRoute.value.query?.customCode
        const attributive = router.currentRoute.value.query?.attributive
        const leavel = router.currentRoute.value.query?.leavel
        if (signatureType && signatureType === '1' && leavel && leavel !== '4' && attributive !== '4'){
          state.orderSignedDetail.autographDetailList = state.orderSignedDetail.autographDetailList.filter(item =>item.customCode === customCode)
        }
      }

      if (!props.finishedType){
        if(state.isToPlay) clearInterval(state.isToPlay)
        getOperatorSwitchData()
      }
    })
    onUnmounted(() => {
      if(state.isToPlay) clearInterval(state.isToPlay)
    })

    return {
      reSign,
      getOperatorSwitchData,
      getSignatureStatus,
      ...toRefs(state),
      refreshIcon,
      getSignDate,
      refresh,
      shareLocal,
      toSign,
      insuranceErrorHandleOk,
      toShowSignImg,
      getNewDicList,
      sendFn,
    }
  }
}
</script>

<style scoped>
.signedWrap {
  background-color: #fff;
  margin-top: 2rem;
  padding: 1.2rem 1.3rem;
}

.leftText {
  width: 8.5rem;
  font-size: .9rem;
  line-height: 1.3rem;
}

.signSure {
  border-top: 1px solid #DEDEDE;
  font-size: 1.12rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25rem 0;
  line-height: 2.5rem;
}

.leftBTn {
  color: #fff;
  text-align: center;
  width: 5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 1rem;
  font-size: 0.9rem;
}

.leftBTnDate {
  width: 10rem;
  text-align: right;
}

.clickCSS {
  width: 4rem;
  text-align: center;
  font-size: .9rem;
}

.signSure img {
  width: 1.3rem;
  height: 1.2rem;
}

.signed {
  margin-top: .7rem;
  background: #fff;
}

.signed {
  /*padding: .7rem 0 0 .8rem;*/
  display: flex;
  flex-direction: column;
}

.signed {
  width: 100%;
  font-size: 1rem;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
  padding-bottom: .7rem;
}
.holder, .holderphone {
  border-bottom: 1px solid #DEDEDE;
  padding: 0.7rem 0.8rem 0.7rem 0 !important;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  color: #666;
}

.colr {
  color: #999;
}

.tips {
  font-size: .7rem;
  color: #999;
  letter-spacing: 0;
  font-weight: 400;
  padding: .8rem;
}

.note {
  border: 1px solid #0079FE;
  border-radius: .3rem;
  color: #0079FE;
  padding: .08rem .5rem;
}

.gray {
  border: 1px solid gray;
  color: gray;
}
.floatA{
  float: left;
}
.floatB{
  float: right;
}
.leftText_yes{
  width: 8.5rem;
  color: #222;
  font-size: 1rem;
}
</style>
