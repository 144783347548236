<template>
  <div>
<!--  被保人信息确认页 --- 刘洋说待定（2023-04-10）-->
    <div>
<!--      顶部标题-->
    </div>
    <div>
<!--      内容区域-->
    </div>
    <div>
<!--      底部标题-->
    </div>
    <div>
<!--      底部按钮-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsuredInfoConfirmation'
}
</script>

<style scoped>

</style>
