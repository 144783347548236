<template>
<!--附加险-->
  <div>

  </div>
</template>

<script>
export default {
  name: 'AdditionalRisk'
}
</script>

<style scoped>

</style>
