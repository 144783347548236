<style>
body {
  background-color: #f6f8fb;
}
</style>
<style scoped>
.w45{
  width: 45% !important;
}
.product_wrap > .modal_class > :global(.ant-modal-content) {
  border-radius: 15px !important;
}

#tab-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 49px;
}

.tab-bar1 {
  float: left;
  background-color: #afb1be;
  width: 50%;
  height: 100%;
}

.tab-bar2 {
  float: left;
  width: 50%;
  height: 100%;
}

.confirmBox {
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  background: #fff;
}

.confirmTitle {
  width: 100%;
  line-height: 45px;
  /*height: 40px;*/
  color: #333333;
  height: auto;
  font-size: 17px;
  border-bottom: 1px solid #DEDEDE;
}

:deep(.ant-modal-body) {
  border-radius: 10px !important;
  color: #00a0e9;
}

.confirmList {
  width: 100%;
}

.confirmList ul {
  list-style: none;
  padding: 0px 15px 15px;
}

.confirmList ul li {
  line-height: 25px;
}

.confirmList ul span {
  display: inline-block;
  margin-right: 3px;
  /* color: #7e7e7e; */
}

.checkbox-yes {
  margin: 15px;
  position: relative;
}

.check-btn {
  position: absolute;
}

.check-content {
  display: block;
  margin-left: 24px;
}

.confirmP {
  /* color: #5f5f5f; */
  margin: 0;
  margin-bottom: 7px;
}

.confirmHeight {
  height: 100px;
}


.confirmBottom {
  width: 100%;
  height: 50px;
  text-align: center;
  position: fixed;
  border-radius: 5px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  font-family: '微软雅黑';
}

.confirmBottom p {
  background-color: #ffffff;
  color: #FF7D15;
  height: 50px;
  line-height: 50px;
  width: 50%;
  font-size: 20px;
}

.confirmBottom div {
  background-color: rgb(0, 121, 254);
  color: #fff;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  width: 50%;
  border-radius: 0
}

.confirmBox .fright {
  flex: 3;
  margin-right: 5px !important;
  text-align: right;
}
.bottom_bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
}
.prem_total {
  color: #ff8400;
  margin-left: 10px;
  height: 100%;
  font-weight: 500;
}
.insure_btn {
  font-size: 1.2rem;
  height: 100%;
  width: 40%;
  font-weight: 500;
  border-radius: 0;
}

.boxApp {
  padding-bottom: 100px;
  background-color: #f6f8fb;
}

.listyle {
  height: 50px;
  line-height: 50px !important;
  border-bottom: 1px solid #e2e2e2
}

.disfex {
  display: flex;
}

.fex9 {
  flex: 9 !important;
}

.fex5 {
  flex: 5 !important;
}

.fex8 {
  flex: 8 !important;
}

.color_blue {
  font-size: .8rem;
  line-height: 1.6rem;
  /* display: inline-block; */
  color: rgb(0, 121, 254) !important;
}

.font_14 {
  display: inline-block;
  font-size: 14px;
}

.spanBlue {
  border: 1px solid cornflowerblue;
  border-radius: 10px;
  height: 25px;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 8px;
  background: cornflowerblue;
  color: #fff !important;
}

.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}

.confirmDiv {
  margin-top: 10px;
  font-size: 15px;
  font-family: "微软雅黑";
  color: #666666;
}
.select_checkbox{
  height: auto;
  font-size: .98rem;
}
.select_checkbox .van-checkbox{
  background-color: #ffffff;
  padding: 0.5rem 1rem;

}
.select_title{
  height: auto;
  padding: 0.4rem 0.7rem 0;
  font-size: .98rem;
  margin: 10px;
}

.doubleRecord{
  position: fixed;
  top: 0;
  height: 0;
  left: 0;
  right: 0;
  width:100%;
  height:100vh;
  background: rgba(0,0,0,.6);
  z-index:10;
  /* height:5.6rem; */
}
.dRBox{
  max-width: 100%;
  background: #fff;
  border-radius: .8rem .8rem 0 0;
  position: absolute;
  bottom: 0;
  padding:1rem 1rem 0;
  line-height: 1rem;
  box-sizing: border-box;
}
 .dRBoxHT{height:5.6rem;}
 .tip{font-size: 1rem;color:#EF2218;padding:.8rem 0;}
.doubleRecord-p{font-size: .8rem;color:#666;padding-bottom:.8rem;padding-right:.8rem}
.doubleRecord .dRCoxbtn{margin-top:.5rem;text-align:center;font-size: .9rem;color:#0079FE;border-top: 1px solid #DEDEDE;height: 2.5rem;line-height:2.5rem;width: 100vw;transform: translateX(-0.8rem);}
.noPaddingTop{
  padding-top: 0;
}
.doubleRecord_tip{
  font-size: 1rem;
  padding:.8rem 0;
  color:#EF2218;
}

.img_detail_class{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background: #fff;
  height: 3rem;
  line-height: 3rem;
  padding: 0 15px 15px;
}
.img_detail_class label{
  font-size: 17px;
}
.risk-info {
  background: #fff;
  padding: 15px;
  font-size: 15px;
}
.show-face{
  padding: 0 15px 25px;
  text-align: center;
  color: #000;
  font-size: 18px;
  line-height: 35px;
  background: #fff;

}
.show-face .title{
  border-bottom: 1px solid #eee;
  padding: 28px 0 18px;
  text-align: left;
  font-size: 17px;
  margin-bottom: 25px;

}
.show-face .but{
  height: 35px;
  color: #1A90FE;
}
.face-file{
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #1A90FE;

}
.face-file img{
  width: 32px;
  height: 32px;
}
.footer-messge {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <IOSbackBar :title="'信息确认页'" :backMethod="backMethod"></IOSbackBar>
  <!--  设置H5页面布局-->
  <div class="boxApp">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div class="risk-info" style="margin-top: 10px;">
      <div class="confirmTitle" style="margin-bottom: 12px;">险种信息</div>
      <template v-for="item in selectRisk.riskList" :key="item.riskId">
      <p class="confirmP">
      <span>产品名称：</span>
      <span>{{ item.riskName }}</span>
      </p>
      <p class="confirmP">
      <span>保费（元）：</span>
      <span>{{ premium }}</span>
      </p>
      <p class="confirmP">
      <span>保额（元）：</span>
      <span>{{ findValue(item.calculateFactor, 'Amnt') ? parseFloat(Number(findValue(item.calculateFactor, 'Amnt'))).toFixed(2) : '' }}</span>
      </p>
      <p class="confirmP">
      <span>保险期间：</span>
      <span>{{ findValue(item.calculateFactor, 'Period') }}</span>
      </p>
      <p class="confirmP">
      <span>缴费方式：</span>
      <span>{{ findValue(item.calculateFactor, 'PayType') }}</span>
      </p>
      <p class="confirmP">
      <span>缴费期间：</span>
      <span>{{ findValue(item.calculateFactor, 'FeeYear') }}</span>
      </p>
      </template>
    </div>
    <!--    开始一个循环-->
    <div v-for="(item,index) in showData" :key="index">
      <!--      信息展示-->
      <div class="confirmList" v-if="item.moduleType !== 'insuranceInfo'">
        <ul v-for="(child,index2) in item.productElementList" :key="index2" class="confirmBox">
          <!--      顶部标题-->
          <div class="confirmTitle">
            <span>{{ item.productElementList.length > 1 ? item.moduleName + (index2 + 1) : item.moduleName }}</span>
          </div>
          <div class="confirmDiv">
            <li v-for="(childA,index3) in child" :key="index3">
              <p class="confirmP"
                 v-if="childA.elementCode !=='bnfType' && childA.elementCode!=='bankCardDetail' && childA.elementCode!=='updateLodeDetail' && childA.elementCode!=='newBankCardUrl' && childA.elementCode!=='uploadIDcard' && childA.isShow">
                <span>{{ childA.elementTitle }}:</span>
                {{ childA.show_tagName ? childA.show_tagName : (childA.elementCode === 'salary' ? (Number(childA.firstValue)/10000) : childA.firstValue) }}
                <span v-if="childA.elementCode==='premium'">元</span>
              </p>
              <p class="confirmP"
                 v-if="childA.elementCode ==='bnfType' && isInclude &&childA.elementCode!=='bankCardDetail' && childA.elementCode!=='updateLodeDetail'&& childA.elementCode!=='newBankCardUrl' && childA.elementCode!=='uploadIDcard'">
                <span>{{ childA.elementTitle }}:</span>
                {{ beneficiaryList && beneficiaryList.lenght &&  beneficiaryList[0].label ? beneficiaryList[0].label: childA.show_tagName }}
              </p>
              <div v-if="childA.elementCode =='bnfType' && isInclude && beneficiaryList?.length > 0 && beneficiaryList[0].value === '1'">
                  <template v-for="benefit in benefitDataList" :key="benefit.name">
                    <p class="confirmP">姓名：{{ benefit.name }}</p>
                    <p class="confirmP">受益顺序：{{benefitOrderTrans(benefit.bnfNo)}}</p>
                    <p class="confirmP">受益比例：{{ benefit.bnfLot }}%</p>
                    <!-- <p class="confirmP">与投保人关系：{{ benefit.relaToAppnt_tagName }}</p> -->
                    <p class="confirmP">与被保人关系：{{ benefit.relationToInsured_tagName }}</p>
                    <p class="confirmP">证件类型：{{ benefit.idType_tagName }}</p>
                    <p class="confirmP">证件号码：{{ benefit.idNo }}</p>
                    <!-- <p class="confirmP">证件是否长期有效：{{ benefit.idDateType === '0' ? '非长期' : '长期' }}</p> -->
                    <p class="confirmP">证件有效起期：{{ benefit.idTypeStartDate }}</p>
                    <p class="confirmP">证件有效止期：{{ benefit.idDateType === '1' ? '9999-01-01' : benefit.idTypeEndDate }}</p>
                    <p class="confirmP">出生日期：{{ benefit.birthday }}</p>
                    <p class="confirmP">性别：{{ benefit.sex === '1' ? '男' : '女' }}</p>
                    <p class="confirmP">国籍：{{ benefit.nativePlace_tagName }}</p>
                    <p class="confirmP">婚姻状态：{{ benefit.marriage_tagName }}</p>
                    <p class="confirmP">职业：{{ benefit.occupationCode_tagName }}</p>
                    <p class="confirmP">省市：{{ benefit.areaCode_tagName }}</p>
                    <p class="confirmP">详细地址：{{ benefit.bnfAddress }}</p>
                    <p class="confirmP">邮编：{{ benefit.zipCode }}</p>
                    <p class="confirmP">手机号：{{ benefit.mobile }}</p>
                  </template>
              </div>
              <div v-if="!isInclude && childA.elementCode ==='bnfType'">
                <span>本合同的各项保险金的受益人均为被保险人本人</span>
              </div>
            </li>
          </div>

        </ul>
      </div>

    </div>
    <div class="confirmDiv confirmBox" v-if="guaranteeList && guaranteeList.length > 0" style="padding: 0px 15px 15px;">
      <div class="confirmTitle">
        <span>保障详情</span>
      </div>
      <p class="confirmDiv confirmP" v-for="(tee, i) in guaranteeList" :key="i">
        <span>{{tee.elementTitle}}:  </span><span class="confirmSpan"> {{ tee.firstValue }}</span>
      </p>
    </div>
      <!--    可选责任  -->
    <div class="select_checkbox" v-if="optionalDutys.length > 0 && optionalDutysShow">
      <h3 class="select_title">可选责任</h3>
      <div style="background-color: #ffffff;">
        <template v-for="dutyItem in optionalDutysList" :key="'optionalDuty_' + dutyItem.id" >
          <van-checkbox disabled  v-if="optionalDutys.includes(dutyItem.id) && dutyItem.isShowStatus === 'Y' && dutyItem.isSelected" v-model="dutyItem.isSelected" :name="dutyItem.id">{{ dutyItem.dutyName }}</van-checkbox>
        </template>
      </div>
    </div>
    <!--    影像附件展示-->
    <div v-if="isShowUpload">
      <div class="img_detail_class">
        <label>影像附件</label>
        <img-attachments
          :imgDetailList="imgDetailList"
          position="confirm"
          :confirmationObj="confirmationObj"
          :isConfirmation="isConfirmation"
        ></img-attachments>
      </div>
    </div>

    <div v-show="isShare !== '1'" class="checkbox-yes">
      <a-checkbox class="check-btn" v-model:checked="checkedShow"></a-checkbox>
      <label class="check-content" @click="showRead">
        本次投保已经过被保险人同意并确认保险金额，已阅读
        <span v-for="(item,index) in readDataList" :key="index" class="color_blue">《{{ item.fileDataTypeName }}》</span>
        <span v-if="clauseShow.hasSign==='A'" class="color_blue">《现金价值表》</span>
        的全部内容
      </label>
    </div>
    <provision ref="provisionRef"  @readAll="readAll" @showRead="showRead"></provision>
    <!--    签名数据展示 ALLIANZ JINGDONG GENERAL INSURANCE COMPANY LTD. 暂不展示-->
    <div class="confirmList" v-if="signFlag">
      <sign-part-in-confirmation v-if="signFlag" :checkedShow="checkedShow" ref="sginRef" :finishedType="finishedType" :sign-p-dic="signPList" :share-sign="shareSignObj"
                                 :obj="orderSignedDetail" @chooseSign="chooseSign" @showSMS="showSMS" @changeClickSign="changeClickSign" @insuredSingStatusChange="insuredSingStatusChange"
                                 @getElectronicProposalFun="getElectronicProposalFun" @showReConfirmFun="showReConfirmFun"></sign-part-in-confirmation>
    </div>
<!--    人脸识别的展示 isShowFace    v-if="isShowFace && hasFace !== 'Y'"-->
    <div v-if="isShowFace" class="show-face">
      <div class="title">人脸识别</div>
      <div v-if="isShowFace && hasFace !== 'Y'"  class="face-file">
        <span class="but"  @click="onToIdentification">去识别</span>
        <img  alt=""  @click="onToIdentification" :src="faceShow"/>
      </div>
      <div v-if="hasFace === 'Y'" class="face-file">
        <span class="but">已识别</span>
        <img alt="" :src="faceShow"/>
      </div>
    </div>
    <!-- <div class="confirmBottom">
      <p>{{ premium }}<span style="font-size: 1.3rem;color: #FF7D15;">元</span></p>
      <div @click="lijitoubao" style="width: 50%">{{ sureBtnTxt }}</div>
    </div> -->
    <div>
      <div class="bottom_bar">
      <!-- <label class="prem_total">{{ premium }}元</label> -->
      <label class="prem_total">
          <span>{{ premium }}元</span>
        </label>
      <a-button v-show="!isHiddenBtn" type="primary" class="insure_btn" size="large" @click="lijitoubao">{{ sureBtnTxt }}</a-button>
      <a-button v-show="isHiddenBtn && insuredSingStatus" type="primary" class="insure_btn" size="large" @click="remoteColse">完成</a-button>
    </div>
      <form :action="payObj.payUrl" method="get" ref="payform" style="display:none"   accept-charset="GBK">
        <label>请求数据:</label><textarea name="requestDoc" ref="requestDoc" placeholder="请求数据"  ></textarea><br/>
      </form>
      <form :action="payObj.payUrl" method="post" style="display:none" ref="payZDform"  accept-charset="GBK"  v-if="JSON.stringify(paymentData.detailData) !== '{}' && JSON.stringify(paymentData.detailData.value) !== '{}'">
        <div  :key="index" v-for="(item,index) in Object.keys(paymentData.detailData.value)">
          <input :name="item" :value="paymentData.detailData.value[item]" />
        </div>
      </form>
      <form :action="payObj.payUrl" method="post" style="display:none" ref="payGFform" accept-charset="UTF-8" v-if="JSON.stringify(paymentData.detailGfData) !== '{}' && JSON.stringify(paymentData.detailGfData.value) !== '{}'">
        <div  :key="index" v-for="(item,index) in Object.keys(paymentData.detailGfData.value)">
          <input :name="item" :value="paymentData.detailGfData.value[item]" />
        </div>
      </form>
    </div>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isError" @ok="()=>isError=false">
      <p style="font-family: PingFangSC-Regular;font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">
        {{ errMessage }}
      </p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="()=>isError=false">确认
        </a-button>
      </template>
    </a-modal>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isError1" @ok="()=>isError=false">
      <p style="font-family: PingFangSC-Regular;font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">
        {{ errMessage1 }}
      </p>
      <template #footer>
      <div class="footer-messge">
        <a-button type="link"
                  style="width: 40%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="backInput">确认
        </a-button>
        <a-button type="link"
                  style="width: 40%;font-size: 20px;text-align: center !important; color: #646566;"
                  @click="()=>isError1=false">取消
        </a-button>
      </div>
      </template>
    </a-modal>
    <!-- error -->
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical" @ok="insuranceErrorHandleOk">
      <p style="font-family: PingFangSC-Regular;font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">
        {{ detailModal }}
      </p>
      <p v-if="detailModalTwo" style="font-family: PingFangSC-Regular;font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">
        {{detailModalTwo}}
      </p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  :class="{w45:  uwStatusType}"
                  @click="insuranceErrorHandleOk">{{okButton}}
        </a-button>
        <a-button
          v-if="uwStatusType"
          type="link"
          style="width:45%;font-size: 20px;text-align: center !important; color: #1677FF;"
          @click="insuranceErrorHandleOff">取消
        </a-button>
      </template>
    </a-modal>
    <a-modal style="width: 90%;" centered :closable="false" v-model:visible="isSMS">
      <verification-code ref="codeRef" :attributive="attributive" :customCode="customCode" v-if="isSMS" :sign-provider="selectSigned.provider" :trade-no="tradeNo" :name="vName" :phone="vPhone" @handleCode="handleCode" ></verification-code>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="verificationEvent">确认
        </a-button>
      </template>
    </a-modal>
<!--    支付中转-->
    <a-modal style="width: 90%;" centered :footer="false" :closable="false" :visible="paymentData.supplierPayment === 'N'? true : false">
      <div>
        <h3 style="font-weight: 650">产品名称: {{paymentData.productName}}</h3>
        <p>下单时间: {{paymentData.paymentTime}}</p>
        <p>支付保费: {{premium}}</p>
      </div>
      <div>
        <h3 style="font-weight: 650">选择支付方式: </h3>
        <div v-for="(item, index) in paymentData.paymentMethod" :key="index">
          <a-row>
            <a-col span="18">
              <h4>{{item.PayName}}支付</h4>
            </a-col>
            <a-col span="4">
              <a-button size="small" type="primary" @click="paymentClick(item)">立即支付</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
    <!-- 双录提示 -->
    <div class="doubleRecord" v-if="doubleRecordS">
      <div :class="dRBoxT ?'dRBox dRBoxHT':' dRBox'">
        <div class="tip">根据监管要求，本单需要录音录像</div>
        <p>订单号：{{drsData.proData && drsData.proData.orderNo ? drsData.proData.orderNo : tradeNo }}</p>
        <p>投保人：{{drsData.holderData.customName}}</p>
        <p v-for="(item,index) in drsData.insuredInfoList"
            :key="index">
          <span>被保人：<span>{{item.customName}}</span></span>
        </p>
        <p>主险产品：{{drsData.proData.productName}}</p>
        <div v-if="drsData.drPrompt">
          <p class="doubleRecord-p doubleRecord_tip">本单需要进行双录，请您前往双录APP完成录制</p>
          <p  class="doubleRecord-p" style="color:#EF2218;"><span>当前状态：</span>{{drsData.drPrompt.drMessage}}</p>
          <p class="doubleRecord-p" style="color:#EF2218;"><span>双录系统：</span>{{drsData.drPrompt.drSystemName}}</p>
          <p class="doubleRecord-p" style="color:#EF2218;"><span>双录账号：</span>{{drsData.agentList[0].mobile}}</p>
          <p class="doubleRecord-p" style="color:#EF2218;"><span>双录方式（现场/远程）：</span>{{drsData.drPrompt.drMode == 3 ? '两者都支持' : (drsData.drPrompt.drMode == 1 ? '现场': '远程')}}</p>
        </div>
        <div class="dRCoxbtn" @click="toJumpUrl">确定</div>
      </div>
    </div>
    <reConfirm @updateShowHealth="updateShowHealth"  :showReConfirm="showReConfirm" @closeConfrim="closeConfrim" :confirmInfo="{selectRisk: selectRisk, premium: premium}" @goSign="goSign"/>
    <healthConfirm :showHealthConfirm="showHealthConfirm" @updateShowHealth="updateShowHealth" @goSign="goSign"/>
  </div>

</template>

<script>
// import {routerJump} from '../../utils/product_process'
import provision from './public/provision'
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue'
import {request} from '../../utils/request'
import {getExtendUri, tradeNoStateIshavePay} from '../../utils/request_extend'
import useClipboard from 'vue-clipboard3'
import {message} from 'ant-design-vue'
// import {startRecord} from '../../utils/rrweb'
import store from '../../store'
import router from '../../router'
import SignPartInConfirmation from './elSign/componts/signPartInConfirmation'
import VerificationCode from './public/verificationCode'
import reConfirm from './public/reConfirm.vue'
import healthConfirm from './public/healthConfirm.vue'
import {gePInfo, getCacheDate, getInformationEntry, getRouterJumpBean, nowPathShow, showArr} from '../../utils/product_process'
import {getFaceRecognition, getPhoneDateApi, getSignListApi, sendSMSApi} from './elSign/js/signature_api'
import {Checkbox,Dialog} from 'vant'
import 'vant/lib/index.css'
import {formatNumberToFix2, getQueryJson} from '@/utils/utlis'
import imgAttachments from '../../pages/InsuranceConfiguration/element/views/ImgAttachments.vue'
import {newPremiumInTK} from '@/utils/special_treatment'
import {benefitConfig} from './addBenefit.js'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
import faceYes from '@/assets/img/face-yes.png'
import faceShow from '@/assets/img/face-show.png'

export default defineComponent({
  components: {
    IOSbackBar,
    SignPartInConfirmation,
    // ShowPayForm,
    provision,
    VerificationCode,
    [Checkbox.name]: Checkbox,
    imgAttachments,
    reConfirm,
    healthConfirm
  },
  setup() {
    const state = reactive({
      insuredSingStatus: false,
      showHealthConfirm: false,
      // 人核标识
      uwStatus: '',
      uwStatusType: false,
      remoteSigned: false,
      isError1: false,
      errMessage1: '',
      clickSign: false,
      hasFace: router.currentRoute.value.query?.hasFace,
      isShowFace: false,
      hasUserSigned: false,
      isShare: '0',
      isHiddenBtn: false,
      showReConfirm: false,
      selectRisk: {},
      isInclude: false,
      benefitDataList: [],
      insuranceReminder: '',
      relationship: '',
      premiumList: [],
      isShowUpload: false,
      finishedType: false,
      isShow: false,
      okButton: '确认',
      optionalDutysShow: false,
      beneficiaryList: [],
      guaranteeList: [],// 保障详情
      timeHandle: undefined,
      isHaveGetProduct: 0,
      isMounted: false,
      isToPlay: {},
      isHaveNodeDetail: false,
      productId: '',
      isUnderwriting: '',
      readDataList: [],
      premium: 0,
      productPlanName: '',
      productProName: '',
      loadingShow: true,
      name: '',
      isError: false,
      errMessage: '',
      // 跳链状态
      status: '2',
      productInfo: {},
      statusList: [],
      riskList: [],
      callbackData: {},
      showData: [],
      smsObj: {},
      signatureList: [],
      // 签名对象
      orderSignedDetail: {},
      // 选择的对象
      selectSigned: {},
      signatureAll: '-1',
      clauseShow: {
        hasSign: 'N'
      },
      riskListShow: [],
      BeneficiaryType: null,

      // 是否确认了同意条款
      checkedShow: false,
      readAllStatus: false,
      payObj: {
        payStateResult: '',
        payUrl: '',
        payKey: '',
        payJson: {},
        payState: false,
        supId: '',
        processType: '',
      },
      tradeNo: '',
      submitDate: {},
      detailModal: '',
      detailModalTwo: '',
      isIdentical: false,
      signFlag: false,
      isSMS: false,
      vName: '',
      vPhone: '',
      vCode: '',
      vCodestatus: '',
      visible: false,
      shareId: '',
      sureBtnTxt: '立即投保',
      userFlag: (router.currentRoute.value.query.userFlag) || store.getters['insured/userSignFlag'] || false,
      shareSignObj: null,
      signPList: [],
      optionalDutys: [],
      optionalDutysList: [],
      // 双录标识
      doubleRecordS: false,
      dRBoxT: false,
      // 双录对象
      drsData: {},
      premAddSign: 1,
      customCode: '',
      attributive: '',
      imgDetailList: [
        {type: 'applicant', list: []},
        {type: 'insureds', list: []},
        {type: 'beneficiarys', list: []},
        {type: 'payment', list: []},
        {type: 'health', list: []},
      ],
      confirmationObj:{
        relationshipList: [],
        reminderList: []
      },
      isConfirmation:'',
      orderSignedDetailCopy:{},
      isPay: false,
      detailRiskData: {}
    })
    const paymentData = reactive({
      productName: '',
      paymentTime: `${new Date().getFullYear() +'-'+ (new Date().getMonth() + 1) +'-'+new Date().getDate()}`,
      supplierPayment: 'Y',
      paymentNum: state.premium,
      paymentMethod: '',
      detailData: {},
      detailGfData: {}
    })
    const provisionRef = ref()
    const sginRef = ref()
    // 复制
    const {toClipboard} = useClipboard()
    const jumpObj = computed(() => (JSON.parse(store.getters['insured/getRouterJump'])))
    const getSignPeopleDicList = () => {
      // getDictionaryData(state.signPList,'signUserType')
      request(getExtendUri('dictionary_query_byType'),{data: {types: ['signUserType']}}).then(
        res => {
          state.signPList = res.result.signUserType
        })
    }
    // 获取签名人类型
    getSignPeopleDicList()
    onBeforeMount(async () => {
      state.tradeNo = router.currentRoute.value.query.tradeNo
      document.title = '订单确认'
      const a = await gePInfo()
      const b =await getCacheDate(state.tradeNo)
      if (a&&b) {
        // startRecord(state.tradeNo)
        getPlanData()
        premCalculator()
        handelReadDataList()
        await provisionRef.value.riskListData()
        state.readAllStatus = store.getters['insured/getClausePdfAllReadState']
        state.checkedShow = store.getters['insured/getClausePdfAllReadState']
        // 核保后去获取
        // state.timeHandle = setTimeout(()=> saveDateToCheck(), 500)
        const leavel = router.currentRoute.value.query?.leavel
        const signatureType = router.currentRoute.value.query?.signatureType
        const attributive = router.currentRoute.value.query?.attributive
        if (signatureType === '1' && leavel !== '4' && attributive !== '4') {
          state.sureBtnTxt = '联系业务员'
        }
        getOperatorSwitchFun()
      } else {
        state.detailModal = '获取数据异常'
        state.isError = true
      }
      if(!state.isShow){
        nowPathShow(null, null, state.tradeNo)
      }
    })
    const isHiddenNextBtn = () => {
      console.debug('隐藏按钮1')
      const agentInfo = JSON.parse(store.getters['insured/getProductDetailData'])?.agentInfo
      const leavel = router.currentRoute.value.query?.leavel
      const signatureType = router.currentRoute.value.query?.signatureType
      const attributive = router.currentRoute.value.query?.attributive
      if (signatureType === '1' && leavel !== '4' && attributive !== '4' && !(agentInfo && agentInfo.agentName && agentInfo.agentName!=='')) {
        state.isShare = '1'
        state.isHiddenBtn = true
      } else {
        state.isShare = '0'
        state.isHiddenBtn = false
      }
      // 由于三方跳转的原因，增加一个缓存去标记，该标记在录入页录入页会清空
      // if (localStorage.getItem('isShareFlag')==='1') {
      //   state.isHiddenBtn = true
      // } else {
      //   if (signatureType === '1' && leavel !== '4' && attributive !== '4' && !(agentInfo && agentInfo.agentName && agentInfo.agentName!=='')){
      //     console.debug('隐藏按钮3')
      //     state.isHiddenBtn = true
      //     // localStorage.setItem('isShareFlag', '1')
      //   } else {
      //     // localStorage.setItem('isShareFlag', '0')
      //   }
      // }
    }
    const getUwStatusPerson = () =>{
      state.uwStatus = ''
      state.uwStatusType = false
      request(getExtendUri('getUwStatus'), {tradeNo: state.tradeNo}).then(res =>{
        if(res.code === 1){
          state.uwStatus = res.result
        }
      })
    }
    onMounted( () => {
      const userId = router.currentRoute.value.query.userId ?? ''
        if (userId) {
          store.commit('insured/setUserId', userId)
        }
      isHiddenNextBtn()
      // 获取是否转人核的标志
      getUwStatusPerson()
    })
    if (router.currentRoute.value.query.tradeNo) {
      state.tradeNo = router.currentRoute.value.query.tradeNo
      store.dispatch('insured/changeInsureJSInfo', {'tradeNo': state.tradeNo})
      store.dispatch('insured/changeInsureJSInfo', {'uuid': state.tradeNo})
    } else {
      state.tradeNo = store.getters['insured/getTradeNo']
    }
    if (router.currentRoute.value.query.shareId) {
      state.shareId = router.currentRoute.value.query.shareId
      store.dispatch('insured/changeInsureJSInfo', {'shareId': state.shareId})
    } else {
      state.shareId = store.getters['insured/getShareId']
    }


    watch(() => state.checkedShow, () => {
      if (!state.readAllStatus && state.checkedShow) {
        state.checkedShow = false
        store.dispatch('insured/changeInsureReadState', '未读')
        const url = new URL(window.location)
        url.searchParams.set('openDialog', 'ok')
        window.history.pushState({}, '', url)
      }
    })
    onBeforeUpdate(() => {
      const url = new URL(window.location)
      if (url.searchParams.get('openDialog') === 'ok') {
        provisionRef.value.reading(state.clauseShow.hasSign === 'Y')
      } else if (url.searchParams.get('openDialog') === 'okSub') {
        provisionRef.value.reading(state.clauseShow.hasSign === 'Y', 'sub')
      } else {
        provisionRef.value.readCancel()
      }
      document.title = '订单确认'
    })
    const readAll = function () {
      state.readAllStatus = true
      // state.checkedShow = true
      document.title = '订单确认'
      window.history.go(-2)
    }
    const showRead = function () {
      const url = new URL(window.location)
      url.searchParams.set('openDialog', 'ok')

      if (store.getters['insured/getClausePdfList']&&store.getters['insured/getClausePdfList'].length > 0) {
        const list = store.getters['insured/getClausePdfList']
        let yidu = list.filter(item => item.status === '已读')
        let weidu = list.filter(item => item.status === '未读')

        if(yidu && yidu.length && weidu && weidu.length){
          // 有已读 也有未读
          window.history.pushState({}, '', url)
        }else if(yidu && yidu.length && weidu && !weidu.length){
          // 都是已读
          const url = new URL(window.location)
          url.searchParams.set('openDialog', 'okSub')
          window.history.pushState({}, '', url)
          window.history.pushState({}, '', url)
        }else if(yidu && !yidu.length && weidu && weidu.length){
          // 都是未读
          store.dispatch('insured/changeInsureReadState', '未读')
          window.history.pushState({}, '', url)
        }
      }

      provisionRef.value.reading(state.clauseShow.hasSign === 'Y')
    }

    const Initialization = function (jumpObj) {
      console.log(jumpObj, 'jumpObjjumpObjjumpObjjumpObjjumpObj')
      if(state.isShow) return
      //获取回显数据
      request(getExtendUri('selectByInsuredId'), {data: {tradeNo: state.tradeNo}}).then(res => {
        if (res.code == '1') {
          state.callbackData = res.result.contInfo
        } else {
          state.callbackData = store.getters['insured/getContInfoDetail'].contInfo
        }
        state.callbackData.applicant.idTypeEndDate = state.callbackData.applicant?.idDateType === '1' ? '9999-01-01' : state.callbackData.applicant.idTypeEndDate
        state.callbackData.insureds.forEach(item => {
          if (item.idDateType === '1') {
            item.idTypeEndDate = '9999-01-01'
          }
        })
        state.benefitDataList = state.callbackData.beneficiarys
        state.beneficiaryList.forEach(item => {
          item.idTypeEndDate = item.idDateType === '1' ? '9999-01-01' : ''
        })
        let id = router.currentRoute.value.query.id
        if (!id) {
          let temp = new URLSearchParams(location.search)
          id = temp.get('id')
        }

        state.loadingShow = false
        let showDataJson = JSON.parse(jumpObj.value.pageJson)
        let show = true
        if(res.result.contInfo.contract.excelUrl){
          show = false
        }
        console.log(showDataJson, 'showDataJson')
        let selectRisk = JSON.parse(store.getters['insured/getRiskList'])
        state.selectRisk = selectRisk
        console.log(selectRisk, 'selectRiskselectRisk')
        state.isInclude = false
        if (selectRisk.riskList.length > 0) {
          selectRisk.riskList.forEach(item => {
            item.calculateFactor.forEach(ite => {
              if (ite.factorType === 'ElementDuty' && ite.factorValue === '2') {
                state.isInclude = true
              }
            })
          })
        }
        showDataJson.forEach(item => {
              if ((item.moduleType === 'insureds' || item.moduleType === 'beneficiarys') && show) {
                let num = state.callbackData[item.moduleType].length
                for (let i = 0; i < num; i++) {
                  item.productElementList?.[i]?.forEach(res => {
                    res.firstValue = state.callbackData[item.moduleType][i][res.elementCode]
                    let tagName = res.elementCode + '_tagName'
                    res.isShow = true
                    res.show_tagName = state.callbackData[item.moduleType][i][tagName] ? state.callbackData[item.moduleType][i][tagName] : null
                    // if(res.elementCode === 'idTypeEndDate'){
                    //   res.isShow = state.callbackData[item.moduleType][i]['idDateType'] != '1'
                    // }
                    if(res.elementCode === 'staffRelativesRelation'){
                      if(state.callbackData[item.moduleType][i]['staffFlag'] === '0' || state.callbackData[item.moduleType][i]['staffFlag_tagName'] === '0'){
                        res.isShow = false
                      }
                    }
                    if (!state.isInclude && res.elementCode === 'bnfType') {
                      res.isShow = false
                    }
                  })
                }
                if(num === 1){
                  const premiumIndex = item.productElementList[0].filter(ele => ele.elementCode === 'premium')
                  if(premiumIndex.length){
                    premiumIndex[0].isShow = false
                  }
                }
              } else if(item.moduleType !=='insuranceInfo'){
                item.productElementList[0].forEach(res => {
                  res.firstValue = state.callbackData[item.moduleType][res.elementCode]
                  let tagName = res.elementCode + '_tagName'
                  res.isShow = true
                  res.show_tagName = state.callbackData[item.moduleType][tagName] ? state.callbackData[item.moduleType][tagName] : null
                  // if(res.elementCode === 'idTypeEndDate'){
                  //   res.isShow = state.callbackData[item.moduleType]['idDateType'] != '1'
                  // }
                  if(res.elementCode === 'staffRelativesRelation'){
                    if(state.callbackData[item.moduleType]['staffFlag'] === '0' || state.callbackData[item.moduleType]['staffFlag_tagName'] === '0'){
                      res.isShow = false
                    }
                  }
                })
              }
              // 投保人没有配置性别的时候 特殊处理
              if (item.moduleType === 'applicant') {
                let list = item.productElementList[0].filter(ele => ele.elementCode === 'appntSex')
                let indexNum = ''
                item.productElementList[0].forEach((sex, index) => {
                  if (sex.elementCode === 'appntIdNo') indexNum = index
                })
                const appntType = state.callbackData['applicant'].appntType
                let idNo = state.callbackData['applicant'].appntIdNo
                let sex = parseInt(idNo[0].substr(16, 1)) % 2 == 1 ?  1 :  2
                if (list && !list.length && show && appntType === '01') {
                  item.productElementList[0].splice(indexNum + 1, 0,
                    {
                      id: 'applicant' + item.productElementList[0][item.productElementList[0].length - 1].id,
                      elementTitle: '性别',
                      appntSex: state.callbackData['applicant'].appntSex || sex,
                      show_tagName: state.callbackData['applicant'].appntSex_tagName || (['男', '女'][Number(sex) === 1 ? 0 : 1]),
                      firstValue: state.callbackData['applicant'].appntSex || (['男', '女'][Number(sex) === 1 ? 0 : 1])
                    })
                }
              }

              // 被监护人特殊处理
              if (item.moduleType === 'guardian'){
                let list = item.productElementList[0].filter(ele => ele.elementCode === 'idType')
                if(list[0].firstValue === '01'){
                  item.productElementList[0].splice(item.productElementList[0].findIndex(item => item.elementCode === 'birthday'), 1)
                }else if(list[0].firstValue === '50'){
                  item.productElementList[0].splice(item.productElementList[0].findIndex(item => item.elementCode === 'idNo'), 1)
                }
              }

              //支付模块处理
              if(item.moduleType === 'payment'){
                const renewFlag = item.productElementList[0].filter(ele => ele.elementCode === 'renewFlag')
                const isSameNew = item.productElementList[0].filter(ele => ele.elementCode === 'isSameNew')
                if(renewFlag.length){
                  if(renewFlag[0].firstValue === 'N'){
                    item.productElementList[0].forEach(ele =>{
                      if(ele.elementCode !== 'renewFlag'){
                        ele.isShow = false
                      }
                    })
                  }else{
                    item.productElementList[0].forEach(ele =>{
                      if(ele.elementCode === 'newBankCardUrl'){
                        ele.firstValue = 'Y'
                        ele.show_tagName  = '已上传'
                      }
                    })
                  }
                }
                // 续期银行账号信息同首期
                if(isSameNew.length && isSameNew[0].firstValue === '1'){
                  const list = ['payLocation','bankCode','bankName','bankAccNo','accName','bankCardUrl','bankProvinceCode', 'bankCityCode', 'bankAreaCode', 'renewalPhone', 'accountType']
                  item.productElementList[0].forEach(ele =>{
                    if(list.includes(ele.elementCode)){
                      ele.isShow = false
                    }
                  })
                }

              }
            })
        state.showData = showDataJson
        console.log(state.showData, 'state.showData1111')
        getGuaranteeDetails(state.showData)
        // 受益人类型展示单独处理
        handelBeneficiarysBytype(state.showData)
        // 可选责任
        getOptionalRes()
        // 影像附件
        getUpdateLodeDetail()
        if(!show) reorganizingData(res.result.contInfo)
        if(state.premAddSign === 0){
          getPremCalculator()
        }
        if(router.currentRoute.value.query.detailType !== '1'){
          // 录入页的数据存一下
          saveRiskList()
        }
      })
    }
    const saveRiskList = () =>{
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+'riskListDetail', saveData:{riskList: JSON.parse(store.getters['insured/getRiskList'])}})
    }
    // 这个是取
    const getRiskListDetail = () =>{
      request(getExtendUri('getCacheParameters'), {
        tradeNo: router.currentRoute.value.query.tradeNo+'riskListDetail'
      }).then(res =>{
        if (res.result){
          state.detailRiskData = res.result.saveData.riskList
          store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.saveData.riskList) })
        }
      })
    }
    const getUpdateLodeDetail = () =>{
      state.showData.forEach(item =>{
        item.productElementList.forEach(ele =>{
          const list = ele.filter(up => up.elementCode === 'updateLodeDetail' || up.elementCode === 'newBankCardUrl')
          if(list.length) state.isShowUpload = true
        })
      })
      if(state.isShowUpload){
        state.imgDetailList.forEach(item =>{
          if(item.type === 'applicant'){
            item.list = state.callbackData.applicant?.imageList
          }
          if(item.type === 'insureds'){
            state.callbackData.insureds.forEach(ele=>{
              if(ele.relationToAppnt === '01'){
                item.list = state.callbackData.applicant?.imageList
              }else{
                item.list = ele?.imageList
              }
            })
          }
          if(item.type === 'beneficiarys'){
            item.list = []
            state.callbackData.beneficiarys.forEach(ele =>{
              if (ele?.imageList?.length > 0) {
                item.list.push(...ele?.imageList)
              }
            })
          }
          if(item.type === 'payment' && state.callbackData.contract.newBankCardUrl){
            item.list.push({
              label: state.callbackData.contract.newBankCardUrl,
            })
          }
        })
        request(getExtendUri('getCacheParameters'), {
          tradeNo: router.currentRoute.value.query.tradeNo + 'healthTold'
        }).then(res => {
          if (res.code === 1) {
            if (res.result?.selectRisk?.remarkDetail?.healthUploadList?.length > 0) {
              state.imgDetailList.forEach(item => {
                if (item.type === 'health') {
                  item.list  = res.result.selectRisk.remarkDetail.healthUploadList.map(el => {
                    return {
                      imageUrl: el.playUrl,
                      ...el
                    }
                  })
                  console.log(item.list, 'item.list')
                }
              })
            }
          }
        })

      }

      // 提示书的
      if(state.callbackData.contract.imageList && state.callbackData.contract.imageList.length){
        state.isConfirmation = '1'
        state.confirmationObj = {
          relationshipList: [],
          reminderList: []
        }
        let num = 1
        const imageList = state.callbackData.contract.imageList
        imageList.forEach(item =>{
          num++
          if(item.imageTYpe === '1'){
            state.confirmationObj.relationshipList.push({
              value: num,
              imageUrl: item.imageUrl
            })
          }else if(item.imageTYpe === '2'){
            state.confirmationObj.reminderList.push({
              value: num,
              imageUrl: item.imageUrl
            })
          }
        })
      }


    }

    // 团险展示的数据
    const groupInsurance = reactive({
      // 被保人
      insureds:{
        // name: '姓名',
        // birthday: '出生日期',
        // sex: '性别',
        // phone: '手机号',
        // email: '邮箱',
        // insuredAge: '年龄',
        // relationToAppnt: '与投被保人关系',
        // idType: '证件类型',
        // idNo: '证件号码',
        // premium: '保费',
      },
      // 受益人
      beneficiarys:{
        bnfType: '受益人'
      },
      // 保险模块
      insuranceInfo:{

      }
    })

    // 团险重组数据
    const reorganizingData = (data) =>{
      delete data.excelMapping.sequenceNo
      groupInsurance.insureds = data.excelMapping
      let str = '_tagName'
      if(data.beneficiarys && data.beneficiarys.length > 0){
        let obj = {
          productElementList: []
        }
        for (let i = 0; i < data.beneficiarys.length; i++) {
          obj.productElementList[i] = []
          Object.keys(data.beneficiarys[i]).forEach(key =>{
            if(data.beneficiarys[i][key] && groupInsurance.beneficiarys[key]){
              obj.productElementList[i].push({
                isShow: true,
                elementCode: key,
                elementTitle: groupInsurance.beneficiarys[key],
                show_tagName: data.beneficiarys[i][key + str] ? data.beneficiarys[i][key + str] : data.beneficiarys[i][key]
              })
            }
          })
        }
        state.showData.forEach(item =>{
          if(item.moduleType === 'beneficiarys'){
            item.productElementList = obj.productElementList
          }
        })
      }
      if(data.insureds && data.insureds.length > 0){
        let obj = {
          productElementList: []
        }
        for (let i = 0; i < data.insureds.length; i++) {
          obj.productElementList[i] = []
          Object.keys(data.insureds[i]).forEach(key =>{
            if(data.insureds[i][key] && groupInsurance.insureds[key]){
              obj.productElementList[i].push({
                isShow: true,
                elementCode: key,
                elementTitle: groupInsurance.insureds[key],
                show_tagName: data.insureds[i][key + str] ? data.insureds[i][key + str] : data.insureds[i][key]
              })
            }
          })
        }
        state.showData.forEach(item =>{
          if(item.moduleType === 'insureds'){
            item.productElementList = obj.productElementList
          }
        })

      }

      if(JSON.stringify(data.insuranceInfo) !== '{}'){
        state.premium = formatNumberToFix2(data.insuranceInfo.premium)
      }
    }


    // 取一下 保障详情的数据  (在保险信息里面)
    const getGuaranteeDetails = (list = []) =>{
      if(list.length){
        state.guaranteeList = []
        let detailList = list.filter(item => item.moduleType === 'insuranceInfo')
        if(detailList && detailList.length){
          detailList[0].productElementList[0].forEach(item=>{
            item.isShow = true
          })
          getGuaranteeData(detailList[0].productElementList[0])
        }
      }
    }
    const getGuaranteeData = (dataList = []) =>{
      let guaranteeRiskList = JSON.parse(store.getters['insured/getRiskList']).riskList
      if(!guaranteeRiskList || !guaranteeRiskList.length) return
      const code = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode
      let newList = guaranteeRiskList.map(risk => {
        const list = JSON.parse(JSON.stringify(dataList))
        for (let j = 0; j < list.length; j++) {
          list[j].riskIdShow = risk.riskId
          let riskList = []
          switch (list[j]['elementCode']) {
            case 'productName': //  产品名称
              riskList = risk.calculateFactor.filter(item => item.factorType === 'Plan')
              if(code === '1018'){
                list[j].firstValue = risk.riskName
              }else{
                list[j].firstValue = `${risk.riskName} ` + (riskList && riskList.length && riskList[0].factorValueName ?`(${riskList[0].factorValueName})` : '')
              }
              list[j].id += j
              break
            case 'feeYear': // 缴费期间
              riskList = risk.calculateFactor.filter(item => item.factorType === 'FeeYear')
              if(riskList.length && riskList[0].factorInMain){
                list[j].firstValue = riskList[0].factorValueName
              }else if(riskList.length){
                list[j].firstValue = riskList[0].factorValueName
              }else{
                list[j].firstValue = ''
                list[j].required = '0'
              }
              break
            case 'insurancePeriod': // 保险期间
            case 'period': //保险信息
              riskList = risk.calculateFactor.filter(item => item.factorType === 'Period')
              if(riskList.length && riskList[0].factorInMain){
                list[j].firstValue = riskList[0].factorValueName
              }else if(riskList.length){
                list[j].firstValue = riskList[0].factorValueName
              }else{
                list[j].firstValue = ''
                list[j].required = '0'
              }
              break
            case 'Amnt':
            case 'amount': // 保额
              riskList = risk.calculateFactor.filter(n => n.factorType === 'Amnt')
              if(risk.exemptRisk === 'Y' && state.premiumList.length){
                var preList = state.premiumList.filter(pre => pre.riskId === risk.riskId)
                if(preList.length && preList[0].exemptRisk === 'Y'){
                  list[j].firstValue = preList[0].exemptCoverage
                }
              }else{
                if(riskList.length && riskList[0].factorInMain){
                  list[j].firstValue = riskList[0].factorValueName
                }else if(riskList.length){
                  list[j].firstValue = riskList[0].factorValueName
                }
              }
              break
            case 'premium': // 保费
              if(risk.premiumCalculation === '2'){
                if(risk.riskSign === '2' && risk.supRiskCode ==='U24S'&& risk.riskCode === '100000000889'){
                  riskList = risk.calculateFactor.filter(item => item.factorType === 'Prem')
                }else if(risk.supRiskCode === 'AV1' && risk.riskCode === '100000000887'){
                  let baseCoverage = 0
                  let mult = 0
                  for (let i = 0; i < risk.calculateFactor.length; i++) {
                    let item = risk.calculateFactor[i]
                    if (item.factorType === 'BaseCoverage') {
                      baseCoverage = item.factorValue ? Number(item.factorValue) : 0
                    }
                    if (item.factorType === 'Mult') {
                      mult = item.factorValue ? Number(item.factorValue) : 0
                    }
                  }
                  list[j].firstValue = baseCoverage * mult
                }else{
                  riskList = risk.calculateFactor.filter(item => item.factorType === 'Amnt')
                }
                if(riskList.length){
                  list[j].firstValue = riskList[0].factorValue
                }
              }else{
                list[j].firstValue = state.premium
              }
              break
          }
          if(!list[j].firstValue && list[j].elementCode !== 'healthToldFlag' && list[j].elementCode !== 'imageAttachments'){
            list[j].isShow = true
          }
        }
        return list
      }).flat()

      let healthList = newList.filter (item => item.elementCode === 'healthToldFlag')
      for (let i = 0; i < healthList.length-1; i++) {
        let index = newList.findIndex(item => item.elementCode === 'healthToldFlag')
        newList.splice(index, 1)
      }
      let productElementList = newList.reduce((prev, cur) => {
        let index = prev.findIndex((item) => {
          return item.elementCode === cur.elementCode && item.firstValue === cur.firstValue && item.riskIdShow === cur.riskIdShow
        })
        if (index === -1) {
          prev.push(cur)
        }
        return prev
      }, [])

      productElementList.forEach(pro =>{
        if(pro.elementCode !== 'imageAttachments'){
          state.guaranteeList.push({
            elementTitle: pro.elementTitle,
            firstValue: pro.elementCode === 'healthToldFlag' ? '已填写' : pro.firstValue
          })
        }
      })
    }


    // 受益人类型展示单独处理
    const handelBeneficiarysBytype = (list = []) =>{
      if(list.length){
        let detailList = list.filter(item => item.moduleType === 'beneficiarys')
        if(detailList && detailList.length){
          let arr = detailList[0].productElementList
          for (let i = 0; i < arr.length; i++) {
            let value = arr[i].filter(item => item.elementCode === 'bnfType')[0].firstValue
            for (let j = 0; j < arr[i].length; j++) {
              if(arr[i][j].isDictionary === 'false' || !arr[i][j].isDictionary ){
                state.beneficiaryList = arr[i][j].elementOperatingMode.mode_select.dropDownConfiguration.filter(drop => drop.value == value)
              }else if(arr[i][j].isDictionary){
                request(getExtendUri('dictionary_query_byType'),{data: {ids:[],types:[arr[i][j].dictionaryName]}}).then(
                  res =>{
                    if(res.code == 1){
                      state.beneficiaryList = res.result[arr[i][j].dictionaryName].filter(drop => drop.value == value)
                    }
                  })
              }
            }
          }
        }
      }
    }

    const querySignDate = (str = '',type = false) => {
      if(type) state.finishedType = true
      let param = {
        data: {
          tradeNo: state.tradeNo,
          shareId: state.shareId|| store.getters['insured/getShareId']
        }
      }
      getSignListApi(param).then(res => {
        if (res.code === 1) {
          if (!res.result){
            state.signFlag = false
            return
          }
          // autographDetailList
          const strType = router.currentRoute.value.query?.signatureType
          if(strType !== '1') {
            request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+ 'autographDetailList',selectRisk:{autographDetailList: res.result.autographDetailList}})
          }
          state.orderSignedDetailCopy = JSON.parse(JSON.stringify(res.result))
          state.orderSignedDetail = res.result
          if (state.orderSignedDetail?.autographDetailList?.length > 0) {
            if (router.currentRoute.value.query.customCode && router.currentRoute.value.query.signatureType === '1') {
              let remoteSignArr = state.orderSignedDetail.autographDetailList.filter(item => {
                return (item.customCode === router.currentRoute.value.query.customCode && item.signed === '1')
              })
              console.log(remoteSignArr.length, 'remoteSignArr')
              if (remoteSignArr.length > 0) {
                console.log(remoteSignArr, 'remoteSignArr111')
                state.remoteSigned = true
                console.log(state.remoteSigned, 'state.remoteSigned')
              }
            }
            state.orderSignedDetail.autographDetailList.forEach(item => {
              if (item.signed === '1') {
                state.hasUserSigned = true
              }
            })
          }
          if (state.orderSignedDetail.finished === true){
            state.signatureAll = '0'
          } else {
            state.signatureAll = '1'
          }
          if(res.result.autographDetailList && res.result.autographDetailList.length && str !== 'signFlag'){
            let arr = []
            res.result.autographDetailList.forEach(item => {
              arr.push({
                customCode: item.customCode,
                msgPassed: item.msgPassed === 'Y',
                signature: item.signed === '1'
              })
            })
            store.dispatch('insured/changeInsureJSInfo', {
              verifySMSFlag: arr
            })
          }
          if (state.userFlag && (state.userFlag == true || state.userFlag === 'true')) {
            const obj = getQueryJson()
            // 这个是确认签署后的
            if(obj.leavel && obj.leavel !== '4' && obj.signatureType && obj.attributive !== '4'){
              state.shareSignObj = {
                customCode: obj.customCode,
                attributive: obj.attributive,
                holderInsuredSame: obj.holderInsuredSame,
                signProvider: obj.signProvider
              }
            }else if(obj.attributive !== '4' && !obj.leavel && obj.attributive !== '4' && obj.signatureType){
              state.shareSignObj = {
                customCode: obj.customCode,
                attributive: obj.attributive,
                holderInsuredSame: obj.holderInsuredSame,
                signProvider: obj.signProvider
              }
            }
          }
          // 重新刷一下组件
          state.signFlag = false
          nextTick(() => {
            state.signFlag = true
          })
        }
      }).catch(err => {
        console.error(err)
      })
    }
    const getElectronicProposalFun = function (str,type) {
      querySignDate(str,type)
    }
    const getOperatorSwitchFun = function () {
      state.productInfo = JSON.parse(store.getters['insured/getProductDetailData'])
      let temp = new URLSearchParams(location.search)
      const ids = temp.get('id')
      request(getExtendUri('getOperatorSwitch'), {baseInfo: {
        tradeNo: state.tradeNo,
        processId: ids,
        processType: 'informationEntry'
      }}).then(res => {
        state.clauseShow = res.result
        if (res.code === 1 && res.result.hasBenefitDemo === 'Y') {
          //  利益演示（现金价值）是否展示
        }
        if (res.code === 1 && res.result.hasSign === 'Y') {
          state.signFlag = true
          state.signatureAll = '1'
          state.riskListShow = []
          console.log(state.productInfo)
          state.productInfo.productInfo.riskList.forEach(item => {
            item.coverageDataList.forEach(item1 => {
              if (item1.uploadFile != null && item1.uploadFileName != null) {
                state.riskListShow.push(item1)
              }
            })
          })
          getElectronicProposalFun()
        }else if(res.code === 1 && res.result.hasFace === 'Y'){
          state.isShowFace = true
        } else {
          state.signatureAll = '-1'
          state.signFlag = false
        }
      })
    }
    const copyPath = function (path) {
      toClipboard(path)
      message.success('复制成功')
    }
    const lijitoubao = async () => {
      state.isPay = true
      if (state.sureBtnTxt === '联系业务员') {
        // 执行联系业务员逻辑：获取业务员信息并弹窗
        showSalesmanPop()
        return
      }

      let a = await tradeNoStateIshavePay(state.tradeNo)
      // if (a === '1') {
      //   message.error('异常操作，该单正在进行核保处理,不允许重新提交')
      //   return
      // }
      // if (a === '2') {
      //   message.error('异常操作，该单已支付,不允许重新提交')
      //   return
      // }
      if (a === '-1') {
        message.error('服务异常,请稍后操作')
        return
      }
      if (!state.checkedShow && state.isShare !== '1') {
        state.errMessage = '请确认是否同意产品相关条款'
        state.isError = true
        return false
      }
      if (state.signatureAll === '1' && state.signFlag) {
        message['error']('请确认已全部签名')
        return false
      }

      if (state.isShowFace && state.hasFace !== 'Y') {
        state.errMessage = '请投保人进行人脸识别'
        state.isError = true
        return false
      }
      if (state.signatureAll === '-100' && state.signFlag ) {
        message['error']('产品配置错误，无法投保!')
        return false
      }
      // if(state.uwStatus === '1'){
      //   state.uwStatusType = true
      //   state.detailModal = '是否确认转人核'
      //   state.isIdentical = true
      //   return
      // }

      if (state.checkedShow) {
        state.clickSign = true
        state.loadingShow = true
        saveDateToCheck()
        // saveTopay()
      }
    }
    /**
     * 调用支付
     */
    const saveTopay = () => {
      state.loadingShow = true
      state.isShow = true
      // state.payObj.payState = true
      // request(getExtendUri('confirmCharge'), {
      request(getExtendUri('getInterfaceRequest'), {
        data: {
          tradeNo: state.tradeNo,
          shareId: store.getters['insured/getShareId'],
          processId: JSON.parse(store.getters['insured/getRouterJump']).processId,
          processType: 'acknowledgementOrder'
        }
      }).then(res => {
        if (res.code === 1) {
          if(res.result.supplierPayment === 'N'){
            // 是否供应商支付
            state.loadingShow = false
            state.payObj.payUrl = res.result.url
            paymentData.detailData = res.result
            paymentData.supplierPayment = res.result.supplierPayment
            paymentData.productName = res.result.supplierName
            paymentData.paymentMethod = res.result.value.PayList
            paymentData.detailData.value['PageReturnUrl'] = encodeURIComponent(res.result.value.PageReturnUrl)
            delete paymentData.detailData.value.PayList
          }else if(res.result.processType && (res.result.processType === 'generatePolicy' || (res.result.processType === 'offlineUnderwriting' && res.result.offlineUnderwritingResult === 1))) {
            state.clickSign = true
            // 承保的那个
            router.push({
              path: '/app/payStatus',
              query: {
                ...router.currentRoute.value.query,
                tradeNo: res.result.tradeNo,
                underwritingResult: res.result?.underwritingResult,
                processType: res.result.processType,
                offlineUnderwritingResult: res.result?.offlineUnderwritingResult,
              }
            })
          }else if(res.result.payUrlType === '4') {
            state.payObj.payUrl = res.result.url
            paymentData.detailGfData = res.result
            nextTick(()=>{
              payGFform.value.submit()
              state.loadingShow = false
            })
          }
          else {
            payAction(res.result)
          }
        }else {
          state.isIdentical = true
          state.detailModal = res.message || '失败'
          state.loadingShow = false
        }
      })
    }
    // 中间页支付跳转
    const paymentClick =(data) =>{
      state.loadingShow = true
      paymentData.detailData.value.PayEnum = data.PayCode
      nextTick(()=>{
        payZDform.value.submit()
        paymentData.supplierPayment = 'Y'
        state.loadingShow = false
      })
    }
    const saveDateToCheck = () => {
      let submitData
      if (store.getters['insured/getSaveDate']&&store.getters['insured/getSaveDate']!=={}) {
        submitData = JSON.parse(JSON.stringify(store.getters['insured/getSaveDate']))
      }
      if (submitData && submitData.hasOwnProperty.call('baseInfo')) {
        xxx(submitData)
      } else {
        // 根据接口获取riskLisk数据存入缓存
        request(getExtendUri('getCacheParameters'), {
          tradeNo: router.currentRoute.value.query.tradeNo
        }).then(res => {
          console.log('getCacheParameters:', res.result)
          store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
          store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
          xxx(JSON.parse(store.getters['insured/getSaveDate']))
        })
      }
    }
    const isJson = (str) => {
      if (typeof str != 'string') {
        return false
      }
      try {
        let obj = JSON.parse(str)
        if (obj && typeof obj == 'object') {
          return true
        } else {
          return false
        }
      }catch (err){
        console.error(err)
        return false
      }
    }
    const xxx = (submitData) => {
      if (isJson(submitData)) {
        submitData = JSON.parse(submitData)
      }
      // state.isUnderwriting = store.getters['insured/isUnderwriting']
      submitData = getSub(submitData)

      store.dispatch('insured/changeInsureJSInfo', {
        contInfoDetail: submitData
      })
      doubleRecordSEvent({
        data: {
          tradeNo: state.tradeNo,
          shareId: store.getters['insured/getShareId']
        }
      } )
    }
    // 增加只校验规则不核保标识
    const getSub = (submitData) => {
      submitData['contInfo']['contract']['callerSource'] = 'rule'
      return submitData
    }
    // 这个是核保
    const handelUnderwriting = (submitData) => {
      // 删除
      Reflect.deleteProperty(submitData['contInfo']['contract'], 'callerSource')
      request(getExtendUri('saveData'), submitData).then(res => {
        if (res.backInfo.result === 1) {
          saveTopay()
        } else {
          state.detailModal = getErrListInfo(res.backInfo.resultInfos.resultInfo)
          state.isIdentical = true
          state.loadingShow = false

        }
      })
    }
    const insuranceErrorHandleOk = function () {
      if(state.uwStatusType){
        state.uwStatus = ''
        state.uwStatusType = false
        lijitoubao()
      }
      state.insuranceError = false
      state.isIdentical = false
      state.detailModalTwo = ''
      state.okButton = '确认'
    }
    const insuranceErrorHandleOff = () =>{
      state.insuranceError = false
      state.isIdentical = false
      state.uwStatusType = false
      state.detailModalTwo = ''
      state.okButton = '确认'
    }
    const getErrListInfo = (errList) => {
      let str = ''
      for (let i = 0; i < errList.length; i++) {
        str += errList[i].resultContent + '\n'
      }
      return str
    }
    /**
     * 执行form表单的提交
     * obj中包含要提交的接口地址，传参，返回值
     */
    const payAction = (obj) => {
      state.payObj.supId = obj.supplierCode
      state.payObj.processType = obj.processType
      let type = true
      // 更改成payUrl去判，如果有，则表示直接跳链接
      if (obj.processType === 'payment' && obj.payUrl) {
        state.payObj.payUrl = obj.payUrl
        state.payObj.payJson = ''
        state.payObj.payKey = ''
      } else {
        state.payObj.payJson = state.payObj.payStateResult = obj.value || ''
        state.payObj.payUrl = obj.url
        state.payObj.payKey = obj.key
        type = false
      }
      // 跳转
        initDate(state.payObj, type)
    }
    const handelReadDataList = () => {
      state.readDataList = []
      let arr = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.riskList[0].coverageDataList
      if (arr && arr.length) {
        arr.forEach(item =>{
          if(item.fileDataType !== '7' && item.fileDataType !== '12' && item.fileDataType !== '6'){
            state.readDataList.push(item)
          }
        })
        // state.readDataList.push(...arr.filter(d => ['1', '2', ' 3', '4', '5', '8', '9', '10', '11', '13', '14', '16', '17', '18', '19', '21', '22'].includes(d.fileDataType)))
        for (let i = 0; i < state.readDataList.length; i++) {
          if(state.readDataList[i].fileList.length){
            let arr = state.readDataList[i].fileList.filter(m => m.mustReadOrder !== 1)
            if (!arr.length){
              state.readDataList.splice(i, 1)
              i--
            }else{
              state.readDataList[i].fileList = arr
            }
          }
        }
      }
    }
    const premCalculator = async () => {
      const routerData = JSON.parse(store.getters['insured/getRouterJump'])
      const str = 'calPremium'
      if(router.currentRoute.value.query.detailType && router.currentRoute.value.query.detailType === '1'){
        await getRiskListDetail()
      }else{
        state.detailRiskData = JSON.parse(store.getters['insured/getRiskList'])
      }
      await request(getExtendUri(str), {
        productInfo: state.detailRiskData
      }).then(res => {
        if (res.code === 1 && res.result) {
          state.premiumList = res.result.riskPremiumList
          if (routerData.maximumLimit > 1 && res.result.premAddSign === 0){
            state.premAddSign=res.result.premAddSign
            return
          }
          let tk = newPremiumInTK(state.detailRiskData)
          if(tk){
            state.premium = tk
          }else{
            state.premium = res.result.totalPremium
          }
        } else {
          message['error'](res.message)
        }
      })
    }

    const getPlanData = () => {
      state.productPlanName = (JSON.parse(store.getters['insured/getRiskList']).riskList[0].calculateFactor.filter(item => item.factorType === 'Plan')[0] || {}).factorValueName || ''
      state.productProName = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.productName
    }


    watch(()=> store.getters['insured/getClausePdfAllReadState'], (newVal)=>{
      state.readAllStatus = newVal
      // state.checkedShow = newVal
    })
    // 获取总保费
    const  getPremCalculator = () =>{
      let totalPremium = 0
      for (const insured of state.showData.filter(item => item.moduleType === 'insureds')) {
        for (const elementList of insured.productElementList) {
          for (const element of elementList) {
            if (element.elementCode === 'premium' && element.firstValue !== null && element.firstValue !== undefined) {
              totalPremium += Number(element.firstValue)
            }
          }
        }
      }
      state.premium = formatNumberToFix2(totalPremium)
    }
    const showErrPop = (str) => {
      state.isIdentical = true
      state.detailModal = str
    }
    // 跳转到人脸识别页面
    const onToIdentification = () =>{
      if (!state.checkedShow) {
        state.isIdentical = true
        state.detailModal = '请确认是否同意产品相关条款'
        return false
      }
      const params = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        shareId: router.currentRoute.value.query.shareId,
      }
      getFaceRecognition({
        data: params
      }).then(res => {
        console.error('faceapi::=>', JSON.stringify(res))
        if (res.code === 1) {
          if (res.result.bizStatus === 'Y' && res.result.bizNo) {
            router.push({
              path: '/app/identification',
              query: {...router.currentRoute.value.query, bizNo: res.result.bizNo, hasFace: 'Y'}
            })
          }
          else {
            if (res.result.respUrl) {
              res.result.respUrl = res.result.respUrl+'&hasFace=Y'
              if (router.currentRoute.value.query.signatureType === '1') {
                res.result.respUrl = res.result.respUrl + '&userFlag=true&signatureType=1'
              }
              if (router.currentRoute.value.query.leavel) {
                res.result.respUrl = res.result.respUrl + `&leavel=${getQueryJson('leavel')}&code=${getQueryJson('code')}`
              }
              window.location.href = res.result.respUrl
            }
          }
        } else {
          // 获取校验异常
          showErrPop('获取校验身份信息接口异常，请联系管理员')
        }
      })
    }

    /**
     * 显示短信校验弹窗
     * @param item
     */
    const showSMS = (item) => {
      let param = {
        tradeNo:item.tradeNo,
        attributive: item.attributive,
        customCode: item.customCode
      }
      state.attributive = item.attributive
      state.customCode = item.customCode

      // todo 根据这三个参数去获取
      console.log(item)
      getPhoneDateApi({
        data: param
      }).then(res => {
        if (res.code === 1) {
          if (res.result){
            state.vPhone = res.result.customPhone
            state.vName = res.result.customName
            state.customCode = res.result.customCode
            state.isSMS = false
            nextTick(() => {
              state.isSMS = true
            })
          }
        }
      })
    }
    const changeClickSign = () => {
      state.clickSign = true
    }
    const chooseSign = (item) => {
      state.clickSign = true
      state.selectSigned = item
    }
    const getChooseSign = () => {
      return state.selectSigned
    }
    const codeRef = ref()
    /**
     * 校验手机号
     */
    const verificationEvent = () => {
      const type = codeRef.value.getCheck(state.vCodestatus)
      if (type){
        return message.warning(type)
      }
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+'PDF', saveData: {clausePdfList: store.getters['insured/getClausePdfList']}})
      if (state.vCodestatus) {
        // todo 校验手机号逻辑: vPhone，vCode
        sendSMSApi({
          data:{
            mobile: state.vPhone,
            verifyCode: state.vCode,
            attributive: state.attributive,
            customCode: state.customCode
          }
        }).then(res => {
          if (res.code === 1) {
            // 如果成功跳转签名
            // 存一下标识
           let arr =  store.getters['insured/getVerifySMSFlag']
            arr.forEach(item =>{
              if (item.customCode === state.customCode){
                item.msgPassed = true
                item.signature = true
              }
            })
            store.dispatch('insured/changeInsureJSInfo', {
              verifySMSFlag: arr
            })
            state.clickSign = true
            // 直接跳转签名
            sginRef.value.toSign(state.selectSigned.beanItem,state.selectSigned.provider)
          } else {
            message.error(res.message)
          }
        })
      }
    }
    const showReConfirmFun = () => {
      state.showReConfirm = true
    }
    const goSign = () => {
      state.showReConfirm = false
      sginRef.value.reSign(state.selectSigned.beanItem,state.selectSigned.provider)
    }
    const handleCode = (code) => {
      if (code) {
        state.vCode = code
        state.vCodestatus = true
      } else {
        state.vCodestatus = false
      }
    }
    /**
     * 联系业务员弹出窗
     */
    const showSalesmanPop = () => {
      const agentInfo = JSON.parse(store.getters['insured/getProductDetailData'])?.agentInfo
      if(agentInfo){
        state.okButton = '知道了'
        state.detailModal = '业务员姓名: '+ agentInfo.agentName
        state.detailModalTwo = '业务员电话: '+ agentInfo.mobile
        state.isIdentical = true
      }
    }
    // 这个是可选责任
    const getOptionalRes = () =>{
      let list = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.riskList || []
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i]['dutyList'].length; j++) {
          if (list[i]['dutyList'][j]['isRisk'] === 'Y') {
            state.optionalDutys.push(list[i]['dutyList'][j]['id'])
            list[i]['dutyList'][j].indexValue = i
            state.optionalDutysList.push(list[i]['dutyList'][j])
          }
        }
        resetOptionalDutysList(state.optionalDutysList)
        // 获取计划中的要素
        let factorValueList = (list[i]['calculateFactor'].filter(item => item.factorType == 'Plan')[0] || {}).factorValueList || []
        resetOptionalDuty(factorValueList)
      }
      // 需求, 没选中则不展示可选责任
      state.optionalDutysList.forEach(item =>{
        if(state.optionalDutys.includes(item.id) && item.isShowStatus === 'Y' && item.isSelected){
          state.optionalDutysShow = true
        }
      })
    }
    const resetOptionalDuty = (valList) => {
      for (let j = 0; j < valList.length; j++) {
        for (let m = 0; m < valList[j]['dutyList'].length; m++) {
          if (valList[j]['dutyList'][m]['isOptional'] !== 'Y' && state.optionalDutys.includes(valList[j]['dutyList'][m]['riskDutyId'])) {
            state.optionalDutys.splice(state.optionalDutys.indexOf(valList[j]['dutyList'][m]['riskDutyId']), 1)
          }
        }
      }
    }
    // 是否默认选中
    const resetOptionalDutysList = (list=[]) =>{
      // 防止不参加保费计算的可选责任
      const optionalDutyList = store.getters['insured/getOptionalDutyList']
      if (list.length && optionalDutyList.length) {
        list.forEach(item =>{
          const index = optionalDutyList.findIndex(op =>op.id === item.id)
          if(index !== -1) item.isSelected = true
        })
      }
      // 这个是根据可选责任是参加保费计算的
      // const routerData = JSON.parse(store.getters['insured/getRouterJump'])
      // let data = []
      // if(typeof(store.getters['insured/getMultipleRiskList']) == 'string' && store.getters['insured/getMultipleRiskList'] && routerData.maximumLimit > 1){
      //   data = JSON.parse(store.getters['insured/getMultipleRiskList'])['multipleInsuredFormList'][0].riskList
      // }else{
      //   data = JSON.parse(store.getters['insured/getRiskList']).riskList
      // }
      // if (list.length) {
      //   data.forEach(item => {
      //     item.calculateFactor.filter(factor => factor.factorType === 'duty')
      //       .map(factor => factor.factorId)
      //       .forEach(id => {
      //         const sel = list.find(duty => duty.id === id)
      //         if (sel) {
      //           sel.isSelected = true
      //         }
      //       })
      //   })
      // }
    }
    let flag = ref(false)

    onUnmounted(()=> {
      clearTimeout(state.timeHandle)

    })

    watch(jumpObj, ()=> {
      if(!flag.value){
        // saveDateToCheck()
        getProssData()
      }else{
        if(!state.isPay){
          premCalculator()
        }
        Initialization(jumpObj)
      }
    })
    const getProssData = async () =>{
      const {id, sort} = router.currentRoute.value.query
      const been =  await getRouterJumpBean(state.shareId,state.tradeNo, 'informationEntry')
      await getInformationEntry(been?.id ?? id, (been?.sort ?? sort-1)+'', state.tradeNo)
      flag.value =await true
    }


    // 双录网络请求
    const doubleRecordSEvent =  (data) => {
       request(getExtendUri('getDoubleRecordsInfo'), data).then(res => {
         let call = res.result
          if (res.code === 1 && call.isNeedDRS === 'Y') {
            state.drsData = call
            state.doubleRecordS = true
            if(state.drsData.drsResult === 'D'){
              saveTopay()
              getProssData()
            }
            state.loadingShow = false
          }else{
            state.loadingShow = false
            saveTopay()
            getProssData()
          }
      })
    }
    // 双录关闭
    const toJumpUrl = () => {
      state.doubleRecordS = false
    }

    const payform = ref()
    const requestDoc = ref()
    const payZDform= ref()
    const payGFform= ref()
    // 支付跳转
    const initDate = (data, type) => {
      state.loadingShow = true
      if (data.processType === 'payment' && data.payUrl && type) {
        nextTick(() => {
          window.location.href = data.payUrl
          // payform.value.submit()
        })
      } else {
        if (data.payJson) {
          requestF2(data.payUrl)
        } else {
          message.warn('异常')
        }
      }
    }
      const requestF2 = (url) => {
        if (url) {
          nextTick(() => {
            requestDoc.value.value = state.payObj.payJson
            payform.value.submit()
          })
        }
      }
      const benefitOrderTrans = (code) => {
      const options = [
        {
          value: '1',
          label: '第一顺位'
        },
        {
          value: '2',
          label: '第二顺位'
        },
        {
          value: '3',
          label: '第三顺位'
        },
      ]
      return options.find(item => {
        return item.value === code
      })?.label ?? ''
    }
    const findValue = (list, type) => {
      if (type === 'Amnt') {
        return list.find(item => {
        return item.factorType === type
      })?.factorValue
      }
      return list.find(item => {
        return item.factorType === type
      })?.factorValueName
    }
    const closeConfrim = () => {
      state.showReConfirm = false
    }
    const backInput = () => {
      state.clickSign = true
      backMethod1()
    }
    const backMethod1 = () => {
      const processDetailsList = store.getters['insured/getProcessDetailsList']
      const currentPcrocessNode = store.getters['insured/getCurrentProcessNode']
      processDetailsList.forEach((item,index) => {
        if (item.id === currentPcrocessNode.id && index !== 0) {
          router.replace({
            path: '/app' + showArr[processDetailsList[index - 1].code].value,
            query: {
              ...router.currentRoute.value.query,
              sort: router.currentRoute.value.query.sort - 1
            }
          })
        } else if (item.id === currentPcrocessNode.id && index === 0) {
          router.replace({
            path: '/app/detail',
            query: {
              shareId: router.currentRoute.value.query.shareId
            }
          })
        }
      })
    }
    const backMethod = async () => {
      const bean = await getRouterJumpBean(state.shareId, state.tradeNo, 'informationEntry')
      console.log('7777777', bean)
      if (router.currentRoute.value.query?.signatureType === '1' && router.currentRoute.value.query?.customCode&& router.currentRoute.value.query?.leavel !== '4' && router.currentRoute.value.query?.attributive !== '4') {
        state.isIdentical = true
        state.detailModal = '当前页面不可返回'
        return false
      }
      let a = await tradeNoStateIshavePay(state.tradeNo)
      if (!router.currentRoute.value.query?.userId && a !== '2' &&((router.currentRoute.value.query.signatureType !== '1' && state.hasUserSigned === true) || state.hasFace === 'Y')) {
        state.isError1= true
        state.errMessage1='返回后将重新进行投保操作，是否返回？'
        return false
      } else if (a === '2') {
        state.isIdentical = true
        state.detailModal = '该单已支付不可修改'
        return false
      } else {
        const continuousRenew = router.currentRoute.value.query?.continuousRenew
        const uwStatus = router.currentRoute.value.query?.uwStatus
        if (bean && !continuousRenew && uwStatus != '1') {
          await router.replace({
            path: bean.path,
            query: {
              tradeNo: bean.tradeNo,
              shareId: bean.shareId,
              sort: Number(router.currentRoute.value.query.sort) - 1,
              id: bean.id,
            }
          })
        }else{
          router.back()
        }
      }
    }
    const updateShowHealth = (val) => {
      state.showHealthConfirm = val
    }
    const closeConfrimDalog = () => {
      state.showHealthConfirm = false
    }
    const remoteColse = () => {
      state.clickSign = true
      router.replace({
        path: '/app/signatureVerification',
        query: {...router.currentRoute.value.query}
      })
    }
    const insuredSingStatusChange = (val) => {
      state.insuredSingStatus = val
    }
    return {
      insuredSingStatusChange,
      remoteColse,
      updateShowHealth,
      closeConfrimDalog,
      backMethod,
      goSign,
      showReConfirmFun,
      closeConfrim,
      findValue,
      benefitOrderTrans,
      benefitConfig,
      initDate,
      payform,
      payZDform,
      payGFform,
      requestDoc,
      flag,
      getOptionalRes,
      handelReadDataList,
      premCalculator,
      ...toRefs(state),
      groupInsurance,
      lijitoubao,
      provisionRef,
      sginRef,
      copyPath,
      showRead,
      getElectronicProposalFun,
      readAll,
      insuranceErrorHandleOk,
      insuranceErrorHandleOff,
      handelUnderwriting,
      verificationEvent,
      handleCode,
      showSMS,
      chooseSign,
      getChooseSign,
      jumpObj,
      toJumpUrl,
      getPremCalculator,
      paymentData,
      paymentClick,
      codeRef,
      onToIdentification,
      faceShow,
      faceYes,
      Dialog,
      router,
      changeClickSign,
      backInput
    }
  },
  async beforeRouteLeave (to, from) {
    console.log(to, from, 'to-from')
    if (!this.clickSign && this.router.currentRoute.value.query?.signatureType === '1' && this.router.currentRoute.value.query?.customCode && this.router.currentRoute.value.query?.leavel !== '4' && this.router.currentRoute.value.query?.attributive !== '4') {
      console.log('1111111')
      this.isIdentical = true
      this.detailModal = '当前页面不可返回'
      return false
    }
    console.log('22222222')
    let a = await tradeNoStateIshavePay(this.tradeNo)
    if (a === '2' && to.path === '/app/informationInput') {
      this.errMessage = '该单已支付不可修改'
      this.isError = true
      return false
    }
    if (!this.clickSign && to.path !== '/app/informationInput') {
      let a = await tradeNoStateIshavePay(this.tradeNo)
      if (!this.router.currentRoute.value.query?.userId && a !== '2' &&((this.router.currentRoute.value.query.signatureType !== '1' && this.hasUserSigned === true) || this.hasFace === 'Y')) {
        this.isError1= true
        this.errMessage1='返回后将重新进行投保操作，是否返回？'
        return false
      } else if (!this.clickSign && a === '2') {
        this.isIdentical = true
        this.detailModal = '该单已支付不可修改'
        return false
      }
    }
  }
})
</script>
