<template>
  <van-pull-refresh v-model="pullRefreshing" @refresh="$emit('pullOnRefresh')">
    <div class="order-item">
      <OrderListItem :listShow="listShow" :finished="finished" @nextJump="nextJump"/>
    </div>
  </van-pull-refresh>
</template>

<script>
import { computed} from 'vue'
import OrderListItem from './OrderListItem.vue'
import { PullRefresh, Loading, Empty} from 'vant'

export default {
  name: 'OrderListRefresh',
  components: {
    OrderListItem,
    [PullRefresh.name]: PullRefresh,
    [Loading.name]: Loading,
    [Empty.name]: Empty,
  },
  props: {
    listShow: {
      type: Array,
      default: () => [],
    },
    refreshing: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => true
    },
    finished: {
      type: Boolean,
      default: () => false
    },
  },
  emits: ['nextJump'],
  setup(props,{emit}) {
    
    const pullRefreshing = computed(() => 
        props.refreshing 
    )
    const nextJump = (item) => {
      emit('nextJump', item)
    }
    return {
      pullRefreshing,
      nextJump
    }
  },
}
</script>

<style scoped>
.order-item {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
}

.loading-container,
.empty-container {
  margin-top: 20px;
  text-align: center;
}

.loading-text {
  margin-left: 10px;
  font-size: 14px;
  color: #c9c9c9;
}
</style>
