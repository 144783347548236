<style scoped>
.w45{
  width: 45% !important;
}
.product_wrap {
  width: 100%;
  height: 100vh;
  /* background-color: #f6f8fb; */
  background: #fff;
  overflow-x: hidden;
  text-align: left;
}

.bottom_title {
  position: fixed;
  bottom: 2.6rem;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
  padding: 0 1rem;
  box-shadow: 0 0 2px 0 rgba(179, 179, 179);
}
.bottom_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.6rem;
  line-height: 2.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;
}

.bottom_fix {
  height: 7rem;
}
.insure_active{
  color: #fff;
  font-weight: 500;
  border-radius: 0;
  width: 7rem;
}
.next_page_class{
  color: #fff;
  font-weight: 500;
  border-radius: 25px;
  /*margin: auto 5px;*/
  width: 100%;

}

.insure_btn {
  /*font-size: 1.2rem;*/
  /*height: 100%;
 // width: 50%;*/
  width: 7rem;
  color: #0079f1;
  border: 1px solid #0079f1;
  font-weight: 500;
  border-radius: 0;
}
:deep(.ant-modal-content .ant-modal-footer) {
  border-top: none !important;
  text-align: center !important;
}
.modal-footer{
  width: 8.5rem;
  height: 2.6rem;
  border:1px solid #0079f1;
  color: #0079f1;
}
.modal_class{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.1rem;
}
.bottom_bar2 {
  position: fixed;
  bottom: 0;
  width: 100%;
  /*height: 2.6rem;*/
  line-height: 2.6rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;

  padding: 0 1rem 1rem;
  box-shadow: none;
}
.butt_class{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}
.insure_button {
  width: 7rem;
  border: 1px solid #0079f1;
  font-weight: 500;
  border-radius: 0;
}
.detailModal_class{
  font-family: PingFangSC-Regular;
  font-size: 1.01rem;
  color: #333333;
  text-align: center;
  margin: 0.45rem 0.25rem;
}
.underwriting_title{
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}
.BMI {
  padding: 0 20px 0 20px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.previewPop {
  padding-bottom: 40px;
}
.health-upload{
  padding: 6px 0;
}


.health-upload .title{
  font-size: 18px;
  font-weight: bold;
  color: #000;
  padding: 8px 15px;
  /*padding: 6px 15px 6px 20px;*/
}
/*.displayType{*/
/*  margin-: 0 20px 10px;*/
/*}*/
.prove_class{
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.add_class{
  width: 8rem;
  height: 5.7rem;
  padding-top: .5rem;
  background: #F0F7FF;
}
.add_class_img img{
  width: 2rem;
  height: 2rem;
  /*margin-bottom: 5px;*/
}
.add_class div{
  text-align: center;
  line-height: 2.35rem;
}
.BMI-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.BMI-tips {
  height: 40px;
  line-height: 40px;
}
.BMI-modal_class {
  display: flex;
  justify-content: center;
  /* height: 50px; */
  align-items: center;
  margin-top: 20px;
}
.modal-footer-button {

}
</style>

<template>
  <div>
    <IOSbackBar :title="'健康告知'" :backMethod="backMethod" ></IOSbackBar>
    <div class="product_wrap" id="product_wrap">
      <div style="padding: 10px 20px 0 20px;">
        <label style="border: 0px !important; font-size: 0.94rem; line-height: 1.5rem;"><b>投保人及被保险人应对被保险人健康、职业及历史投保记录等情况充分了解的基础上履行如实告知义务。若被保险人实际情况与告知内容不符：<br/>
          1.保险公司有权解除保险合同；<br>
          2.保险合同解除前发生的保险事故，保险公司不承担赔偿或给付保险金责任，并有权不退还保险费。<br></b></label>
      </div>
      <!-- <div class="BMI" v-if="BIM">BMI：{{ BIM }}</div> -->
      <template v-for="(item,index) in showData" :key="'healthTold_' + index">
        <PreviewPop class="previewPop" ref="previewPopRef" id="previewPop" :previewData="item" @PreviewPopData="PreviewPopData" :BIM="BIM" @changeShowBMI="changeShowBMI"/>
      </template>
      <!--    健康告知中选择是的时候展示-->
      <div v-show="dialogShow" class="health-upload">
        <div class="title">健康资料</div>
        <div class="prove_class displayType">
          <upload-file-new  :select-list="['image/*']" @getFileList="handelDelCard"  filetype="img"  @fileChange="uploadFileUrl" listType="picture-card"  :echoList="healthUploadList"></upload-file-new>
        </div>
      </div>



      <div class="bottom_fix"></div>
      <div class="bottom_title" v-if="configList && configList.length" v-html="showEditorContent"></div>
      <div>
        <div class="bottom_bar" v-if="configList && configList.length === 1">
          <a-button v-for="(item, index) in configList" :key="index"
                    type="primary" :disabled="loading || noViewed"
                    size="large" class="next_page_class"  @click="allNo(item, 'check')">{{item.priInput}}</a-button>
        </div>
        <div class="bottom_bar" v-else-if="configList && configList.length > 1">
          <a-button v-for="(item, index) in configList" :key="index"
                    :type="item.modelValue==='1' || item.modelValue==='2' || item.modelValue==='4'? 'ghost': 'primary'" :disabled="loading || (item.priInput === '全部为否' && noViewed)"
                    size="large" :class="item.modelValue == '99' || item.modelValue == '3'? 'insure_active' :  'insure_btn'"  @click="allNo(item)">{{item.priInput}}</a-button>
        </div>
        <div class="bottom_bar2" v-else>
          <div>请确认是否存在上述健康问题:</div>
          <div class="butt_class">
            <a-button :disabled="(loading || noViewed)" type="text" class="insure_button" size="large" style="color: #949494" @click="allNo(1)">全部为{{yesOrNo}}</a-button>
            <a-button :disabled="loading" type="primary" class="insure_button" size="large" @click="nextPage('path')">下一步</a-button>

          </div>
        </div>
        <a-modal :visible="visible" :footer="null"  centered :closable = 'false'>
          <div style="height: 3rem;">很抱歉，被保人的健康状况不满足该保险的投保规定！</div>
          <div class="modal_class">
            <a-button class="modal-footer" @click="modalClose" >选错了</a-button>
            <a-button class="modal-footer" type="primary" @click="handleOk" style="color:#fff ">返回</a-button>
          </div>
        </a-modal>
        <!--      智能核保的-->
        <a-modal :visible="isUnderwriting" :footer="null" centered :closable = 'false'>
          <div class="underwriting_title">健康告知不通过</div>
          <div style="min-height: 3rem;">{{ underwriting }}</div>
          <div class="modal_class">
            <a-button class="modal-footer" @click="()=> isUnderwriting =false" >取消</a-button>
            <a-button class="modal-footer" type="primary" @click="toUnderwriting" style="color:#fff ">智能核保</a-button>
          </div>
        </a-modal>
        <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical" @ok="insuranceErrorHandleOk">
          <div class="detailModal_class">{{ detailModal }}</div>
          <template #footer>
            <a-button
              type="link"
              style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
              :class="{w45:  dialogType}"
              @click="insuranceErrorHandleOk">确认
            </a-button>
            <a-button
              v-if="dialogType"
              type="link"
              style="width:45%;font-size: 20px;text-align: center !important; color: #1677FF;"
              @click="insuranceErrorHandleOff">取消
            </a-button>
          </template>
        </a-modal>
        <a-modal style="width: 80%;" :visible="BMITipsvisible" :footer="null"  centered :closable = 'false'>
          <div class="BMI-title">BMI</div>
          <div class="BMI-tips">BMI=体重(kg)/身高(m)<sup>2</sup></div>
          <div class="BMI-modal_class">
            <a-button class="modal-footer-button" type="primary" @click="changeShowBMI(false)" >我知道了</a-button>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, toRefs,watch,onBeforeUpdate, onMounted, nextTick, ref} from 'vue'
// eslint-disable-next-line no-unused-vars
import {routerJump, nowPathShow, showArr} from '../../utils/product_process'
import {request} from '../../utils/request.js'
import {getExtendUri} from '../../utils/request_extend.js'
import {message} from 'ant-design-vue'
// import {startRecord} from '../../utils/rrweb'
import { useRouter } from 'vue-router'
import store from '../../store'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
import UploadFileNew from './public/rt_health_upload'
import {Form, Field, CellGroup} from 'vant'
import { deepClone } from '../../utils/utlis'

export default defineComponent({
  components: {
    IOSbackBar,
    UploadFileNew,
    [Field.name]: Field,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
  },
  beforeRouteEnter(to, from, next) {
    // document.title = '健康告知'
    next()
  },
  setup() {
    let  router  = useRouter()
    const previewPopRef = ref()
    // const routerData = JSON.parse(localStorage.getItem('routerJump'))
    const state = reactive({
      routerFlag: false,
      time:new Date().getTime(),
      BMITipsvisible: false,
      noViewed: true,
      loading: false,
      name: '',
      showData: [],
      BIM: '___',
      visible: false,
      isUnderwriting: false,
      underwriting: '本保障产品支持被保人智能核保,如有健告所述疾病或状况,请进入智能核保,在线实时审核是否可继续投保',
      modelValue: '1',
      showEditorContent: '',
      configList: [],
      showObj: {},
      nowArr: [],
      riskList: '',
      riskObj: {},
      tradeNo: '',
      isIdentical: false,
      detailModal:'',
      dialogType: '',
      dialogShow: false,
      yesOrNo: '否',
      associationType: '',
      uploadIndex: '',
      uploadClickData: {},
      // healthRemarks: '',
      healthUploadList: [{value: new Date().getTime()}],
      clickData: {},
      num: 1
    })
    onBeforeMount(() => {
      state.tradeNo = router.currentRoute.value.query['tradeNo']
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
      state.riskObj = state.riskList.filter(risk => risk.riskSign === '1')[0]['calculateFactor'].reduce((all, curVal) => {
        return {[curVal.factorType]: curVal.factorValue, ...all}
      }, {})
      // refactoringFun(state.showData)
      // InitializeFun(state.showData)
      // startRecord(localStorage.getItem('uuid'))
      // 清个缓存
      if(sessionStorage.getItem('processSelectList')){
        sessionStorage.setItem('processSelectList', '')
      }
    })
    onBeforeUpdate(()=>{
      if(state.showData && state.showData.length)  document.title =state.showData[0].moduleName
    })
    onMounted(() => {
      nextTick(() => {
        let contentContainer = document.getElementById('product_wrap') // 获取id为content-container的元素，作为滚动容器
        let clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
        // var previewPopHeight = document.getElementById('previewPop').clientHeight

        contentContainer.addEventListener('scroll', function() { // 为滚动容器添加滚动事件监听器
          let scrollHeight = contentContainer?.scrollHeight // 获取滚动容器的总高度（包括溢出隐藏的内容）
          let scrollTop = contentContainer?.scrollTop // 获取滚动容器已经滚动的高度
          clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
          let threshold = 40 // 设置一个阈值，当接近底部一定距离时触发回调，这里设为20px

          // 判断当前滚动位置加上可见高度是否大于等于总高度减去阈值，如果是则认为接近底部
          if (scrollTop + clientHeight + threshold >= scrollHeight) {
            state.noViewed = false
            // 当接近底部时触发的回调事件，可以在这里执行加载更多数据的逻辑
            console.log('页面已接近底部，可以加载更多数据')
          }
        })
      })
    })

    // 点击上传
    const handelHealthClick = (data) =>{
      state.clickData = data
    }
    const handelSaveProve = () =>{
      state.num ++
    }


    const uploadFileUrl = (file) =>{
      file.forEach((item, index) => {
        state.healthUploadList[index] = {}
        state.healthUploadList[index] = item
      })
    }

    const handelDelCard = (file) =>{
      console.log(file)
      if(state.healthUploadList.length === 1){
        state.healthUploadList = []
      }else{
        for (let i = 0; i < state.healthUploadList.length; i++) {
          if(state.healthUploadList[i].playUrl === file.playUrl){
            state.healthUploadList.splice(i, 1)
            return
          }
        }
      }
    }





    const updatePage = function(){
      state.tradeNo = router.currentRoute.value.query['tradeNo']
      nowPathShow(null, null, state.tradeNo, () => {
        const routerData = JSON.parse(store.getters['insured/getHealthRouterJump'])
        // 在此处添加内容
        state.showEditorContent = routerData.bottomTitleConfig
        state.configList = routerData.buttonConfigList
        state.configList.sort((a,b)=> a.modelValue - b.modelValue)
        document.title = routerData.pageJson && JSON.parse(routerData.pageJson)[0].moduleName ? JSON.parse(routerData.pageJson)[0].moduleName : routerData.name
        getHealthToldData(routerData.pageJson)
        // refactoringFun(state.showData)
        // InitializeFun(state.showData)
        // startRecord(store.getters['insured/getUuid'])
      })
    }
    watch(() =>router.currentRoute.value.query.sort,()=> {
      state.showObj = {}
      state.showData = []
      updatePage()
    },{immediate:true, deep: true })
    watch(() =>router.currentRoute.value.path,()=> {
      console.log('watch', router.currentRoute)
      state.showObj = {}
      state.showData = []
      updatePage()
    },{immediate:true, deep: true })
    function isNumber(value) {
      return Number.isFinite(value)
    }
    // 触发的值
    const PreviewPopData = function (data) {
      console.log(data, 'data111112')
      let keys = Object.keys(data)[0]
      state.showObj[keys] = Object.assign(state.showObj[keys], data[keys])
      if (state.showObj['3274804072001']['3274804072001'] && state.showObj['185332815012008']['185332815012008']) {
        let BIM = (Number(state.showObj['3274804072001']['3274804072001'][0].value) /((Number(state.showObj['185332815012008']['185332815012008'][0].value) / 100) * (Number(state.showObj['185332815012008']['185332815012008'][0].value)/100))).toFixed(1)
        if (isNumber(Number(BIM))&& BIM > 0) {
          state.BIM = BIM
        } else {
          state.BIM = '___'
        }
      }
      console.log(state.showData, 'productElementList')
      state.showData?.[0]?.productElementList?.[0]?.forEach(item => {
        // console.log(data, item, 'yyyyy')
        if  (keys === item.elementCode) {
          console.log(item, 'pppppp')
          if (item.elementOperatingMode && item.elementOperatingMode.mode_health.conSpanList.length > 0 ) {
              console.log(item.elementOperatingMode.mode_health.conSpanList, 'item.elementOperatingMode.mode_health.conSpanList')
            if (data[keys][keys] === 0) {
              item.elementOperatingMode.mode_health.conSpanList.forEach(con => {
                if (con.optionState === '1') {
                  if (con.elementList.length) {
                    con.elementList.forEach(ele => {
                      if (ele.healthType === 'radioType') {
                        console.log('55555')
                        nextTick(() => {
                          state.showObj[ele.elementCode].required = '0'
                          state.showObj[ele.elementCode][ele.elementCode] = null
                          ele.firstValue = null
                          if (ele.elementOperatingMode.mode_health.conSpanList.length > 0) {
                            ele.elementOperatingMode.mode_health.conSpanList.forEach(i => {
                              i.elementList.forEach(ite => {
                                ite.firstValue = null
                              })
                            })
                          }
                        })
                      }
                    })
                  }
                } else if (con.optionState === '0')  {
                  if (con.elementList.length) {
                    con.elementList.forEach(ele => {
                      if (ele.healthType === 'radioType') {
                        nextTick(() => {
                          state.showObj[ele.elementCode].required = '1'
                          state.showObj[ele.elementCode][ele.elementCode] = null
                          ele.firstValue = null
                          if (ele.elementOperatingMode.mode_health.conSpanList.length > 0) {
                            ele.elementOperatingMode.mode_health.conSpanList.forEach(i => {
                              i.elementList.forEach(ite => {
                                ite.firstValue = null
                              })
                            })
                          }
                        })
                        // state.showObj[keys].required = '1'
                      }
                    })
                  }
                }
              })
            }
            if (data[keys][keys] === 1) {
              item.elementOperatingMode.mode_health.conSpanList.forEach(con => {
                if (con.optionState === '0') {
                  if (con.elementList.length) {
                    con.elementList.forEach(ele => {
                      if (ele.healthType === 'radioType') {
                        console.log('55555')
                        nextTick(() => {
                          state.showObj[ele.elementCode].required = '0'
                          state.showObj[ele.elementCode][ele.elementCode] = null
                          ele.firstValue = null
                          if (ele.elementOperatingMode.mode_health.conSpanList.length > 0) {
                            ele.elementOperatingMode.mode_health.conSpanList.forEach(i => {
                              i.elementList.forEach(ite => {
                                ite.firstValue = null
                              })
                            })
                          }
                        })
                      }
                    })
                  }
                } else if (con.optionState === '1')  {
                  if (con.elementList.length) {
                    con.elementList.forEach(ele => {
                      if (ele.healthType === 'radioType') {
                        // state.showObj[keys].required = '1'
                        nextTick(() => {
                          state.showObj[ele.elementCode].required = '1'
                          state.showObj[ele.elementCode][ele.elementCode] = null
                          ele.firstValue = null
                          if (ele.elementOperatingMode.mode_health.conSpanList.length > 0) {
                            ele.elementOperatingMode.mode_health.conSpanList.forEach(i => {
                              i.elementList.forEach(ite => {
                                ite.firstValue = null
                              })
                            })
                          }
                        })
                      }
                    })
                  }
                }
              })
            }
          }
        }
      })
      // allNoFun(state.showData)

    }
    const allNo = function (item) {
      if(typeof item == 'object'){
        if(item.modelValue === '1') {
          state.visible = true
        }else if(item.modelValue === '99' || item.modelValue === '3'){
          // 下一步 / 校验
          let type = true
          if(item.mainIdentification !== null && item.mainIdentification !== '' && item.mainIdentification !== undefined){
            // Object.keys(state.showObj).forEach(key =>{
            //   if(state.showObj[key].healthType === 'radioType' && Number(state.showObj[key].required) === 1  && (state.showObj[key][key] !== Number(item.mainIdentification) || state.showObj[key][key] === '' || state.showObj[key][key] === null || state.showObj[key][key] === undefined)){
            //     type = false
            //   }
            // })
            console.log(previewPopRef, 'previewPopRef')
            const previewPopRefData = previewPopRef.value?.[0].previewData?.productElementList[0]
            console.log(state.showObj, 'state.showObj111')
            for (const key of Object.keys(state.showObj)) {
              if((state.showObj[key][key] === '' || state.showObj[key][key] === null || state.showObj[key][key] === undefined) && state.showObj[key].required === '1'){
                state.isIdentical = true
                state.detailModal = '请检查录入选项,有未填项'
                return
              }
            }
            for(let i = 0; i < previewPopRefData.length; i++) {
              if ( previewPopRefData[i].healthType === 'radioType' && (previewPopRefData[i].firstValue === '' || previewPopRefData[i].firstValue === null || previewPopRefData[i].firstValue === undefined)) {
                state.isIdentical = true
                state.detailModal = '请检查录入选项,有未填项'
                return
              }
              if (previewPopRefData[i].healthType === 'radioType' && Number(previewPopRefData[i].required) === 1 && (Number(previewPopRefData[i].firstValue) !== Number(item.mainIdentification))) {
                type = false
              }
            }
          }
          if(!type) {
            state.isIdentical = true
            state.detailModal = '很抱歉，根据您的健康告知问卷结果，您不适合购买此产品，请重新评估或选择其他产品投保'
            return
          }else{
            //全部为否
            for (let keys in state.showObj) {
              if (state.showObj[keys].healthType === 'radioType') {
                state.showObj[keys][keys] = item.mainIdentification === '1' || item.mainIdentification === '0' ? Number(item.mainIdentification) : 1
                state.showObj[keys].moduleType = 'healthNotification'
              }
            }
            nextPage('jd')
          }
        }else if(item.modelValue === '2') {
          state.underwriting = item.promptValue || state.underwriting
          state.isUnderwriting = true
        }else if(item.modelValue === '4') {
          // 1. 判断是否填写完成 2. 将数据传到后端 3. 跳转到智能核保页面
          let arr = []
          for (const key of Object.keys(state.showObj)) {
            if((state.showObj[key][key] === '' || state.showObj[key][key] === null || state.showObj[key][key] === undefined) && state.showObj[key].required === '1'){
              state.isIdentical = true
              state.detailModal = '请检查录入选项,有未填项'
              return
            }
            if(state.showObj[key][key] === '0' || state.showObj[key][key] === 0) arr.push(state.showObj[key])
          }
          if(arr.length > 0){
            nextPage('jd', 'underwritingProcess')
            router.push({
              path: '/app/underwritingProcess',
              query: {
                ...router.currentRoute.value.query,
                associationType: state.associationType
              }
            })
          }else{
            state.isIdentical = true
            state.detailModal = '未选择是, 不予跳转智能核保'
            return
          }
        }else if(item.modelValue === '5') {
          let arr = []
          for (const key of Object.keys(state.showObj)) {
            if((state.showObj[key][key] === '' || state.showObj[key][key] === null || state.showObj[key][key] === undefined) && state.showObj[key].required === '1'){
              state.isIdentical = true
              state.detailModal = '请检查录入选项,有未填项'
              return
            }
            if(state.showObj[key][key] === '0' || state.showObj[key][key] === 0) arr.push(state.showObj[key])
          }
          if(arr.length > 0){
            let newList = []
            let upList = []
            let nextType = false
            arr.forEach(item => {
              if(item.valueToChildCode && item.valueToChildCode.length > 0){
                item.valueToChildCode.forEach(va =>{
                  newList.push(va.elementList.map(ele => ele.elementCode))
                })
              }
            })
            newList = newList.flat(Infinity)
            console.log(newList, 'newList')
            for (let i = 0; i < newList.length; i++) {
              if(Array.isArray(state.showObj[newList[i]][newList[i]]) && state.showObj[newList[i]][newList[i]].length > 0){
                const newArr = state.showObj[newList[i]][newList[i]].filter(s => s.value && s.value !== '')
                if(newArr.length > 0){
                  nextType = true
                  break
                }
              }else if(state.showObj[newList[i]][newList[i]] && state.showObj[newList[i]][newList[i]] !== null){
                nextType = true
                break
              }
            }
            if(state.healthUploadList.length > 0){
              upList = state.healthUploadList
            }

            state.isIdentical = true
            if (upList.length > 0 || nextType) {
              state.detailModal = '是否进入人核'
              state.dialogType = 'person'
            }else{
              state.detailModal = '是否进入智能核保'
              state.dialogType = 'health'
            }
            console.log('准备跳转', state)

          }else{
            nextPage('jd')
          }
        }
      }else{
        //全部为否
        for (let keys in state.showObj) {
          if (state.showObj[keys].healthType === 'radioType') {
            state.showObj[keys][keys] = 1
          }
        }
      }

      //  数据处理
      allNoFun(state.showData)
    }
    // 去智能核保页面
    const toUnderwriting = () =>{
      //全部为否
      for (let keys in state.showObj) {
        if (state.showObj[keys].healthType === 'radioType') {
          state.showObj[keys][keys] = 1
          state.showObj[keys].moduleType = 'healthNotification'
        }
      }
      nextPage('jd', 'underwritingProcess')
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+ 'underwritingProcess', saveData: {select: []}}).then(res =>{
        console.debug(res)
        router.push({
          path: '/app/underwritingProcess',
          query: {
            ...router.currentRoute.value.query,
            associationType: state.associationType
          }
        })
      })
    }


    const handleOk = () => {
      state.visible = false
      window.history.back(-1)
      // useRouter().go(-1)
    }
    const modalClose = () => {
      state.visible = false

    }
    //健康告知全部为否
    const allNoFun = function (Arr = []) {
      for (let i = 0; i < Arr.length; i++) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            item.forEach(obj => {
              obj.firstValue = state.showObj[obj.elementCode][obj.elementCode]
              obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                allNoFun(item2.elementList)
              })
            })
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            Arr[i].firstValue = state.showObj[Arr[i].elementCode][Arr[i].elementCode]
            Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
              allNoFun(item2.elementList)
            })
          }
        }
      }
    }

    // 查询缓存数据 重新赋值
    const getHealthToldData = (pageJson) =>{
      if(!pageJson) return
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo+ 'healthTold'
      }).then(res =>{
        state.associationType = JSON.parse(pageJson)[0].associationType
        const list = JSON.parse(pageJson)[0].productElementList[0].filter(item => item.healthType === 'radioType')
        if(list.length && list[0].elementOperatingMode.mode_health.conSpanList.length){
          state.yesOrNo = list[0].elementOperatingMode.mode_health.conSpanList[1].label
        }
        if(res.code === 1 && res.result){
          console.log(JSON.parse(pageJson), 'JSON.parse(pageJson)')
          state.showData = JSON.parse(pageJson)

          res.result.selectRisk.showData.forEach(item =>{
            const findIndex = state.showData.findIndex(child => child.id === item.id)
            if(findIndex !== -1){
              item.productElementList.forEach(pro =>{
                pro.forEach(pc =>{
                  if(pc.healthType === 'inputType'){
                    const arr = res.result.selectRisk.submitData.submitRecordList.filter(s => s.submitKey ===pc.elementCode)
                    if (arr.length){
                      arr[0].submitValueObj.forEach(a => {
                        const pIndex = pc.firstValue.findIndex(p => p.mPropKey === a.key)
                        if(pIndex !== -1)  pc.firstValue[pIndex].value = a.value
                      })
                    }
                  }
                })
              })
              state.showData[findIndex] = item
            }
          })
          console.log(state.showData, 'state.showData1111111')
          refactoringFun(state.showData)
          InitializeFun(state.showData)
          Object.keys(state.showObj).forEach(key =>{
            const arr = res.result.selectRisk.submitData.submitRecordList.filter(item => item.submitKey === key)
            if(arr.length) {
              console.log(arr[0], 'arr[0]arr[0]')
              state.showObj[key].titleStr = arr[0].titleStr
              state.showObj[key][key] = arr[0].submitValueObj
            }
          })
          // state.healthRemarks = res.result.selectRisk.remarkDetail.healthRemarks
          state.healthUploadList = res.result.selectRisk.remarkDetail.healthUploadList
        }else{
          state.showData = JSON.parse(pageJson)
          refactoringFun(state.showData)
          InitializeFun(state.showData)
        }

        // Object.keys(state.showObj).forEach(key =>{
        //   const arr = res.result.selectRisk.submitData.submitRecordList.filter(item => item.submitKey === key)
        //   if(arr.length) state.showObj[key][key] = arr[0].submitValueObj
        // })
        nextTick(() => {
          let contentContainer = document.getElementById('product_wrap') // 获取id为content-container的元素，作为滚动容器
          let clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
          let previewPopHeight = document.getElementById('previewPop')?.clientHeight

          if (previewPopHeight <  clientHeight) {
            console.log(previewPopHeight, clientHeight)
            state.noViewed = false
          }
        })
      })
    }
    const InitializeFun = function (eleList = [], moduleType = '', parentId = '') {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].productElementList) {
          eleList[i].productElementList.forEach(item => {
            item.forEach(obj => {
              //如果是第一层级的数据key,并且还有下一层，添加obj
              if (obj.elementOperatingMode) {
                state.showObj[obj.elementCode] = {
                  [obj.elementCode]: null,
                  required: obj.required ?? (obj.healthType === 'radioType' ? '1' : '0'),
                  elementParentId: parentId,
                  elementId: obj.id,
                  titleStr: obj.titleStr,
                  healthType: obj.healthType,
                  moduleType: obj.moduleType,
                  valueToChildCode: []
                }
                obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  if (item2.elementList && item2.elementList.length > 0) {
                    state.showObj[obj.elementCode]['valueToChildCode'].push({
                      value: item2['value'],
                      elementList: item2.elementList
                    })
                  }
                  InitializeFun(item2.elementList, obj.moduleType, obj.id)
                })
              }
            })
          })
        } else if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
          state.showObj[eleList[i].elementCode] = {
            required: eleList[i].required,
            elementParentId: parentId,
            elementId: eleList[i].id,
            titleStr: eleList[i].titleStr,
            moduleType: moduleType,
            [eleList[i].elementCode]: null,
            valueToChildCode: []
          }
          eleList[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
            if (item2.elementList && item2.elementList.length > 0) {
              state.showObj[eleList[i].elementCode]['valueToChildCode'].push({
                value: item2['value'],
                elementList: item2.elementList
              })
            }
            InitializeFun(item2.elementList, moduleType, eleList[i].id)
          })
        }
      }
    }
    //初始化加校验处理数据
    const refactoringFun = function (Arr = []) {
      for (let i = Arr.length - 1; i >= 0; i--) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            for (let j = item.length - 1; j >= 0; j--) {
              let expressionValue = item[j].expressionValue
              if (expressionValue) {
                for (let objKey in state.riskObj) {
                  if (expressionValue.indexOf(objKey) != -1) {
                    //  匹配成功
                    let str = '/' + objKey + '/g'
                    expressionValue = expressionValue.replace(eval(str), state.riskObj[objKey])
                  }
                }
                //  替换完成做校验
                if (!eval(expressionValue)) {
                  item.splice(j, 1)
                } else {
                  item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                    refactoringFun(item2.elementList)
                  })
                }
              } else {
                item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            }
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            let expressionValue = Arr[i].expressionValue
            if (expressionValue) {
              for (let objKey in state.riskObj) {
                if (expressionValue.indexOf(objKey) != -1) {
                  //  匹配成功
                  let str = '/' + objKey + '/g'
                  expressionValue = expressionValue.replace(eval(str), state.riskObj[objKey])
                }
              }
              //  替换完成做校验
              if (!eval(expressionValue)) {
                Arr.splice(i, 1)
              } else {
                Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            } else {
              Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                refactoringFun(item2.elementList)
              })
            }

          }
        }
      }
    }

    const cleanChildrenCode = (eleList = [], saveObj = {}) => {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) :
              false))) {
          if(saveObj[eleList[i].elementCode]){
            saveObj[eleList[i].elementCode]['valueToChildCode'].forEach(item => {
              cleanChildrenCode(item.elementList, saveObj)
              item.elementList.forEach(element => delete saveObj[element.elementCode])
            })
          }

        }
      }
    }
    const nextPage = function (type, value='') {
      let nextObj = {}
      for (let key in state.showObj) {
        let elementParentId = state.showObj[key].elementParentId
        let first = state.showObj[key].elementParentId === ''
        if (first) {
          //first为true,一级选项,直接添加
          nextObj[key] = state.showObj[key]
        } else {
          //二级选线需判断是否选中的是当前二级数据的一级数据
          for (let keyA in state.showObj) {
            let elementId = state.showObj[keyA].elementId
            if (elementId === elementParentId) {
              //如果父节点有值，说明存在
              if (state.showObj[keyA][keyA] !== '' && state.showObj[keyA][keyA] !== undefined && state.showObj[keyA][keyA] !== null) {
                nextObj[key] = state.showObj[key]
              }
            }
          }
        }
      }
      console.debug('重构的值',nextObj )
      for (let key in nextObj) {
        console.log(nextObj, 'ppppooooo')
        if (nextObj[key]) {
          if ((nextObj[key][key] === null || nextObj[key][key] === undefined || nextObj[key][key] === '') && nextObj[key].required==='1') {
            if(type === 'jd' && !state.configList.length) return
            state.isIdentical = true
            state.detailModal = '请检查录入选项,有未填项'
            return false
          }else if(Array.isArray(nextObj[key][key]) && nextObj[key].required==='1'){
            for(let i = 0; i < nextObj[key][key].length; i++){
              if(!nextObj[key][key][i].value){
                state.isIdentical = true
                state.detailModal = '请输入' + nextObj[key].titleStr
                return false
              }
            }
          }
          else {
            // TODO 非空值对应的其他校验
            // 删除不需要记录的问题
            nextObj[key]['valueToChildCode'].filter(item => item.value !== nextObj[key][key]).forEach(item => {
              cleanChildrenCode(item.elementList, nextObj)
              item.elementList.forEach(element => delete nextObj[element.elementCode])
            })
          }
        }
      }
      //将组建返回数据组合成接口传参
      let arr = []
      let arr1 = []
      console.log(nextObj, 'nextObj')
      for (let key in nextObj) {
        arr.push({
          moduleId: nextObj[key].moduleType,
          elementId: nextObj[key].elementId,
          submitKey: key,
          titleStr: nextObj[key].titleStr,
          submitValueObj: reconsitution(nextObj[key], key),
          elementParentId: nextObj[key].elementParentId
        })
        arr1.push({
          moduleId: nextObj[key].moduleType,
          elementId: nextObj[key].elementId,
          submitKey: key,
          titleStr: nextObj[key].titleStr,
          submitValueObj: nextObj[key][key],
          elementParentId: nextObj[key].elementParentId
        })
      }
      arr.forEach(item => {
        if (item.submitKey === 'NB035-1-B-1' && item.submitValueObj?.length > 0) {
          // item.submitValueObj.forEach(ele => {
          //   ele.key = 'NB035-1_1-1'
          // })
          // item.submitKey = 'NB035-1_1' 
          arr.forEach(ele => {
            if(ele.submitKey === 'NB035-1_1') {
              ele.submitValueObj = item.submitValueObj
            }
            if (ele.submitKey === 'NB035-1-B') {
              item.elementParentId = ele.elementParentId
              ele.submitValueObj = item.submitValueObj
            }
          })
          // item.submitKey = 'NB035-1_1'
        }
        if (item.submitKey === 'NB035-1-B' && item.submitValueObj === 0) {
          arr.forEach(ele => {
            if(ele.submitKey === 'NB035-1') {
              ele.submitValueObj = 0
            }
          })
        }
      })
      let arr3 = deepClone(arr)
      arr3.forEach((item, index) => {
        if (item.submitKey === 'NB035-1-B' && item.submitValueObj === 1) {
          arr3.splice(index, 1)
        }
      })
      // const routerData = JSON.parse(sessionStorage.getItem(`routerJump${state.tradeNo}`))
      const routerData = JSON.parse(store.getters['insured/getHealthRouterJump'])
      let submitData = {
        // 'productId': sessionStorage.getItem('productId'),
        'productId': store.getters['insured/getProductId'],
        'tradeNo': state.tradeNo,
        'sortCode': routerData.code,
        'sort': routerData.sort,
        'submitRecordList': arr3,
        'associationType': state.associationType
      }
      let submitData1 = {
        // 'productId': sessionStorage.getItem('productId'),
        'productId': store.getters['insured/getProductId'],
        'tradeNo': state.tradeNo,
        'sortCode': routerData.code,
        'sort': routerData.sort,
        'submitRecordList': arr1,
        'associationType': state.associationType
      }
      state.loading = true
      console.log('999999')
      // 提交数据
      submitHealth(submitData, submitData1, value)
    }
    const reconsitution = (obj, key) => {
      if (obj.titleStr?.includes('___') && obj.titleStr !== '___' && obj[key]?.length > 0) {
        let str = obj.titleStr
        console.log(str.replace('___', '222'), 'errerer')
        for (let i = 0; i < obj[key].length; i++) {
          str = str.replace('___', obj[key][i].value)
          console.log(str, obj[key][i].value, 'obj[key][i].value')
        }
        return [{key: obj[key][0].key, value: str, configType: obj[key][0].configType, titleStr: obj.titleStr}]
      } else {
        return obj[key]
      }
      // return obj[key]
    }
    const submitHealth = async (submitData, submitData1, value) => {
      console.log(submitData, 'submitData8888')
      await request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: state.tradeNo+'healthTold', selectRisk: {showData: state.showData, submitData: submitData1, remarkDetail: { healthRemarks: '', healthUploadList: state.healthUploadList}}})
      request(getExtendUri('record'), submitData).then(res => {
        state.loading = false
        if (res.code === 1) {
          console.debug('接口响应成功', res)
          state.routerFlag = true
          if(!value) routerJump(state.tradeNo, 'health')
        } else {
          message['error'](res.message)
        }
      })
    }
    // 弹窗
    const insuranceErrorHandleOk = () => {
      console.log('确定',state.dialogType, state)
      if (state.dialogType === 'person') {
        let imageList = []
        console.log(imageList, state.healthUploadList, '9999999p')
        if(state.healthUploadList.length > 0){
          imageList = state.healthUploadList.map(item => {
            return {url: item.playUrl}
          })
        }
        request(getExtendUri('onSavePerson'), {
          tradeNo: state.tradeNo,
          uwStatus: '1',
          remark: '',
          imageList
        }).then(res =>{
          if (res.code === 1) {
            console.log(res.code, 'res.coderes.code')
            nextPage()
            state.isIdentical = false
          }else{
            state.isIdentical = true
            state.detailModal = res.message
          }
        })
      }else if (state.dialogType === 'health') {
        state.routerFlag = true
        nextPage('jd', 'underwritingProcess')
        router.push({
          path: '/app/underwritingProcess',
          query: {
            ...router.currentRoute.value.query,
            associationType: state.associationType
          }
        })
      }else{
        state.detailModal = ''
        state.isIdentical = false
      }
    }
    // 弹窗取消
    const insuranceErrorHandleOff = () => {
      if (state.dialogType === 'health') {
        state.detailModal = '是否进入人核'
        state.dialogType = 'person'
      }else{
        state.dialogType = ''
        state.isIdentical = false
      }
    }

    watch(state.showObj, () =>{
      if (JSON.stringify(state.showObj) !== '{}') {
        let arr = []
        for (const key of Object.keys(state.showObj)) {
          if(state.showObj[key][key] === '0' || state.showObj[key][key] === 0) arr.push(state.showObj[key])
        }
        if(arr.length > 0){
          state.dialogShow = true
        }else{
          state.healthUploadList = []
          state.dialogType = ''
          // state.healthRemarks = ''
          state.dialogShow = false
        }
      }
    }, {immediate: true, deep: true})


    const changeShowBMI = (val) => {
      state.BMITipsvisible = val
    }
    const backMethod = () => {
      const processDetailsList = store.getters['insured/getProcessDetailsList']
      const currentPcrocessNode = store.getters['insured/getCurrentProcessNode']
      processDetailsList.forEach((item,index) => {
        if (item.id === currentPcrocessNode.id && index !== 0) {
          router.push({
            path: '/app' + showArr[processDetailsList[index - 1].code].value
          })
        } else if (item.id === currentPcrocessNode.id && index === 0) {
          router.push({
            path: '/app/detail',
            query: {
              shareId: router.currentRoute.value.query.shareId
            }
          })
        }
      })
    }
    return {
      backMethod,
      changeShowBMI,
      insuranceErrorHandleOk,
      insuranceErrorHandleOff,
      previewPopRef,
      ...toRefs(state),
      nextPage,
      PreviewPopData,
      allNo,
      handleOk,
      modalClose,
      toUnderwriting,
      uploadFileUrl,
      handelDelCard,
      handelHealthClick,
      handelSaveProve
    }
  },
  async beforeRouteLeave (to, from) {
    console.log(to, from, 'to-from')
    if (to.path !== '/app/detail' && this.routerFlag === false) {
      this.backMethod()
    }
  }
})
</script>
