/**
 * 电子签名js
 */

/**
 * 假设签名所需的json对象
 */
export const demoJson2 = () => {
  return {
    'riskNotice':null,
    'showInsuranceAuth':false,
    'signImage64':null,
    'customName':'王俊',
    'signComposeSource': 1,
    'attachList':[
    {
      'attachType':'9',
      'attributive':'1',
      'attachName':'百万爱驾免责条款',
      'uuid':'f3d17b3f63c542aaad797765d571ed3f',
      url: 'https://product-15a5.obs.cn-north-4.myhuaweicloud.com/upload/common/product/2023/03/21/7f9a8209_7ff1_4439_8613_c9c5518bfe43/7、【信息披露】信息披露.pdf',
      'customCode':null
    },
    {
      'attachType':'8',
      'attributive':'1',
      'attachName':'德华安顾投保提示书',
      'uuid':'a9f84aab9c1045b4807b9c95ae33abdb',
      url: 'https://product-15a5.obs.cn-north-4.myhuaweicloud.com/upload/common/product/2023/03/17/f82f68b1_cc9e_4251_a3ad_f8471f0bf10d/6、【保险条款】百万玫瑰条款.pdf',
      'customCode':null
    },
    {
      'attachType':'13',
      'attributive':'1',
      'attachName':'德华安顾电子投保单',
      'uuid':'c031a9bdd3504a7bb5f8415b9efecfc5',
      url: 'https://product-15a5.obs.cn-north-4.myhuaweicloud.com/upload/common/product/2023/03/17/b7483a77_991e_4d13_9af3_fbc95bd10e78/9、【其他】6、【被保险人同意声明】被保险人同意声明.pdf',
      'customCode':null
    }
  ],
    'riskStatus':'0',
    'signDate':'2023-03-28'
  }
}

export const showSigeRisk = (signData) => {
  if (signData.riskStatus == 2) return
  window.location.href = '../anysign/signRisk.html' + window.location.search
}

/**
 * 创建远程链接
 * @param obj
 */
export const createShareUrls = (obj, tradeNo, shareId, shareObj, toSign) => {
  console.debug('debug：链接参数-->', window.location.search)
  console.debug('debug：obj参数-->', JSON.stringify(obj))
  console.debug('debug：shareObj参数-->', JSON.stringify(shareObj))
  if (!obj.signProvider) {
    obj.signProvider = 1
  }
  if (!obj.signMethod) {
    obj.signMethod = 3
  }
  if (obj && obj.autographDetailList && obj.autographDetailList.length > 0) {
    for (let i = 0; i < obj.autographDetailList.length; i++) {
      let item = obj.autographDetailList[i]
      if (item.signed === '0') {
        item.signed = false
      } else {
        item.signed = true
      }
      // if (!item.signMethod) {
      //   item.signMethod = 1
      // }
      // 这个是拼装远程签名的数据
      // 这里有可能签完名去确认页，有可能签完名去签名页，所以加个字段，目前统一是确认页，所以一定走else
      if (toSign) {
        item.remoteShareUrl = `${window.location.origin}/product/app/elecSignature?tradeNo=${tradeNo}&shareId=${shareId}&customCode=${item.customCode}&attributive=${item.attributive}&holderInsuredSame=${item.holderInsuredSame}&signProvider=${obj.signProvider}&userFlag=true`
      } else {
        item.remoteShareUrl = `${window.location.href}&customCode=${item.customCode}&attributive=${item.attributive}&holderInsuredSame=${item.holderInsuredSame}&signProvider=${obj.signProvider}&userFlag=true`
      }
      // 这两个是针对保司签名情况下的直接发短信问题
      item.buttonTextStr = '获取短信'
      item.countdown = 0
      item.signProvider = obj.signProvider
    }
  }
  if (shareObj && shareObj.customCode) {
    obj.autographDetailList = obj.autographDetailList.filter(item => item.customCode === shareObj.customCode)
  }
  return obj
}
//
// let DATA_CANNOT_PARSED = '10003' //输入数据项无法解析
// let SERVICE_SYSTEM_EXCEPTION = '10011' //服务系统异常错误
// let RECOGNITION_RESULT_EMPTY = '10100' //识别结果为空
// let CONNECTION_SERVICE_TIMEOUT = '10101' //连接识别服务超时
// let CONNECTION_RECOGNITION_EXCEPTION = '10102' //连接识别服务异常
// let SUCCESS = '0' //识别成功
// let RECOGNITION_FALSE = '-1'//识别错误
// let RESULT_OK = 0 //操作成功
// let CALLBACK_TYPE_SIGNATURE = 10 //签名框点击确认之后的回调，回调中包含签名快照
// let CALLBACK_TYPE_DIALOG_CANCEL = 11 //点击签名框"取消"按钮时的回调，同时也会触发dismiss回调
// let CALLBACK_TYPE_COMMENTSIGN = 12 //批注签名框点击确认之后的回调，回调中包含签名快照
// let CALLBACK_TYPE_GETVERSION = 13 //获得版本号
//
// let RESULT_ERROR = -1 //操作失败
// let EC_API_NOT_INITED = 1 //接口未初始化错误
// //let CALLBACK_TYPE_START_RECORDING = 14;
// //let CALLBACK_TYPE_STOP_RECORDING = 15;
//
// /**
//  * 签名入口--初始化
//  * @param channel
//  */
// export const initSignSdk = (channel) => {
//   //demo总入口
//     let res
//     //识别回调接口
//     let identify_callback = function(errCode){
//       if(errCode == SUCCESS){
//         return
//       }
//       if(errCode == DATA_CANNOT_PARSED) {
//         alert('输入数据项无法解析！')
//       } else if(errCode == SERVICE_SYSTEM_EXCEPTION) {
//         alert('服务系统异常错误！')
//       } else if(errCode == RECOGNITION_RESULT_EMPTY) {
//         alert('识别结果为空！')
//       } else if(errCode == CONNECTION_SERVICE_TIMEOUT) {
//         alert('连接识别服务超时！')
//       } else if(errCode == CONNECTION_RECOGNITION_EXCEPTION) {
//         alert('连接识别服务异常！')
//       } else if(errCode == RECOGNITION_FALSE) {
//         alert('书写错误！')
//       } else {
//         alert(errCode)
//       }
//     }
//
//     let callback = function(context_id,context_type,val){
//       document.getElementById('other').style.display = 'block'
//       if(context_type == CALLBACK_TYPE_SIGNATURE){
//         //签名回显
//         document.getElementById('xss_20').src = 'data:image/png;base64,' + val
//         let aImg=document.getElementById('xss_20')
//         for(let i=0;i<aImg.length;i++){
//           aImg[i].style.height='1500'
//           aImg[i].style.width='1500'
//         }
//       }else if(context_type == CALLBACK_TYPE_COMMENTSIGN){
//         //签名回显
//         document.getElementById('xss_21').src = 'data:image/png;base64,' + val
//         let aImg=document.getElementById('xss_21')
//         for(let i=0;i<aImg.length;i++){
//           aImg[i].style.height='250'
//           aImg[i].style.width='250'
//         }
//       }
//       setAlertTitle()
//       alert('收到浏览器回调：' + 'context_id：' + context_id + ' context_type：' + context_type + ' value：' + val)
//     }//测试回调，将回调数据显示
//
//     //设置签名算法，默认为RSA，可以设置成SM2
//     EncAlgType.EncAlg = 'SM2'
//
//     apiInstance = new AnySignApi()
//     //let channel = "10010";//渠道号，由信手书提供，请咨询项目经理
//     //初始化签名接口
//     res = apiInstance.initAnySignApi(callback,channel)
//
//     if(!res){
//       alert('init error')
//     }else{
//
//     }
//     ////////////////////////////////////////////////
//
//     //开启识别
//     ocrCapture = new OCRCapture()
//     ocrCapture.text = 'a'
//     ocrCapture.IPAdress = 'http://223.70.139.221:11204/HWRV2/RecvServlet'
//     // ocrCapture.IPAdress = "http://192.168.126.32:11204/HWRV2/RecvServlet";
//     ocrCapture.appID = '123'
//     ocrCapture.count = 5
//     ocrCapture.language = Language.CHS
//     ocrCapture.resolution = 80
//     ocrCapture.serviceID = '999999'
//
//     setIdentifyCallBack(identify_callback)
//
//     ///////////////////////////////////////////////
//
//     //注册单字签字对象20
//     res = testAddSignatureObj(20)
//     if(!res){
//       alert('testAddSignatureObj error')
//       return
//     }else{
//
//     }
//
//     res = testAddSignatureObj(21)
//     if(!res){
//       alert('testAddSignatureObj error')
//       return
//     }else{
//
//     }
//
//     res = testAddCommentObj(30)
//     if(!res){
//       alert('testAddCommentObj error')
//       return
//     }else{
//
//     }
//
//     res = testAddCommentObj(31)
//     if(!res){
//       alert('testAddCommentObj error')
//       return
//     }else{
//
//     }
//
//     ////////////////////////////////////////////////
//
//     //注册一个单位签章
//
//     let signer = new Signer('小明','110xxxxxx')
//     /**
//      * 使用服务器规则配置签名
//      * @param tid 服务器端生成的配置规则
//      * @constructor
//      */
//     /*let signerRule = new SignRule_Tid("111");
//     let cachet_config = new CachetConfig(signer, signerRule, false);
//
//     res = apiInstance.addCachetObj(cachet_config);
//
//     if(!res){
//        alert("addCachetObj error");
//     }else{
//
//     }*/
//
//     //将配置提交
//     res = apiInstance.commitConfig()
//
//     if(res){
//       alert('Init ALL 提交配置成功')
//     }else{
//       alert('Init ALL 提交配置失败')
//     }
// }
