export class ExpandableList {
  constructor(container, items) {
    this.container = container
    this.items = items
    this.render()
    this.bindEvents()
  }

  render() {
    const list = document.createElement('ul')
    list.classList.add('expandable-list')
    this.items.forEach(item => {
      const listItem = document.createElement('li')
      const title = document.createElement('h3')
      title.textContent = item.title
      title.classList.add('expandable-list__title')
      listItem.appendChild(title)
      const content = document.createElement('div')
      content.innerHTML = item.content
      content.classList.add('expandable-list__content')
      listItem.appendChild(content)
      list.appendChild(listItem)
    })
    this.container.appendChild(list)
  }

  bindEvents() {
    const titles = this.container.querySelectorAll('.expandable-list__title')
    titles.forEach(title => {
      title.addEventListener('click', () => {
        title.parentElement.classList.toggle('is-expanded')
      })
    })
  }
}

// const container = document.querySelector('.expandable-list-container');
// const items = [
//   { title: 'Item 1', content: '<p>Content for item 1</p>' },
//   { title: 'Item 2', content: '<p>Content for item 2</p>' },
//   { title: 'Item 3', content: '<p>Content for item 3</p>' }
// ];
// const expandableList = new ExpandableList(container, items);
