<template>
<!--  延时点击按钮-->
<!--  <a-button type="primary" :disabled="countdown_state" @click="confirmedEvent">{{btn_label}}</a-button>-->
  <a-button type="primary" :class="countdown_state?'btn-already':'btn-ready'" @click="confirmedEvent">{{btn_label}}</a-button>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'

export default {
  name: 'readClickBtn',
  props: {
    btnLabel: {
      type: String,
      default: ()=> '确认'
    },
    seconds: {
      type: Number,
      default: ()=> 0
    }
  },
  setup(props, {emit}){
    const state = reactive({
      time_second: props.seconds,
      btn_label: props.btnLabel,
      countdown_state: false,
      timer: null
    })
    /**
     * 开启一个计时器，用于倒计时
     */
    const sendCode = () => {
        state.timer=setInterval(() => {
          if (state.time_second === 1) {
            closeTimer()
          } else {
            loading()
            changeLabel()
          }
        },1000)
    }
    /**
     * --
     * @returns {number}
     */
    const loading = () => {
      return state.time_second--
    }
    /**
     * 点击事件
     */
    const confirmedEvent = () => {
      if (state.countdown_state === false) {
        emit('sureCLick', true)
      } else {
        console.log('11')
      }
    }

    const changeLabel = (val) => {
      state.btn_label = val===0?`${props.btnLabel}`:`${props.btnLabel}${state.time_second}s`
    }
    /**
     * 关闭计时器
     */
    const closeTimer = () => {
      clearInterval(state.timer)
      state.timer = null
      changeLabel(0)
      state.countdown_state = false
    }

    onMounted(()=> {
      if (state.time_second !== 0) {
        state.countdown_state = true
        changeLabel()
        sendCode()
      } else {
        changeLabel(0)
        state.countdown_state = false
      }
    })

    return {
      ...toRefs(state),
      confirmedEvent
    }
  }
}
</script>

<style scoped>
.btn-ready{
  background-color: #0079FE;
  border: none
}
.btn-already {
  background-color: #93C3FC;
  border: none;
}
</style>
