<template>
<div></div>
</template>

<script>
export default {
  name: 'faceCallBackUrl',
  setup(){

  }
}
</script>

<style scoped>

</style>
