<script>
// import IOSbackBar from '@/components/extended/IOSbackBar.vue'

export default {
  name: 'MoneyInfoCorfimPage',
  // components: {IOSbackBar}
}
</script>

<template>
<!--  加到该组件调用的页面去-->
<!--  <IOSbackBar :title="'信息确认页'" :backMethod="closePop" ></IOSbackBar>-->
  <div class="money-info-corfim">
    确认页信息

  </div>
</template>

<style scoped>
.money-info-corfim{

}

</style>
