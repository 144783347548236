/**
 * 换个写法试试
 * 投保要素bean
 */
//定义Symbol，用于内部私有变量,Symbol的作用--查资料
const privatePro = Symbol('privatePro')

export class InsuranceElementBean {
  // 属性


  /**
   * 构造函数
   * ps: 如果不写，会有一个默认的constructor()
   */
  constructor(eleId,eleLabel,eleShowType, abc) {
    // public 变量
    this.eleId = eleId
    this.eleLabel = eleLabel
    this.eleShowType = eleShowType
    // private 变量
    this[privatePro] = abc
  }



  toString() {
    return '(' + this.eleId + ',' + this.eleLabel + this.eleShowType + ')'
  }

}
