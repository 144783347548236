<style scoped>

.flexTop{
  position: fixed;
  top: 0;
  z-index:99;
  width: 100%;
}
.flexBox{
  width: 100%;
  height: 148px;
}
.tabCardClass {
  /*text-align: center;*/
  /*width: 300px;*/
  text-align: center;
  width: auto;
  margin: 0 auto;
}
.listContent {
  padding: 8px 25px;
  background-color: #fff;
}

.tabSpan {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  border: 1px solid #e2d8d8;
}
:deep(.van-tabs--card>.van-tabs__wrap){
  margin: 8px;
}
:deep(.van-tabs__nav--card){
  border-radius: v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border: v-bind(borderWidth) solid v-bind(tabSeletColor);
}
:deep(.van-tab--card) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border-right: 0px;
}
:deep(.van-tab--card.active) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
}
:deep(.van-tab--card:last-child) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: v-bind(tabSeletColor);
  color: v-bind(tabNoSeletColor);
}
.activeBlue{
  color: #1989fa;
  border: 1px solid #1989fa;
}
:deep(.van-dropdown-menu__title--active) {
  color: #1989fa;
}

</style>


<template>
  <div class="boxApp2">
  <div class="flexTop">
    <van-tabs v-model:active="LevelTop" type="card" color="#318dff" class="tabCardClass" @click-tab="onClickTabFirst">
      <!-- 第一层标签  -->
      <van-tab title="线上订单"></van-tab>
      <van-tab title="线下订单"></van-tab>
    </van-tabs>

    <div class="policyListConent" >
      <div class="onlineDiv" v-if="LevelTop === 0">
      <!-- 待支付/待完成... -->
        <van-tabs v-model:active="LevelFirst" @click-tab="onClickTabSecond">
          <van-tab v-for="(item,index) in listFirst" :key="index" :title="item.label"></van-tab>
        </van-tabs>
        <!-- 下拉选择 -->
        <div class="pullTabs">
          <order-dropdown-menu 
          :orderOption="orderOption" 
          :orderList="orderList" 
          :listNext="listNext"
          :isShow="isShow"
          @secondSpanClick="secondSpanClick"
          @onConfirm="onConfirm"
          ></order-dropdown-menu>
        </div>
        <!-- 列表内容 -->
        <OrderListRefresh
        :refreshing="refreshing"
        :loading="loading"
        @pullOnRefresh="pullOnRefresh"
        :listShow="listShow"
        :finished="finished"
        @nextJump="nextJump"
        ></OrderListRefresh>
        
      </div>
      <div class="offlineDiv" v-if="LevelTop === 1">
        <h2>这是线下订单页面</h2>
          <!-- 在这里添加线下订单页面的内容 -->
      </div>
    </div>

  </div>
    
  </div>
</template>

<script>
import {defineComponent, ref, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import { request} from '../../../utils/request'
import {getExtendUri, isOnlyPayUrl} from '../../../utils/request_extend'
import { List, Tab, Tabs, Icon, PullRefresh, DropdownMenu, DropdownItem, Button, Space, Tabbar, TabbarItem, DatetimePicker, Popup} from 'vant'
import { message } from 'ant-design-vue'
import store from '../../../store'
import { nowPathShow } from '../../../utils/product_process'
import OrderDropdownMenu from './component/OrderDropdownMenu.vue'
import OrderListRefresh from './component/OrderListRefresh'

export default defineComponent({
  components: {
    OrderDropdownMenu,
    OrderListRefresh,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Space.name]: Space,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  },
  setup() {
    const state = reactive({
      isShow: 0,
      productId: '',
      payObj: {
        payStateResult: '',
        payUrl: '',
        payKey: '',
        payJson: {},
        payState: false,
        supId: '',
        temp: ''
      },
      radiusParam: '15px',
      // 选中背景和未选中文字
      tabSeletColor: '#318dff',
      // 未选中背景和选中文字
      tabNoSeletColor: '#ffffff',
      borderWidth: '1px',
      onLineTabs: [
        {title: '未完成', name: 'uncompleted'},
        {title: '待支付', name: 'unpaid'},
        {title: '已支付', name: 'paid'},
        {title: '全部订单', name: 'all'},
      ],
      orderList: []
    })
    
    const LevelTop = ref(0)
    const showPopover = ref(false)
    const timerPickerShow = ref(false)
    const timeTabTitle = ref('全部时间')  // 全部时间/自定义时间
    const buttonType = ref('default')  // 按钮样式
    const activeTabBar = ref('')
    const orderOption = ref([])
    const orderValue = ref('')
    const router = useRouter()
    const listFirst = ref([])
    const listNext = ref([])
    const listShow = ref([])
    const lock = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const LevelFirst = ref(0)
    const LevelNext = ref(0)
    const pageNum = ref(1)
    const prtStatusType = ref('')
    const prtStatus = ref('')
    const total = ref(0)

    const showPopup = event => {
      event
      timerPickerShow.value = true
    }
    // 通过 actions 属性来定义菜单选项
    const actions = [
      { text: '选项一' },
      { text: '选项二' },
      { text: '选项三' },
    ]
    const onSelect = (action) => console.log(action.text)
    const onClickTabFirst = function (item) {
      console.log(item)
    }
    const onClickTabSecond = function(event){
      finished.value = false
      pageNum.value = 1
      listNext.value = listFirst.value[event.name].children
      prtStatusType.value = listFirst.value[event.name].value
      prtStatus.value = listNext.value[0].value
      listShow.value = []
      selectList()
    }
    const onClickTabNext = function(value){
      pageNum.value = 1
      finished.value = false
      listShow.value = []
      prtStatus.value = listNext.value[value.name].value
      selectList()
    }

    const onRefresh = () => {
      pageNum.value ++
      selectList()
    }

    const pullOnRefresh = () => {
      pageNum.value = 1
      selectList()
    }
    // 调接口查询订单列表
    const selectList = function() {
      if(!finished.value&&!lock.value){
        loading.value = true
        lock.value = true
        request(getExtendUri('postQueryListForPage'), {data: {agentCode: '',prtStatusType: prtStatusType.value, prtStatus:prtStatus.value}, pagination: {current: pageNum.value, pageSize: 5}}).then(res => {
          total.value = res.pagination.total
          lock.value = false
          if(listShow.value.length>=total.value){
            loading.value = false
            finished.value = true
            refreshing.value = false
          }else{
            finished.value = false
            res.result.forEach(item=>{
              listShow.value.push(item)
            })
            loading.value = false
            refreshing.value = false
          }
        })
      }
    }
     // 点击事件
    const onConfirm = function(listDropdownItem){
      pageNum.value = 1
      finished.value = false
      listShow.value = []
      console.log(' state.isShow', state.isShow)
      prtStatus.value = state.temp
      selectList()
      listDropdownItem.value.toggle()
    }
    // 第二层标签点击后的事件 todo
    const secondSpanClick = function (data) {
      console.log(data)
      state.isShow = data.index
      state.temp = data.item.value
    }
     // 获取并保存productDetailData，需要用到productDetailData.productInfo.productId
    const saveProductInfo = (item) => {
      request(getExtendUri('getLinkData'), {
        data: {
          id: item.shareId
        }
      }).then(res => {
        if (res.code === 1 && res.result) {
          console.log('productDetailData:', res.result)
          // 将详情信息存入
          store.dispatch('insured/changeInsureJSInfo', { productDetailData: JSON.stringify(res.result) })
          store.dispatch('insured/changeInsureJSInfo', { productId: res.result.productInfo.productId })
          // console.log('risklist', res.result.productInfo.riskList)
          let productDetailInfo = res.result
          state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''
          
          getNodeState(item)
        }else if (res.code === 300) {
          window.location.assign(res.result)
        } else {
          message['error'](res.message)
        }
      })
    
    }
    // 根据productId判断到哪个节点
    const getNodeState = (item) => {
      request(getExtendUri('getProcessByProductId'), {
          data: {
            productId: state.productId
          }
      }).then(res => {
          // 先将获取到的node节点存入缓存
        let nodeDetailData = res.result
        // 遍历获取到的node节点
        nodeDetailData.forEach(nodeItem => {
          // for (const key in showArr) {
            // 'acknowledgementOrder'
            if (nodeItem.nextCode) {
              if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
                console.log('匹配成功:', nodeItem.nextCode)
                console.log('sort:', nodeItem.sort)
                nowPathShow(nodeItem.processId, nodeItem.sort, state.tradeNo)  // todo
                // testRiskListFun(item.tradeNo)
                getRiskListFun(item.tradeNo)
                router.push({
                  path: '/app/confirmation',
                  query: {
                      tradeNo: item.tradeNo,
                      shareId: item.shareId,
                      processId: nodeItem.processId
                    }
                }) 
              }
            }
            // }
        })   
      })
    }
    
    // const testRiskListFun = (tradeNo) => {
    //   request(getExtendUri('cacheParameters'), {
    //       tradeNo: tradeNo,
    //       saveData: {
    //         'a': '123'
    //       },
    //       selectRisk: {
    //         'b': '123'
    //       }

    //   }).then(res => {
    //     console.log('cacheParameters:', res.result)
    //   })
    // }
    // 根据接口获取riskLisk数据存入缓存
    const getRiskListFun = (tradeNo) => {
      request(getExtendUri('getCacheParameters'), {
          tradeNo: tradeNo
      }).then(res => {
        console.log('getCacheParameters:', res.result)
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })  
        store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})      
      })
    }
    // 根据订单状态跳转不同的页面
    const nextJump =  function (item) {
      // console.log('item:', item)
      // sessionStorage.setItem('shareId',item.shareId)
      if (item.orderStatus == '1') {  // todo
        saveProductInfo(item)
        
      } else if(item.orderStatus == '0') {
        saveTopay(item.tradeNo,item.shareId)
      } else {
        saveProductInfo(item)
    }
    }
    // 跳转支付页面
     const saveTopay = (tradeNo,shareId) => {
      request(getExtendUri('confirmCharge'), {
        baseInfo: {
          tradeNo: tradeNo,
          shareId: shareId
          }
        }).then(res => {
          if (res.code === 1) {
            payAction(res.result)
          }
        })
    }
        
    const payAction = (obj) => {
      console.log('supplierCode', obj.supplierCode)
      console.log('supplierName', obj.supplierName)
      state.payObj.supId = obj.supplierCode
      // 平安
      // if (obj.supplierCode === '1004') {
      if (isOnlyPayUrl(obj.supplierCode)) {
        state.payObj.payUrl = obj.payUrl
        state.payObj.payJson = ''
        state.payObj.payKey = ''
      } else {
        // 安盛
        const xmlData = state.payObj.payStateResult = obj.value || ''
        state.payObj.payJson = xmlData
        state.payObj.payUrl = obj.url
        state.payObj.payKey = obj.key
      }
    }

    const selectTabs = function() {
      request(getExtendUri('dictionary_query_byType'), {data: {types:['insuranceSlipStatus']}}).then(res=> {
        listFirst.value = res.result.insuranceSlipStatus
        listNext.value = listFirst.value[0].children
        prtStatusType.value = listFirst.value[0].value
        prtStatus.value = listNext.value[0].value
        selectList()
      })
    }
    selectList()
    selectTabs()
    return {
      onConfirm,
      ...toRefs(state),
      onClickTabFirst,
      secondSpanClick,
      LevelTop,
      actions,
      onSelect,
      showPopover,
      showPopup,
      timerPickerShow,
      timeTabTitle,
      buttonType,
      activeTabBar,
      orderOption,
      orderValue,
      listShow,
      loading,
      finished,
      onRefresh,
      pullOnRefresh,
      refreshing,
      LevelNext,
      LevelFirst,
      listFirst,
      listNext,
      onClickTabSecond,
      onClickTabNext,
      nextJump
    }
  }
})
</script>
