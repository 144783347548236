<style scoped>
.product_wrap {
  width: 100%;
  height: 100%;
  background-color: #f6f8fb;
  overflow-x: hidden;
  text-align: left;
}

.bottom_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
}

.bottom_fix {
  height: 4rem;
}

.insure_btn {
  font-size: 1.2rem;
  height: 100%;
  width: 50%;
  font-weight: 500;
  border-radius: 0;
}
</style>

<template>
  <div class="product_wrap">
    <template v-for="(item,index) in showData" :key="'healthTold_' + index">
      <PreviewPop :previewData="item" @PreviewPopData="PreviewPopData"/>
    </template>
    <div class="bottom_fix"></div>
    <div class="bottom_bar">
      <a-button :disabled="loading" type="text" class="insure_btn" size="large" style="color: #b6b0b0" @click="allNo">全部为否</a-button>
      <a-button :disabled="loading" type="primary" class="insure_btn" size="large" @click="nextPage">下一步</a-button>
    </div>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, toRefs, watch} from 'vue'
// eslint-disable-next-line no-unused-vars
import {routerJump, nowPathShow} from '../../utils/product_process'
import {request} from '../../utils/request.js'
import {getExtendUri} from '../../utils/request_extend.js'
import {message} from 'ant-design-vue'
// import {startRecord} from '../../utils/rrweb'
import {useRouter} from 'vue-router'
import store from '../../store'

export default defineComponent({
  components: {
    // PreviewPop
  },
  beforeRouteEnter(to, from, next) {
    document.title = '财务告知'
    next()
  },
  setup() {
    let  router  = useRouter()
    const state = reactive({
      loading: false,
      name: '',
      showData: [],
      showObj: {},
      nowArr: [],
      // riskList: localStorage.getItem('riskList') ? JSON.parse(localStorage.getItem('riskList')).riskList : [],
      riskList: '',
      riskObj: {},
      tradeNo: ''
    })
    onBeforeMount(()=> {
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
    })
    const updatePage = function(){
      state.tradeNo = router.currentRoute.value.query.tradeNo
      nowPathShow(null, null, state.tradeNo, () => {
        // const routerData = JSON.parse(localStorage.getItem('routerJump'))
        const routerData = JSON.parse(store.getters['insured/getRouterJump'])
        state.showData = JSON.parse(routerData.pageJson)
        refactoringFun(state.showData)
        InitializeFun(state.showData)
        // startRecord(store.getters['insured/getUuid'])
      })
    }
    updatePage()
    watch(() =>router.currentRoute.value.query.sort,(newValue,oldValue)=> {
      console.log('watch',newValue,oldValue)
      updatePage()
    },{ deep: true })
    const PreviewPopData = function (data) {
      let keys = Object.keys(data)[0]
      state.showObj[keys] = Object.assign(state.showObj[keys], data[keys])
    }
    const allNo = function () {
      //全部为否
      for (let keys in state.showObj) {
        if (state.showObj[keys].healthType === 'radioType') {
          state.showObj[keys][keys] = 2
        }
      }
      //  数据处理
      allNoFun(state.showData)
    }
    //健康告知全部为否
    const allNoFun = function (Arr = []) {
      for (let i = 0; i < Arr.length; i++) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            item.forEach(obj => {
              obj.firstValue = state.showObj[obj.elementCode][obj.elementCode]
              obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                allNoFun(item2.elementList)
              })
            })
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            Arr[i].firstValue = state.showObj[Arr[i].elementCode][Arr[i].elementCode]
            Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
              allNoFun(item2.elementList)
            })
          }
        }
      }
    }
    const InitializeFun = function (eleList = [], moduleType = '', parentId = '') {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].productElementList) {
          eleList[i].productElementList.forEach(item => {
            item.forEach(obj => {
              //如果是第一层级的数据key,并且还有下一层，添加obj
              // if (obj.elementOperatingMode && (obj.elementOperatingMode.mode_health.conSpanList.length > 0 ||(obj.elementOperatingMode.mode_health.healthInputConfig && obj.elementOperatingMode.mode_health.healthInputConfig.strArray?(obj.elementOperatingMode.mode_health.healthInputConfig.strArray.length>0?true:false):false))) {
              if (obj.elementOperatingMode) {
                state.showObj[obj.elementCode] = {
                  required: obj.required,
                  elementParentId: parentId,
                  elementId: obj.id,
                  healthType: obj.healthType,
                  moduleType: obj.moduleType,
                  [obj.elementCode]: null,
                  valueToChildCode: []
                }
                obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  if (item2.elementList && item2.elementList.length > 0) {
                    state.showObj[obj.elementCode]['valueToChildCode'].push({
                      value: item2['value'],
                      elementList: item2.elementList
                    })
                  }
                  InitializeFun(item2.elementList, obj.moduleType, obj.id)
                })
              }
            })
          })
        } else if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) :
              false))) {
          state.showObj[eleList[i].elementCode] = {
            required: eleList[i].required,
            elementParentId: parentId,
            elementId: eleList[i].id,
            moduleType: moduleType,
            [eleList[i].elementCode]: null,
            valueToChildCode: []
          }
          eleList[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
            if (item2.elementList && item2.elementList.length > 0) {
              state.showObj[eleList[i].elementCode]['valueToChildCode'].push({
                value: item2['value'],
                elementList: item2.elementList
              })
            }
            InitializeFun(item2.elementList, moduleType, eleList[i].id)
          })
        }
      }
    }
    //初始化加校验处理数据
    const refactoringFun = function (Arr = []) {
      for (let i = Arr.length - 1; i >= 0; i--) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            for (let j = item.length - 1; j >= 0; j--) {
              let expressionValue = item[j].expressionValue
              if (expressionValue) {
                for (let objKey in state.riskObj) {
                  if (expressionValue.indexOf(objKey) != -1) {
                    //  匹配成功
                    let str = '/' + objKey + '/g'
                    expressionValue = expressionValue.replace(eval(str), state.riskObj[objKey])
                  }
                }
                //  替换完成做校验
                if (!eval(expressionValue)) {
                  item.splice(j, 1)
                } else {
                  item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                    refactoringFun(item2.elementList)
                  })
                }
              } else {
                item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            }
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            let expressionValue = Arr[i].expressionValue
            if (expressionValue) {
              for (let objKey in state.riskObj) {
                if (expressionValue.indexOf(objKey) != -1) {
                  //  匹配成功
                  let str = '/' + objKey + '/g'
                  expressionValue = expressionValue.replace(eval(str), state.riskObj[objKey])
                }
              }
              //  替换完成做校验
              if (!eval(expressionValue)) {
                Arr.splice(i, 1)
              } else {
                Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            } else {
              Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                refactoringFun(item2.elementList)
              })
            }

          }
        }
      }
    }
    onBeforeMount(() => {
      state.riskObj = state.riskList.filter(risk => risk.riskSign === '1')[0]['calculateFactor'].reduce((all, curVal) => {
        return {[curVal.factorType]: curVal.factorValue, ...all}
      }, {})
      // refactoringFun(state.showData)
      // InitializeFun(state.showData)
    })
    const cleanChildrenCode = (eleList = [], saveObj = {}) => {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) :
              false))) {
          if(saveObj[eleList[i].elementCode]){
            saveObj[eleList[i].elementCode]['valueToChildCode'].forEach(item => {
              cleanChildrenCode(item.elementList, saveObj)
              item.elementList.forEach(element => delete saveObj[element.elementCode])
            })
          }

        }
      }
    }
    const nextPage = function () {
      let nextObj = {}
      for (let key in state.showObj) {
        let elementParentId = state.showObj[key].elementParentId
        let first = state.showObj[key].elementParentId === ''
        if (first) {
          //first为true,一级选项,直接添加
          nextObj[key] = state.showObj[key]
        } else {
          //二级选线需判断是否选中的是当前二级数据的一级数据
          for (let keyA in state.showObj) {
            let elementId = state.showObj[keyA].elementId
            if (elementId === elementParentId) {
              //如果父节点有值，说明存在
              if (state.showObj[keyA][keyA]) {
                nextObj[key] = state.showObj[key]
              }
            }
          }
        }
      }
      for (let key in nextObj) {
        if (nextObj[key]) {
          if (nextObj[key][key] === null &&nextObj[key].required==='1') {
            // TODO 空值校验
            message['error']('请检查录入选项,有漏填！')
            return false
            // ......
          } else {
            // TODO 非空值对应的其他校验
            // ......
            // 删除不需要记录的问题
            nextObj[key]['valueToChildCode'].filter(item => item.value !== nextObj[key][key]).forEach(item => {
              cleanChildrenCode(item.elementList, nextObj)
              item.elementList.forEach(element => delete nextObj[element.elementCode])
            })
          }
        }
      }
      console.debug('healthTold Save', nextObj)
      //将组建返回数据组合成接口传参
      let arr = []
      for (let key in nextObj) {
        arr.push({
          moduleId: nextObj[key].moduleType,
          elementId: nextObj[key].elementId,
          submitKey: key,
          submitValueObj: nextObj[key][key],
          elementParentId: nextObj[key].elementParentId
        })
      }
      // const routerData = JSON.parse(localStorage.getItem('routerJump'))
      const routerData = JSON.parse(store.getters['insured/getRouterJump'])
      let submitData = {
        'productId': store.getters['insured/getProductId'],
        'tradeNo': state.tradeNo,
        'sortCode': routerData.code,
        'sort': routerData.sort,
        'submitRecordList': arr
      }
      state.loading = true
      request(getExtendUri('record'), submitData).then(res => {
        state.loading = false
        if (res.code === 1) {
          console.log(res)
          // TODO 需要打开跳转
          routerJump(router.currentRoute.value.query.tradeNo)
        } else {
          message['error'](res.message)
        }
      })
    }
    return {
      ...toRefs(state),
      nextPage,
      PreviewPopData,
      allNo,
    }
  },
})
</script>
