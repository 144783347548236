import {getAge} from '@/utils/utlis'
import store from '@/store'
import dayjs from 'dayjs'

/**
 * 保险信息模块的 告知问卷校验
 * applicant 投保人 insureds 被保人  beneficiarys 受益人
 * payment 支付模块    payMethod: 缴费方式模块
 * @param {*} data
 * @param module
 */
export const informationVerification = (data, module) => {
  if (module.moduleId === 'insureds'){
    // 多单个的被保人告知问卷的校验
    return moduleHealthCheck(data, module)
  }
  let keys = Object.keys(data)
  for(let i = 0; i < keys.length; i++){
    if(keys[i] !== 'applicant' && keys[i] !== 'insureds' && keys[i] !== 'beneficiarys' && keys[i] !== 'payMethod' && keys[i] !== 'payment') return
    let type = undefined
    if(data[keys[i]] && data[keys[i]].length){
      for (let j = 0; j < data[keys[i]].length; j++) {
        // if((keys[i] === 'insureds' && data[keys[i]][j]['relationToAppnt']['relationToAppnt'] === '01') ||
        //   (keys[i] === 'beneficiarys' && data[keys[i]][j]['bnfType']['bnfType'] === '01')
        // ) continue
        type = inforDetail(data[keys[i]][j], keys[i])
        if(typeof type === 'string'){
          return type
        }
      }
    }
    // if(typeof type === 'string'){
    //   return type
    // }
  }
  return true
}
export const inforDetail = (obj) => {
  let keys = Object.keys(obj)
  for(let i = 0; i < keys.length; i++){
    let key = keys[i]
    if (key === 'relationToAppnt' && obj[key][key] === '新增') {
      return obj[key]['elementName']
    }
    if (key === 'updateLodeDetail' && obj[key][key] === 'N' && obj[key]['required'] === '1') {
      return obj[key]['elementName']
    }
    if((obj[key][key] === null || obj[key][key] === undefined ||obj[key][key] === '' ) && obj[key]['required'] === '1'){
      return obj[key]['elementName']
    }
  }
  return true
}

/**
 * 单个被保人的告知问卷校验
 * @param data
 * @param module
 */
const moduleHealthCheck = (data, module) =>{
  const insuredsData = data['insureds'][module.moduleIndex]
  const applicantData = data['applicant'][0]
  let keys = Object.keys(insuredsData)

  if(insuredsData['relationToAppnt']['relationToAppnt'] === '01'){
    return samePolicyholder(applicantData, insuredsData, keys)
  }
  for(let i = 0; i < keys.length; i++){
    if (keys[i] === 'healthToldFlag'  || keys[i] === 'socialInsuFlag') continue
    if(keys[i] === 'relationToAppnt' && insuredsData[keys[i]][keys[i]] === '新增'){
      return insuredsData[keys[i]]['elementName']
    }
    if (keys[i] === 'updateLodeDetail' && insuredsData[keys[i]][keys[i]] === 'N' && insuredsData[keys[i]].required === '1') {
      return insuredsData[keys[i]]['elementName']
    }
    if((insuredsData[keys[i]][keys[i]] === 'N' || insuredsData[keys[i]][keys[i]] === undefined ||insuredsData[keys[i]][keys[i]] === null) && insuredsData[keys[i]].required === '1'){
      return insuredsData[keys[i]]['elementName']
    }
  }
  return true
}
/**
 * 单被保人的告知问卷校验 且同投保人
 * @param applicantData
 * @param insuredsData
 * @param insuredsKeys
 */
const samePolicyholder = (applicantData, insuredsData, insuredsKeys) =>{
  const applicanKeys = Object.keys(applicantData)
  for(let i = 0; i < insuredsKeys.length; i++){
    if (insuredsKeys[i] === 'healthToldFlag') continue
    if((insuredsData[insuredsKeys[i]][insuredsKeys[i]] === undefined ||insuredsData[insuredsKeys[i]][insuredsKeys[i]] === null) && insuredsData[insuredsKeys[i]].required === '1'){
      return insuredsData[insuredsKeys[i]]['elementName']
    }
  }
  for(let i = 0; i < applicanKeys.length; i++){
    if((applicantData[applicanKeys[i]][applicanKeys[i]] === undefined ||applicantData[applicanKeys[i]][applicanKeys[i]] === null) && applicantData[applicanKeys[i]].required === '1'){
      return applicantData[applicanKeys[i]]['elementName']
    }
  }
  return true
}






/** 德华安顾的主险可选责任
 * @param code
 * @param list
 */
const dutyList = ['328902', '328102', '328103', '328903', '328104', '328904','328202', '328203', '328204','331702', '331703', '331704']
export const insuredsFirstOne= (dutyItem, obj) =>{
  let flag = {
    type : true,
    message: ''
  }
  if(obj && dutyList.includes(dutyItem.dutyCode)){
    let age = ''
    let sex = ''
    if(obj['insureds'][0]['relationToAppnt']['relationToAppnt'] === '01'){
      if(obj['applicant'][0]['appntBirthday'].appntBirthday){
        age = calAge(obj['applicant'][0]['appntBirthday'].appntBirthday)
      }
      sex = obj['applicant'][0]['appntSex']?.appntSex
    }else{
      if(obj['insureds'][0]['birthday'].birthday){
        age = calAge(obj['insureds'][0]['birthday'].birthday)
      }
      sex = obj['insureds'][0]['sex']?.sex
    }
    let value = ''
    if ((Number(sex) === 1 && (dutyItem.dutyCode === '328903' || dutyItem.dutyCode === '328103' || dutyItem.dutyCode ==='328203' || dutyItem.dutyCode === '331703')) || (Number(sex) === 2 && (dutyItem.dutyCode === '328904' || dutyItem.dutyCode ==='328104' || dutyItem.dutyCode === '328204' || dutyItem.dutyCode === '331704')) ){
      Number(sex) === 1 ? value = '男' : value = '女'
      flag = {
        type: false,
        message: '可选责任“' + dutyItem.dutyName +'”，被保人不允许是' + value +'性'
      }
    }else if(dutyItem.dutyCode === '328902' || dutyItem.dutyCode === '328102' || dutyItem.dutyCode === '328202' || dutyItem.dutyCode === '331702'){
      if (age && (age+'').includes('D')){
        age = age.replace('D', '')
        if (Number(age) < 30){
          flag = {
            type: false,
            message: '可选责任“少儿特定疾病保险金”，被保险人投保年龄为 30 天-17 周岁'
          }
        }
      }else if (age && Number(age) > 17){
        flag = {
          type: false,
          message: '可选责任“' + dutyItem.dutyName +'”，被保险人投保年龄为 30 天-17 周岁'
        }
      }
    }
  }
  return flag
}
const calAge = (d) => {
  let age = getAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : undefined).age[0]
  return age
}

// 国富的下一步保额校验
export const checkGFRule = (code, obj, list) =>{
  if(code !== '2090') return
  let flag = {
    type : false,
    msg: ''
  }
  let age = ''
  const amntObj = list[0].calculateFactor.filter(item=> item.factorType === 'Amnt')[0]
  let index = store.getters['insured/getOptionalDutyList'].findIndex(item=> item.dutyCode === '20900000109')
  // 找被保人年龄
  if(obj['insureds'][0]['relationToAppnt']['relationToAppnt'] === '01'){
    age = calAge(obj['applicant'][0]['appntBirthday']['appntBirthday'])
  }else{
    age = calAge(obj['insureds'][0]['birthday']['birthday'])
  }
  if(Number(age)< 46 && Number(amntObj.factorValue) < 200000 && index === -1){
     flag = {
      type: true,
      msg: '被保人年龄为0至45周岁，未选择身故保险金或全残保险金责任，最低保额为20万'
    }
  }else if(Number(age)< 46 && Number(amntObj.factorValue) < 100000 && index > -1){
    flag = {
      type: true,
      msg: '被保人年龄为0至45周岁，选择身故保险金或全残保险金责任，最低保额为10万'
    }
  }
  // 投保人 国籍 外国人永久居留身份证 校验
  for (let i = 0; i < obj['applicant'].length; i++) {
    const element = obj['applicant'][i]
    if (element['appntIdType']['appntIdType'] == '15') {
      if (element['idDateType']['idDateType'] == '1') {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (calAge(element['appntBirthday']['appntBirthday']) < 18 && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(5, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD')) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (calAge(element['appntBirthday']['appntBirthday']) >= 18 && calAge(element['appntBirthday']['appntBirthday']) <= 22 && (dayjs(element['idTypeStartDate']['idTypeStartDate']).add(5, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD') && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(10, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD'))) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (calAge(element['appntBirthday']['appntBirthday']) >= 23 && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(10, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD')) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      }
      if (element['nativePlace']['nativePlace'] == 'CHN') {
        flag = {
          type: true,
          msg: '亲，中国国籍不能选择外国人永久居留身份证哦~'
        }
      }
    } else if (element['appntIdType']['appntIdType'] == '01') {
      if (element['nativePlace']['nativePlace'] != 'CHN') {
        flag = {
          type: true,
          msg: '亲，非中国国籍不能选择身份证哦~'
        }
      }
    }
  }
  // 被保人 国籍 外国人永久居留身份证 校验
  for (let i = 0; i < obj['insureds'].length; i++) {
    const element = obj['insureds'][i]
    if (element['idType']['idType'] && element['idType']['idType'] == '15') {
      if (element['idDateType']['idDateType'] && element['idDateType']['idDateType'] == '1') {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (element['birthday']['birthday'] && calAge(element['birthday']['birthday']) < 18 && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(5, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD')) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (element['birthday']['birthday'] && calAge(element['birthday']['birthday']) >= 18 && calAge(element['birthday']['birthday']) <= 22 && (dayjs(element['idTypeStartDate']['idTypeStartDate']).add(5, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD') && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(10, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD'))) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      } else if (element['birthday']['birthday'] && calAge(element['birthday']['birthday']) >= 23 && dayjs(element['idTypeStartDate']['idTypeStartDate']).add(10, 'years').subtract(1, 'days').format('YYYY-MM-DD') != dayjs(element['idTypeEndDate']['idTypeEndDate']).format('YYYY-MM-DD')) {
        flag = {
          type: true,
          msg: '亲，请您确认证件有效期是否正确哦~'
        }
      }
      if (element['nativePlace']['nativePlace'] && element['nativePlace']['nativePlace'] == 'CHN') {
        flag = {
          type: true,
          msg: '亲，中国国籍不能选择外国人永久居留身份证哦~'
        }
      }
    } else if (element['idType']['idType'] && element['idType']['idType'] == '01') {
      if (element['nativePlace']['nativePlace'] && element['nativePlace']['nativePlace'] != 'CHN') {
        flag = {
          type: true,
          msg: '亲，非中国国籍不能选择身份证哦~'
        }
      }
    }
  }
  return flag

}

