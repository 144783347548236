
/**
 * 录入页被保人筛选是否隐藏元素
 * @param list
 */
export const hiddenElement = (showData) =>{
  const insuredsList = showData.filter(item => item.moduleType === 'insureds')
  const applicantList = showData.filter(item => item.moduleType === 'applicant')
  const applicantElementList = []
  // const insuredsElementList = []

  // 先找到投保人的元素编码
  if(applicantList.length){
    const appEleList = applicantList[0]['productElementList'][0]
    for (let i = 0; i < appEleList.length; i++) {
      applicantElementList.push(appEleList[i].elementCode)
    }
  }
  // 被保人
  if(insuredsList.length){
    const insuredsEleList = insuredsList[0]['productElementList']
    for (let i = 0; i < insuredsEleList.length; i++) {
      // 与投保人类型  必须展示
      if (insuredsEleList[i]['elementCode'] === 'relationToAppnt') {
        insuredsEleList[i]['isShow'] = false
        continue
      }
      // 是否本人
      const type = insuredsEleList[i].filter(pro => {
        return pro.elementCode === 'relationToAppnt' && (pro.firstValue === '01' || !pro.firstValue)
      })
      if (type && type.length) {
        for (let j = 0; j < insuredsEleList[i].length; j++) {
          if(applicantElementList.includes(insuredsEleList[i][j].elementCode)){
            insuredsEleList[i][j].isShow = true
          }else{
            hiddenElementShow(insuredsEleList[i][j], applicantElementList)
          }
          insuredsEleList[i][j].isPublic = insuredsEleList[i][j].isShow
          // insuredsEleList[i][j].required = insuredsEleList[i][j].isShow ? '0' : '1'
          if(insuredsEleList[i][j].elementCode === 'premium'){
            // insuredsEleList[i][j].required = '0'
            insuredsEleList[i][j].isShow = insuredsEleList.length <= 1
          }
        }
      }else{
        for (let j = 0; j < insuredsEleList[i].length; j++) {
          insuredsEleList[i][j].isPublic = insuredsEleList[i][j].isShow
          // insuredsEleList[i][j].required = '1'
        }
      }
    }
  }
}

const  hiddenElementShow = (insuredObj, applicantElementList) =>{
  switch (insuredObj.elementCode){
    case 'name':
        insuredObj.isShow = applicantElementList.includes('appntName')
      break
    case 'sex':
      insuredObj.isShow = applicantElementList.includes('appntSex')
      break
    case 'idType':
      insuredObj.isShow = applicantElementList.includes('appntIdType')
      break
    case 'idNo':
      insuredObj.isShow = applicantElementList.includes('appntIdNo')
      break
    case 'birthday':
      insuredObj.isShow = applicantElementList.includes('appntBirthday')
      break
    case 'insuredAge':
      insuredObj.isShow = applicantElementList.includes('appntAge')
      break
    case 'nativePlace':
      insuredObj.isShow = applicantElementList.includes('nativePlace') || applicantElementList.includes('nationality')
      break
    default:
      insuredObj.isShow = false
      break
  }

}

// 续期银行账号信息同首期  为是的是否筛选元素是否隐藏
export const sameNewIsY = (obj, elementList, insuredObj) =>{
  switch (obj.elementCode){
    case 'bankProvinceCode':
      // obj.isShow = insuredObj['isSameNew'] elementList.includes('newBankProvinceCode')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankProvinceCode') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankProvinceCode') ? '0' : '1'
      break
    case 'bankCityCode':
      // obj.isShow = elementList.includes('newBankCityCode')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankCityCode') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankCityCode') ? '0' : '1'
      break
    case 'bankAreaCode':
      // obj.isShow = elementList.includes('newBankAreaCode')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankAreaCode') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankAreaCode') ? '0' : '1'
      break
    case 'renewalPhone':
      // obj.isShow = elementList.includes('phoneNo')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('phoneNo') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('phoneNo') ? '0' : '1'
      break
    case 'accountType':
      // obj.isShow = elementList.includes('newAccountType')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newAccountType') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newAccountType') ? '0' : '1'
      break
    case 'bankCardUrl':
      // obj.isShow = elementList.includes('newBankCardUrl')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankCardUrl') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankCardUrl') ? '0' : '1'
      break
    case 'accName':
      // obj.isShow = elementList.includes('newAccName')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      // obj.firstValue = elementList.includes('newAccName') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newAccName') ? '0' : '1'
      break
    case 'bankAccNo':
      // obj.isShow = elementList.includes('newBankAccNo')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankAccNo') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankAccNo') ? '0' : '1'
      break
    case 'bankName':
      // obj.isShow = elementList.includes('newBankName')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankName') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankName') ? '0' : '1'
      break
    case 'bankCode':
      // obj.isShow = elementList.includes('newBankCode')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newBankCode') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newBankCode') ? '0' : '1'
      break
    case 'payLocation':
      // obj.isShow = elementList.includes('newPayMode')
      obj.isShow = insuredObj['isSameNew']['isSameNew'] === '1'
      obj.firstValue = elementList.includes('newPayMode') ? '' : obj.firstValue
      insuredObj[obj.elementCode]['required'] = elementList.includes('newPayMode') ? '0' : '1'
      break
    default:
      obj.isShow = false
      break
  }
}


