<template>
    <div>
      <van-dropdown-menu >
        <van-dropdown-item  title="全部订单" :lazy-render="false" ref="listDropdownItem">
          <!-- 需要第二层tab放入 待完成 -->
          <van-grid :column-num="3"  :border="false">
            <van-grid-item v-for="(item,index) in listNext" :key="index" >
              <!-- 样式：选中后变蓝色
                  点击后设置为选中：selectedEvent = 1
               -->
              <span :class="{activeBlue: isShow === index}" :value="item.value" :selectedValue="selectedValue" class="tabSpan" @click="SpanClick(item,index)">{{ item.label }}</span>
            </van-grid-item>
          </van-grid>
           
          <div style="padding-top: 16px;">
            <!-- 待完成，事件编写 -->
            <van-button type="default" @click="cancelConfirm" style="width:50%">取消</van-button>
            <van-button type="primary" @click="onConfirm()" style="width:50%">确认</van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item ref="item" title="全部时间">
              <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              />          
        </van-dropdown-item>
          
      </van-dropdown-menu>
      
  </div>
</template>

<script>
// import { ref} from 'vue'
import {  DropdownMenu, DropdownItem, Button, Space,  DatetimePicker, Grid, GridItem } from 'vant'
import { ref } from 'vue'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [Space.name]: Space,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
    },
  props: {
    orderOption: {
      type: String,
      required: false,
      default: () => {
        return 'left'
      }
    },
    listNext: {
        type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    isShow: {
      type: String,
      required: false,
      default: () => {
        return '0'
      }
    },
  },
  emits: ['secondSpanClick','onConfirm'],
  setup(props, { emit }) {
    
    const listDropdownItem = ref(null)
    const SpanClick = (item, index) => {
      emit('secondSpanClick',{item, index})
    }
    const onConfirm = () => {
      emit('onConfirm',listDropdownItem)
    }

    return {
      listDropdownItem,
      SpanClick,
      onConfirm,
    }
  }




}
</script>

<style scoped>
.activeBlue{
  color: #1989fa;
  border: 1px solid #1989fa !important;
}
  .tabSpan {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  border: 1px solid #e2d8d8;
}
</style>