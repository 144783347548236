<template>
  <div class="form-group">
    <div class="form-row">
      <input type="text" class="form-input" :value="nameS" disabled />
    </div>
    <div class="form-row">
      <input type="text" class="form-input" :value="phoneS" disabled />
    </div>
    <div class="form-row">
      <div class="form-input">
        <input type="text" v-model="code" class="code-input" :maxlength="6" placeholder="输入验证码" />
        <div class="code-divider"></div>
        <div class="button-class">
          <button :disabled="countdown > 0" @click="sendCode" class="code-button">{{ buttonText }}</button>
        </div>
      </div>
    </div>
    <a-button v-if="isShow" class="btn-ensure" :class="{'btn-already':code.length === 6}"  @click="toSign">确定</a-button>
    <a-button v-if="!isShow" class="btn-ensure" style="background: #4f4f4f;color:white" disabled>您已经完成了签名</a-button>

  </div>
</template>

<script>
import {computed, nextTick, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {getSignListApi, getVCodeApi, sendSMSApi} from '../elSign/js/signature_api'
import {request} from '@/utils/request'
import {getExtendUri} from '@/utils/request_extend'
import router from '@/router'
import {message} from 'ant-design-vue'

export default {
  name: 'verificationCode',
  setup() {
    const code = ref('')
    const countdown = ref(0)
    const state = reactive({
      isShow: true,
      phoneS: '',
      nameS:'',
      attributive: '',
      customCode: '',
      tradeNo: '',
      signProvider:'',
      status: false,
      holderInsuredSame: '',
      sendCodeClick: false
    })

    const buttonText = computed(() => {
      if (countdown.value > 0) {
        return `${countdown.value}s后重新获取`
      } else {
        return '获取验证码'
      }
    })

    const sendCode = async () => {
      state.sendCodeClick = true
      if (countdown.value > 0) {
        return
      }
      //禁用获取验证码按钮
      const {code, message} = await getVCodeApi({
        data: {
          attributive: state.attributive,
          customCode: state.customCode,
          mobile: state.phoneS,
          tradeNo: state.tradeNo,
          signProvider: state.signProvider
        }
      }).then(res => {
        return res
      })
      if (code === 1) {
        countdown.value = 60
        countDownTimer()
      } else {
        console.log(`验证码发送失败：${message}`)
      }
    }
    // 倒计时函数
    const countDownTimer = () => {
      if (countdown.value > 0) {
        setTimeout(() => {
          countdown.value--
          countDownTimer()
        }, 1000)
      }else{
        state.sendCodeClick = false
      }
    }
    const handleCode = (code) => {
      if (code) {
        state.status = true
        return
      }
      state.status = false
    }
    watch(code,(newValue)=> {
      if (newValue){
        handleCode(code)
      }else{
        handleCode(undefined)
      }
    })
    const getQueryString = (name) => {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }
    const getSignInfo = () => {
      let data = {
        tradeNo: router.currentRoute.value.query.tradeNo,
        attributive: router.currentRoute.value.query.attributive,
        customCode:  router.currentRoute.value.query.customCode
      }
      request(getExtendUri('getCheckedMessage'),{data}).then(res =>{
        if(res.code === 1){
          state.phoneS = res.result.customPhone
          state.nameS = res.result.customName
          state.attributive = router.currentRoute.value.query.attributive
          state.customCode = router.currentRoute.value.query.customCode
          state.tradeNo = router.currentRoute.value.query.tradeNo
          state.signProvider = router.currentRoute.value.query.signProvider

        }
      })
    }
    getSignInfo()

    const toSign = () => {
      if(state.sendCodeClick && !state.status ){
        return message.warning('请输入验证码')
      }else if(code.value.length !== 6){
        return message.warning('验证码错误')
      }
      if (state.status) {
        //校验手机号逻辑
        sendSMSApi({
          data:{
            mobile: state.phoneS,
            verifyCode: code.value,
            attributive: state.attributive,
            customCode: state.customCode
          }
        }).then(res => {
          if (res.code === 1) {
            router.push({
              path: '/app/confirmation',
              query: {
                id: router.currentRoute.value.query.id,
                sort: router.currentRoute.value.query.sort,
                shareId: router.currentRoute.value.query.shareId,
                tradeNo: state.tradeNo,
                customCode: state.customCode,
                attributive: state.attributive,
                signatureType: getQueryString('signatureType'),
                userFlag: getQueryString('userFlag'),
                signature: '1',
                leavel:state.attributive,
                code: state.customCode,
              }
            })
          } else {
            message.error(res.message)
          }
        })
      }
    }
    const getSignatureStatus = () =>{
      const customCode = router.currentRoute.value.query.customCode
      let param = {
        data: {
          tradeNo: getQueryString('tradeNo'),
          shareId: getQueryString('shareId')
        }
      }
      getSignListApi(param).then(res => {
        if (res.code === 1 && res.result.autographDetailList){
          res.result.autographDetailList.forEach(item =>{
            if(item.customCode === customCode && item.signed === '1'){
              state.isShow = false
            }
          })
        }
      })
    }
    onMounted(()=>{
      document.title = '身份验证'
      nextTick(()=>{
        getSignatureStatus()
      })
    })


    return {
        getSignatureStatus,
      ...toRefs(state),
      code,
      countdown,
      buttonText,
      sendCode,
      getSignInfo,
        toSign,
        getQueryString
    }
  }
}
</script>

<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 24px;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-input {
  height: 48px;
  display: flex;
  align-items: center;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.code-divider {
  height: 20px;
  width: 1px;
  background-color: #ccc;
  margin: 0 10px;
}


.code-button:disabled {
  font-size: smaller;
  background-color: #00000000;
  cursor: not-allowed;
  line-height: 1;
}

.code-input {
  /*flex: 0.8;*/
  border: none;
  padding: 5px;
  outline: none;
  min-width: 0; /* 设置表单元素的最小宽度 */
}
.button-class{
  /*width: 48%;*/
  text-align: center;
}
.code-button {
  background-color: #00000000;
  color: #0077cc;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 1;
}
.btn-ensure {
  height: 3rem;
  background: #93C3FC;
  color: white;
  z-index: 1001;
  text-align: center;
  font-size: 1.13rem;
  border-radius: 10px;
}
.btn-already {
  background: #0079FE;
}

</style>
