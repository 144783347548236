<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
  <meta name="referrer" content="no-referrer">

  <!--审核页面-->
  <div class="content" >
    <div v-if="status !== 'ING'">
      <img class="sigeNameImg" :src="status === 'PASS' ? SuccessIcon: ErrorIcon" >
      <p class="imgP">{{status === 'PASS' ? '验证通过': '验证失败'}}</p>
      <img v-if="status === 'PASS'&& imgUrl!== ''" style="width: 80%;" :src="'//images.weserv.nl/?url='+imgUrl" alt="识别结果图片"/>
    </div>
    <div v-else>
      <div class="imgBox" v-if="status==='ING'" @click="init">
        <p class="reload">人脸识别结果处理中<br>请稍后点击此处刷新页面重试</p>
        <a-button type="info" @click="init">刷新</a-button>
      </div>
    </div>
    <a-button class="btn-ensure btn-already"  v-if="status === 'PASS' && hasFace !== 'Y'" @click="toSign">开始签名</a-button>
    <a-button class="btn-ensure btn-already"  v-if="status === 'PASS' && hasFace === 'Y'" @click="nextPage">人脸识别成功,下一步</a-button>
    <a-button class="btn-ensure btn-already"  v-if="status === 'FAIL'" @click="reFace">重新拍摄</a-button>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isShowErr" @ok="insuranceErrorHandleOk">
      <p style="font-family: PingFangSC-Regular;
        font-size: 1.01rem;
        color: #333333;
        text-align: center;
        margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {onBeforeMount, onMounted, reactive, toRefs} from 'vue'
import ErrorIcon from '../../../assets/img/sign/sign_error.png'
import SuccessIcon from '../../../assets/img/sign/sign_success.png'
import {useRouter} from 'vue-router'
import {faceEventApi, getFaceRecognition, getFacialReturnApi} from './js/signature_api'
// import store from '@/store'
import {routerJump} from '@/utils/product_process'
// import {startRecord} from '../../../utils/rrweb'


// http://82.156.34.193/product/app/identification?
// tradeNo=HB2023xgkphl0000015904
// & shareId=157adcdd8fe84c64845ba2570661f613
// & customCode=4d61cdc5c7e748b8bc947423093153c9
// & holderInsuredName=null
// & bizNo=e61b56bb21fd42569fc20433e5ceeccb
export default {
  name: 'identification',
  setup(){
    const router = useRouter()
    const state = reactive({
      imgUrl: '',
      isShowErr: false,
      detailModal: '',
      status: 'ING',
      hasFace: router.currentRoute.value.query?.hasFace,
      loading: false,
      faceApi: {
        tradeNo:router.currentRoute.value.query['tradeNo'],
        shareId:router.currentRoute.value.query['shareId'],
        customCode:router.currentRoute.value.query['customCode'],
        attributive:router.currentRoute.value.query['attributive'] ? router.currentRoute.value.query['attributive'] : '1',
        holderInsuredSame:router.currentRoute.value.query['holderInsuredSame'],
        signatureType: router.currentRoute.value.query?.signatureType,
        bizNo: router.currentRoute.value.query['bizNo'],
        verifyToken: router.currentRoute.value.query?.verifyToken
      },
      // 汇彬参数
      // holderIndex:0,
      // insurerelationname: '',
      // attachList: [],
      // signData: {},
      // signImage64: '',
      // attributive: '',
      // attributiveType: '',
      // showFlag: false,
      // checkType: '',
      // state: '',
      // attachuuid: ''

    })
    /**
     * 关掉错误弹窗
     */
    const insuranceErrorHandleOk = () => {
      state.isShowErr = false
      state.detailModal = ''
    }

    const showErrPop = (str) => {
      state.isShowErr = true
      state.detailModal = str
    }
    /**
     * 去获取审核状态
     */
    const getFaceState = (f) => {
      // let param =
      // request(url, param).then(res => {
      //
      // })
      state.loading = true
      if (f) {
        getFacialReturn()
      } else {
        // 先用汇彬的测试,跨域走不通
        // getFaceResult()
        getFacialReturn()
        getByDelay()
      }
    }
    // 延迟访问一下结果，这里用于接口回调后，先执行将数据发送到人脸表，然后延迟再去获取一下最终结果getFaceResult
    const getByDelay = () => {
      // setTimeout(() => getFacialReturn(),2000)
      // 测试：http://developer.7ytech.com:8978/product/app/identification/?tradeNo=HB2023fbvclv0000016398&shareId=0af8d367ba7f42419617c8ce259815f8&customCode=e49c2642083db3ed3acb5c116bcd930d&attributive=1&signProvider=1&bizNo=060413d98ccf40018716ec6e219d1fa4
    //http://developer.7ytech.com:8978/product/app/identification/?tradeNo=HB2023dqwkxe0000016515&shareId=28c9359f7cc74ef5b48520a047144f3b&customCode=ac9e33a77828d29a2396f986aa6d8a7e&attributive=1&signProvider=null&bizNo=89fcb16b7cf34d309f574e2b610ab24a
    }
    // const getFaceResult = () => {
    //   if (state.faceApi.bizNo){
    //     state.loading = true
    //      getFaceResultApi(
    //       state.faceApi.bizNo
    //     ).then(res => {
    //       state.loading = false
    //        res.result
    //        if (res.code === 1) {
    //          getByDelay()
    //        } else {
    //          getByDelay()
    //        }
    //     }).catch(err => {
    //       state.loading = false
    //       console.error(err)
    //     })
    //   }
    // }

    const getFacialReturn = () => {
      state.status = 'ING'
      state.hasFace = router.currentRoute.value.query?.hasFace
      if (state.hasFace === 'Y') state.faceApi.attributive = '1'
      getFacialReturnApi({data: state.faceApi}
      ).then(resp => {
        console.log('getFaceResult',JSON.stringify(resp))
        state.loading = false
        if (resp.code === 1){
          state.status = 'PASS'
          state.imgUrl = resp.result.imgUrl
        } else if (resp.code === 0){
          state.status = 'FAIL'
        }else {
          state.status = 'ING'
        }
      }).catch(err => {
        console.error(err)
      })
    }
    const init = () => {
      getFaceState(1)
    }
    // 去人脸
    const reFace = () => {
      state.hasFace = router.currentRoute.value.query?.hasFace
      if(state.hasFace === 'Y'){
        getFaceRecognition({
          data: {
            tradeNo: router.currentRoute.value.query.tradeNo,
            shareId: router.currentRoute.value.query.shareId,
          }
        }).then(res => {
          if (res.code === 1) {
            if (res.result.respUrl) {
              if (state.hasFace === 'Y') res.result.respUrl = res.result.respUrl + '&hasFace=Y'
              window.location.href=res.result.respUrl
            }
          } else {
            showErrPop('获取校验身份信息接口异常，请联系管理员')
          }
        })
        return
      }
      faceEventApi({
        data: state.faceApi
      }).then(res => {
        if (res.code === 1) {
          if (res.result.respUrl) {
            window.location.href=res.result.respUrl
          }
        } else {
          showErrPop('获取校验身份信息接口异常，请联系管理员')
        }
      })
    }
    // 去签名
    const toSign = () => {
      if (state.status === 'PASS') {
        startSign()
      }
    }
    const nextPage = () =>{
      routerJump(router.currentRoute.value.query.tradeNo, '', 'Y')
      // router.push({
      //   path: '/app/confirmation',
      //   query:{
      //     ...router.currentRoute.value.query,
      //     id: store.getters['insured/getHealthRouterJump'].id,
      //     sort: store.getters['insured/getHealthRouterJump'].sort,
      //   }
      // })
    }
    const startSign = () => {
      window.location.href = `/product/anysign/signName.html${ window.location.search }`
    }
    onMounted(()=> {
        // startRecord(state.faceApi.tradeNo)
    })
    onBeforeMount(()=> {
      console.log(11111)
      getFaceState()
    })
    return{
      ...toRefs(state),
      ErrorIcon,
      SuccessIcon,
      insuranceErrorHandleOk,
      toSign,
      init,
      reFace,
      nextPage
    }
  }
}
</script>

<style scoped>
.content {
  padding-top: 1rem;
  width: 100%;
  height: 100%;
}

.noticeBox {
  background-color: #ffffff;
  padding: 0.1rem 0;
}

#noticeList {
  /*display: flex;*/
  margin: .1rem 0;
  display: block;
}

.aggreeList {
  margin: 0 .3rem;
}

.rule_list {
  border: none;
  /*display: flex;*/
  line-height: .5rem;
  margin: .1rem 0;
  display: flex;
}

.aggreeList .check_01 {
  background: none;
}

.aggreeList .check_01 {
  padding-left: 0;
  display: block;
}

.weidu,
.yidu {
  float: right;
  width: 1rem;
  text-align: right;
  border-radius: 8px;
}

.weidu span,
.yidu span {
  display: inline-block;
  line-height: .35rem;
  padding: 0 .1rem
}

.weidu span {
  background: #F0F5FF;
  font-size: .24rem;
  color: #0079FE;
}

.yidu span {
  background: #EDEDED;
  font-size: .24rem;
  color: #666;
}

.agree-head {
  font-family: PingFangSC-Regular;
  font-size: .32rem;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
  padding: .2rem .3rem;
}

.aggreeList .check_01 .insure_noticeList,
.aggreeList a {
  color: #333;
}

.rule_list a {
  display: inline;
  padding-right: .1rem;
}

.btn-ensureBox {
  width: 100%;
}

.btn-ensure {
  background: #93C3FC;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  line-height: .94rem;
  z-index: 1001;
  text-align: center;
  font-size: .3rem;
}



.insure {
  margin: 0.24rem 0;
}

.signatureBox .default {
  width: 100%;
  height: 2.2rem;
  text-align: center;
  background: #F2F8FF;
  border-radius: 10px;
}

.signatureImg {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.6rem 0 0.15rem;
}

.signatureText {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #0079FE;
  letter-spacing: 0;
  font-weight: 400;
}

.signatureBox .sigeName {
  width: 100%;
  height: 2.2rem;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  border: 1px solid #aaa;
}

.sigeNameText {
  font-family: PingFangSC-Regular;
  font-size: 0.3rem;
  color: #0079FE;
  letter-spacing: 0;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 16px;
}

.list_info li .riskLabel {
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}

.riskText {
  font-family: PingFangSC-Regular;
  font-size: 0.26rem;
  color: #999999;
  letter-spacing: 0;
  line-height: 0.4rem;
  font-weight: 400;
  background-color: #ffffff;
  border-top: 1px solid #DCDCDC;
  padding: 0.2rem 0.3rem;
}

.healt_fault {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.bg_hui {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.7;
}

.health_div {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  margin-top: -0.8rem;
  z-index: 1002;
  border-radius: 16px;
  padding-top: .52rem;
}

.healt_fault .msg {
  line-height: 0.48rem;
  text-align: center;
  margin-bottom: .2rem;
  color: #666;
  font-size: .34rem;
  color: #333;
  padding: 0 0.1rem;
}

.healt_fault .sub {
  line-height: 0.42rem;
  text-align: center;
  color: #666;
  font-size: .3rem;
  color: #333;
  margin-bottom: .48rem;
  padding: 0 0.6rem;
}

.health_btn a.left {
  float: left;
  border-right: 1px solid #ccc;
  color: #666;
  text-align: center;
  width: 50%;
  box-sizing: border-box;
}

.health_btn a.right {
  float: right;
  color: #0079FE;
  text-align: center;
  width: 50%;
}

.health_btn {
  font-size: 0.32rem;
  overflow: hidden;
  padding: 0;
  line-height: 1rem;
  border-top: 1px solid #ccc
}
.imgBox img{
  display: block;
  width: 18rem;
  margin: 1.3rem auto 0.6rem;
}
.imgP {
  font-family: PingFangSC-Regular;
  font-size: 1.32rem;
  color: #333333;
  text-align: center;
}
.imgBox p{
  font-family: PingFangSC-Regular;
  font-size: 1.5rem;
  color: #333333;
  text-align: center;
}
.reload{
  margin: 1.3rem auto 0.6rem;
}
.btn-ensure {
  height: 3rem;
  background: #93C3FC;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  line-height: 2.94rem;
  z-index: 1001;
  text-align: center;
  font-size: 1.13rem;
}
.sigeNameImg {
  display: block;
  width: 17rem;
  margin: 1.3rem auto 0.6rem;
}
.btn-already {
  background: #0079FE;
}
</style>
