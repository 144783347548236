<template>
  <div>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onRefresh"
      >
      <div v-for="(item,index) in listShow" :key="item" :title="index"  style="height: 100%">
          <div class="listContent">
           <div class="orderHeader">
            <span class="orderName">{{ item.productInfoName }}</span>
            <span class="order-status">{{ item.status }}</span>
          </div>
          <van-divider />
          <div class="order-details">
            <div class="order-info">
              <span class="order-label">订单号：</span>
              <span class="order-value">{{ item.prtNo }}</span>
            </div>
            <div class="order-info">
              <span class="order-label">投保人：</span>
              <span class="order-value">{{ item.applicantName }}</span>
            </div>
            <div class="order-info">
              <span class="order-label">被保人：</span>
              <span class="order-value">{{ item.protectedmanName }}</span>
            </div>
             <div class="order-info">
              <span class="order-label">投保时间：</span>
              <span class="order-value">{{ item.polApplyDate }}</span>
            </div>
          </div>
          <van-divider />
          <div class="order-bottom">
            <div class="order-left">
              <span class="order-label">保费：</span>
              <span style="color: #DC6180;font-size: 18px">￥{{item.payMoney}}</span>
            </div>
            <div class="order-actions">
              <van-button
                v-if="item.status === '待支付'"
                class="cancel-button"
                type="default"
                size="small"
                @click="cancelOrder"
              >
                取消
              </van-button>
              <van-button
                class="confirm-button"
                type="primary"
                size="small"
                 @click="nextClick(item)"
              >
                继续
              </van-button>
            </div>
          </div>

        </div>

      </div>
    </van-list>
  </div>

</template>

<script>
// import {  } from 'vue'
import {  Button, List, Divider} from 'vant'

export default {
  components: {
    [Button.name]: Button,
    [Divider.name]: Divider,
    [List.name]: List,

  },
  name: 'OrderListItem',
  props: {
    listShow: {
      type: Array,
      default: () => [],
    },
    finished: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['nextJump'],
  setup(props, {emit}){
    const cancelOrder = () => {

    }
    const nextClick = (item) => {
      // console.log('item', item)
      emit('nextJump', item)
    }

    return {
      cancelOrder,
      nextClick
    }
  }
}
</script>

<style scoped>
.order-list-item {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.order-name {
  font-weight: bold;
  font-size: 18px;
}

.order-status {
  color: #ff3333;
  font-weight: bold;
  font-size: 16px;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
}

.order-label {
  color: #999999;
}

.order-value {
  text-align: right;
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}
.order-bottom{
  display: flex;
  justify-content: space-between;
}
.cancel-button {
  margin-right: 16px;
}
</style>
