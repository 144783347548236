/**
 * 匹配基本责任和其他可选责任
 *  20900000105: 第二次重大疾病保险金
 *  20900000106:  疾病关爱金
 *  20900000107:  “恶性肿瘤一轻度”、原位癌、“恶性肿瘤一重度”额外保险金
 *  20900000108:  特定心脑血管疾病额外保险金
 *  20900000109:  身故保险金或全残保险金
 */

const dutyCodelist = ['20900000105', '20900000106', '20900000107', '20900000108', '20900000109']

let responsibility = {
  '000': '1',
  '00020900000105': '2',
  '00020900000106': '3',
  '00020900000107': '4',
  '00020900000108': '5',
  '00020900000109': '6',
  '0002090000010520900000106': '7',
  '0002090000010520900000107': '8',
  '0002090000010520900000108': '9',
  '0002090000010520900000109': '10',
  '0002090000010620900000107': '11',
  '0002090000010620900000108': '12',
  '0002090000010620900000109': '13',
  '0002090000010720900000109': '14',
  '0002090000010820900000109': '15',
  '000209000001052090000010620900000107': '16',
  '000209000001052090000010620900000108': '17',
  '000209000001052090000010620900000109': '18',
  '000209000001052090000010720900000109': '19',
  '000209000001052090000010820900000109': '20',
  '000209000001062090000010720900000109': '21',
  '000209000001062090000010820900000109': '22',
  '00020900000105209000001062090000010720900000109': '23',
  '00020900000105209000001062090000010820900000109': '24',
}

/**
 * 进入
 */
export const checkGFDutySelect = function (riskObj) {
  let str = '000'
  for (let i = 0; i < dutyCodelist.length; i++) {
    for (let j = 0; j < riskObj.dutyList.length; j++) {
      if(dutyCodelist[i] === riskObj.dutyList[j].dutyCode && riskObj.dutyList[j].isSelected){
        str += dutyCodelist[i]
      }
    }
  }
  riskObj.calculateFactor.forEach(item =>{
    if(item.factorType === 'CommonA'){
      item.factorValue = responsibility[str]
      item.factorValueName = responsibility[str]
    }
  })

}
